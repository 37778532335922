var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-box" },
    [
      _c(
        "el-autocomplete",
        {
          staticClass: "search-input",
          attrs: {
            "popper-class": "search-box-popper card-base card-shadow--small",
            "fetch-suggestions": _vm.querySearch,
            placeholder: "Cerca...",
            "trigger-on-focus": true,
            clearable: "",
            "prefix-icon": "el-icon-search",
            size: "small",
          },
          on: { select: _vm.handleSelect },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (props) {
                return [
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(props.item.value)),
                  ]),
                  _c("span", { staticClass: "tags" }, [
                    _vm._v(_vm._s(props.item.tags)),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        },
        [
          _c("template", { slot: "suffix" }, [
            _vm.search
              ? _c("i", {
                  staticClass: "el-input__icon el-icon-circle-close-outline",
                  on: {
                    click: function ($event) {
                      _vm.search = ""
                    },
                  },
                })
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }