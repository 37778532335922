<template>
    <div class="page-configuration scrollable only-y">
        <div class="section">
            <StopWords></StopWords>
        </div>
        <div class="section">
            <BotToken></BotToken>
        </div>
        <div class="section">
            <ThreadLabels></ThreadLabels>
        </div>
        <div class="section">
            <SupportSign></SupportSign>
        </div>
        <div class="text-right">
            <small class="o-050">v{{ version }}</small>
        </div>
    </div>
</template>

<script>
import StopWords from "@/components/Configuration/StopWords"
import BotToken from "@/components/Configuration/BotToken"
import ThreadLabels from "@/components/Configuration/ThreadLabels"
import SupportSign from "@/components/Configuration/SupportSign"

export default {
    name: "Configuration",
    data() {
        return {
            version: process.env.VUE_APP_VERSION
        }
    },
    computed: {},
    methods: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: { StopWords, BotToken, ThreadLabels, SupportSign }
}
</script>

<style lang="scss" scoped>
.page-configuration {
    .section {
        display: block;
        margin-bottom: 30px;
    }
}
</style>
