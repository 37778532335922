<template>
    <div class="reply-box flex column" :class="[size, { opened: opened }]" v-loading="replyLoading">
        <div class="template-box flex column" :class="{ opened: !!replySearch }">
            <div class="template-list box grow scrollable only-y" v-if="replySearch">
                <div v-for="t in filteredTemplates" :key="t._id" class="template" @click="appendTemplate(t)">
                    <div class="flex header">
                        <div class="box grow tags">{{ t.tagsString }}</div>
                    </div>

                    <div class="body" v-html="t.body"></div>
                </div>
            </div>
            <div class="template-search flex box">
                <input v-model="replySearch" type="text" placeholder="Cerca una risposta..." class="box grow" />
                <button @click="replySearch = ''" v-if="replySearch" class="box">
                    <i class="mdi mdi-close-circle-outline"></i>
                </button>
            </div>
        </div>

        <div class="editor-box box grow scrollable only-y">
            <Editor
                :html.sync="replyHtml"
                :text.sync="replyText"
                :list="true"
                :table="false"
                :hiding="false"
                :placeholder="'Rispondi...'"
                ref="editor"
                class=""
            ></Editor>
        </div>

        <div class="toolbar-box text-right flex align-center">
            <div class="attachments box grow">
                <div
                    v-for="(attc, index) in replyAttachments"
                    :key="index"
                    class="block"
                    @click="removeAttachment(index)"
                >
                    <div
                        class="icon image"
                        v-if="attc.isImage"
                        :style="{ backgroundImage: `url(${attc.content})` }"
                    ></div>
                    <div class="icon ext" v-if="!attc.isImage" :style="{ backgroundColor: attc.color }">
                        {{ attc.ext }}
                    </div>
                    <div class="del-btn">
                        <i class="mdi mdi-close-circle-outline"></i>
                    </div>
                </div>
            </div>
            <div class="box" v-if="!disabledAttachmentUpload">
                <FileDrop @change="fileChange" :accept="'*'" :hover="false" :icon="'mdi mdi-attachment'"></FileDrop>
            </div>
            <div class="box">
                <button @click="reset">Reset</button>
            </div>
            <div class="box">
                <button :disabled="!replyText" @click="send">Invia</button>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import utils from "@/utils"
import stc from "string-to-color"
import prettyBytes from "pretty-bytes"
import striptags from "striptags"

import FileDrop from "@/components/common/FileDrop"
import Editor from "@/components/common/Editor"

export default {
    name: "ThreadReplyBox",
    props: {
        opened: { type: Boolean, default: false },
        thread: { type: Object, required: true },
        size: { type: String, default: "large" }
    },
    data() {
        return {
            replySearch: "",
            replyHtml: "",
            replyText: "",
            replyAttachments: [],
            replyLoading: false,
            templates: [],
            supportSign: ""
        }
    },
    computed: {
        accountFirstname() {
            return this.$store.getters.accountFirstname
        },
        accountLastname() {
            return this.$store.getters.accountLastname
        },
        filteredTemplates() {
            return this.replySearch
                ? _.filter(
                      this.templates,
                      t => t.tagsString.toLowerCase().indexOf(this.replySearch.toLowerCase()) !== -1
                  )
                : []
        },
        disabledAttachmentUpload() {
            return this.thread.type === "ticket" && this.replyAttachments.length === 1
        },
        isTicket() {
            return this.thread.type === "ticket"
        }
    },
    methods: {
        fileChange(file) {
            utils.file2Base64(file).then(base64 => {
                let ext = "-"
                if (file.name && file.name.indexOf(".") !== -1) {
                    const parts = file.name.split(".")
                    ext = parts[parts.length - 1].toUpperCase()
                }

                this.replyAttachments.push({
                    type: file.type,
                    size: prettyBytes(file.size || 0),
                    name: file.name,
                    content: base64,
                    ext,
                    color: stc(ext),
                    isImage: file.type.indexOf("image") !== -1
                })
            })
        },
        removeAttachment(index) {
            this.replyAttachments.splice(index, 1)
        },
        reset() {
            this.$refs.editor.reset()
            this.replyAttachments = []
            this.$emit("update:opened", false)
        },
        send() {
            this.replyLoading = true

            let html = this.replyHtml

            if (!this.isTicket) {
                let sign = this.supportSign
                sign = _.replace(sign, "{{firstname}}", this.accountFirstname)
                sign = _.replace(sign, "{{lastname}}", this.accountLastname.charAt(0) + ".")
                html += "<br>" + sign
            }

            // html = `<div style="background:#fff">` + html + "</div>"

            const text = _.trim(_.replace(striptags(html), /&nbsp;/gi, "")) || ""

            const lastInbox = _.findLast(this.thread.children, c => c.admin === false)
            const lastSent = _.findLast(this.thread.children, c => c.admin === true)
            const messageId = lastInbox ? lastInbox.messageId : lastSent.messageId

            let email = {
                to: this.thread.from.address,
                subject: "Re: " + this.thread.subject,
                text,
                html,
                attachments: _.map(this.replyAttachments, a => ({
                    filename: a.name,
                    path: a.content
                    //content: a.content
                    //contentType: a.type,
                    //encoding: "base64"
                })),
                inReplyTo: messageId
            }

            if (this.isTicket) {
                email = {
                    id: messageId,
                    message: html,
                    attachment: null
                }

                if (this.replyAttachments.length) {
                    email.attachment = _.map(this.replyAttachments, a => a.content)[0]
                }
            }

            const method = this.isTicket ? "sendTicketReply" : "send"

            api.emails[method](email)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.$emit("sent", { type: this.thread.type, email })

                        this.reset()

                        this.$notify.success({
                            title: "Fatto!",
                            message: `Risposta inviata correttamente`
                        })
                    } else {
                        this.$notify.error({
                            title: "Ops...",
                            message: "Errore invio risposta"
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$notify.error({
                        title: "Ops...",
                        message: "Errore invio risposta"
                    })
                })
                .finally(() => {
                    this.replyLoading = false
                })
        },
        getTemplates() {
            api.emails
                .getTemplates()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const templates = res.body
                        this.templates = _.map(templates, t => {
                            t.tagsString = (t.tags || []).join(", ")
                            return t
                        }).reverse()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        appendTemplate(template) {
            const name = _.split(this.thread.from.name, " ")[0] || _.split(this.thread.from.address, "@")[0]
            const body = _.replace(template.body, "{{name}}", name)

            this.replyHtml += "<br>" + body
            this.$refs.editor.setContent(this.replyHtml)
            this.replySearch = ""
        },
        getSupportSign() {
            api.common
                .getSupportSign()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.supportSign = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    },
    mounted() {
        this.getTemplates()
        this.getSupportSign()
    },
    created() {},
    beforeDestroy() {},
    components: {
        FileDrop,
        Editor
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";

.reply-box {
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    .template-box {
        transition: all ease 0.2s;

        .template-list {
            margin-bottom: 10px;
            transition: all ease 0.2s;
            padding: 0 10px;

            .template {
                background: rgba($background-color, 0.9);
                margin-bottom: 30px;
                transition: all ease 0.2s;
                border-radius: 5px;
                cursor: pointer;

                .header {
                    padding: 10px 20px;
                    padding-bottom: 0;

                    .tags {
                        font-weight: bold;
                        font-size: 20px;
                    }
                }

                .body {
                    padding: 0 20px;
                    padding-bottom: 10px;

                    :deep {
                        img {
                            max-width: 100%;
                            border-radius: 6px;
                        }
                    }
                }

                &:hover {
                    @extend .card-shadow--small;
                }
            }
        }

        .template-search {
            background: rgba($background-color, 0.9);
            width: calc(100% + 40px);
            border: none;
            outline: none;
            margin-bottom: 14px;
            margin-left: -20px;
            margin-top: -20px;
            transition: all ease 0.2s;

            input {
                width: 100%;
                font-size: 14px;
                padding: 10px 20px;
                border: none;
                outline: none;
                background: transparent;
            }

            button {
                font-size: 14px;
                border: none;
                outline: none;
                background: transparent;
                width: 30px;
                opacity: 0.4;
                padding: 8px 2px;
                padding-right: 10px;
                cursor: pointer;
            }
        }

        &.opened {
            min-height: #{"max(500px, 100%)"};
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            z-index: 1;
            padding: 0 10px;
            padding-top: 20px;
            box-sizing: border-box;

            .template-search {
                margin: 10px;
                width: auto;
                margin-bottom: 20px;
                border-radius: 6px;
            }
        }
    }

    .editor-box {
        :deep {
            .editor {
                height: 100%;

                .editor__content {
                    margin-bottom: 10px;
                    max-height: 400px;
                }
            }
        }
    }

    .toolbar-box {
        margin: -5px;

        .file-drop {
            position: relative;
            height: 34px;
            width: 50px;
            margin: 0 5px;

            :deep {
                label {
                    background: transparent;

                    &:hover {
                        background: rgba($color-primary, 0.1);
                    }

                    i {
                        font-size: 24px;
                        line-height: 24px;
                        color: $color-primary;
                        text-shadow: none;
                    }
                }
            }
        }

        button {
            padding: 6px 12px;
            margin: 5px;
            border: none;
            outline: none;
            background: transparent;
            text-transform: uppercase;
            font-family: inherit;
            color: $color-primary;
            font-weight: bold;
            cursor: pointer;
            border-radius: 4px;
            transition: all 0.25s;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }
            &:not(:disabled) {
                &:hover {
                    background: rgba($color-primary, 0.1);
                }
                &:focus {
                    box-shadow: 0px 0px 0px 2px rgba($color-primary, 0.4) inset;
                }
            }
        }

        .attachments {
            margin: -5px;
            padding-right: 20px;

            .block {
                display: inline-block;
                margin: 5px;
                position: relative;
                text-decoration: none;
                border-radius: 6px;
                overflow: hidden;
                background: rgba(255, 255, 255, 0.5);
                width: 40px;
                min-width: 40px;
                cursor: pointer;

                .icon {
                    height: 40px;
                    line-height: 40px;
                    font-weight: bold;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    text-align: center;
                    font-size: 10px;
                    color: #fff;
                    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
                    border-radius: 6px;
                }

                .del-btn {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.4);
                    color: #fff;
                    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
                    text-align: center;
                    line-height: 40px;
                    font-size: 24px;
                    opacity: 0;
                    transition: all 0.2s;
                }

                &:hover {
                    .del-btn {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.medium,
    &.small {
        display: none;

        &.opened {
            display: inherit;
            min-height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            z-index: 2;
            padding: 20px;
            padding-bottom: 10px;
            box-sizing: border-box;
            border: none;

            :deep {
                .menubar {
                    margin: 0 -1%;
                    margin-bottom: 5px;

                    .menubar__button {
                        font-size: 12px;
                        height: 24px;
                        line-height: 24px;
                        width: 9.1%;
                        margin: 0 1%;
                        margin-bottom: 0.2rem;
                        //min-width: 26px;
                        //max-width: 26px;

                        i {
                            font-size: 14px;
                        }
                    }
                }

                .editor__content {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 0;
                    flex: 1 0;
                    max-height: inherit;
                }
            }
        }
    }
}
</style>
