<template>
    <div class="qna-actions flex">
        <div class="action" v-if="!nested && showGoto" @click="goTo()">
            <el-tooltip :content="`Vai alla ${isAnswer ? 'risposta' : 'domanda'}`" placement="top-end">
                <i class="mdi mdi-comment-question-outline"></i>
            </el-tooltip>
        </div>

        <el-popconfirm
            :title="`Sicura di voler ripristinare la ${typeText} ?`"
            confirmButtonText="Si"
            cancelButtonText="No"
            @confirm="restoreItem"
            confirmButtonClass="el-button--danger"
            v-if="item.suspended && can_PerformQnAActions"
        >
            <div slot="reference">
                <div class="action">
                    <el-tooltip :content="`Ripristina ${typeText}`" placement="top-end">
                        <i class="mdi mdi-restore"></i>
                    </el-tooltip>
                </div>
            </div>
        </el-popconfirm>

        <el-popconfirm
            :title="`Sicura di voler eliminare la ${typeText} ?`"
            confirmButtonText="Si"
            cancelButtonText="No"
            @confirm="deleteItem"
            confirmButtonClass="el-button--danger"
            v-if="can_PerformQnAActions"
        >
            <div slot="reference">
                <div class="action">
                    <el-tooltip :content="`Elimina ${typeText}`" placement="top-end">
                        <i class="mdi mdi-delete-outline"></i>
                    </el-tooltip>
                </div>
            </div>
        </el-popconfirm>

        <div class="action" @click="deleteMute()" v-if="can_PerformQnAActions">
            <el-tooltip :content="`Elimina ${typeText} e silenzia l'utente`" placement="top-end">
                <i class="mdi mdi-volume-off"></i>
            </el-tooltip>
        </div>

        <div class="action" @click="deleteBan()" v-if="can_PerformQnAActions">
            <el-tooltip :content="`Elimina ${typeText} e blocca l'utente`" placement="top-end">
                <i class="mdi mdi-cancel"></i>
            </el-tooltip>
        </div>

        <el-dialog :title="`Elimina ${typeText} e silenzia l'utente`" :visible.sync="dialogDelMuteVisible" width="300">
            <div v-if="item && can_PerformQnAActions" class="report-dialog">
                <div class="mb-20">{{ userFullname }} (#{{ item.user.id }})</div>
                <div class="text-left mb-10">
                    <div class="o-080">Durata in ore</div>
                    <el-input-number v-model="muteDuration" :min="1"></el-input-number>
                </div>
                <div>
                    <el-select v-model="muteReason" placeholder="Motivazione" clearable>
                        <el-option
                            v-for="item of penaltyReasons"
                            :key="item.penalty"
                            :label="item.penalty"
                            :value="item.penalty"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer">
                <el-button size="small" plain @click="dialogDelMuteVisible = false">Chiudi</el-button>
                <el-button @click="mute()" type="warning" size="small" :disabled="muteDuration <= 0">
                    Silenzia
                </el-button>
            </div>
        </el-dialog>

        <el-dialog :title="`Elimina ${typeText} e blocca l'utente`" :visible.sync="dialogDelBanVisible" width="300">
            <div v-if="item && can_PerformQnAActions" class="report-dialog">
                <div class="mb-20">{{ userFullname }} (#{{ item.user.id }})</div>
                <div>
                    <el-select v-model="banReason" placeholder="Motivazione" clearable>
                        <el-option
                            v-for="item of penaltyReasons"
                            :key="item.penalty"
                            :label="item.penalty"
                            :value="item.penalty"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer">
                <el-button size="small" plain @click="dialogDelBanVisible = false">Chiudi</el-button>
                <el-button @click="ban()" type="danger" size="small">Blocca</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/api"

export default {
    name: "QnaActions",
    props: {
        nested: {
            type: Boolean,
            default: false
        },
        showGoto: {
            type: Boolean,
            default: true
        },
        item: {
            type: Object,
            required: true
        },
        itemType: {
            type: String,
            required: true,
            validator: val => ["question", "answer"].indexOf(val) !== -1
        }
    },
    data() {
        return {
            dialogDelMuteVisible: false,
            dialogDelBanVisible: false,
            banReason: "",
            muteReason: "",
            muteDuration: 0
        }
    },
    computed: {
        isQuestion() {
            return this.itemType === "question"
        },
        isAnswer() {
            return this.itemType === "answer"
        },
        typeText() {
            return this.itemType === "question" ? "domanda" : "risposta"
        },
        userFullname() {
            return `${this.item.user.name} ${this.item.user.surname}`
        },
        penaltyReasons() {
            return this.$store.getters.penaltyReasons
        },
        can_PerformQnAActions() {
            return this.$store.getters.can_PerformQnAActions
        }
    },
    methods: {
        goTo() {
            if (this.isQuestion) {
                this.$emit("goToQuestion")
                this.$router.push(`/qna/questions/${this.item.id}`).catch(err => {})
            } else {
                this.$emit("goToAnswer")
                this.$router.push(`/qna/questions/${this.item.qna.id}/${this.item.id}`).catch(err => {})
            }
        },
        restoreItem() {
            this.$emit("restoreItem")

            if (this.isQuestion) {
                this.restoreQuestion()
            } else {
                this.restoreAnswer()
            }
        },
        deleteItem() {
            this.$emit("deleteItem")

            if (this.isQuestion) {
                this.deleteQuestion()
            } else {
                this.deleteAnswer()
            }
        },
        deleteMute() {
            this.$emit("deleteMute")
            this.dialogDelMuteVisible = true
        },
        deleteBan() {
            this.$emit("deleteBan")
            this.dialogDelBanVisible = true
        },

        deleteQuestion() {
            api.qna
                .delete(this.item.id)
                .then(res => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Domanda eliminata con successo`
                    })
                    this.$emit("deleted")
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Si è verificato un errore durante l'eliminazione della domanda"
                    })
                })
        },
        deleteAnswer() {
            api.qna
                .deleteComment(this.item.qna.id, this.item.id)
                .then(res => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Risposta eliminata con successo`
                    })
                    this.$emit("deleted")
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Si è verificato un errore durante l'eliminazione della risposta"
                    })
                })
        },
        restoreQuestion() {
            api.qna
                .restore(this.item.id)
                .then(res => {
                    this.item.suspended = false
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Domanda ripristinata con successo`
                    })
                    this.$emit("restored")
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Si è verificato un errore durante il ripristino della domanda"
                    })
                })
        },
        restoreAnswer() {
            api.qna
                .restoreComment(this.item.qna.id, this.item.id)
                .then(res => {
                    this.item.suspended = false
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Risposta ripristinata con successo`
                    })
                    this.$emit("restored")
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Si è verificato un errore durante il ripristino della risposta"
                    })
                })
        },

        mute() {
            if (this.muteDuration) {
                const muteReason = this.muteReason
                const muteDuration = this.muteDuration
                this.closeMuteBanDialog()

                this.action_muteUser(this.item.user.id, muteReason, muteDuration).then(() => {
                    return this.deleteItem()
                })
            }
        },
        ban() {
            const banReason = this.banReason
            this.closeMuteBanDialog()

            this.action_banUser(this.item.user.id, banReason).then(() => {
                return this.deleteItem()
            })
        },
        action_muteUser(userId, muteReason, muteDuration) {
            this.$message("Sto silenziando l'utente...")

            return api.users
                .mute(userId, muteReason, muteDuration)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `${this.userFullname} (#${userId}) silenziato correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore silenziamento ${this.userFullname} (#${userId})`
                    })
                })
        },

        action_banUser(userId, banReason) {
            this.$message("Sto bloccando l'utente...")

            return api.users
                .ban(userId, banReason)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `${this.userFullname} (#${userId}) bloccato correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore blocco ${this.userFullname} (#${userId})`
                    })
                })
        },

        closeMuteBanDialog() {
            this.dialogDelMuteVisible = false
            this.dialogDelBanVisible = false
            this.banReason = ""
            this.muteReason = ""
            this.muteDuration = 1
        }
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
.qna-actions {
    & > * {
        & + * {
            margin-left: 10px;
        }
    }

    font-size: 20px;
    transition: all 0.2s;

    .action {
        margin-left: 20px;
        cursor: pointer;
        opacity: 0.7;
        outline: none;

        &:first-child {
            margin-left: 0;
        }
    }

    & > span {
        margin: 0;
        margin-left: 20px;

        .action {
            margin-left: 0;
        }
    }

    .report-dialog {
        .el-select {
            width: 100%;
        }
    }
}
</style>
