<template>
    <div class="room-info" v-loading="loading" :class="size" ref="room">
        <resize-observer @notify="__resizeHandler" />

        <div class="flex room-detail" v-if="room">
            <div class="room-image">
                <vue-load-image>
                    <img slot="image" class="image" :src="room.image" />
                    <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                    <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                </vue-load-image>
            </div>

            <div class="info-box">
                <div class="name mr-10">{{ room.name }}</div>

                <div class="detail">
                    <span class="type">
                        {{ room.roomType }}
                    </span>
                    <span class="app">({{ room.applist }})</span>
                    <span class="icons-box">
                        <el-tooltip effect="dark" content="Pubblica" placement="top">
                            <i class="mdi mdi-web ml-10" v-show="room.ispublic"></i>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="Speciale" placement="top">
                            <i class="mdi mdi-star-outline ml-10" v-show="room.isspecial"></i>
                        </el-tooltip>
                    </span>
                </div>

                <div class="date">
                    <span>Creata il: </span>
                    <strong>{{ room.datetime }}</strong>
                </div>

                <div class="description">
                    {{ room.description }}
                </div>
            </div>
        </div>

        <div v-if="room" class="users-list-count">
            Utenti connessi: <strong>{{ room.userscount }}</strong>
        </div>

        <div v-if="room && room.userscount" class="users-list scrollable only-y">
            <div v-for="user in users" :key="user._id" class="user">
                <div class="flex align-center">
                    <div class="propic">
                        <vue-load-image>
                            <img slot="image" class="image" :src="user.image" />
                            <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                            <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                        </vue-load-image>
                    </div>
                    <div class="box info">
                        <div class="username" @click="openUser(user)" :style="{ color: user.color }">
                            @{{ user.username }}
                        </div>
                        <div class="icons-box">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="`Bloccato: ${user.ban_reason || '-'}`"
                                placement="bottom"
                                :disabled="size !== 'large'"
                            >
                                <i class="mdi mdi-cancel mr-10" v-show="user.isBanned"></i>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="`Silenziato fino a: ${user.muted_date || '-'}`"
                                placement="bottom"
                                :disabled="size !== 'large'"
                            >
                                <i class="mdi mdi-volume-off mr-10" v-show="user.isMuted"></i>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="admin"
                                placement="top"
                                :disabled="size !== 'large'"
                            >
                                <i class="mdi mdi-shield-outline mr-10" v-show="user.admin"></i>
                            </el-tooltip>

                            <span v-pure-tooltip.up-right="'iOS'" v-show="user.os === 'ios'" class="mr-10">
                                <i class="mdi mdi-apple"></i>
                            </span>
                            <span v-pure-tooltip.up-right="'Android'" v-show="user.os === 'android'" class="mr-10">
                                <i class="mdi mdi-android"></i>
                            </span>

                            <span v-pure-tooltip.up-right="'Web'" v-show="user.os === 'web'" class="mr-10">
                                <i class="mdi mdi-web"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import VueLoadImage from "vue-load-image"
import { openUserDialog } from "@/common"

export default {
    name: "RoomInfo",
    props: ["id"],
    data() {
        return {
            room: null,
            loading: true,
            users: [],
            usersInterval: null,
            size: "large"
        }
    },
    computed: {},
    watch: {},
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.room) {
                const width = this.$refs.room.offsetWidth

                this.size = "large"
                if (width < 350) {
                    this.size = "small"
                } else if (width < 500) {
                    this.size = "medium"
                }
            }
        },
        parseUsers(user) {
            user.isMuted = user.muted ? this.$dayjs(user.muted, "YYYY-MM-DD HH:mm").isSameOrAfter() : false
            user.muted_date = user.isMuted ? this.$dayjs(user.muted, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm") : ""
            user.isBanned = !!user.banned
            user.image = user.propic_thumb || user.propic || require("@/assets/images/img-not-found.svg")
            user.os = _.toLower(user.os || "web")

            return user
        },
        getRoom() {
            this.loading = true

            api.chat
                .getRooms(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const room = res.body

                        if (!room.image) room.image = require("@/assets/images/img-not-found.svg")

                        room.applist = _.isArray(room.app) ? _.join(room.app, ", ") : room.app

                        room.datetime = this.$dayjs(room.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")

                        this.room = room
                        this.users = _.map(room.users, u => this.parseUsers(u))
                    }
                    this.loading = false
                    this.$emit("loaded", this.room)
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                    this.$emit("error")
                })
        },
        getUsers() {
            api.chat
                .getRooms(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const room = res.body

                        room.users = _.map(room.users, u => this.parseUsers(u))

                        room.userscount = room.users ? room.users.length : 0

                        this.room.userscount = room.userscount
                        this.users = room.users
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openUser(author) {
            if (author.id) {
                openUserDialog({ id: author.id, user: author })
            }
        }
    },
    mounted() {
        this.calcDim()

        if (this.id) {
            this.getRoom()

            this.usersInterval = setInterval(() => {
                this.getUsers()
            }, 2000)
        }
    },
    beforeDestroy() {
        clearInterval(this.usersInterval)
    },
    components: {
        "vue-load-image": VueLoadImage
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";
@import "../../assets/scss/roles";

.room-info {
    .room-image {
        margin-bottom: 20px;

        img {
            border: 5px solid #fff;
            width: 140px;
            border-radius: 50%;
            @extend .card-shadow--small;
            outline: none;
            box-sizing: border-box;
        }
    }

    .info-box {
        margin-left: 30px;

        .name {
            font-weight: bold;
            font-size: 30px;
            word-break: break-word;
            display: inline-block;
            margin-bottom: 10px;
        }

        .detail,
        .date {
            font-weight: bold;
            font-size: 16px;
            opacity: 0.7;
            margin-bottom: 5px;

            .type {
                text-transform: capitalize;
            }
        }
    }

    .users-list-count {
        font-size: 18px;
    }

    .users-list {
        background: rgba($background-color, 0.9);
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        margin-top: 4px;
        max-height: 400px;

        .user {
            @extend .card-base;
            @extend .card-shadow--small;
            display: inline-block;
            font-size: 16px;
            padding: 10px;
            padding-right: 20px;
            margin-right: 15px;
            margin-bottom: 10px;

            .propic {
                margin-right: 10px;

                img {
                    display: block;
                    border: 5px solid #fff;
                    width: 50px;
                    border-radius: 50%;
                    outline: none;
                }
            }

            .username {
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .icons-box {
                [aria-label][data-balloon-length="small"]:after {
                    white-space: nowrap;
                    width: inherit;
                    font-family: inherit !important;
                    font-size: 12px !important;
                }
                i {
                    display: inline;
                }
            }
        }
    }

    &.medium,
    &.small {
        [aria-label][data-balloon-pos="up-right"]:before,
        [aria-label][data-balloon-length="small"]:after {
            display: none;
        }

        .room-detail {
            flex-direction: column;
            -webkit-box-orient: vertical;

            .room-image {
                margin: 0;
                margin-bottom: 30px;

                img {
                    width: 110px;
                    margin: 0 auto;
                    display: block;
                }
            }

            .info-box {
                margin: 0;

                .name {
                    font-size: 22px;
                }
                .date,
                .detail {
                    font-size: 14px;

                    .icons-box {
                        display: block;
                        clear: both;
                        margin-top: 5px;
                        margin-left: -10px;
                    }
                }
                .description {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }

        .users-list-count {
            margin-top: 10px;
            font-size: 16px;
        }

        .users-list {
            .user {
                box-sizing: border-box;
                display: block;
                width: 100%;

                .propic {
                    img {
                        width: 30px;
                    }
                }
                .info {
                    .username {
                        font-size: 14px;
                    }
                    .icons-box {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
