var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qna-actions flex" },
    [
      !_vm.nested && _vm.showGoto
        ? _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.goTo()
                },
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `Vai alla ${
                      _vm.isAnswer ? "risposta" : "domanda"
                    }`,
                    placement: "top-end",
                  },
                },
                [_c("i", { staticClass: "mdi mdi-comment-question-outline" })]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.item.suspended && _vm.can_PerformQnAActions
        ? _c(
            "el-popconfirm",
            {
              attrs: {
                title: `Sicura di voler ripristinare la ${_vm.typeText} ?`,
                confirmButtonText: "Si",
                cancelButtonText: "No",
                confirmButtonClass: "el-button--danger",
              },
              on: { confirm: _vm.restoreItem },
            },
            [
              _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: `Ripristina ${_vm.typeText}`,
                          placement: "top-end",
                        },
                      },
                      [_c("i", { staticClass: "mdi mdi-restore" })]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.can_PerformQnAActions
        ? _c(
            "el-popconfirm",
            {
              attrs: {
                title: `Sicura di voler eliminare la ${_vm.typeText} ?`,
                confirmButtonText: "Si",
                cancelButtonText: "No",
                confirmButtonClass: "el-button--danger",
              },
              on: { confirm: _vm.deleteItem },
            },
            [
              _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: `Elimina ${_vm.typeText}`,
                          placement: "top-end",
                        },
                      },
                      [_c("i", { staticClass: "mdi mdi-delete-outline" })]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.can_PerformQnAActions
        ? _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.deleteMute()
                },
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `Elimina ${_vm.typeText} e silenzia l'utente`,
                    placement: "top-end",
                  },
                },
                [_c("i", { staticClass: "mdi mdi-volume-off" })]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.can_PerformQnAActions
        ? _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.deleteBan()
                },
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `Elimina ${_vm.typeText} e blocca l'utente`,
                    placement: "top-end",
                  },
                },
                [_c("i", { staticClass: "mdi mdi-cancel" })]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `Elimina ${_vm.typeText} e silenzia l'utente`,
            visible: _vm.dialogDelMuteVisible,
            width: "300",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDelMuteVisible = $event
            },
          },
        },
        [
          _vm.item && _vm.can_PerformQnAActions
            ? _c("div", { staticClass: "report-dialog" }, [
                _c("div", { staticClass: "mb-20" }, [
                  _vm._v(
                    _vm._s(_vm.userFullname) +
                      " (#" +
                      _vm._s(_vm.item.user.id) +
                      ")"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "text-left mb-10" },
                  [
                    _c("div", { staticClass: "o-080" }, [
                      _vm._v("Durata in ore"),
                    ]),
                    _c("el-input-number", {
                      attrs: { min: 1 },
                      model: {
                        value: _vm.muteDuration,
                        callback: function ($$v) {
                          _vm.muteDuration = $$v
                        },
                        expression: "muteDuration",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Motivazione", clearable: "" },
                        model: {
                          value: _vm.muteReason,
                          callback: function ($$v) {
                            _vm.muteReason = $$v
                          },
                          expression: "muteReason",
                        },
                      },
                      _vm._l(_vm.penaltyReasons, function (item) {
                        return _c("el-option", {
                          key: item.penalty,
                          attrs: { label: item.penalty, value: item.penalty },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogDelMuteVisible = false
                    },
                  },
                },
                [_vm._v("Chiudi")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: "small",
                    disabled: _vm.muteDuration <= 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.mute()
                    },
                  },
                },
                [_vm._v(" Silenzia ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `Elimina ${_vm.typeText} e blocca l'utente`,
            visible: _vm.dialogDelBanVisible,
            width: "300",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDelBanVisible = $event
            },
          },
        },
        [
          _vm.item && _vm.can_PerformQnAActions
            ? _c("div", { staticClass: "report-dialog" }, [
                _c("div", { staticClass: "mb-20" }, [
                  _vm._v(
                    _vm._s(_vm.userFullname) +
                      " (#" +
                      _vm._s(_vm.item.user.id) +
                      ")"
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Motivazione", clearable: "" },
                        model: {
                          value: _vm.banReason,
                          callback: function ($$v) {
                            _vm.banReason = $$v
                          },
                          expression: "banReason",
                        },
                      },
                      _vm._l(_vm.penaltyReasons, function (item) {
                        return _c("el-option", {
                          key: item.penalty,
                          attrs: { label: item.penalty, value: item.penalty },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogDelBanVisible = false
                    },
                  },
                },
                [_vm._v("Chiudi")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.ban()
                    },
                  },
                },
                [_vm._v("Blocca")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }