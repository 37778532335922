<template>
    <div class="child" :class="[size]">
        <resize-observer @notify="__resizeHandler" />
        <div class="header flex justify-space-between">
            <div class="user flex">
                <div class="flex column">
                    <div class="initials" :style="{ backgroundColor: child.color }">
                        {{ child.initials }}
                    </div>
                </div>
                <div class="flex column pl-20 break-word">
                    <div>
                        <strong class="name" v-if="child && child.from">
                            {{ child.from.name || child.from.address }}
                        </strong>
                        <span
                            class="clickable username"
                            v-if="child.userData && child.userData.id"
                            @click="openUser(child.userData)"
                        >
                            @{{ child.userData.username }}
                        </span>
                    </div>
                    <span v-if="child.from && child.from.name && child.from.address" class="address">
                        {{ child.from.address }}
                    </span>
                </div>
            </div>
            <div class="info">
                <div class="datetime">
                    {{ child.date | dayjs("format", "DD MMMM YYYY HH:mm") }}
                </div>
                <div v-if="child.attachments && child.attachments.length" class="has-attachments">
                    <strong>{{ child.attachments.length }}</strong>
                    <i class="mdi mdi-attachment"></i>
                </div>
            </div>
        </div>

        <div class="content" v-if="body">
            <iframe
                ref="iframe"
                :style="{
                    height: iframeHeight + 'px'
                }"
            ></iframe>
            <!--<VueReadMoreSmooth :lines="7" :max-lines="10" :text="'Espandi'" :text-less="'Mostra meno'">
				<div v-html="body"></div>
			</VueReadMoreSmooth>-->
        </div>

        <div class="attachments" v-if="child.attachments && child.attachments.length">
            <a v-for="attc in child.attachments" :key="attc.checksum" class="block" :href="attc.url" target="_blank">
                <div class="flex">
                    <div class="preview box">
                        <div
                            class="icon image"
                            v-if="attc.isImage"
                            :style="{ backgroundImage: `url(${attc.url})` }"
                        ></div>
                        <div class="icon ext" v-if="!attc.isImage" :style="{ backgroundColor: attc.color }">
                            {{ attc.ext }}
                        </div>
                    </div>
                    <div class="info box grow flex column justify-space-between">
                        <div class="filename">
                            {{ attc.filename }}
                        </div>
                        <div class="size">{{ attc.size }}</div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { openUserDialog } from "@/common"
import VueReadMoreSmooth from "vue-read-more-smooth"
import _ from "lodash"

export default {
    name: "ThreadChild",
    props: ["child", "size"],
    data() {
        return {
            iframeHeight: 0
        }
    },
    computed: {
        body() {
            return this.child.html || this.child.text || ""
        }
        /*iframe() {
			return "data:text/html;charset=utf-8," + encodeURIComponent(this.body)
		}*/
    },
    watch: {},
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.resizeIframe()
        }, 700),
        openUser(user) {
            if (user.id) {
                openUserDialog({ id: user.id, user: user })
            }
        },
        stripHtml(html) {
            return html.replace(/(<style[\w\W]+style>)/g, "")
        },
        setIframe() {
            if (this.$refs.iframe) {
                this.$refs.iframe?.contentWindow?.document?.write(this.body)
            }
        },
        resizeIframe() {
            if (this.$refs.iframe) {
                this.iframeHeight = this.$refs.iframe?.contentWindow?.document?.body?.scrollHeight || 1000
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.resizeIframe()
        }, 1000)
        setTimeout(() => {
            this.resizeIframe()
        }, 2000)

        this.setIframe()
        this.resizeIframe()
    },
    created() {},
    beforeDestroy() {},
    components: { VueReadMoreSmooth }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";

.child {
    margin: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    background: rgba($background-color, 0.7);
    overflow: hidden;
    position: relative;

    .header {
        padding: 20px;

        .user {
            user-select: text;

            .initials {
                --size: 40px;
                width: var(--size);
                height: var(--size);
                color: #fff;
                font-weight: bold;
                font-size: 14px;
                box-sizing: border-box;
                padding-top: 1px;
                border-radius: 50%;
                text-align: center;
                line-height: var(--size);
                text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
            }

            .name {
                margin-right: 10px;
            }
            .address {
                margin-right: 10px;
            }
            .clickable {
                cursor: pointer;
                font-weight: bold;
                text-decoration: underline;
                text-decoration-color: $color-primary;
                color: $color-primary;
            }
        }
        .info {
            .has-attachments {
                font-size: 16px;
                text-align: right;

                i {
                    font-size: 20px;
                    position: relative;
                    top: 1px;
                    margin-left: 10px;
                }
            }
            .datetime {
                opacity: 0.7;
                font-size: 14px;
                padding-left: 20px;
                text-align: right;
            }
        }
    }

    .content {
        padding: 20px;

        iframe {
            width: 100%;
            border: none;
            outline: none;
        }

        :deep {
            .hide-text {
                background-image: linear-gradient(
                    180deg,
                    hsla(0, 0%, 100%, 0) 0,
                    $background-color 90%,
                    $background-color
                ) !important;
            }
            .read-more-button {
                margin: 10px 0;
                width: 100%;

                span {
                    background: $background-color;
                }
            }
        }
    }

    .attachments {
        .block {
            display: inline-block;
            margin: 10px;
            padding: 10px;
            max-width: #{"min(300px, 90%)"};
            position: relative;
            text-decoration: none;
            border-radius: 6px;
            transition: all 0.2s;
            box-sizing: border-box;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.5);

            .preview {
                width: 50px;
                min-width: 50px;

                .icon {
                    height: 50px;
                    line-height: 50px;
                    font-weight: bold;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
                    border-radius: 6px;
                }
            }

            .info {
                min-height: 50px;
                margin-left: 10px;
                overflow: hidden;
                padding: 5px 0;
                box-sizing: border-box;

                .size {
                    font-weight: bold;
                    font-size: 12px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: $color-primary;
                }

                .filename {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: $text-color;
                    font-size: 14px;
                }
            }

            &:hover {
                @extend .card-shadow--small;
                background: #fff;
            }
        }
    }

    &.medium,
    &.small {
        .header {
            flex-direction: column;
            -webkit-box-orient: vertical;

            .datetime {
                padding-top: 10px;
                padding-left: 0;
                text-align: left;
            }

            .has-attachments {
                text-align: left;
            }
        }
    }

    &.small {
        margin: 0;
        margin-bottom: 20px;
        border-radius: 0;
        background: rgba($background-color, 0.7);
    }
}
</style>
