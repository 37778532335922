<template>
    <div class="page-reviews-list flex column" v-loading="loading">
        <div class="reviews-list scrollable only-y box grow">
            <ReviewCard v-for="r in reviews" :key="r._id" :review="r" :handle="false" @click="goTo(r._id)"></ReviewCard>

            <div v-if="!loading && !reviews.length" class="review flex align-center" @click="goTo()">
                <div class="box grow info">
                    <div class="title mt-10">Non sono presenti recensioni in bozza, creane una!</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import ReviewCard from "@/components/Reviews/ReviewCard"

export default {
    name: "ReviewsDraft",
    data() {
        return {
            reviews: [],
            loading: true
        }
    },
    computed: {},
    methods: {
        getList() {
            this.loading = true
            api.reviews
                .getDraft()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.reviews = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        goTo(id) {
            this.$router.push(`/reviews/${id || ""}`).catch(err => {})
        }
    },
    created() {},
    mounted() {
        this.getList()
    },
    beforeDestroy() {},
    components: { ReviewCard }
}
</script>

<style lang="scss" scoped>
.page-reviews-list {
    user-select: none;
    padding-bottom: 0px !important;

    .reviews-list {
        padding: 0 10px;
        margin-left: -10px;
        margin-right: -15px;
    }
}
</style>
