<template>
    <div class="questions-page flex column">
        <QuestionList class="box grow" :show-reported="reported"></QuestionList>
    </div>
</template>

<script>
import QuestionList from "@/components/Qna/QuestionList"

export default {
    name: "QuestionsPage",
    data() {
        return {
            reported: false
        }
    },
    mounted() {
        const query_type = _.get(this.$route, "query.type")
        if (query_type === "reported") {
            this.reported = true
        }
    },
    components: {
        QuestionList
    }
}
</script>

