var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _vm._v(
      _vm._s(_vm.count) +
        " richieste, " +
        _vm._s(_vm.notSeenCount) +
        " non lette"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }