var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-user scrollable only-y" },
    [
      _c("div", { staticClass: "buttons-box" }, [
        _vm.showGoToBack
          ? _c(
              "div",
              {
                staticClass: "go-back-btn mr-30",
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-arrow-left" }),
                _vm._v(" indietro "),
              ]
            )
          : _vm._e(),
      ]),
      _vm.$route.params.id
        ? _c("UserInfo", {
            staticClass: "mb-60",
            attrs: { id: _vm.$route.params.id },
            on: { loaded: _vm.setUser, "user-deleted": _vm.hardGoToList },
          })
        : _vm._e(),
      _vm.user.username
        ? _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Cloud", name: "cloud" } },
                [
                  _vm.$route.params.id && _vm.user.username
                    ? _c("UserCloud", {
                        attrs: {
                          id: _vm.$route.params.id,
                          "flow-duration": _vm.user.flow_duration,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Bambini", name: "children" } },
                [
                  _vm.$route.params.id && _vm.user.username
                    ? _c("UserChildren", {
                        attrs: { id: _vm.$route.params.id },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Domande", name: "questions" } },
                [_c("QuestionList", { attrs: { user: _vm.userId } })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Risposte", name: "answers" } },
                [_c("AnswerList", { attrs: { user: _vm.userId } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }