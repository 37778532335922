var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor-html" },
    [
      _c("codemirror", {
        ref: "cmEditor",
        attrs: { value: _vm.html, options: _vm.cmOptions },
        on: { input: _vm.onCmCodeChange },
      }),
      _vm.preview
        ? _c("div", [
            _c("div", { staticClass: "label" }, [_vm._v("Anteprima")]),
            _c("div", {
              staticClass: "preview",
              domProps: { innerHTML: _vm._s(_vm.html) },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }