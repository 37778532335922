var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-dashboard scrollable only-y" },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xs: 24,
                    sm: _vm.can_ViewReviewsCountWidget ? 12 : 8,
                    md: _vm.can_ViewReviewsCountWidget ? 12 : 8,
                    lg: _vm.can_ViewReviewsCountWidget ? 6 : 8,
                    xl: _vm.can_ViewReviewsCountWidget ? 6 : 8,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: !_vm.loadedInfo,
                          expression: "!loadedInfo",
                        },
                      ],
                      staticClass: "widget p-20 mb-10 clickable",
                      on: {
                        click: function ($event) {
                          return _vm.goTo("/users")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text-uppercase flex" }, [
                        _c("div", { staticClass: "icon-box accent-text" }, [
                          _c("i", { staticClass: "mdi mdi-account-outline" }),
                        ]),
                        _c("div", { staticClass: "box grow" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Utenti"),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.info.users_count)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xs: 24,
                    sm: _vm.can_ViewReviewsCountWidget ? 12 : 8,
                    md: _vm.can_ViewReviewsCountWidget ? 12 : 8,
                    lg: _vm.can_ViewReviewsCountWidget ? 6 : 8,
                    xl: _vm.can_ViewReviewsCountWidget ? 6 : 8,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: !_vm.loadedReports,
                          expression: "!loadedReports",
                        },
                      ],
                      staticClass: "widget p-20 mb-10 clickable",
                      on: {
                        click: function ($event) {
                          return _vm.goTo("/chat/reports?type=reported")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text-uppercase flex" }, [
                        _c("div", { staticClass: "icon-box accent-text" }, [
                          _c("i", {
                            staticClass: "mdi mdi-message-alert-outline",
                          }),
                        ]),
                        _c("div", { staticClass: "box grow" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Segnalazioni"),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.info.reportedCount)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xs: 24,
                    sm: _vm.can_ViewReviewsCountWidget ? 12 : 8,
                    md: _vm.can_ViewReviewsCountWidget ? 12 : 8,
                    lg: _vm.can_ViewReviewsCountWidget ? 6 : 8,
                    xl: _vm.can_ViewReviewsCountWidget ? 6 : 8,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: !_vm.loadedReports,
                          expression: "!loadedReports",
                        },
                      ],
                      staticClass: "widget p-20 mb-10 clickable",
                      on: {
                        click: function ($event) {
                          return _vm.goTo("/chat/reports?type=suspicious")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text-uppercase flex" }, [
                        _c("div", { staticClass: "icon-box accent-text" }, [
                          _c("i", {
                            staticClass: "mdi mdi-comment-search-outline",
                          }),
                        ]),
                        _c("div", { staticClass: "box grow" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Sospetti"),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.info.suspiciousCount)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm.can_ViewReviewsCountWidget
                ? _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: !_vm.loadedInfo,
                              expression: "!loadedInfo",
                            },
                          ],
                          staticClass: "widget p-20 mb-10 clickable",
                          on: {
                            click: function ($event) {
                              return _vm.goTo("/reviews/published")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "text-uppercase flex" }, [
                            _c("div", { staticClass: "icon-box accent-text" }, [
                              _c("i", {
                                staticClass: "mdi mdi-file-check-outline",
                              }),
                            ]),
                            _c("div", { staticClass: "box grow" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("Recensioni"),
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.info.reviews)),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } }, [
            _c("h3", { staticClass: "mt-40 mb-0" }, [
              _vm._v(" Segnalazioni in evidenza "),
              _vm.pinnedCount
                ? _c("small", { staticClass: "o-050" }, [
                    _vm._v("(" + _vm._s(_vm.pinnedCount) + ")"),
                  ])
                : _vm._e(),
              _vm.pinnedCount ? _c("span", [_vm._v(" - ")]) : _vm._e(),
              _vm.pinnedCount
                ? _c(
                    "span",
                    {
                      staticClass: "text-clickable",
                      on: {
                        click: function ($event) {
                          return _vm.goTo("/chat/reports?type=pinned")
                        },
                      },
                    },
                    [_vm._v(" vedi tutte ")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "widget p-20 card-base card-alt mt-10 card-shadow--small",
              },
              [
                _c("ReportsPinned", {
                  attrs: { count: _vm.pinnedCount },
                  on: {
                    "update:count": function ($event) {
                      _vm.pinnedCount = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } }, [
            !_vm.hideOnTest
              ? _c("h3", { staticClass: "mt-40 mb-0" }, [
                  _vm._v(" Ultime Richieste non lette "),
                  _vm.emailsCount
                    ? _c("small", { staticClass: "o-050" }, [
                        _vm._v("(" + _vm._s(_vm.emailsCount) + ")"),
                      ])
                    : _vm._e(),
                  _vm.emailsCount ? _c("span", [_vm._v(" - ")]) : _vm._e(),
                  _vm.emailsCount
                    ? _c(
                        "span",
                        {
                          staticClass: "text-clickable",
                          on: {
                            click: function ($event) {
                              return _vm.goTo("/support/requests?type=notSeen")
                            },
                          },
                        },
                        [_vm._v(" vedi tutte ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            !_vm.hideOnTest
              ? _c(
                  "div",
                  {
                    staticClass:
                      "widget p-20 card-base card-alt mt-10 card-shadow--small",
                  },
                  [
                    _c("EmailsNotSeen", {
                      attrs: { count: _vm.emailsCount },
                      on: {
                        "update:count": function ($event) {
                          _vm.emailsCount = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } }, [
            _c("h3", { staticClass: "mt-40 mb-0" }, [
              _vm._v(" Domande segnalate "),
              _vm.questionsReportedCount
                ? _c("small", { staticClass: "o-050" }, [
                    _vm._v("(" + _vm._s(_vm.questionsReportedCount) + ")"),
                  ])
                : _vm._e(),
              _vm.questionsReportedCount
                ? _c("span", [_vm._v(" - ")])
                : _vm._e(),
              _vm.questionsReportedCount
                ? _c(
                    "span",
                    {
                      staticClass: "text-clickable",
                      on: {
                        click: function ($event) {
                          return _vm.goTo("/qna/questions?type=reported")
                        },
                      },
                    },
                    [_vm._v(" vedi tutte ")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "widget p-20 card-base card-alt mt-10 card-shadow--small",
              },
              [
                _c("QuestionsReported", {
                  attrs: { count: _vm.questionsReportedCount },
                  on: {
                    "update:count": function ($event) {
                      _vm.questionsReportedCount = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } }, [
            _c("h3", { staticClass: "mt-40 mb-0" }, [
              _vm._v(" Risposte segnalate "),
              _vm.answersReportedCount
                ? _c("small", { staticClass: "o-050" }, [
                    _vm._v("(" + _vm._s(_vm.answersReportedCount) + ")"),
                  ])
                : _vm._e(),
              _vm.answersReportedCount ? _c("span", [_vm._v(" - ")]) : _vm._e(),
              _vm.answersReportedCount
                ? _c(
                    "span",
                    {
                      staticClass: "text-clickable",
                      on: {
                        click: function ($event) {
                          return _vm.goTo("/qna/answers?type=reported")
                        },
                      },
                    },
                    [_vm._v(" vedi tutte ")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "widget p-20 card-base card-alt mt-10 card-shadow--small",
              },
              [
                _c("AnswersReported", {
                  attrs: { count: _vm.answersReportedCount },
                  on: {
                    "update:count": function ($event) {
                      _vm.answersReportedCount = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } }, [
            _c("h3", { staticClass: "mt-40 mb-0" }, [
              _vm._v("Utenti online in chat"),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "widget card-base card-alt mt-10 card-shadow--small",
              },
              [_c("ChatChart")],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }