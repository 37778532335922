var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "box-logo flex align-center",
      class: { "nav-collapsed": _vm.collapseNav, [_vm.mode]: true },
    },
    [
      _c("img", {
        staticClass: "image-logo",
        attrs: { src: require("@/assets/images/icon-mini.svg"), alt: "logo" },
      }),
      _c("img", {
        staticClass: "image-text-logo",
        attrs: {
          src: require("@/assets/images/logo_h_mini-v2.svg"),
          alt: "logo",
        },
        on: {
          click: function ($event) {
            return _vm.goto("/dashboard")
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }