<template>
	<div class="room flex card-base card-shadow--small" @click="open" :class="{ active: active }">
		<div class="room-users-count">{{ room.userscount || room.usersCount || 0 }}</div>
		<div class="room-name box grow">{{ room.name }}</div>
		<div class="room-special" v-if="room.isspecial || room.special"><i class="mdi mdi-star-outline"></i></div>
		<div class="room-info" @click.stop="openRoomInfo(room._id)">
			<i class="mdi mdi-information-outline"></i>
		</div>
	</div>
</template>

<script>
import { openRoomDialog } from "@/common"

export default {
	name: "RoomCard",
	props: ["room", "active"],
	methods: {
		open() {
			this.$emit("open")
		},
		openRoomInfo(id) {
			if (id) {
				openRoomDialog({ id })
			}
		}
	},
	created() {},
	mounted() {},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";

.room {
	overflow: visible !important;
	margin: 10px 14px;
	padding: 10px 0px;
	border-right: 5px solid transparent;
	cursor: pointer;
	transition: all ease 0.25s;

	.room-name {
		font-size: 16px;
		padding-top: 4px;
		padding-right: 10px;
	}
	.room-users-count {
		background: $color-primary;
		color: #fff;
		font-size: 12px;
		font-family: monospace;
		font-weight: bold;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		text-align: center;
		line-height: 28px;
		margin-left: -14px;
		margin-right: 10px;
		box-shadow: 0 3px 6px 0 rgba($color-primary, 0.35), 0 1px 1px 0 rgba(255, 255, 255, 0.3);
	}
	.room-special {
		color: $color-warning;
		padding: 0 10px;
		padding-top: 2px;
		font-size: 18px;
	}
	.room-info {
		cursor: pointer;
		color: $color-primary;
		padding: 0 10px;
		padding-top: 2px;
		font-size: 18px;
	}

	&.active {
		border-color: $color-primary;
	}

	&:hover {
		@extend .card-shadow--medium;
	}
}
</style>