<template>
    <div class="years-select">
        <el-select v-model="yearSelected" placeholder="Anno" @change="$emit('update:year', $event)">
            <el-option v-for="year of years" :key="year" :label="year" :value="year"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    name: "YearsSelect",
    props: ["years", "year"],
    data() {
        return {
            yearSelected: null
        }
    },
    watch: {
        year(val) {
            this.yearSelected = val
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.years-select {
    .el-select {
        :deep {
            .el-input {
                margin-bottom: 20px;

                input {
                    border: none;
                    background: transparent;
                    font-size: 56px;
                    font-weight: 700;
                    line-height: 1;
                    font-family: inherit;
                    color: $text-color;
                    padding-left: 0;
                    height: 50px;
                    width: 180px;
                }
                .el-input__suffix {
                    .el-select__caret {
                        color: $text-color;
                        font-size: 24px;
                    }
                }

                @media (max-width: 1000px) {
                    margin-bottom: 0px;

                    input {
                        font-size: 40px;
                        width: 140px;
                    }
                }

                @media (max-width: 400px) {
                    input {
                        font-size: 30px;
                        width: 100px;
                    }
                    .el-input__suffix {
                        .el-select__caret {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
</style>
