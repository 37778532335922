<template>
    <div class="cloud-item card-base card-shadow--small">
        <div class="date">
            <strong class="text-capitalized">{{ data.date | dayjs("format", "DD MMMM") }}</strong>
            <span class="ml-5">{{ data.date | dayjs("format", "YYYY") }}</span>
            <span class="ml-5">alle</span>
            <strong class="text-capitalized ml-5">{{ data.date | dayjs("format", "HH:mm") }}</strong>
        </div>
        <div class="info">
            <div v-if="type === 'bath'">
                <strong class="text-capitalized">{{ bathType }}</strong>
            </div>
            <div v-if="type === 'biberon'">
                <strong>{{ data.amount }} ml</strong>
            </div>
            <div v-if="type === 'breastfeeding'">
                <span>Seno </span>
                <strong>{{ breast }}</strong>
                <span> per </span>
                <strong>{{ breastDuration }}</strong>
            </div>
            <div v-if="type === 'diaper'">
                <strong class="text-capitalized">{{ diaperType }}</strong>
            </div>
            <div v-if="type === 'meal'">
                <strong class="text-capitalized">{{ mealType }}</strong>
                <span>, </span>
                <strong class="text-capitalized">{{ mealCategory }}</strong>
            </div>
            <div v-if="type === 'sleep'">
                <span>fino alle </span>
                <strong>{{ data.date_end | dayjs("format", "HH:mm") }}</strong>
                <span> per </span>
                <strong>{{ sleepDuration }}</strong>
            </div>
            <div v-if="type === 'teeth'">
                <span>Arcata </span>
                <strong>{{ teethType }}</strong>
                <span>, </span>
                <strong>{{ teethPosition }}</strong>
            </div>
        </div>
        <div class="notes" v-if="data.notes">
            {{ data.notes }}
        </div>
    </div>
</template>

<script>
import _ from "lodash"

const EnumBathType = {
    bath: "Bagnetto",
    both: "Misto",
    massage: "Massaggio"
}
const EnumBreast = {
    left: "Sinistro",
    right: "Destro"
}
const EnumDiaperType = {
    clean: "Pulito",
    pee: "Pipì",
    poo: "Popò",
    both: "Misto"
}
const EnumMealType = {
    meal: "Pappa",
    snack: "Merenda"
}
const EnumMealCategory = {
    meat: "Carne",
    fish: "Pesce",
    vegetables: "Verdure",
    fruit: "Frutta"
}
const EnumTeethType = {
    sup: "Superiore",
    inf: "Inferiore"
}
const ListTeethPosition = [
    "Secondo Molare Sinistro",
    "Primo Molare Sinistro",
    "Canino Sinistro",
    "Incisivo Laterale Sinistro",
    "Incisivo Centrale Sinistro",
    "Incisivo Centrale Destro",
    "Incisivo Laterale Destro",
    "Canino Destro",
    "Primo Molare Destro",
    "Secondo Molare Destro"
]

export default {
    name: "CloudItem",
    props: {
        data: Object,
        type: {
            type: String,
            required: true,
            validator: val =>
                ["bath", "biberon", "breastfeeding", "diaper", "meal", "sleep", "teeth"].indexOf(val) !== -1
        }
    },
    data() {
        return {}
    },
    computed: {
        bathType() {
            return _.get(EnumBathType, this.data?.type, "")
        },
        breast() {
            return _.get(EnumBreast, this.data?.breast, "")
        },
        breastDuration() {
            if (this.type === "breastfeeding" && this.data?.duration) {
                return this.getDuration(this.data?.duration)
            }
            return ""
        },
        diaperType() {
            return _.get(EnumDiaperType, this.data?.type, "")
        },
        mealType() {
            return _.get(EnumMealType, this.data?.type, "")
        },
        mealCategory() {
            return _.get(EnumMealCategory, this.data?.category, "")
        },
        sleepDuration() {
            if (this.type === "sleep" && this.data?.duration) {
                return this.getDuration(this.data?.duration)
            }
            return ""
        },
        teethType() {
            return _.get(EnumTeethType, this.data?.type, "")
        },
        teethPosition() {
            return _.get(ListTeethPosition, this.data?.position, "")
        }
    },
    watch: {},
    methods: {
        getDuration(duration) {
            const iso = this.$dayjs.duration(duration)?.$d
            let text = ""
            if (iso.hours) {
                text += iso.hours + "h "
            }
            if (iso.minutes) {
                text += iso.minutes + "m "
            }
            if (iso.seconds) {
                text += iso.seconds + "s "
            }

            return text
        }
    },
    mounted() {},
    components: {}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.cloud-item {
    display: inline-block;
    vertical-align: top;

    .date {
        padding: 10px 20px;
    }
    .info {
        padding: 10px 20px;
    }
    .notes {
        padding: 10px 20px;
        background: $background-color;
    }
}
</style>
