<template>
	<div class="room-info" v-if="room">
		<div>
			<span>{{ room.roomType }}</span>
			<span>/</span>
			<strong>{{ room.name }}</strong>
			<span class="badges">
				<span class="users-count">{{ room.usersCount || room.userscount }}</span>
				<i class="mdi mdi-star-outline" v-if="room.special || room.isspecial"></i>
				<i class="mdi mdi-information-outline" @click.stop="openRoomInfo(room._id)"></i>
			</span>
		</div>
		<div v-if="typingList && typingList.length" class="mt-10">
			{{ typingText }}
		</div>
	</div>
</template>

<script>
import { openRoomDialog } from "@/common"

export default {
	name: "RoomHeader",
	props: ["room", "typingList", "typingText"],

	methods: {
		openRoomInfo(id) {
			if (id) {
				openRoomDialog({ id })
			}
		}
	},
	mounted() {},
	beforeDestroy() {},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.room-info {
	border-bottom: 1px solid rgba($text-color, 0.15);
	text-align: center;
	padding: 10px;

	span {
		margin: 0 4px;

		&:first-child {
			text-transform: capitalize;
		}
	}
	.badges {
		display: none;
		margin-left: 20px;

		.users-count {
			background: $color-primary;
			font-weight: bold;
			padding: 1px 7px;
			line-height: 18px;
			display: inline-block;
			border-radius: 20px;
			margin: 0;
			color: #fff;
			font-size: 14px;
			font-family: monospace;
		}

		i {
			line-height: 1;
			font-size: 18px;
			margin-left: 10px;
			display: inline-block;
			position: relative;
			top: 2px;

			&.mdi-star-outline {
				color: $color-warning;
			}
			&.mdi-information-outline {
				color: $color-primary;
			}
		}
	}
}
</style>
