var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor flex column" },
    [
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor, "keep-in-bounds": _vm.keepInBounds },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ commands, isActive, focused, getMarkAttrs }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "menubar box",
                    class: {
                      "is-focused": focused && _vm.hiding,
                      "is-hidden": _vm.hiding,
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.bold() },
                        on: { click: commands.bold },
                      },
                      [_c("i", { staticClass: "mdi mdi-format-bold" })]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.italic() },
                        on: { click: commands.italic },
                      },
                      [_c("i", { staticClass: "mdi mdi-format-italic" })]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.strike() },
                        on: { click: commands.strike },
                      },
                      [_c("i", { staticClass: "mdi mdi-format-strikethrough" })]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "menubar__button",
                        class: { "is-active": isActive.underline() },
                        on: { click: commands.underline },
                      },
                      [_c("i", { staticClass: "mdi mdi-format-underline" })]
                    ),
                    _vm.heading
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            class: {
                              "is-active": isActive.heading({ level: 1 }),
                            },
                            on: {
                              click: function ($event) {
                                return commands.heading({ level: 1 })
                              },
                            },
                          },
                          [_vm._v(" H1 ")]
                        )
                      : _vm._e(),
                    _vm.heading
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            class: {
                              "is-active": isActive.heading({ level: 2 }),
                            },
                            on: {
                              click: function ($event) {
                                return commands.heading({ level: 2 })
                              },
                            },
                          },
                          [_vm._v(" H2 ")]
                        )
                      : _vm._e(),
                    _vm.heading
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            class: {
                              "is-active": isActive.heading({ level: 3 }),
                            },
                            on: {
                              click: function ($event) {
                                return commands.heading({ level: 3 })
                              },
                            },
                          },
                          [_vm._v(" H3 ")]
                        )
                      : _vm._e(),
                    _vm.list
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            class: { "is-active": isActive.bullet_list() },
                            on: { click: commands.bullet_list },
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-format-list-bulleted",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.list
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            class: { "is-active": isActive.ordered_list() },
                            on: { click: commands.ordered_list },
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-format-list-bulleted-type",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.table
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            on: {
                              click: function ($event) {
                                return commands.createTable({
                                  rowsCount: 3,
                                  colsCount: 3,
                                  withHeaderRow: false,
                                })
                              },
                            },
                          },
                          [_c("i", { staticClass: "mdi mdi-table" })]
                        )
                      : _vm._e(),
                    isActive.table() && _vm.table
                      ? _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.deleteTable },
                            },
                            [_c("i", { staticClass: "mdi mdi-table-remove" })]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.addColumnBefore },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-column-plus-before",
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.addColumnAfter },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-column-plus-after",
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.deleteColumn },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-column-remove",
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.addRowBefore },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-row-plus-before",
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.addRowAfter },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-row-plus-after",
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.deleteRow },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-row-remove",
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: { click: commands.toggleCellMerge },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-table-split-cell",
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.history
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            on: { click: commands.undo },
                          },
                          [_c("i", { staticClass: "mdi mdi-undo" })]
                        )
                      : _vm._e(),
                    _vm.history
                      ? _c(
                          "button",
                          {
                            staticClass: "menubar__button",
                            on: { click: commands.redo },
                          },
                          [_c("i", { staticClass: "mdi mdi-redo" })]
                        )
                      : _vm._e(),
                    _vm.link
                      ? _c("div", [
                          _vm.linkMenuIsActive
                            ? _c(
                                "form",
                                {
                                  staticClass: "menubar__form",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.setLinkUrl(
                                        commands.link,
                                        _vm.linkUrl
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.linkUrl,
                                        expression: "linkUrl",
                                      },
                                    ],
                                    ref: "linkInput",
                                    staticClass: "menubar__input",
                                    attrs: {
                                      type: "text",
                                      placeholder: "https://",
                                    },
                                    domProps: { value: _vm.linkUrl },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "esc",
                                            27,
                                            $event.key,
                                            ["Esc", "Escape"]
                                          )
                                        )
                                          return null
                                        return _vm.hideLinkMenu.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.linkUrl = $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "menubar__button",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setLinkUrl(
                                            commands.link,
                                            null
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "mdi mdi-close" })]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.linkMenuIsActive && _vm.isSelected
                            ? _c(
                                "button",
                                {
                                  staticClass: "menubar__button",
                                  class: { "is-active": isActive.link() },
                                  on: {
                                    click: function ($event) {
                                      _vm.showLinkMenu(getMarkAttrs("link"))
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "mdi mdi-link-variant",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("editor-content", {
        staticClass: "editor__content box grow scrollable only-y",
        attrs: { editor: _vm.editor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }