var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "box", staticClass: "question-list flex column" },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _vm.filtersEnabled
        ? _c("QnaFilter", {
            staticClass: "box",
            attrs: { filter: _vm.filter, showCategory: true },
            on: { change: _vm.resetPage },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "list-wrap box grow flex column",
        },
        [
          _c(
            "div",
            { ref: "list", staticClass: "list-box box grow scrollable only-y" },
            [
              !_vm.questions.length && !_vm.loading
                ? _c("div", [
                    _c("p", [
                      _vm._v("Non sono presenti domande "),
                      _vm.pagination.page > 1
                        ? _c("span", [_vm._v("in questa pagina")])
                        : _vm._e(),
                      _vm._v("."),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.questions, function (question) {
                return _c("Question", {
                  key: question.id,
                  attrs: { question: question, size: _vm.size },
                  on: { deleted: _vm.getQuestions, restored: _vm.getQuestions },
                })
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-pagination",
        {
          ref: "pagination",
          staticClass: "card-base box card-shadow--small",
          attrs: {
            small: _vm.pagination.small,
            "current-page": _vm.pagination.page,
            "page-sizes": _vm.pagination.sizes,
            "page-size": _vm.pagination.size,
            layout: _vm.layout,
            total: _vm.pagination.total,
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.pagination, "page", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.pagination, "page", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.pagination, "size", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.pagination, "size", $event)
            },
            "size-change": _vm.getQuestions,
            "current-change": _vm.getQuestions,
            "prev-click": _vm.getQuestions,
            "next-click": _vm.getQuestions,
          },
        },
        [
          _c("ul", { staticClass: "el-pager" }, [
            _c("li", { staticClass: "number active" }, [
              _vm._v(_vm._s(_vm.pagination.page)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }