var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-chat-institutional scrollable only-y" },
    [
      _c("MsgInstitutionalForm", {
        ref: "form",
        on: { sent: _vm.getInstitutionalMessages },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingMessages,
              expression: "loadingMessages",
            },
          ],
          staticClass: "history",
        },
        [
          _c("div", { staticClass: "header fs-20 mt-50" }, [
            _vm._v("Storico messaggi"),
          ]),
          !_vm.messages.length && !_vm.loadingMessages
            ? _c("div", [
                _vm._v("Non hai ancora inviato messaggi istituzionali"),
              ])
            : _vm._e(),
          _vm._l(_vm.messages, function (m) {
            return _c("MsgInstitutional", {
              key: m._id,
              attrs: { msg: m },
              on: { copy: _vm.copy, info: _vm.info },
            })
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogRoomsDataVisible,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            width: "550px",
            "custom-class": "rooms-institutional-data-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRoomsDataVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "pt-8", attrs: { slot: "title" }, slot: "title" },
            [
              _vm._v(" Utenti raggiunti : "),
              _vm.currentRoomsData
                ? _c("strong", { staticClass: "accent-text" }, [
                    _vm._v(_vm._s(_vm.currentRoomsData.total)),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.currentRoomsData
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "rooms-list" },
                  _vm._l(_vm.currentRoomsData.rooms, function (room) {
                    return _c(
                      "div",
                      { key: room._id, staticClass: "mv-6 fs-16" },
                      [
                        _c("span", { staticClass: "text-capitalized" }, [
                          _vm._v(_vm._s(room.type)),
                        ]),
                        _c("span", { staticClass: "mh-6" }, [_vm._v("/")]),
                        _c("strong", { staticClass: "break-word" }, [
                          _vm._v(" " + _vm._s(room.name) + ": "),
                          _c("span", { staticClass: "accent-text" }, [
                            _vm._v(_vm._s(room.online)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }