var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "thread-card flex card-base card-shadow--small",
      attrs: { "data-card": "thread" },
      on: {
        click: function ($event) {
          return _vm.open(_vm.thread._id)
        },
      },
    },
    [
      !_vm.thread.isSeen ? _c("div", { staticClass: "new" }) : _vm._e(),
      _vm.thread.type && _vm.thread.type === "ticket"
        ? _c("div", { staticClass: "type" }, [
            _c("i", { staticClass: "mdi mdi-ticket-confirmation-outline" }),
          ])
        : _vm._e(),
      !_vm.thread.type || _vm.thread.type === "email"
        ? _c("div", { staticClass: "type" }, [
            _c("i", { staticClass: "mdi mdi-email-outline" }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "flex column pl-20" }, [
        _c(
          "div",
          {
            staticClass: "initials",
            style: { backgroundColor: _vm.thread.color },
          },
          [_vm._v(_vm._s(_vm.thread.initials))]
        ),
        _vm.thread.hasAttachments
          ? _c("div", { staticClass: "attachments" }, [
              _c("i", { staticClass: "mdi mdi-attachment" }),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex column box grow" }, [
        _c("div", { staticClass: "thread-card-date box" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("dayjs")(_vm.thread.date, "format", "DD MMM YYYY HH:mm")
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "thread-card-from break-word box" }, [
          _vm._v(
            " " + _vm._s(_vm.thread.from.name || _vm.thread.from.address) + " "
          ),
        ]),
        _c("div", { staticClass: "thread-card-subject break-word box flex" }, [
          _vm._v(" " + _vm._s(_vm.thread.subject) + " "),
        ]),
        _c(
          "div",
          { staticClass: "thread-card-labels" },
          _vm._l(_vm.thread.labels, function (label) {
            return _c(
              "span",
              { key: label.name, style: { backgroundColor: label.color } },
              [_vm._v(" " + _vm._s(label.name) + " ")]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }