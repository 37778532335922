var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:swipe.left",
          value: _vm.swipeLeft,
          expression: "swipeLeft",
          arg: "swipe",
          modifiers: { left: true },
        },
        {
          name: "touch",
          rawName: "v-touch:swipe.right",
          value: _vm.swipeRight,
          expression: "swipeRight",
          arg: "swipe",
          modifiers: { right: true },
        },
      ],
      staticClass: "toolbar",
      class: [_vm.size],
    },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            "open-delay": 500,
            content: "Segna come non letto",
            placement: "top-start",
            disabled: _vm.tooltipHidden,
          },
        },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.thread.isSeen,
                  expression: "thread.isSeen",
                },
              ],
              attrs: { disabled: _vm.seenLoading },
              on: { click: _vm.toggleSeen },
            },
            [
              !_vm.seenLoading
                ? _c("i", { staticClass: "mdi mdi-email-outline" })
                : _vm._e(),
              _vm.seenLoading
                ? _c("i", { staticClass: "mdi mdi-loading" })
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            "open-delay": 500,
            content: "Segna come letto",
            placement: "top-start",
            disabled: _vm.tooltipHidden,
          },
        },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.thread.isSeen,
                  expression: "!thread.isSeen",
                },
              ],
              attrs: { disabled: _vm.seenLoading },
              on: { click: _vm.toggleSeen },
            },
            [
              !_vm.seenLoading
                ? _c("i", { staticClass: "mdi mdi-email-open-outline" })
                : _vm._e(),
              _vm.seenLoading
                ? _c("i", { staticClass: "mdi mdi-loading" })
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-start",
            width: "280",
            trigger: "click",
            "popper-class": "thread-labels-select",
          },
        },
        [
          _vm._l(_vm.filteredLabels, function (label) {
            return _c(
              "button",
              {
                key: label.name,
                style: { backgroundColor: label.color },
                on: {
                  click: function ($event) {
                    return _vm.addLabel(label)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(label.name))])]
            )
          }),
          !_vm.filteredLabels.length
            ? _c("div", [_vm._v("Non sono presenti etichette assegnabili")])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    "open-delay": 500,
                    content: "Assegna etichetta",
                    placement: "top-start",
                    disabled: _vm.tooltipHidden,
                  },
                },
                [
                  _c("button", { attrs: { disabled: _vm.labelsLoading } }, [
                    !_vm.labelsLoading
                      ? _c("i", { staticClass: "mdi mdi-label-outline" })
                      : _vm._e(),
                    _vm.labelsLoading
                      ? _c("i", { staticClass: "mdi mdi-loading" })
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._l(_vm.thread.labels, function (label) {
        return _c(
          "button",
          {
            key: label.name,
            staticClass: "label",
            style: { backgroundColor: label.color },
            attrs: { disabled: _vm.labelsLoading },
            on: {
              click: function ($event) {
                return _vm.delLabel(label.name)
              },
            },
          },
          [
            !_vm.labelsLoading
              ? _c("i", { staticClass: "mdi mdi-close" })
              : _vm._e(),
            _vm.labelsLoading
              ? _c("i", { staticClass: "mdi mdi-loading" })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(label.name))]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }