<template>
    <div class="channel-message-buttons flex column">
        <draggable
            class="list-group"
            tag="div"
            handle=".handle"
            v-model="bkp"
            v-bind="dragOptions"
            group="featured"
            @start="startDrag"
            @end="endDrag"
        >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div class="row flex" v-for="row of bkp" :key="row.id">
                    <div class="row-del" v-if="row.items.length">
                        <button @click="delRow(row.id)"><i class="mdi mdi-delete-outline"></i></button>
                    </div>

                    <draggable
                        :list="row.items"
                        tag="div"
                        class="box grow flex"
                        handle=".handle"
                        v-model="row.items"
                        v-bind="dragOptions"
                        :group="{ name: 'row' }"
                        @start="startDrag"
                        @end="endDrag"
                    >
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="box grow flex">
                            <div class="btn box grow" v-for="btn of row.items" :key="btn.id">
                                <div class="flex">
                                    <div class="flex column box">
                                        <button class="handle">
                                            <i class="mdi mdi-drag handle"></i>
                                        </button>
                                    </div>
                                    <div class="flex column box grow">
                                        <input v-model="btn.text" placeholder="Testo..." />
                                        <input v-model="btn.url" placeholder="Url..." />
                                    </div>
                                    <div class="flex column box">
                                        <button @click="delBtn(row.id, btn.id)">
                                            <i class="mdi mdi-close-circle-outline"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>

                    <div class="btn-add box" v-if="row.items.length">
                        <button @click="addBtn(row.id)">
                            <i class="mdi mdi-plus-circle-outline"></i>
                        </button>
                    </div>
                    <div class="btn-handle box" v-if="row.items.length">
                        <button class="handle">
                            <i class="mdi mdi-drag"></i>
                        </button>
                    </div>
                </div>
            </transition-group>
        </draggable>

        <div class="row-add">
            <button @click="addRow()">
                <i class="mdi mdi-plus-circle-outline"></i>
                <span>Riga pulsanti</span>
            </button>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
const draggable = () => import("vuedraggable")

export default {
    name: "ChannelMessageButtons",
    data() {
        return {
            drag: false,
            dragOptions: {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            },
            bkp: []
        }
    },
    /* watch: {
        buttons(val) {
            const buttons = [...val]
            const parsed = _.chain(buttons)
                .map(b => {
                    const btn = {
                        id: uuidv4(),
                        items: b
                    }

                    btn.items = _.map(btn.items, i => {
                        return {
                            id: uuidv4(),
                            ...i
                        }
                    })

                    return btn
                })
                .value()
            this.bkp = parsed
        }
    },*/
    methods: {
        addBtn(r_id) {
            const index = this.bkp.findIndex(r => r.id === r_id)
            this.bkp[index].items.push({
                id: uuidv4(),
                text: "",
                url: ""
            })
            this.checkBtn()
        },
        addRow() {
            this.bkp.push({
                id: uuidv4(),
                items: [
                    {
                        id: uuidv4(),
                        text: "",
                        url: ""
                    }
                ]
            })
            this.checkBtn()
        },
        delRow(r_id) {
            const index = this.bkp.findIndex(r => r.id === r_id)
            this.bkp.splice(index, 1)
            this.checkBtn()
        },
        delBtn(r_id, b_id) {
            const r_index = this.bkp.findIndex(r => r.id === r_id)
            const b_index = this.bkp[r_index].items.findIndex(b => b.id === b_id)
            this.bkp[r_index].items.splice(b_index, 1)
            this.checkBtn()
        },
        startDrag() {
            this.drag = true
            this.checkBtn()
        },
        endDrag() {
            this.drag = false
            this.checkBtn()
        },
        checkBtn() {
            const buttons = _.chain(this.bkp)
                .map(r => {
                    return r.items && _.isArray(r.items) && r.items.length ? r.items : null
                })
                .compact()
                .value()
            this.$emit("change", buttons)
        },
        reset() {
            this.bkp = []
            this.$emit("change", [])
        }
    },
    components: {
        draggable
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.channel-message-buttons {
    button {
        background: $background-color;
        border: none;
        outline: none;
        border-radius: 4px;
        font-weight: bold;
        font-family: inherit;
        width: 100%;
        padding: 6px 8px;
        cursor: pointer;
        font-size: 16px;

        i {
            margin-right: 5px;
        }
    }

    .row {
        button {
            height: 100%;

            i {
                margin-right: 0px;
            }

            &.handle {
                cursor: ns-resize;
            }
        }

        .row-del {
            margin: 4px 0;
            margin-right: 4px;

            button {
                background: rgba($color-danger, 0.1);
                color: $color-danger;
            }
        }

        .btn-add {
            margin: 4px 0;
            margin-left: 4px;
            margin-right: 4px;

            button {
                background: rgba($color-primary, 0.1);
                color: $color-primary;
            }
        }
        .btn-handle {
            margin: 4px 0;
            margin-left: 4px;

            button {
                background: rgba($color-info, 0.1);
                color: $color-info;
            }
        }

        .btn {
            background: $background-color;
            border-radius: 4px;
            padding: 2px;
            margin: 4px;

            input {
                max-width: 100%;
                width: 100%;
                border-radius: 2px;
                border: none;
                outline: none;
                margin: 2px;
                padding: 4px 8px;
                font-size: 14px;
                font-family: inherit;
                box-sizing: border-box;
            }

            .handle {
                cursor: move;
            }
        }
    }

    .row-add {
        margin: 4px 0;

        button {
            background: rgba($color-primary, 0.1);
            color: $color-primary;
        }
    }
}
</style>
