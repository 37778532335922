<template>
    <div id="chat-box" :class="size" class="flex" ref="chat">
        <resize-observer @notify="__resizeHandler" />

        <div class="box flex column">
            <div class="users-total-count">
                <span>Utenti connessi: </span>
                <strong>{{ usersCount }}</strong>
            </div>

            <RoomSelect :roomId.sync="roomId" :roomsGroups="roomsGroups" @change="openRoom(roomId)"></RoomSelect>

            <div id="rooms-list" class="box scrollable only-y">
                <div class="rooms-group" v-for="group of roomsGroups" :key="group.name">
                    <div class="group-name">{{ group.name }}</div>

                    <RoomCard
                        v-for="room of group.items"
                        :key="room._id"
                        :room="room"
                        :active="room._id === roomId"
                        @open="openRoom(room._id)"
                    ></RoomCard>
                </div>
            </div>
        </div>

        <div
            v-if="!roomId"
            id="message-box"
            class="box grow flex card-base card-shadow--small align-center text-center"
        >
            <div class="box grow text-center fs-20 p-30">Clicca su una stanza per aprire la chat</div>
        </div>

        <div v-if="roomId" id="message-box" class="box grow flex column card-base card-shadow--small">
            <RoomHeader
                v-if="currentRoom"
                :room="currentRoom"
                :typingList="typing"
                :typingText="typingText"
            ></RoomHeader>

            <div id="message-list" class="box grow scrollable only-y" ref="msgList" @scroll="msgListScrollHandler">
                <div class="scroll-wrap" ref="scrollWrap">
                    <div
                        class="message-row flex align-center"
                        :class="{
                            'is-mine': msg.isMine,
                            'justify-flex-start': !msg.isMine,
                            'justify-flex-end': msg.isMine,
                            found: msg._id === messageToFind
                        }"
                        v-for="msg of messages"
                        :key="msg._id"
                        :id="msg._id"
                    >
                        <div v-if="msg._id === messageToFind && msg.isMine" class="msg-found box grow"></div>

                        <div
                            class="msg-box flex align-flex-end"
                            v-if="msg.type !== 'info'"
                            :class="{ reverse: msg.isMine }"
                        >
                            <div class="msg-propic">
                                <vue-load-image>
                                    <img slot="image" class="image" :src="msg.author.pictureThumb" />
                                    <img
                                        slot="preloader"
                                        class="preloader"
                                        :src="require('@/assets/images/loading.png')"
                                    />
                                    <img
                                        slot="error"
                                        class="error"
                                        :src="require('@/assets/images/img-not-found.svg')"
                                    />
                                </vue-load-image>
                            </div>
                            <div class="msg-content" :style="{ background: getUserPinnedColor(msg.author.id) }">
                                <div class="msg-action" v-if="!msg.isMine && !msg.isSpecial">
                                    <el-dropdown trigger="click" @command="command => msgActions(command, msg)">
                                        <span class="el-dropdown-link">
                                            <i class="el-icon-arrow-down"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="copyLink">
                                                <i class="mdi mdi-link-variant"></i>Copia link
                                            </el-dropdown-item>
                                            <el-dropdown-item command="reply">
                                                <i class="mdi mdi-reply"></i>Rispondi
                                            </el-dropdown-item>
                                            <el-dropdown-item command="report" class="warning-text">
                                                <i class="mdi mdi-alert"></i>Segnala
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                command="delete"
                                                class="danger-text"
                                                v-if="can_DeleteChatMessage"
                                            >
                                                <i class="mdi mdi-delete"></i>Elimina messaggio
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="msg-parent" v-if="msg.parent" @click="scrollToMessage(msg.parent)">
                                    <div class="msg-parent-info">
                                        <span>in risposta a:</span>
                                        <strong @click.stop="openUser(msg.parent.author)" class="clickable">
                                            @{{ msg.parent.author.username }}
                                        </strong>
                                    </div>
                                    <div class="msg-parent-body" v-if="msg.parent.body" v-html="msg.parent.body"></div>
                                    <div
                                        class="msg-parent-body-img"
                                        v-if="msg.parent.type === 'img' || msg.parent.type === 'sticker'"
                                    >
                                        <img :src="msg.parent.imageThumb" />
                                    </div>
                                </div>
                                <div class="msg-info flex">
                                    <div
                                        class="msg-author"
                                        @click="openUser(msg.author)"
                                        :style="{ color: !msg.isMine && msg.author.color }"
                                    >
                                        @{{ msg.author.username }}
                                    </div>
                                    <el-tooltip :content="$dayjs(msg.dateSent).format('DD MMMM YYYY [alle] HH:mm:ss')">
                                        <div class="msg-time">{{ msg.timeAdded }}</div>
                                    </el-tooltip>
                                </div>
                                <div class="msg-body" v-if="msg.body" v-html="msg.body"></div>
                                <div
                                    class="msg-body-img"
                                    v-if="
                                        msg.type === 'img' ||
                                        msg.type === 'sticker' ||
                                        (msg.type === 'institutional' && msg.imageThumb)
                                    "
                                >
                                    <vue-load-image>
                                        <img slot="image" class="image" :src="msg.imageThumb" />
                                        <img
                                            slot="preloader"
                                            class="preloader"
                                            :src="require('@/assets/images/loading.png')"
                                        />
                                        <img
                                            slot="error"
                                            class="error"
                                            :src="require('@/assets/images/img-not-found.svg')"
                                        />
                                    </vue-load-image>
                                </div>
                                <div class="msg-body-url" v-if="msg.url">
                                    <a target="_blank" :href="msg.url">
                                        <i class="mdi mdi-open-in-new"></i> Vai al link
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div v-if="msg._id === messageToFind && !msg.isMine" class="msg-found box grow"></div>

                        <div v-if="msg.type === 'info'" class="msg-type-info">
                            <div v-if="msg.info === 'join'" class="msg-info-join">
                                <span :style="{ background: getUserPinnedColor(msg.user.id) }">
                                    <strong>@{{ msg.user.username }}</strong>
                                    <span class="ml-6">è entrata nella stanza</span>
                                    <small class="ml-6 o-050">{{ msg.timeAdded }}</small>
                                </span>
                            </div>
                            <div v-if="msg.info === 'leave'" class="msg-info-leave">
                                <span :style="{ background: getUserPinnedColor(msg.user.id) }">
                                    <strong>@{{ msg.user.username }}</strong>
                                    <span class="ml-6">ha lasciato la stanza</span>
                                    <small class="ml-6 o-050">{{ msg.timeAdded }}</small>
                                </span>
                            </div>
                            <div v-if="msg.info === 'day'" class="mv-10">
                                <span class="o-070">{{ msg.day | dayjs("format", "DD MMM YYYY") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="reply-box" class="flex" v-if="replyMsg">
                <div class="reply-info box grow">
                    <div class="reply-author">@{{ replyMsg.author.username }}</div>
                    <div class="reply-body" v-html="replyMsg.body" v-if="replyMsg.body"></div>
                    <div class="reply-body-img" v-if="replyMsg.type === 'img' || replyMsg.type === 'sticker'">
                        <img :src="replyMsg.imageThumb" />
                    </div>
                </div>
                <div class="reply-action">
                    <button @click="resetReply">
                        <i class="mdi mdi-close"></i>
                    </button>
                </div>
            </div>
            <div id="input-box" class="flex align-center">
                <div class="input-el box grow">
                    <el-input
                        type="textarea"
                        :autosize="{ maxRows: 4 }"
                        placeholder="Messaggio..."
                        v-model="message"
                        ref="textarea"
                    >
                    </el-input>
                </div>
                <div class="image-el" :style="{ 'background-image': bg }" :class="{ small: !image }">
                    <FileDrop
                        @change="fileChange"
                        :hover="false"
                        :icon="'mdi mdi-image-outline'"
                        v-if="!image"
                    ></FileDrop>
                    <div class="btn-clear-photo" v-if="image" @click="image = ''"><i class="mdi mdi-close"></i></div>
                </div>
                <div class="send-el box flex column center">
                    <button @click="send" :disabled="!isValid">Invia</button>
                </div>
            </div>
        </div>
        <transition-group name="list" id="users-pinned-list" class="scrollable only-y" v-if="usersPinned.length">
            <div
                v-for="user in usersPinned"
                :key="user.id"
                class="user-card card-base card-shadow--small flex"
                :style="{ background: user.color }"
            >
                <div class="propic">
                    <vue-load-image>
                        <img slot="image" class="image" :src="user.pictureThumb" />
                        <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                        <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                    </vue-load-image>
                </div>
                <div class="info box grow flex column">
                    <div class="username box grow" @click="openUser(user, false)">@{{ user.username }}</div>
                    <div class="close-btn">
                        <button @click="removeUserPinned(user.id)"><i class="mdi mdi-close"></i>rimuovi</button>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
import { io } from "socket.io-client"
const config = process.env
import VueLoadImage from "vue-load-image"
import { EventBus } from "@/event-bus"
import utils from "@/utils"
import FileDrop from "@/components/common/FileDrop"
import RoomCard from "@/components/Chat/RoomCard"
import RoomSelect from "@/components/Chat/RoomSelect"
import RoomHeader from "@/components/Chat/RoomHeader"
import api from "@/api"
import { openUserDialog } from "@/common"

export default {
    name: "Chat",
    props: ["app", "goToRoom", "goToMessage"],
    data() {
        return {
            chat_url: config.VUE_APP_CHAT_URL,
            messages: [],
            rooms: [],
            typing: [],
            rootSocket: null,
            roomSocket: null,
            v2Socket: null,
            message: "",
            image: "",
            roomId: null,
            replyMsg: null,
            userData: {
                authorization: this.$store.state.account.token,
                username: this.$store.state.account.username,
                propic: this.$store.state.account.picture,
                propic_thumb: this.$store.state.account.picture,
                id: this.$store.state.account.mysqlId
            },
            usersPinned: [],
            usersColors: ["#ffcfcf", "#f3cfff", "#a8d3ff", "#a6dada", "#b0e8a5", "#e8dda5"],
            messageFound: false,
            messageToFind: null,
            stickersList: [],
            size: "large",
            roomTimer: 0
        }
    },
    computed: {
        roomsGroups() {
            return _.chain(this.rooms)
                .orderBy([r => r.special, r => r.order], ["desc", "asc"])
                .groupBy("roomType")
                .map(obj => ({ name: obj[0].roomType, items: obj }))
                .value()
        },
        usersCount() {
            return _.reduce(this.rooms, (a, v) => a + (v.usersCount || 0), 0)
        },
        userId() {
            return this.userData._id
        },
        currentRoom() {
            return _.find(this.rooms, r => r._id === this.roomId)
        },
        typingText() {
            const users = this.typing.map(u => `@${u}`).join(", ")
            return `${users} ${this.typing.length === 1 ? "sta" : "stanno"} scrivendo...`
        },
        bg() {
            return `url(${this.image}`
        },
        isValid() {
            if (!_.trim(this.message) && !this.image) {
                return false
            }

            return true
        },
        can_DeleteChatMessage() {
            return this.$store.getters.can_DeleteChatMessage
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.chat) {
                const width = this.$refs.chat.offsetWidth

                this.size = "large"
                if (width < 350) {
                    this.size = "small"
                } else if (width < 850) {
                    this.size = "medium"
                }
            }
        },

        msgListScrollHandler(ev) {
            if (ev.target.scrollTop === 0) {
                const msg = _.find(this.messages, m => m.type !== "info")
                if (msg && msg._id) {
                    this.roomSocket.emit("V2/roompagination", {
                        room: this.roomId,
                        messageId: msg._id
                    })
                }
            }
        },
        msgSanitizer(msg) {
            if (msg.body) {
                msg.body = msg.body.replace(/(?:\r\n|\r|\n)/g, "<br>")
            }

            if (msg.parent) {
                msg.parent.body = msg.parent.body.replace(/(?:\r\n|\r|\n)/g, "<br>")

                if (msg.parent.type === "sticker") {
                    if (_.some(this.stickersList, s => s.name === msg.parent.body)) {
                        msg.parent.imageThumb = _.find(this.stickersList, s => s.name === msg.parent.body).image
                        msg.parent.body = ""
                    }
                }
            }

            if (msg.dateAdded) {
                msg.timeAdded = this.$dayjs(msg.dateAdded).format("HH:mm")
            }

            if (msg.author) {
                if (!msg.author.picture) {
                    msg.author.pictureThumb = require("@/assets/images/img-not-found.svg")
                }

                msg.isMine = msg.author._id === this.userId
            }

            if (msg.type === "sticker") {
                if (_.some(this.stickersList, s => s.name === msg.body)) {
                    msg.imageThumb = _.find(this.stickersList, s => s.name === msg.body).image
                    msg.body = ""
                }
            }

            if (msg.type === "institutional") {
                msg.isSpecial = true
                msg.author = {
                    id: new Date().getTime(),
                    pictureThumb: require("@/assets/images/admin-propic.webp"),
                    color: "#863ccc",
                    isInstitutional: true,
                    username: "iMamma"
                }
            }

            return msg
        },
        addMsg(msg) {
            const msgListScroll = this.$refs.msgList
                ? this.$refs.msgList.scrollTop + this.$refs.msgList.offsetHeight
                : 0
            const scrollWrapHeight = this.$refs.scrollWrap ? this.$refs.scrollWrap.clientHeight : 0

            const forceScroll = msgListScroll === scrollWrapHeight

            const lastMsg = _.cloneDeep(this.messages[this.messages.length - 1])
            if (lastMsg) {
                const lastNotInfo = lastMsg.type !== "info"
                const msgNotInfo = msg.type !== "info"
                const sameDate =
                    this.$dayjs(msg.dateAdded).format("YYYY-MM-DD") ===
                    this.$dayjs(lastMsg.dateAdded).format("YYYY-MM-DD")

                if (lastNotInfo && msgNotInfo && !sameDate) {
                    this.messages.push({
                        _id: uuidv4(),
                        dateAdded: msg.dateAdded,
                        type: "info",
                        info: "day",
                        day: msg.dateAdded
                    })
                }
            }

            msg = this.msgSanitizer(msg)
            this.messages.push(msg)

            if (forceScroll) {
                this.msgScrollTo(scrollWrapHeight)
            }
        },
        msgScrollTo(position) {
            setTimeout(() => {
                if (this.$refs.msgList) {
                    this.$refs.msgList.scrollTop = position
                }
            }, 0)
        },
        setMessages(messages) {
            const msgWithDay = []
            const msgSan = _.map(messages, msg => this.msgSanitizer(msg))

            for (let i = 0; i < msgSan.length; i++) {
                const lastDay = _.findLast(msgWithDay, m => m.type === "info" && m.info === "day") || null
                const lastDayText = lastDay ? this.$dayjs(lastDay.dateAdded).format("YYYY-MM-DD") : null
                const currentDayText = this.$dayjs(msgSan[i].dateAdded).format("YYYY-MM-DD")

                if (lastDayText !== currentDayText) {
                    msgWithDay.push({
                        _id: uuidv4(),
                        dateAdded: msgSan[i].dateAdded,
                        type: "info",
                        info: "day",
                        day: msgSan[i].dateAdded
                    })
                }
                msgWithDay.push(msgSan[i])
            }

            this.messages = msgWithDay
            setTimeout(() => {
                if (this.$refs.msgList) {
                    this.$refs.msgList.scrollTop = this.$refs.scrollWrap?.clientHeight || 0
                }

                this.findMessage()
            }, 0)
        },
        prependMessages(messages) {
            // save current scroll state
            const scrollBefore = this.$refs.scrollWrap?.clientHeight || 0
            const scrollMsgList = this.$refs.msgList?.scrollTop || 0

            const msgWithDay = []
            const msgSan = _.map(messages, msg => this.msgSanitizer(msg)).reverse()

            for (let i = 0; i < msgSan.length; i++) {
                const lastDay = _.findLast(msgWithDay, m => m.type === "info" && m.info === "day") || null
                const lastDayText = lastDay ? this.$dayjs(lastDay.dateAdded).format("YYYY-MM-DD") : null
                const currentDayText = this.$dayjs(msgSan[i].dateAdded).format("YYYY-MM-DD")

                if (lastDayText !== currentDayText) {
                    msgWithDay.push({
                        _id: uuidv4(),
                        dateAdded: msgSan[i].dateAdded,
                        type: "info",
                        info: "day",
                        day: msgSan[i].dateAdded
                    })
                }
                msgWithDay.push(msgSan[i])
            }

            let oldMessages = _.cloneDeep(this.messages)

            const newLastDay = _.findLast(msgWithDay, m => m.type === "info" && m.info === "day")
            const newLastDayText = newLastDay ? this.$dayjs(newLastDay.dateAdded).format("YYYY-MM-DD") : null
            const oldFirstDay = _.find(oldMessages, m => m.type === "info" && m.info === "day")
            const oldFirstDayText = oldFirstDay ? this.$dayjs(oldFirstDay.dateAdded).format("YYYY-MM-DD") : ""

            if (newLastDayText === oldFirstDayText) {
                oldMessages = _.filter(oldMessages, m => m._id !== oldFirstDay._id)
            }

            this.messages = [...msgWithDay, ...oldMessages]

            setTimeout(() => {
                const scrollAfter = this.$refs.scrollWrap?.clientHeight || 0
                const diff = scrollAfter - scrollBefore
                // update scroll position
                if (this.$refs.msgList) {
                    this.$refs.msgList.scrollTop = scrollMsgList + diff
                }

                this.findMessage()
            }, 0)
        },
        findMessage() {
            if (this.messageToFind && !this.messageFound) {
                const msgExist = _.some(this.messages, { _id: this.messageToFind })
                if (msgExist) {
                    const el = document.getElementById(this.messageToFind)
                    if (el) {
                        this.msgScrollTo(el.offsetTop - 200)
                    }
                    this.messageFound = true
                } else {
                    if (this.$refs.msgList) {
                        this.$refs.msgList.scrollTop = 0
                    }
                }
            }
        },
        initRootSocket() {
            const logStyle = ["%cROOT", "color:cyan;font-weight: bold;"]

            this.connect({ path: "/", socketVar: "rootSocket" }, () => {
                this.rootSocket.on("connect", () => {
                    console.log(...logStyle, "connect:", this.rootSocket)
                })

                this.rootSocket.on("disconnect", () => {
                    console.log(...logStyle, "disconnect:", this.rootSocket)
                })

                this.rootSocket.on("rooms", res => {
                    /*
					this.rooms = res.data

                    if (this.goToRoom) {
                        this.openRoom(this.goToRoom)
                    }
					*/
                    console.log(...logStyle, "rooms:", res)
                })

                this.rootSocket.on("all_rooms", res => {
                    // this.rooms = res.data
                    console.log(...logStyle, "all_rooms:", res)
                })

                this.rootSocket.on("new_message", res => {
                    console.log(...logStyle, "new_message:", res)
                })

                this.rootSocket.on("V2/usercount", res => {
                    console.log(...logStyle, "V2/usercount:", res)
                })

                this.rootSocket.on("chaterror", res => {
                    console.log(...logStyle, "chaterror:", res)
                })
            })
        },
        initV2Socket() {
            const logStyle = ["%cV2", "color:deeppink;font-weight: bold;"]

            this.connect({ path: "/v2", socketVar: "v2Socket" }, () => {
                this.v2Socket.on("connect", () => {
                    console.log(...logStyle, "connect:", this.v2Socket)
                })

                this.v2Socket.on("disconnect", () => {
                    console.log(...logStyle, "disconnect:", this.v2Socket)
                })

                this.v2Socket.on("rooms", res => {
                    console.log(...logStyle, "rooms:", res)
                })

                this.v2Socket.on("chaterror", res => {
                    console.log(...logStyle, "chaterror:", res)
                })
            })
        },
        initRoomSocket(id) {
            const logStyle = ["%cCHAT", "color:greenyellow;font-weight: bold;"]

            if (this.roomSocket) {
                this.roomSocket.disconnect()
                this.roomSocket = null
                this.typing = []
            }

            this.connect({ path: "/chat", socketVar: "roomSocket", room: id }, () => {
                this.roomSocket.on("connect", () => {
                    console.log(...logStyle, "connect:", this.roomSocket)
                })

                this.roomSocket.on("disconnect", () => {
                    console.log(...logStyle, "disconnect:", this.roomSocket)
                })

                this.roomSocket.on("rooms", res => {
                    console.log(...logStyle, "rooms:", res)
                    this.rooms = res.data
                })

                this.roomSocket.on("connection", res => {
                    this.userData = { ...this.userData, ...res.userInfo }

                    this.setMessages(res.messages)
                    console.log(...logStyle, "connection:", res)
                })

                this.roomSocket.on("V2/userjoined", res => {
                    console.log(...logStyle, "V2/userjoined:", res)
                    if (this.size === "large") {
                        this.$message(`@${res.user.username} è entrata nella stanza`)
                    }
                    this.addMsg({
                        _id: uuidv4(),
                        dateAdded: new Date(),
                        type: "info",
                        info: "join",
                        user: res.user
                    })
                })

                this.roomSocket.on("V2/userleft", res => {
                    console.log(...logStyle, "V2/userleft:", res)
                    if (this.size === "large") {
                        this.$message(`@${res.user.username} ha lasciato la stanza`)
                    }
                    this.addMsg({
                        _id: uuidv4(),
                        dateAdded: new Date(),
                        type: "info",
                        info: "leave",
                        user: res.user
                    })
                })

                this.roomSocket.on("userlist", res => {
                    console.log(...logStyle, "userlist:", res)
                })

                this.roomSocket.on("useristyping", res => {
                    console.log(...logStyle, "useristyping:", res)
                    this.typing = _.filter(this.typing, t => t !== res.user)
                    this.typing.push(res.user)
                })

                this.roomSocket.on("userstoptyping", res => {
                    console.log(...logStyle, "userstoptyping:", res)
                    this.typing = _.filter(this.typing, t => t !== res.user)
                })

                this.roomSocket.on("messageadded", res => {
                    console.log(...logStyle, "messageadded:", res)
                    this.addMsg(res.message)
                })

                this.roomSocket.on("V2/newmessage", res => {
                    console.log(...logStyle, "V2/newmessage:", res)
                    this.addMsg(res.message)
                })

                this.roomSocket.on("special", res => {
                    console.log(...logStyle, "special:", res)
                    this.addMsg(res.message)
                })

                this.roomSocket.on("roompagination_done", res => {
                    console.log(...logStyle, "roompagination_done:", res)

                    this.prependMessages(res.messages)
                })

                this.roomSocket.on("user_banned", res => {
                    console.log(...logStyle, "user_banned:", res)
                })

                this.roomSocket.on("reportmessage_done", res => {
                    console.log(...logStyle, "reportmessage_done:", res)
                    this.$notify({
                        title: "Segnalazione",
                        message: "Messaggio segnalato correttamente",
                        type: "success"
                    })
                })

                this.roomSocket.on("chaterror", res => {
                    console.log(...logStyle, "chaterror:", res)
                    this.$notify({
                        title: "Attenzione",
                        message: "Qualcosa è andato storto",
                        type: "error"
                    })
                })
            })
        },
        connect({ path = "/", socketVar = "rootSocket", room }, cb) {
            let query = {
                authorization: this.userData.authorization,
                username: this.userData.username,
                propic: this.userData.propic,
                propic_thumb: this.userData.propic_thumb,
                id: this.userData.id,
                version: 2,
                admin: true,
                app: this.app,
                roomType: "3fe9e583-45f7-4b4d-b529-ff658b56de2a",
                os: "web",
                ranking: 1
            }

            if (path === "/chat") {
                query.room = room
            }

            if (path === "/v2") {
                query = {
                    authorization: config.VUE_APP_CHAT_V2_TOKEN
                }
            }

            this[socketVar] = io(this.chat_url + path, {
                transports: ["websocket"],
                withCredentials: true,
                query,
                auth: query
            })

            if (cb && typeof cb === "function") cb()
        },
        openRoom(id) {
            if (id !== this.roomId) {
                this.roomId = id
                this.initRoomSocket(id)
            }
        },
        send() {
            const time = new Date().getTime()
            const user = {
                token: this.userData.authorization
            }
            const message = {
                body: this.message,
                author: this.userData._id,
                type: "txt",
                date_sent: time,
                temp_id: time.toString()
            }

            if (this.image) {
                message.type = "img"
                message.base64 = this.image
            }

            if (this.replyMsg) {
                message.parent_id = this.replyMsg._id
            }

            this.roomSocket.emit("usermessage", {
                user,
                message
            })

            this.message = ""
            this.image = ""
            this.resetReply()
        },
        reply(msg) {
            this.replyMsg = msg
            if (this.$refs.textarea) {
                this.$refs.textarea.$el.focus()
            }
        },
        delete(msg) {
            this.$confirm("Sicura di voler eliminare il messaggio ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    api.chat
                        .deleteMessage(msg._id, this.roomId)
                        .then(response => {
                            const bkp = this.messages
                            _.remove(bkp, m => m._id === msg._id)
                            this.messages = _.cloneDeep(bkp)

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Messaggio eliminato`
                            })
                        })
                        .catch(err => {
                            console.error(err)
                            this.$notify.error({
                                title: "Ops...",
                                message: "Il messaggio non è stato eliminato"
                            })
                        })
                })
                .catch(() => {})
        },
        resetReply() {
            this.replyMsg = null
        },
        msgActions(command, msg) {
            this[command](msg)
        },
        report(msg) {
            const message = {
                _id: msg._id
            }

            this.$confirm("Sicura di voler segnalare il messaggio ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--warning"
            })
                .then(() => {
                    this.roomSocket.emit("reportmessage", {
                        message
                    })
                })
                .catch(() => {})
        },
        openUser(author, chat = true) {
            if (author.id && !author.isInstitutional) {
                openUserDialog({ id: author.id, chat, user: author })
            }
        },
        addUserPinned(userData) {
            const user = _.cloneDeep(userData)
            const exist = _.some(this.usersPinned, { id: user.id })
            if (!exist) {
                if (this.usersPinned.length < this.usersColors.length) {
                    const usedColors = _.uniq(_.map(this.usersPinned, u => u.color)) || []
                    const availableColors = _.filter(this.usersColors, c => !_.includes(usedColors, c))

                    user.color = availableColors[_.random(0, availableColors.length - 1)]
                    this.usersPinned.push(user)
                } else {
                    this.$alert(`Puoi monitorare massimo ${this.usersColors.length} utenti`, "Attenzione", {
                        confirmButtonText: "OK",
                        type: "warning"
                    })
                }
            }
        },
        removeUserPinned(id) {
            this.usersPinned = _.filter(this.usersPinned, u => u.id !== id)
        },
        getUserPinnedColor(id) {
            const user = _.find(this.usersPinned, u => u.id === id)
            return user?.color
        },
        getStickers() {
            api.chat
                .getStickers()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        let list = []

                        for (const pack of res.body) {
                            list = [...pack.stickers, ...list]
                        }

                        this.stickersList = list
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        scrollToMessage(msg) {
            this.messageToFind = msg._id
            this.messageFound = false
            this.findMessage()
        },
        copyLink(msg) {
            this.$clipboard(`${location.origin}/chat/rooms/${this.roomId}/${msg._id}`)
            this.$message.success("Link copiato")
        },
        fileChange(file) {
            utils.file2Base64(file).then(base64 => {
                this.image = base64
            })
        },
        getRooms() {
            api.chat
                .getRooms()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.rooms = res.body

                        if (this.$route.params.room || this.goToRoom) {
                            this.openRoom(this.$route.params.room || this.goToRoom)
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    },
    mounted() {
        this.messageToFind = this.goToMessage
        this.initRootSocket()
        //this.initV2Socket()
        this.getStickers()
        this.calcDim()

        this.getRooms()
        this.roomTimer = setInterval(() => {
            this.getRooms()
        }, 10000)

        EventBus.$on("chat:pin", ({ id, user }) => {
            console.log("chat:pin", user)
            this.addUserPinned(user)
        })
    },
    beforeDestroy() {
        if (this.roomTimer) clearInterval(this.roomTimer)
        if (this.rootSocket) this.rootSocket.disconnect()
        if (this.roomSocket) this.roomSocket.disconnect()
    },
    components: {
        "vue-load-image": VueLoadImage,
        FileDrop,
        RoomCard,
        RoomSelect,
        RoomHeader
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/scss/_variables";

#chat-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .users-total-count {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .rooms-select {
        display: none;
    }

    #rooms-list {
        width: 350px;
        margin-right: 20px;
        box-sizing: border-box;

        .rooms-group {
            margin-bottom: 40px;

            .group-name {
                text-transform: capitalize;
                font-weight: bold;
            }
        }
    }
    #message-box {
        //margin: 20px;
        //margin-left: 0;

        #message-list {
            box-sizing: border-box;

            .scroll-wrap {
                padding: 20px;
            }

            .message-row {
                box-sizing: border-box;
                padding-right: 50px;
                margin-bottom: 10px;

                .msg-box {
                    max-width: 100%;

                    .msg-propic {
                        width: 30px;
                        min-width: 30px;
                        max-width: 30px;
                        height: 30px;
                        background: $color-primary;
                        overflow: hidden;
                        border-radius: 50%;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .msg-content {
                        text-align: left;
                        background: $background-color;
                        border-radius: 12px;
                        margin-left: 8px;
                        border-bottom-left-radius: 4px;
                        transition: background 1s;

                        .msg-action {
                            float: right;
                            width: 30px;
                            text-align: center;
                            text-shadow: 0px 0px 1px #fff, 0px 0px 2px #fff, 0px 0px 3px #fff;
                            cursor: pointer;
                            margin-top: 6px;
                            margin-right: 5px;
                        }

                        .msg-parent {
                            background: rgba(77, 88, 96, 0.08);
                            margin: 4px;
                            margin-bottom: 0;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                            padding: 4px 8px;
                            cursor: pointer;

                            .msg-parent-info {
                                font-size: 10px;
                                margin-bottom: 2px;

                                .clickable {
                                    font-weight: bold;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            .msg-parent-body {
                                font-size: 14px;
                                font-weight: bold;
                            }
                            .msg-parent-body-img {
                                padding: 5px 0;

                                img {
                                    display: block;
                                    border-radius: 6px;
                                    max-height: 200px;
                                    max-width: 300px;
                                    width: 100%;
                                }
                            }
                        }

                        .msg-body {
                            padding: 10px;
                            word-break: break-all;
                        }
                        .msg-body-img {
                            padding: 10px;

                            img {
                                border-radius: 6px;
                                display: block;
                                max-width: 300px;
                                width: 100%;
                            }
                        }
                        .msg-body-url {
                            padding: 10px;
                        }

                        .msg-info {
                            padding: 10px;
                            padding-bottom: 0;
                            justify-content: flex-start;
                            font-size: 12px;

                            .msg-author {
                                font-weight: bold;
                                margin-right: 4px;
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                            .msg-time {
                                opacity: 0.5;
                            }
                        }
                    }
                }
                &.is-mine {
                    padding-right: 0;
                    padding-left: 50px;

                    .msg-box {
                        .msg-content {
                            text-align: right;
                            background: $color-primary;
                            color: #fff;
                            margin-left: 0px;
                            margin-right: 8px;
                            border-bottom-left-radius: 12px;
                            border-bottom-right-radius: 4px;

                            .msg-parent {
                                background: rgba(76, 87, 95, 0.3);
                            }

                            .msg-info {
                                justify-content: flex-end;
                                flex-direction: row-reverse;

                                .msg-author {
                                    margin-right: 0px;
                                    margin-left: 4px;
                                }

                                .msg-time {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }

                &.found {
                    --found-color: #c4e0c4;
                    background: rgba($color-warning, 0.2);
                    padding: 10px;
                    border-radius: 8px;

                    /*.msg-found {
						background: var(--found-color);
						height: 20px;
						position: relative;
						border-radius: 4px;

						&::after {
							content: "";
							width: 20px;
							height: 20px;
							background: white;
							position: absolute;
							bottom: 0;
							left: 0;
							border: 10px solid transparent;
							box-sizing: border-box;
							border-right-color: var(--found-color);
						}
					}*/

                    &.is-mine {
                        .msg-found {
                            &::after {
                                right: 0;
                                left: inherit;
                                border-left-color: var(--found-color);
                                border-right-color: transparent;
                            }
                        }
                    }
                }

                .msg-type-info {
                    padding-left: 50px;
                    width: 100%;
                    text-align: center;

                    .msg-info-join,
                    .msg-info-leave {
                        & > span {
                            padding: 10px 16px;
                            border-radius: 12px;
                            margin: 10px;
                            display: inline-block;
                        }
                    }
                }
            }
        }

        #reply-box {
            background: $background-color;

            .reply-info {
                border-left: 5px solid $color-primary;
                padding: 10px;

                .reply-author {
                    font-size: 12px;
                    font-weight: bold;
                    opacity: 0.8;
                    margin-bottom: 5px;
                }
                .reply-body {
                    font-size: 14px;
                }
                .reply-body-img {
                    padding-top: 5px;

                    img {
                        display: block;
                        border-radius: 6px;
                        max-height: 150px;
                    }
                }
            }
            .reply-action {
                button {
                    background: transparent;
                    border: none;
                    outline: none;
                    padding: 10px;
                    font-size: 16px;
                    color: $text-color;
                    cursor: pointer;
                }
            }
        }

        #input-box {
            border-top: 1px solid rgba($text-color, 0.15);

            .input-el {
                padding: 10px 0;

                :deep {
                    textarea {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 20px;
                        margin: 0;
                        font-family: inherit;
                        font-size: 16px;
                        display: block;
                        border: none;
                        outline: none;
                        resize: none;
                        transition: all 0.15s;
                    }
                }
            }

            .image-el {
                position: relative;
                width: 100px;
                height: 100px;
                margin: 16px;
                margin-right: 10px;
                border-radius: 4px;
                overflow: hidden;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 0.2s;
                box-sizing: border-box;

                .file-drop {
                    :deep {
                        label {
                            background: transparent;

                            i {
                                font-size: 30px;
                                line-height: 30px;
                                color: $color-primary;
                                text-shadow: none;
                            }
                        }
                    }
                }

                .btn-clear-photo {
                    color: #fff;
                    cursor: pointer;
                    font-size: 16px;
                    background: rgba(0, 0, 0, 0.5);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 2px solid rgba(255, 255, 255, 0.5);
                    text-align: center;
                    line-height: 24px;
                    margin: 6px;
                    transition: all 0.2s;

                    &:hover {
                        background: rgba($color-danger, 0.6);
                    }
                }

                &.small {
                    width: 30px;
                    height: 30px;
                    margin: 0px;
                    margin-right: 10px;
                }
            }

            .send-el {
                padding: 10px;

                button {
                    padding: 6px 12px;
                    margin: 0;
                    border: none;
                    outline: none;
                    background: transparent;
                    text-transform: uppercase;
                    font-family: inherit;
                    color: $color-primary;
                    font-weight: bold;
                    cursor: pointer;
                    border-radius: 4px;
                    transition: all 0.25s;

                    &:disabled {
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                    &:not(:disabled) {
                        &:hover {
                            background: rgba($color-primary, 0.1);
                        }
                        &:focus {
                            box-shadow: 0px 0px 0px 2px rgba($color-primary, 0.4) inset;
                        }
                    }
                }
            }
        }
    }
    #users-pinned-list {
        margin-left: 34px;

        .list-enter-active,
        .list-leave-active,
        .list-move {
            transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
            transition-property: opacity, transform;
        }

        .list-enter {
            opacity: 0;
            transform: translateX(50px) scaleY(0.5);
        }

        .list-enter-to {
            opacity: 1;
            transform: translateX(0) scaleY(1);
        }

        .list-leave-active {
            position: absolute;
        }

        .list-leave-to {
            opacity: 0;
            transform: scaleY(0);
            transform-origin: center top;
        }

        .user-card {
            padding: 8px;
            min-width: 250px;
            margin-bottom: 10px;

            .propic {
                width: 55px;
                border-radius: 6px;
                overflow: hidden;
                margin-right: 10px;

                img {
                    display: block;
                    width: 100%;
                }
            }

            .info {
                .username {
                    cursor: pointer;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                .close-btn {
                    button {
                        background: rgba(255, 255, 255, 0.4);
                        cursor: pointer;
                        outline: none;
                        border: none;
                        padding: 4px 8px;
                        display: block;
                        border-radius: 4px;
                        color: #969696;
                        letter-spacing: 1px;
                        font-weight: bold;
                        transition: all 0.2s;
                        font-size: 12px;

                        i {
                            position: relative;
                            top: 1px;
                            margin-right: 4px;
                        }

                        &:hover {
                            color: $color-primary;
                            box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.7);
                        }
                    }
                }
            }
        }
    }

    &.medium,
    &.small {
        flex-direction: column;
        -webkit-box-orient: vertical;

        .users-total-count {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .rooms-select {
            display: block;
        }
        #rooms-list {
            display: none;
        }
        #message-box {
            .room-info {
                :deep {
                    .badges {
                        display: inline-block;
                    }
                }
            }
        }
        #users-pinned-list {
            margin: 0;
            margin-top: 10px;
        }
    }
}
</style>
