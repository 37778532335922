var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toolbar flex align-center justify-space-between" },
    [
      _c(
        "div",
        { staticClass: "box-left box grow flex" },
        [
          _c(
            "button",
            {
              staticClass: "toggle-sidebar card-base",
              on: { click: _vm.toggleSidebar },
            },
            [
              _c("img", {
                staticClass: "logo-mini",
                attrs: {
                  src: require("@/assets/images/icon-mini.png"),
                  alt: "logo",
                },
              }),
              _vm.menuBurger !== "right"
                ? _c("i", { staticClass: "mdi mdi-menu" })
                : _vm._e(),
            ]
          ),
          _c("search", { staticClass: "hidden-xs-only" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box-right flex align-center pl-10" },
        [
          _c(
            "button",
            {
              staticClass: "fullscreen-button",
              on: { click: _vm.toggleFullscreen },
            },
            [
              !_vm.fullscreen
                ? _c("i", { staticClass: "mdi mdi-fullscreen" })
                : _vm._e(),
              _vm.fullscreen
                ? _c("i", { staticClass: "mdi mdi-fullscreen-exit" })
                : _vm._e(),
            ]
          ),
          _c("span", { staticClass: "username flex align-center" }, [
            _vm._v(" " + _vm._s(_vm.accountName) + " "),
          ]),
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom" },
              on: { command: _vm.onCommand },
            },
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link flex align-center" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.accountRole,
                        placement: "bottom",
                        disabled: _vm.size !== "large",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "mdi mdi-shield ml-10 roles color",
                        class: _vm.accountRole,
                      }),
                    ]
                  ),
                  _c("vue-load-image", [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: { slot: "image", src: _vm.accountPicture },
                      slot: "image",
                    }),
                    _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        slot: "preloader",
                        src: require("@/assets/images/loading.png"),
                      },
                      slot: "preloader",
                    }),
                    _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        slot: "error",
                        src: require("@/assets/images/img-not-found.svg"),
                      },
                      slot: "error",
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "/profilo" } }, [
                    _c("i", { staticClass: "mdi mdi-account mr-10" }),
                    _vm._v(" Profilo "),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "/logout" } }, [
                    _c("i", { staticClass: "mdi mdi-logout mr-10" }),
                    _vm._v(" Logout "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.menuBurger === "right"
            ? _c(
                "button",
                {
                  staticClass:
                    "toggle-sidebar toggle-sidebar__right card-base card-shadow--small",
                  on: { click: _vm.toggleSidebar },
                },
                [_c("i", { staticClass: "mdi mdi-menu" })]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }