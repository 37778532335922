<template>
    <div class="chat-stats-page scrollable only-y" v-loading="!loadedData">
        <div class="flex">
            <YearsSelect :year.sync="yearSelected" :years="years" v-loading="!loadedYears" class="mb-30"></YearsSelect>
            <RoomSelect :roomId.sync="roomId" :roomsGroups="roomsGroups" :clearable="true"></RoomSelect>
        </div>
        <Calendar :year="yearSelected" :data="dataParsed" :by-room="byRoom" v-if="loadedData" class="mb-60"></Calendar>
        <Chart
            :data="dataParsed"
            :by-room="byRoom"
            v-if="yearSelected && loadedData && !loadingChart"
            @click="setHour($event)"
        >
            <DayHour
                :data="dataParsed"
                :by-room="byRoom"
                :hour-selected="hourSelected"
                v-if="hourSelected"
                class="mb-30"
            ></DayHour>
        </Chart>

        <h3 v-if="byRoom && !dataParsed.length">Dati insufficienti per la stanza selezionata</h3>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

import YearsSelect from "@/components/Chat/Stats/YearsSelect"
import YearsList from "@/components/Chat/Stats/YearsList"
import Calendar from "@/components/Chat/Stats/Calendar"
import DayHour from "@/components/Chat/Stats/DayHour"
import Chart from "@/components/Chat/Stats/Chart"
import RoomSelect from "@/components/Chat/RoomSelect"

export default {
    name: "ChatStats",
    data() {
        return {
            loadedYears: false,
            loadedData: false,
            loadingChart: true,
            years: [],
            yearSelected: null,
            hourSelected: null,
            data: null,
            rooms: [],
            roomId: null,
            dataParsed: []
        }
    },
    computed: {
        roomsGroups() {
            return _.chain(this.rooms)
                .orderBy([r => r.isspecial, r => r.order], ["desc", "asc"])
                .groupBy("roomType")
                .map(obj => ({ name: obj[0].roomType, items: obj }))
                .value()
        },
        currentRoom() {
            return _.find(this.rooms, r => r._id === this.roomId)
        },
        byRoom() {
            return !!this.roomId
        }
    },
    watch: {
        yearSelected(val) {
            this.setYear(val)
        },
        roomId(val) {
            this.hourSelected = null
            this.loadedData = false

            setTimeout(() => {
                this.parseData()
                this.loadedData = true
            }, 300)
        },
        loadedData(val) {
            this.loadingChart = true

            if (val === true) {
                setTimeout(() => {
                    this.loadingChart = false
                }, 300)
            }
        }
    },
    methods: {
        init() {
            this.getStatsYears()
            this.getRooms()
        },
        parseData() {
            if (!this.byRoom) {
                this.dataParsed = this.data
            } else {
                const dataParsed = []
                for (const item of this.data) {
                    const room = item.data.rooms.find(r => r._id === this.roomId)

                    if (!room) {
                        break
                    }

                    const rooms = [_.cloneDeep(room)]
                    dataParsed.push({
                        created_at: item.created_at,
                        data: {
                            total: rooms.reduce((a, o) => a + (o.online || 0), 0),
                            images: rooms.reduce((a, o) => a + (o.images || 0), 0),
                            messages: rooms.reduce((a, o) => a + (o.messages || 0), 0),
                            rooms: rooms
                        }
                    })
                }
                this.dataParsed = dataParsed
            }
        },
        getStatsYears() {
            this.loadedYears = false

            api.chat
                .getStatsYears()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.years = _.reverse(res.body)

                        if (this.years.length) {
                            this.yearSelected = this.years[0]
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadedYears = true
                })
        },
        setYear(year) {
            this.roomId = null
            this.hourSelected = null
            this.loadedData = false

            api.chat
                .getStatsByYear(year)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.data = res.body

                        this.parseData()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadedData = true
                })
        },
        setHour(data) {
            this.hourSelected = data
        },
        getRooms() {
            api.chat
                .getRooms()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.rooms = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    },
    mounted() {
        this.init()
    },
    components: { YearsList, YearsSelect, Calendar, DayHour, Chart, RoomSelect }
}
</script>

<style lang="scss" scoped>
.chat-stats-page {
    :deep {
        .rooms-select {
            margin-right: auto;
            max-width: 270px;
            height: 40px;
            margin-top: 5px;
            margin-left: 20px;
            background: transparent;

            .el-select {
                height: 100%;

                .el-input {
                    height: 100%;

                    input {
                        height: 100%;
                    }
                }
            }

            @media (max-width: 800px) {
                margin-left: 10px;
                height: 30px;
                margin-top: 10px;

                input {
                    padding-left: 8px;
                    padding-right: 20px;
                    line-height: 1;
                }

                .el-input__suffix {
                    right: 0;

                    .el-input__suffix-inner {
                        height: 100%;

                        .el-select__caret {
                            line-height: 1;
                        }
                    }
                }
            }

            @media (max-width: 400px) {
                margin-left: 5px;
            }
        }
    }
}
</style>
