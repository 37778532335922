var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-card flex align-center card-shadow--small",
      on: { click: _vm.click },
    },
    [
      _c(
        "div",
        { staticClass: "box image-box flex align-center" },
        [
          _vm.handle
            ? _c("i", {
                staticClass: "mdi mdi-drag handle card-base card-shadow--small",
              })
            : _vm._e(),
          _c("vue-load-image", [
            _c("img", {
              staticClass: "image",
              attrs: { slot: "image", src: _vm.review.image },
              slot: "image",
            }),
            _c("img", {
              staticClass: "preloader",
              attrs: {
                slot: "preloader",
                src: require("@/assets/images/loading-ar_3-2.png"),
              },
              slot: "preloader",
            }),
            _c("img", {
              staticClass: "error",
              attrs: {
                slot: "error",
                src: require("@/assets/images/img-not-found-ar_3-2.jpg"),
              },
              slot: "error",
            }),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "box grow info" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.review.title) + " "),
          _c("small", { staticClass: "o-050" }, [
            _vm._v("#" + _vm._s(_vm.review.id)),
          ]),
          _vm.review.featured
            ? _c("i", {
                staticClass: "mdi mdi-star-outline warning-text ml-10",
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "type" }, [
          _vm.review.type
            ? _c("span", [_vm._v(_vm._s(_vm.review.type) + " - ")])
            : _vm._e(),
          _c("small", { staticClass: "o-070" }, [
            _vm._v(
              _vm._s(
                _vm._f("dayjs")(
                  _vm.review.updated_at,
                  "format",
                  "DD MMM YYYY (HH:mm)"
                )
              )
            ),
          ]),
        ]),
      ]),
      _vm.handle
        ? _c("div", { staticClass: "box handle-box" }, [
            _c("i", { staticClass: "mdi mdi-drag handle" }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }