var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "block flex column card-base card-shadow--small",
    },
    [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "label box" }, [_vm._v("Etichette supporto")]),
        _c(
          "div",
          { staticClass: "value box grow labels-list" },
          [
            _vm._l(_vm.threadLabels, function (label) {
              return _c(
                "div",
                {
                  key: label.name,
                  staticClass: "label",
                  style: { background: label.color },
                },
                [
                  _vm.can_ManageThreadLabels
                    ? _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.delThreadLabel(label.name)
                            },
                          },
                        },
                        [_c("i", { staticClass: "mdi mdi-close" })]
                      )
                    : _vm._e(),
                  _c("span", [_vm._v(" " + _vm._s(label.name) + " ")]),
                ]
              )
            }),
            _vm.can_ManageThreadLabels
              ? _c("div", { staticClass: "label input" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.labelName,
                            expression: "labelName",
                          },
                        ],
                        attrs: { placeholder: "Label..." },
                        domProps: { value: _vm.labelName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.labelName = $event.target.value
                          },
                        },
                      }),
                      _c("v-swatches", {
                        attrs: {
                          swatches: _vm.swatches,
                          "row-length": 4,
                          "max-height": 200,
                          "swatch-size": 24,
                          shapes: "circles",
                          "trigger-style": {
                            width: "18px",
                            height: "18px",
                            border: "1px solid #ccc",
                          },
                        },
                        model: {
                          value: _vm.labelColor,
                          callback: function ($$v) {
                            _vm.labelColor = $$v
                          },
                          expression: "labelColor",
                        },
                      }),
                      _c(
                        "button",
                        {
                          attrs: {
                            disabled:
                              !_vm.labelName.trim() || !_vm.labelColor.trim(),
                          },
                          on: { click: _vm.addThreadLabel },
                        },
                        [_vm._v(" Salva ")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }