<template>
    <div class="page-chat-attachments flex" :class="size" ref="page">
        <resize-observer @notify="__resizeHandler" />

        <RoomSelect :roomId.sync="roomId" :roomsGroups="roomsGroups" @change="openRoom(roomId)"></RoomSelect>

        <div id="rooms-list" class="box scrollable only-y">
            <div class="rooms-group" v-for="group of roomsGroups" :key="group.name">
                <div class="group-name">{{ group.name }}</div>

                <RoomCard
                    v-for="room of group.items"
                    :key="room._id"
                    :room="room"
                    :active="room._id === roomId"
                    @open="openRoom(room._id)"
                ></RoomCard>
            </div>
        </div>

        <div
            v-if="!roomId"
            id="message-box"
            class="box grow flex card-base card-shadow--small align-center text-center"
        >
            <div class="box grow text-center fs-20 p-30">Clicca su una stanza per visualizzare gli allegati</div>
        </div>

        <div v-if="roomId" id="message-box" class="box grow flex column card-base card-shadow--small">
            <RoomHeader v-if="currentRoom" :room="currentRoom"></RoomHeader>

            <div id="message-list" class="box grow scrollable only-y" ref="msgList" @scroll="msgListScrollHandler">
                <div v-if="!messages.length && !loading">Non sono presenti allegati</div>

                <MsgAttachment
                    v-for="msg of messages"
                    :key="msg._id"
                    :msg="msg"
                    :id="msg._id"
                    @openChat="openChat(roomId, msg._id)"
                    @delete="msgActions('delete', msg)"
                    @report="msgActions('report', msg)"
                ></MsgAttachment>

                <div class="msg-box" v-if="loading">
                    <div class="msg-content p-20 flex column" v-loading="true"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

import RoomCard from "@/components/Chat/RoomCard"
import RoomSelect from "@/components/Chat/RoomSelect"
import RoomHeader from "@/components/Chat/RoomHeader"
import MsgAttachment from "@/components/Chat/MsgAttachment"

export default {
    name: "ChatAttachments",
    data() {
        return {
            messages: [],
            rooms: [],
            roomId: null,
            page: 1,
            stopGet: false,
            loading: false,
            size: "large"
        }
    },
    computed: {
        roomsGroups() {
            return _.chain(this.rooms)
                .orderBy([r => r.isspecial, r => r.order], ["desc", "asc"])
                .groupBy("roomType")
                .map(obj => ({ name: obj[0].roomType, items: obj }))
                .value()
        },
        currentRoom() {
            return _.find(this.rooms, r => r._id === this.roomId)
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.page) {
                const width = this.$refs.page.offsetWidth

                this.size = "large"
                if (width < 350) {
                    this.size = "small"
                } else if (width < 850) {
                    this.size = "medium"
                }
            }
        },
        getRooms() {
            api.chat
                .getRooms()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.rooms = res.body

                        if (this.$route.params.room) {
                            this.openRoom(this.$route.params.room)
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        getRoomAttachments(roomId) {
            this.loading = true

            api.chat
                .getRoomAttachments({ roomId, page: this.page })
                .then(res => {
                    if (res.status === 200 && res.body && res.body.messages && res.body.messages.length) {
                        if (roomId === this.roomId) {
                            const bkp = _.cloneDeep(this.messages)
                            const messages = _.map(res.body.messages, m => {
                                m.roomId = roomId
                                return m
                            })
                            bkp.push(...messages)
                            this.messages = _.chain(bkp)
                                .filter(m => m.roomId === roomId)
                                .uniqBy("_id")
                                .value()
                        }

                        setTimeout(() => {
                            const msgList = this.$refs.msgList
                            if (
                                msgList &&
                                (msgList?.firstElementChild?.offsetHeight || 0) < (msgList?.offsetHeight || 0)
                            ) {
                                this.getNewPage(roomId)
                            }
                        }, 1000)
                    } else {
                        this.stopGet = true
                    }

                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                })
        },
        openRoom(id) {
            this.page = 1
            this.roomId = id
            this.messages = []
            this.stopGet = false
            this.getRoomAttachments(id)
        },
        openChat(roomId, messageId) {
            window.open(`/chat/rooms/${roomId}/${messageId}`)
        },
        getNewPage(roomId) {
            if (!this.stopGet) {
                this.page++
                this.getRoomAttachments(roomId)
            }
        },
        msgListScrollHandler(ev) {
            const canGet =
                (ev.target?.scrollHeight || 0) - (ev.target?.offsetHeight || 0) === (ev.target?.scrollTop || 0)

            if (canGet) {
                this.getNewPage(this.roomId)
            }
        },
        msgActions(command, msg) {
            this[command](msg)
        },
        report(msg) {
            this.$confirm("Sicura di voler segnalare il messaggio ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--warning"
            })
                .then(() => {
                    api.chat
                        .reportMessage(msg._id, this.roomId)
                        .then(response => {
                            msg.reported++

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Messaggio segnalato`
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.$notify.error({
                                title: "Ops...",
                                message: "Il messaggio non è stato segnalato"
                            })
                        })
                })
                .catch(() => {})
        },
        delete(msg) {
            this.$confirm("Sicura di voler eliminare il messaggio ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    api.chat
                        .deleteMessage(msg._id, this.roomId)
                        .then(response => {
                            const bkp = this.messages
                            _.remove(bkp, m => m._id === msg._id)
                            this.messages = _.cloneDeep(bkp)

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Messaggio eliminato`
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            this.$notify.error({
                                title: "Ops...",
                                message: "Il messaggio non è stato eliminato"
                            })
                        })
                })
                .catch(() => {})
        }
    },
    created() {},
    mounted() {
        this.calcDim()
        this.getRooms()
    },
    beforeDestroy() {},
    components: {
        RoomSelect,
        RoomCard,
        RoomHeader,
        MsgAttachment
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-chat-attachments {
    .rooms-select {
        display: none;
    }

    #rooms-list {
        width: 350px;
        margin-right: 20px;
        box-sizing: border-box;

        .rooms-group {
            margin-bottom: 40px;

            .group-name {
                text-transform: capitalize;
                font-weight: bold;
            }
        }
    }

    #message-box {
        //margin: 20px;
        //margin-left: 0;

        #message-list {
            box-sizing: border-box;
            padding: 20px;
        }
    }
    &.medium,
    &.small {
        flex-direction: column;
        -webkit-box-orient: vertical;

        .rooms-select {
            display: block;
        }
        #rooms-list {
            display: none;
        }
        #message-box {
            .room-info {
                :deep {
                    .badges {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>
