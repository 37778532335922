var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    _vm._l(_vm.bots, function (bot) {
      return _c(
        "div",
        {
          key: bot._id,
          staticClass: "block flex column card-base card-shadow--small mb-30",
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "label box" }, [
              _vm._v(" Codici autorizzazione " + _vm._s(bot.type) + " "),
              _c("strong", [_vm._v(_vm._s(bot.name))]),
            ]),
            _c(
              "div",
              { staticClass: "value box grow tag-selector" },
              [
                _c("el-select", {
                  attrs: {
                    multiple: "",
                    filterable: "",
                    "allow-create": "",
                    "default-first-option": "",
                    placeholder: "Aggiungi token",
                    "popper-class": "bot-tag-selector",
                  },
                  on: { change: (value) => _vm.updateKeys(bot.name, value) },
                  model: {
                    value: bot.keys,
                    callback: function ($$v) {
                      _vm.$set(bot, "keys", $$v)
                    },
                    expression: "bot.keys",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: bot.loading,
                  expression: "bot.loading",
                },
              ],
              staticClass: "sub-block chat-list flex column mt-10",
            },
            [
              _c("div", { staticClass: "label" }, [
                _vm._v("Chat autenticate:"),
              ]),
              bot.subscribers && bot.subscribers.length
                ? _c(
                    "div",
                    { staticClass: "value scrollable only-x" },
                    _vm._l(bot.subscribers, function (sub) {
                      return _c(
                        "div",
                        {
                          key: sub.id,
                          staticClass: "box card-base card-shadow--small",
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v("ID:")]),
                            _c("strong", [_vm._v(_vm._s(sub.id))]),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("Data/ora:")]),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dayjs")(
                                    sub.date,
                                    "format",
                                    "DD/MM/YYYY HH:mm"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("Nome:")]),
                            _c("strong", [_vm._v(_vm._s(sub.chat.title))]),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("Tipo:")]),
                            _c("strong", [_vm._v(_vm._s(sub.chat.type))]),
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("Utente:")]),
                            _c("strong", [
                              _vm._v(
                                _vm._s(sub.from.first_name) +
                                  " (@" +
                                  _vm._s(sub.from.username) +
                                  ")"
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "del-btn" }, [
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.delBotSubscriber(
                                      bot.name,
                                      sub.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-delete-outline",
                                }),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !bot.subscribers || !bot.subscribers.length
                ? _c("div", { staticClass: "value" }, [
                    _c("div", { staticClass: "box" }, [
                      _vm._v("Nessuna chat si è ancora autenticata"),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }