var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "box", class: ["qna-filter", _vm.size] },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c(
        "div",
        {
          staticClass: "filter-bar flex justify-space-between align-center",
          class: { hide: !_vm.expanded },
        },
        [
          _vm.showCategory
            ? _c(
                "el-select",
                {
                  staticClass: "option-select card-shadow--small",
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "Scegli categoria",
                  },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.filter.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "category", $$v)
                    },
                    expression: "filter.category",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              )
            : _vm._e(),
          !_vm.showCategory
            ? _c("div", { staticClass: "flex grow" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "switches flex box" },
            [
              _c("el-switch", {
                staticClass: "card-base box grow card-shadow--small",
                attrs: { "active-text": "Solo sospese" },
                on: { change: _vm.change },
                model: {
                  value: _vm.filter.suspended,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "suspended", $$v)
                  },
                  expression: "filter.suspended",
                },
              }),
              _c("el-switch", {
                staticClass: "card-base box grow card-shadow--small",
                attrs: { "active-text": "Solo con allegati" },
                on: { change: _vm.change },
                model: {
                  value: _vm.filter.attachments,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "attachments", $$v)
                  },
                  expression: "filter.attachments",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "filter-button" }, [
        _c("span", { staticClass: "action", on: { click: _vm.action } }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.expanded,
                  expression: "!expanded",
                },
              ],
            },
            [_vm._v("Filtri")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expanded,
                  expression: "expanded",
                },
              ],
            },
            [_vm._v("Chiudi filtri")]
          ),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.expanded,
                expression: "!expanded",
              },
            ],
            staticClass: "mdi mdi-chevron-down",
          }),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.expanded,
                expression: "expanded",
              },
            ],
            staticClass: "mdi mdi-chevron-up",
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }