<template>
	<div class="note card-base card-shadow--small">
		<div class="text">
			{{ data.note }}
		</div>
		<div class="date">
			<strong>{{ data.day | dayjs("format", "DD MMMM") }}</strong>
			<small class="ml-5">{{ data.day | dayjs("format", "YYYY") }}</small>
		</div>
	</div>
</template>

<script>
const Note = function (id, user_id, day, note, created_at, updated_at) {
	this.id = id
	this.user_id = user_id
	this.day = day
	this.note = note
	this.created_at = created_at
	this.updated_at = updated_at
}

export default {
	name: "Note",
	props: {
		data: [Note, Object]
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	methods: {},
	mounted() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.note {
	display: inline-block;
	text-transform: capitalize;
	padding: 10px 20px;
	max-width: 300px;
	vertical-align: top;

	.text {
		font-size: 18px;
		word-wrap: break-word;
		white-space: initial;
		margin-bottom: 20px;
	}
}
</style>
