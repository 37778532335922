<template>
    <div
        class="page-logs-list column scrollable only-y"
        :class="{ flex: !isMobile, overflow: isMobile, [size]: true }"
        ref="page"
    >
        <resize-observer @notify="handleResize" />

        <div class="toolbar-box flex align-center">
            <div class="box grow mr-5 wide">
                <el-date-picker
                    v-model="daterange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    size="mini"
                    class="card-shadow--small"
                    range-separator="To"
                    start-placeholder="Data inizio"
                    end-placeholder="Data fine"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="datePickerOptions"
                >
                </el-date-picker>
            </div>

            <div class="box grow mr-5 half">
                <el-select size="mini" class="card-shadow--small" v-model="server" placeholder="Server">
                    <el-option v-for="item in serverList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </div>

            <div class="box grow mr-5 half">
                <el-select size="mini" class="card-shadow--small" clearable v-model="type" placeholder="Error type">
                    <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </div>
        </div>

        <div class="table-box card-base card-shadow--small box grow flex column" id="table-wrapper">
            <el-table
                :data="list"
                style="width: 100%"
                :max-height="height"
                v-loading="loading"
                v-if="ready"
                @row-click="viewItem"
            >
                <el-table-column label="Data/ora" width="180" prop="datetime" :fixed="!pagination.small">
                    <template slot-scope="scope">
                        <span>{{ scope.row.datetime | dayjs("format", "DD/MM/YYYY HH:mm:ss") }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Code" width="70" prop="codeClass"></el-table-column>
                <el-table-column label="Type" min-width="180" prop="codeName">
                    <template slot-scope="scope">
                        <span class="code-type" :style="{ color: stc(scope.row.codeName) }">
                            {{ scope.row.codeName }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="Message" min-width="400" prop="message"></el-table-column>
            </el-table>

            <el-pagination
                class="box"
                v-if="ready"
                :small="pagination.small"
                :current-page.sync="pagination.page"
                :page-sizes="pagination.sizes"
                :page-size.sync="pagination.size"
                :layout="pagination.layout"
                :total="total"
            ></el-pagination>
        </div>

        <el-dialog
            title="Dettaglio attività"
            :visible.sync="dialogLogVisible"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            width="600px"
        >
            <print-object :printable-object="currentLog" :initial-collapsed="['details']"></print-object>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash"
const PrintObject = () => import("vue-print-object")
import stc from "string-to-color"
import api from "@/api"

export default {
    name: "ErrorLogsPage",
    data() {
        return {
            isMobile: false,
            width: 0,
            height: "auto",
            loading: false,
            ready: false,
            pagination: {
                page: 1,
                size: 30,
                sizes: [10, 15, 20, 30, 50, 100],
                layout: "total, ->, prev, pager, next, jumper, sizes",
                small: false
            },
            size: "large",
            list: [],
            dialogLogVisible: false,
            server: "panel",
            serverList: ["panel", "chat"],
            type: null,
            typeList: [],
            total: 0,
            currentLog: {},
            daterange: "",
            datePickerOptions: {
                firstDayOfWeek: 1,
                shortcuts: [
                    {
                        text: "Last week",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Last month",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit("pick", [start, end])
                        }
                    },
                    {
                        text: "Last 3 months",
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                            picker.$emit("pick", [start, end])
                        }
                    }
                ]
            },
            stc
        }
    },
    computed: {
        currentPage() {
            return this.pagination.page
        },
        itemPerPage() {
            return this.pagination.size
        }
    },
    watch: {
        currentPage(val) {
            this.getData()
        },
        itemPerPage(val) {
            this.getData()
        },
        server(val) {
            this.getData()
            this.getFilters()
        },
        type(val) {
            this.getData()
        },
        daterange(val) {
            this.getData()
        }
    },
    methods: {
        viewItem(log) {
            this.currentLog = log
            this.dialogLogVisible = true
        },
        calcDims() {
            const el = document.getElementById("table-wrapper")
            if (el) {
                this.width = el.clientWidth

                if (!this.isMobile) {
                    this.height = el.clientHeight - 44
                }

                if (this.width < 480) {
                    this.pagination.small = true
                    this.pagination.layout = "prev, pager, next"
                } else if (this.width >= 480 && this.width < 700) {
                    this.pagination.small = false
                    this.pagination.layout = "prev, pager, next, ->, sizes"
                } else {
                    this.pagination.small = false
                    this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
                }

                this.ready = true

                this.calcSize()
            }
        },
        calcSize() {
            if (this.$refs.page) {
                const width = this.$refs.page.offsetWidth

                this.size = "large"
                if (width < 350) {
                    this.size = "small"
                } else if (width < 850) {
                    this.size = "medium"
                }
            }
        },
        handleResize: _.throttle(function (e) {
            //this.ready = false
            //this.width = 0
            setTimeout(this.calcDims, 1000)
        }, 500),
        getData: _.debounce(function (e) {
            this.list = []
            this.loading = true

            const filters = {
                server: this.server,
                type: this.type,
                startDate: "",
                endDate: ""
            }

            if (this.daterange && this.daterange.length === 2) {
                filters.startDate = this.$dayjs(this.daterange[0]).valueOf()
                filters.endDate = this.$dayjs(this.daterange[1]).valueOf()
            }

            api.common
                .getErrorLogs({ pageNumber: this.currentPage, pageItems: this.itemPerPage, filters })
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.list = res.body.data
                        this.total = res.body.total
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                })
        }, 200),
        closeDialog(payload) {
            this.dialogLogVisible = false
        },
        getFilters() {
            this.type = ""

            api.common
                .getErrorLogsFilters(this.server)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.typeList = _.get(res, "body.type")
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        init() {
            this.getData()
            this.getFilters()

            if (window.innerWidth <= 768) this.isMobile = true
        }
    },
    filters: {
        selected: function (value, sel) {
            if (!value) return ""
            if (!sel) return value

            value = value.toString()
            sel = sel.toString()

            const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
            if (startIndex !== -1) {
                const endLength = sel.length
                const matchingString = value.substr(startIndex, endLength)
                return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
            }
            //return value.toString().replace(new RegExp(sel,"gim"), `<span class="sel">${sel}</span>`)
            return value
        }
    },
    created() {
        this.init()
    },
    mounted() {
        //ie fix
        if (!window.Number.parseInt) window.Number.parseInt = parseInt

        this.calcDims()
    },
    components: {
        PrintObject
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-logs-list {
    user-select: none;

    &.overflow {
        overflow: auto;
    }

    .toolbar-box {
        margin-bottom: 10px;

        &.hidden {
            visibility: hidden;
        }

        :deep {
            .card-shadow--small {
                &.el-date-editor {
                    border-color: transparent;
                    width: 100%;
                }
                &.el-input,
                &.el-select {
                    border-radius: 4px;
                    width: 100%;

                    input {
                        border-color: transparent;

                        &:hover {
                            border-color: #c0c4cc;
                        }
                        &:focus {
                            border-color: $color-primary;
                        }
                    }
                }

                &.el-button {
                    border-color: transparent;
                }
            }
        }
    }

    .table-box {
        overflow: hidden;

        .code-type {
            font-weight: bold;
        }

        &.hidden {
            visibility: hidden;
        }

        :deep {
            .el-table {
                font-size: 16px;
            }
            .el-table th.is-leaf,
            .el-table td {
                border-bottom: 1px solid rgba(235, 238, 245, 0.3);
            }
            .el-table th {
                background-color: lighten(darken($background-color, 1%), 2%);
            }
            .el-table-column--selection .cell {
                padding-left: 10px;
                padding-right: 10px;
            }

            .el-table__row {
                cursor: pointer;
            }

            input[type="checkbox"].styled,
            .checkbox {
                background: white;
                -webkit-appearance: none;
                width: 16px;
                height: 16px;
                border: 3px solid transparentize($text-color, 0.8);
                border-radius: 50%;
                box-sizing: border-box;
                outline: none;
                display: block;

                &:checked,
                &.checked {
                    background: transparentize($color-primary, 0.2);
                    border-color: rgba(255, 255, 255, 0.4);
                }
            }

            .el-pagination {
                padding: 8px;

                .el-pagination__sizes {
                    margin: 0;

                    .el-input {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .clickable {
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
    }

    .sel-string {
        .sel {
            background: transparentize($text-color, 0.8);
            border-radius: 5px;
            //text-transform: uppercase;
        }
        strong.sel {
            color: #3f5377;
            font-weight: 800;
            background: transparent;
            border-radius: 0;
        }
    }

    &.medium,
    &.small {
        .toolbar-box {
            display: block;
            margin: 0 -5px;
            margin-bottom: 10px;

            .wide {
                min-width: 100%;
                margin: 0;
                box-sizing: border-box;
                padding: 5px;
            }
            .half {
                width: 50%;
                display: inline-block;
                margin: 0;
                box-sizing: border-box;
                padding: 5px;
            }
        }
    }
    &.small {
        .toolbar-box {
            .half {
                width: 100%;
            }
        }
    }
}
</style>
