<template>
	<div class="page-users-export">
		<div class="form-box flex column card-base card-shadow--small" v-loading="loading">
			<div class="header">Data Export Tool</div>
			<div class="body">
				<ExportToolFilters @updated="filters = $event" ref="exportTool" />
			</div>
			<div class="footer">
				<el-button @click="requestData()" type="primary" :loading="loading" :disabled="!isValid">
					Export
				</el-button>
				<el-button @click="reset()" :loading="loading"> Reset </el-button>
			</div>
		</div>

		<div class="form-box flex column card-base card-shadow--small mt-20">
			<div class="header flex gaps justify-space-between">
				<div>
					Lista richieste export
					<small style="opacity: 0.5"> (ultime 48h) </small>
				</div>
				<div>
					<el-button @click="getExportList()" size="mini" plain v-loading="loadingList">
						<i class="mdi mdi-reload fs-14"></i>
					</el-button>
				</div>
			</div>
			<div class="body">
				<el-table :data="exportList" style="width: 100%" v-loading="loadingList">
					<el-table-column label="ID" prop="id" width="80">
						<template slot-scope="scope">
							<span>{{ scope.row.id }}</span>
						</template>
					</el-table-column>
					<el-table-column label="Data e ora" prop="started">
						<template slot-scope="scope">
							<span>{{ scope.row.started | dayjs("format", "DD/MM/YYYY HH:mm:ss") }}</span>
						</template>
					</el-table-column>
					<el-table-column label="Stato" prop="status">
						<template slot-scope="scope">
							<strong class="export-item-status" :class="scope.row.status">
								{{ scope.row.status }}
							</strong>
						</template>
					</el-table-column>
					<el-table-column label="Tempo exec." prop="execution_time">
						<template slot-scope="scope">
							<span>{{ scope.row.execution_time }}</span>
						</template>
					</el-table-column>
					<el-table-column label="File" prop="link">
						<template slot-scope="scope">
							<a :href="scope.row.link" target="_blank" v-if="scope.row.link">Scarica</a>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import Papa from "papaparse"
import * as FS from "file-saver"

const ExportToolFilters = () => import("@/components/ExportTool/Filters")

export default {
	name: "UsersExportPage",
	data() {
		return {
			filters: [],
			list: [],
			loading: false,
			loadingList: false,
			exportList: []
		}
	},
	computed: {
		accountEmail() {
			return this.$store.getters.accountEmail
		},
		isValid() {
			for (const filter of this.filters) {
				if (
					(Array.isArray(filter.value) && filter.value.length) ||
					(!Array.isArray(filter.value) && filter.value)
				) {
					return true
				}
			}

			return false
		}
	},
	watch: {},
	methods: {
		reset() {
			if (this.$refs?.exportTool?.reset) {
				this.$refs.exportTool.reset()
			}
		},
		requestData() {
			this.list = []
			this.loading = true

			// console.log(this.filters)

			const filters = {}

			for (const filter of this.filters) {
				if (
					(Array.isArray(filter.value) && filter.value.length) ||
					(!Array.isArray(filter.value) && filter.value)
				) {
					filters[filter.key] = filter.value
				}
			}

			api.common
				.export(filters)
				.then(res => {
					if (res.status === 200 && res.body) {
						/*
                        this.list = res.body

                        const csv = Papa.unparse(this.list, {
                            quotes: false, //or array of booleans
                            quoteChar: '"',
                            escapeChar: '"',
                            delimiter: ";",
                            header: true,
                            newline: "\r\n",
                            skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                            columns: null //or array of strings
                        })

                        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
                        FS.saveAs(blob, "users.csv")
						*/

						this.getExportList()

						this.$notify.success({
							title: "Fatto!",
							message: `Riceverai Il file richiesto all'email "${this.accountEmail}"`
						})
					}
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					console.error(err)
				})
		},
		getExportList() {
			this.exportList = []
			this.loadingList = true

			api.common
				.getExportList()
				.then(res => {
					if (res.status === 200 && res.body) {
						this.exportList = _.orderBy(res.body, "started", "desc")
					}
					this.loadingList = false
				})
				.catch(err => {
					this.loadingList = false
					console.error(err)
				})
		},
		askConfirm(to, from, next) {
			if (this.loading) {
				this.$confirm(
					"Uscendo adesso dalla pagina l'export richiesto verrà perso. Sicura di voler abbandonare questa pagina ?",
					"Attenzione",
					{
						confirmButtonText: "Si",
						cancelButtonText: "Annulla",
						type: "warning"
					}
				)
					.then(() => {
						next()
					})
					.catch(err => {
						next(false)
					})
			} else {
				next()
			}
		}
	},
	created() {
		this.getExportList()
	},
	mounted() {},
	beforeRouteUpdate(to, from, next) {
		this.askConfirm(to, from, next)
	},
	beforeRouteLeave(to, from, next) {
		this.askConfirm(to, from, next)
	},
	components: { ExportToolFilters }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-users-export {
	.form-box {
		& > .body {
			padding: 20px;
			padding-top: 0;
		}
		& > .header {
			padding: 20px;
			font-size: 20px;
			user-select: none;
		}

		.export-item-status {
			&.COMPLETED,
			&.COMPLETE {
				color: $color-success;
			}
			&.SERVER_START,
			&.SERVER_STARTED {
				color: $color-warning;
			}
			&.IN_PROGRESS {
				color: $color-warning;
			}
			&.QUEUE,
			&.QUEUED {
				color: $color-info;
			}
		}

		.footer {
			background: rgba($background-color, 0.9);
			padding: 10px 20px;
			color: rgba($text-color, 0.7);
		}
	}
}
</style>
