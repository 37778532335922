<template>
    <div class="years-list scrollable only-x">
        <div v-for="year of years" :key="year" class="item card-base card-shadow--small" @click="$emit('select', year)">
            {{ year }}
        </div>
    </div>
</template>

<script>
export default {
    name: "YearsList",
    props: ["years"]
}
</script>

<style lang="scss" scoped>
.years-list {
    white-space: nowrap;
    padding: 2px 10px;
    margin-left: -10px;
    margin-right: -10px;

    .item {
        display: inline-block;
        margin-right: 20px;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: bold;
        transition: all 0.25s;

        &:hover {
            background: transparent;
        }
    }
}
</style>