var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "block flex card-base card-shadow--small",
    },
    [
      _c("div", { staticClass: "label box" }, [_vm._v("Stop words")]),
      _c(
        "div",
        { staticClass: "value box grow" },
        [
          _vm._l(_vm.stopWords, function (tag) {
            return _c(
              "el-tag",
              {
                key: tag,
                attrs: {
                  closable: "",
                  effect: "plain",
                  "disable-transitions": false,
                },
                on: {
                  close: function ($event) {
                    return _vm.removeStopWord(tag)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          _c(
            "transition",
            { attrs: { name: "slide-fade", mode: "out-in" } },
            [
              _vm.stopWordInputVisible
                ? _c("el-input", {
                    ref: "stopWordInput",
                    staticClass: "input-new-tag",
                    attrs: { size: "small", placeholder: "Nuova parola" },
                    on: {
                      blur: function ($event) {
                        return _vm.toggleStopWordInput(false)
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addStopWord.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.toggleStopWordInput(false)
                        },
                      ],
                    },
                    model: {
                      value: _vm.stopWordInputValue,
                      callback: function ($$v) {
                        _vm.stopWordInputValue = $$v
                      },
                      expression: "stopWordInputValue",
                    },
                  })
                : _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleStopWordInput(true)
                        },
                      },
                    },
                    [_vm._v(" + Aggiungi parola ")]
                  ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }