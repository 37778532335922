var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "period card-base card-shadow--small" }, [
    _c("strong", [
      _vm._v(_vm._s(_vm._f("dayjs")(_vm.startDate, "format", "DD MMMM"))),
    ]),
    !_vm.sameYear
      ? _c("small", { staticClass: "ml-5" }, [
          _vm._v(_vm._s(_vm._f("dayjs")(_vm.startDate, "format", "YYYY"))),
        ])
      : _vm._e(),
    _vm.endDate
      ? _c("span", [
          _c("span", { staticClass: "mh-5" }, [_vm._v("-")]),
          _c("strong", [
            _vm._v(_vm._s(_vm._f("dayjs")(_vm.endDate, "format", "DD MMMM"))),
          ]),
          _c("small", { staticClass: "ml-5" }, [
            _vm._v(_vm._s(_vm._f("dayjs")(_vm.endDate, "format", "YYYY"))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }