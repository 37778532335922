<template>
	<div class="block flex column card-base card-shadow--small" v-loading="loading">
		<div class="label box mb-20">Firma supporto</div>
		<div class="value box">
			<div class="body-editor flex column">
				<EditorHtml :html.sync="supportSignHtml" :preview="true"></EditorHtml>
			</div>

			<div class="toolbar-box flex justify-space-between mt-20" v-if="can_UpdateSupportSign">
				<div class="box grow mr-20">
					<ul class="pl-15">
						<li>usa <strong v-html="'{{firstname}}'"></strong> per il nome</li>
						<li>usa <strong v-html="'{{lastname}}'"></strong> per il cognome</li>
					</ul>
				</div>
				<div class="boxt ext-right">
					<el-button size="medium" plain @click="resetSupportSign">Reset</el-button>
					<el-button
						type="primary"
						plain
						size="medium"
						:disabled="!supportSignHtml"
						@click="updateSupportSign"
					>
						Salva
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api"
import EditorHtml from "@/components/common/EditorHtml"

export default {
	name: "SupportSign",
	data() {
		return {
			loading: false,
			supportSignHtml: ""
		}
	},
	computed: {
		can_UpdateSupportSign() {
			return this.$store.getters.can_UpdateSupportSign
		}
	},
	methods: {
		getSupportSign() {
			this.loading = true

			api.common
				.getSupportSign()
				.then(res => {
					if (res.status === 200 && res.body) {
						this.supportSignHtml = res.body
					}
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.loading = false
				})
		},
		updateSupportSign() {
			this.loading = true
			const template = this.supportSignHtml

			api.common
				.updateSupportSign(template)
				.then(res => {})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.loading = false
				})
		},
		resetSupportSign() {
			this.supportSignHtml = ""
		}
	},
	created() {},
	mounted() {
		this.getSupportSign()
	},
	beforeDestroy() {},
	components: { EditorHtml }
}
</script>

<style lang="scss" scoped>
.block {
	padding: 20px;
	border-radius: 8px;

	.label {
		margin-right: 20px;
	}
}

@media (max-width: 1000px) {
	.block {
		flex-direction: column;
		-webkit-box-orient: vertical;

		.label {
			margin-bottom: 20px;
		}
	}
}
</style>
