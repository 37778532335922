<template>
    <div v-loading="!loaded">
        <div class="main-list scrollable only-y" v-if="emails && emails.length">
            <div
                class="email flex"
                v-for="email of emails"
                :key="email._id"
                data-card="email"
                @click="goToEmail(email._id)"
            >
                <div class="flex column">
                    <div class="initials" :style="{ backgroundColor: email.color }">{{ email.initials }}</div>
                    <div v-if="email.hasAttachments" class="attachments">
                        <i class="mdi mdi-attachment"></i>
                    </div>
                </div>
                <div class="flex column box grow">
                    <div class="email-date box">
                        {{ email.date | dayjs("format", "DD MMM YYYY HH:mm") }}
                    </div>
                    <div class="email-from box">
                        {{ email.from.name || email.from.address }}
                    </div>
                    <div class="email-subject box flex">
                        {{ email.subject }}
                    </div>
                    <div class="email-labels">
                        <span v-for="label in email.labels" :key="label.name" :style="{ backgroundColor: label.color }">
                            {{ label.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!emails || !emails.length">Non sono presenti richieste non lette</div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import stc from "string-to-color"

export default {
    name: "EmailsNotSeen",
    data() {
        return {
            loaded: false,
            emails: []
        }
    },
    computed: {},
    methods: {
        init() {
            this.getEmails()
            this.getEmailsCount()
        },
        getUserInitials(text) {
            let str = "-"

            if (text.indexOf(" ") !== -1) {
                const name = text.split(" ")
                const fn = (name[0].match(/\w+/) || []).join("")
                const ln = (name[name.length - 1].match(/\w+/) || []).join("")

                if (ln) {
                    str = (fn.charAt(0) || "-") + (ln.charAt(0) || "-")
                } else {
                    str = (fn.charAt(0) || "-") + (fn.charAt(1) || "-")
                }
            } else {
                str = (text.charAt(0) || "-") + (text.charAt(1) || "-")
            }

            return str.toUpperCase()
        },
        parseEmails(emails) {
            return _.map(emails, e => {
                e.hasAttachments = false
                e.color = stc(e.from.address)
                e.initials = this.getUserInitials(e.from.name || e.from.address)

                for (const child of e.children) {
                    if (child.attachments.length) {
                        e.hasAttachments = true
                    }
                }
                return e
            })
        },
        getEmails() {
            api.emails
                .get({ lastId: "", itemsPerPage: 10, notSeen: true })
                .then(res => {
                    if (res.status === 200 && res.body && res.body.length) {
                        const emails = this.parseEmails(res.body)
                        this.emails = emails
                    } else {
                        this.emails = []
                    }

                    this.loaded = true
                })
                .catch(err => {
                    console.error(err)
                })
        },
        getEmailsCount() {
            api.emails
                .getNotSeenCount()
                .then(res => {
                    if (res.status === 200 && res.body && res.body.count) {
                        this.$emit("update:count", res.body.count)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        goToEmail(id) {
            this.$router.push(`/support/requests#${id}`).catch(err => {})
        }
    },
    created() {},
    mounted() {
        this.init()
    },
    beforeDestroy() {},
    components: {}
}
</script>



<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.main-list {
    max-height: 380px;

    .email {
        background: rgba($background-color, 0.5);
        border-radius: 8px;
        padding: 10px 20px;
        margin: 10px 0;
        cursor: pointer;
        transition: all 0.2s;

        .initials {
            --size: 40px;
            margin-right: 0;
            margin-top: 26px;
            width: var(--size);
            height: var(--size);
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            box-sizing: border-box;
            padding-top: 1px;
            border-radius: 50%;
            text-align: center;
            line-height: var(--size);
            text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        }

        .attachments {
            text-align: center;
            font-size: 24px;
            opacity: 0.7;
            margin-top: 6px;
            margin-bottom: 4px;
        }

        .email-date {
            text-align: right;
            font-size: 12px;
            padding-top: 10px;
            padding-right: 10px;
            opacity: 0.7;
        }
        .email-from {
            font-weight: bold;
            padding: 0 20px;
            padding-bottom: 4px;
        }
        .email-subject {
            padding: 0px 20px;
            font-size: 14px;
            padding-bottom: 20px;
        }

        .email-labels {
            font-size: 12px;
            font-weight: bold;
            padding: 0px 15px;
            margin-top: -10px;
            padding-bottom: 20px;

            span {
                color: rgba(0, 0, 0, 0.5);
                padding: 2px 6px;
                border-radius: 4px;
                margin: 5px;
            }
        }

        &:hover {
            background: rgba($background-color, 1.5);
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
