<template>
    <div v-loading="!loaded">
        <div class="reports-list scrollable only-y" v-if="reports && reports.length">
            <div v-for="report in reports" :key="report._id" class="report" @click="goToReport(report._id)">
                <div class="header flex mb-5">
                    <div
                        class="msg-author"
                        @click.stop="openUser(report.message.author)"
                        :style="{ color: report.message.author.color }"
                    >
                        @{{ report.message.author.username }}
                    </div>
                    <div class="msg-time hidden-xs-only">
                        {{ report.message.date_sent | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}
                    </div>
                </div>
                <div class="content">
                    <div
                        class="msg-body"
                        v-if="report.message.body"
                        v-html="$options.filters.selected(report.message.body, report.stop_words)"
                    ></div>
                    <div class="msg-body-img" v-if="report.message.type === 'img' || report.message.type === 'sticker'">
                        <img :src="report.message.imageThumb" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!reports || !reports.length">Non sono presenti segnalazioni in evidenza</div>
    </div>
</template>

<script>
import _ from "lodash"
import { openUserDialog } from "@/common"
import api from "@/api"

export default {
    name: "ReportsPinned",
    data() {
        return {
            loaded: false,
            reports: {},
            stickersList: []
        }
    },
    computed: {},
    filters: {
        /**
         * @param {string} value
         * @param {string[]} sel
         *
         * @returns {string}
         */
        selected: function (value, words) {
            if (!value) return ""
            value = value.toString()

            if (!words || !words.length) return value

            for (const word of words) {
                const startIndex = value.toLowerCase().indexOf(word.toLowerCase())
                if (startIndex !== -1) {
                    const endLength = word.length
                    const matchingString = value.substr(startIndex, endLength)
                    value = value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
                }
            }
            //return value.toString().replace(new RegExp(sel,"gim"), `<span class="sel">${sel}</span>`)
            return value
        }
    },
    methods: {
        init() {
            this.getStickers(() => {
                this.getReportsStats()
            })
        },
        getStickers(cb) {
            api.chat
                .getStickers()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        let list = []

                        for (const pack of res.body) {
                            list = [...pack.stickers, ...list]
                        }

                        this.stickersList = list
                    }
                    if (cb && typeof cb === "function") {
                        cb()
                    }
                })
                .catch(err => {
                    console.error(err)
                    if (cb && typeof cb === "function") {
                        cb()
                    }
                })
        },
        msgSanitizer(msg) {
            if (msg.body) {
                msg.body = msg.body.replace(/(?:\r\n|\r|\n)/g, "<br>")
            }

            if (msg.type === "sticker") {
                if (_.some(this.stickersList, s => s.name === msg.body)) {
                    msg.imageThumb = _.find(this.stickersList, s => s.name === msg.body).image
                    msg.body = ""
                }
            }

            return msg
        },
        getReportsStats() {
            api.common
                .getReportsStats()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const body = _.get(res, "body")
                        if (body.reportsPinned && body.reportsPinned.length) {
                            body.reportsPinned = _.map(body.reportsPinned, r => {
                                r.message = this.msgSanitizer(r.message)
                                return r
                            })
                            this.reports = body.reportsPinned

                            this.$emit("update:count", body.reportsPinned.length)
                        } else {
                            this.reports = []
                        }
                    }
                    this.loaded = true
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openUser(author) {
            if (author.id) {
                openUserDialog({ id: author.id, user: author })
            }
        },
        goToReport(id) {
            this.$router.push(`/chat/reports#${id}`).catch(err => {})
        }
    },
    created() {},
    mounted() {
        this.init()
    },
    beforeDestroy() {},
    components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.reports-list {
    max-height: 380px;

    .report {
        background: rgba($background-color, 0.5);
        border-radius: 8px;
        padding: 10px 20px;
        margin: 10px 0;
        cursor: pointer;
        transition: all 0.2s;

        .header {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .msg-author {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: bold;
                margin-right: 10px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
            .msg-time {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                opacity: 0.5;
                text-transform: capitalize;
                font-size: 13px;
                margin-top: 3px;
            }
        }
        .content {
            .msg-body {
                .sel {
                    background: transparentize($color-warning, 0.8);
                    border-radius: 5px;
                    //text-transform: uppercase;
                }
            }
            .msg-body-img {
                img {
                    border-radius: 6px;
                    display: block;
                    max-width: 200px;
                    max-height: 100px;
                }
            }
        }

        &:hover {
            background: rgba($background-color, 1.5);
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
