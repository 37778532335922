<template>
	<div class="page-chat">
		<Chat app="imamma" :goToRoom="$route.params.room" :goToMessage="$route.params.message" />
	</div>
</template>

<script>
const Chat = () => import("@/components/Chat/Client")

export default {
	name: "ChatPage",
	data() {
		return {}
	},
	computed: {},
	methods: {},
	created() {},
	mounted() {},
	beforeDestroy() {},
	components: {
		Chat
	}
}
</script>

<style lang="scss" scoped>
.page-chat {
	overflow: hidden;
}
</style>
