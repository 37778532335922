var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "msg-form flex column" }, [
    _c("div", { staticClass: "header" }, [
      _vm._v("Nuovo messaggio istituzionale"),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingRooms,
            expression: "loadingRooms",
          },
        ],
        staticClass: "rooms-list box flex column",
      },
      [
        _c("div", { staticClass: "header flex" }, [
          _c("div", [_vm._v("Scegli le stanze")]),
          _c("div", { staticClass: "mh-10" }, [_vm._v("-")]),
          !_vm.isAllSelected
            ? _c(
                "div",
                {
                  staticClass: "clickable",
                  on: {
                    click: function ($event) {
                      return _vm.toggleAll(true)
                    },
                  },
                },
                [_vm._v("seleziona tutte")]
              )
            : _vm._e(),
          _vm.isAllSelected
            ? _c(
                "div",
                {
                  staticClass: "clickable",
                  on: {
                    click: function ($event) {
                      return _vm.toggleAll(false)
                    },
                  },
                },
                [_vm._v("deseleziona tutte")]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "list-wrapper box flex wrap gaps" },
          _vm._l(_vm.roomsFiltered, function (group, index) {
            return _c(
              "div",
              { key: group.name, staticClass: "rooms-group box flex column" },
              [
                _c("div", { staticClass: "group-name box flex" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(group.name)),
                  ]),
                  _c("div", { staticClass: "mh-10" }, [_vm._v("-")]),
                  !_vm.isTypeSelected[index]
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable",
                          on: {
                            click: function ($event) {
                              return _vm.toggleType(group.name, true)
                            },
                          },
                        },
                        [_vm._v(" seleziona tutte ")]
                      )
                    : _vm._e(),
                  _vm.isTypeSelected[index]
                    ? _c(
                        "div",
                        {
                          staticClass: "clickable",
                          on: {
                            click: function ($event) {
                              return _vm.toggleType(group.name, false)
                            },
                          },
                        },
                        [_vm._v(" deseleziona tutte ")]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        placeholder: "Filtra...",
                        clearable: "",
                      },
                      model: {
                        value: _vm.roomsFilters[group.name],
                        callback: function ($$v) {
                          _vm.$set(_vm.roomsFilters, group.name, $$v)
                        },
                        expression: "roomsFilters[group.name]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "list box scrollable only-y" },
                  _vm._l(group.items, function (room) {
                    return _c(
                      "div",
                      {
                        key: room._id,
                        staticClass: "room flex card-base card-shadow--small",
                        class: { active: room.selected },
                        on: {
                          click: function ($event) {
                            return _vm.toggleRoom(room._id)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "room-name box grow" }, [
                          _vm._v(_vm._s(room.name)),
                        ]),
                        _c("div", { staticClass: "room-special" }, [
                          room.isspecial
                            ? _c("i", { staticClass: "mdi mdi-star-outline" })
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "room-check" }, [
                          !room.selected
                            ? _c("i", {
                                staticClass: "mdi mdi-checkbox-blank-outline",
                              })
                            : _vm._e(),
                          room.selected
                            ? _c("i", {
                                staticClass: "mdi mdi-check-box-outline",
                              })
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _c("div", { staticClass: "input-box flex" }, [
      _c(
        "div",
        { staticClass: "image-el", style: { "background-image": _vm.bg } },
        [
          !_vm.image
            ? _c("FileDrop", {
                staticStyle: { opacity: "0.4" },
                attrs: { hover: false, icon: "mdi mdi-image-outline" },
                on: { change: _vm.fileChange },
              })
            : _vm._e(),
          _vm.image
            ? _c(
                "div",
                {
                  staticClass: "btn-clear-photo",
                  on: {
                    click: function ($event) {
                      _vm.image = ""
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-close" })]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-el box grow" },
        [
          _c("el-input", {
            ref: "textarea",
            attrs: {
              type: "textarea",
              autosize: { minRows: 5 },
              placeholder: "Messaggio...",
              size: "medium",
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingSend,
              expression: "loadingSend",
            },
          ],
          staticClass: "send-el box flex column",
        },
        [
          _c(
            "div",
            { staticClass: "box mb-10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "medium",
                    disabled: !_vm.isValid,
                  },
                  on: { click: _vm.send },
                },
                [_vm._v(" Invia ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", plain: "" },
                  on: { click: _vm.reset },
                },
                [_vm._v("Reset")]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "url-box mt-20" },
      [
        _c("el-input", {
          attrs: { placeholder: "Url...", size: "medium" },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "mt-20" }, [
      _vm._v(
        " Stanze selezionate: " + _vm._s(_vm.roomsSelected || "nessuna") + " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }