import { render, staticRenderFns } from "./RoomInfo.vue?vue&type=template&id=752145c0&scoped=true&"
import script from "./RoomInfo.vue?vue&type=script&lang=js&"
export * from "./RoomInfo.vue?vue&type=script&lang=js&"
import style0 from "./RoomInfo.vue?vue&type=style&index=0&id=752145c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752145c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/myfamilyplace/web/imamma-community-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('752145c0')) {
      api.createRecord('752145c0', component.options)
    } else {
      api.reload('752145c0', component.options)
    }
    module.hot.accept("./RoomInfo.vue?vue&type=template&id=752145c0&scoped=true&", function () {
      api.rerender('752145c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Chat/RoomInfo.vue"
export default component.exports