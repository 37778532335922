<template>
    <div class="page-review flex column" v-loading="loading">
        <div class="buttons-box box">
            <div @click="goToBack" class="go-back-btn mr-30" v-if="showGoToBack">
                <i class="mdi mdi-arrow-left"></i> indietro
            </div>
        </div>

        <ReviewForm
            class="box grow"
            :id="$route.params.id"
            :dirty.sync="dirty"
            @error="goToPublished"
            @deleted="goToDraft"
            @goToReview="goToReview"
            @loaded="loading = false"
        ></ReviewForm>
    </div>
</template>

<script>
const ReviewForm = () => import("@/components/Reviews/ReviewForm")

export default {
    name: "Review",
    data() {
        return {
            loading: true,
            dirty: false
        }
    },
    computed: {
        showGoToBack() {
            return window.history.state !== null
        }
    },
    methods: {
        goToPublished() {
            this.$router.replace({ path: "/reviews/published" }).catch(err => {})
        },
        goToDraft() {
            this.$router.replace({ path: "/reviews/draft" }).catch(err => {})
        },
        goToReview(id) {
            this.$router.push(`/reviews/${id || ""}`).catch(err => {})
        },
        goToBack() {
            this.$router.go(-1)
        },
        askConfirm(to, from, next) {
            if (this.dirty) {
                this.$confirm(
                    "Hai apportato modifiche alla recensione che non sono state salvate. Sicura di voler abbandonare questa pagina ?",
                    "Attenzione",
                    {
                        confirmButtonText: "Si",
                        cancelButtonText: "Annulla",
                        type: "warning"
                    }
                )
                    .then(() => {
                        next()
                    })
                    .catch(err => {
                        next(false)
                    })
            } else {
                next()
            }
        }
    },
    created() {},
    mounted() {
        if (!this.$route.params.id) {
            this.loading = false
        }
    },
    beforeDestroy() {},
    beforeRouteUpdate(to, from, next) {
        this.askConfirm(to, from, next)
    },
    beforeRouteLeave(to, from, next) {
        this.askConfirm(to, from, next)
    },
    components: { ReviewForm }
}
</script>

<style lang="scss" scoped>
.page-review {
    .go-back-btn {
        cursor: pointer;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    .buttons-box {
        margin-bottom: 20px;
    }
}
</style>
