var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "card", staticClass: "url-meta-card", class: [_vm.size] },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _vm.showInput
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.urlModel,
                expression: "urlModel",
              },
            ],
            domProps: { value: _vm.urlModel },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.urlModel = $event.target.value
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isValid,
              expression: "isValid",
            },
          ],
          staticClass: "card flex column card-base card-shadow--small mv-20",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isValid,
                  expression: "isValid",
                },
              ],
              staticClass: "flex box",
            },
            [
              _vm.meta && _vm.meta.image
                ? _c(
                    "div",
                    { staticClass: "image-box box" },
                    [
                      _c("vue-load-image", [
                        _c("img", {
                          staticClass: "image",
                          attrs: { slot: "image", src: _vm.meta.image },
                          on: {
                            click: function ($event) {
                              return _vm.clickItem("image", _vm.meta.image)
                            },
                          },
                          slot: "image",
                        }),
                        _c("img", {
                          staticClass: "preloader",
                          attrs: {
                            slot: "preloader",
                            src: require("@/assets/images/loading.png"),
                          },
                          slot: "preloader",
                        }),
                        _c("img", {
                          staticClass: "error",
                          attrs: {
                            slot: "error",
                            src: require("@/assets/images/img-not-found.svg"),
                          },
                          slot: "error",
                        }),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "info-box box grow" }, [
                _vm.meta && _vm.meta.site
                  ? _c("div", { staticClass: "site" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clickItem("site", _vm.meta.site)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.meta.site) + " ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.meta && _vm.meta.title
                  ? _c("div", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clickItem("title", _vm.meta.title)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.meta.title) + " ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.meta && _vm.meta.description
                  ? _c("div", { staticClass: "description" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clickItem(
                                "description",
                                _vm.meta.description
                              )
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.meta.description) + " ")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm.meta &&
          _vm.meta.images &&
          _vm.meta.images.length &&
          _vm.meta.images.length > 1
            ? _c("div", { staticClass: "box" }, [
                _c(
                  "div",
                  { staticClass: "images-list scrollable only-x" },
                  _vm._l(_vm.meta.images, function (img, index) {
                    return _c(
                      "vue-load-image",
                      {
                        key: index,
                        staticClass: "card-base card-shadow--small",
                      },
                      [
                        _c("img", {
                          staticClass: "image",
                          attrs: { slot: "image", src: img },
                          on: {
                            click: function ($event) {
                              return _vm.clickItem("image", img)
                            },
                          },
                          slot: "image",
                        }),
                        _c("img", {
                          staticClass: "preloader",
                          attrs: {
                            slot: "preloader",
                            src: require("@/assets/images/loading.png"),
                          },
                          slot: "preloader",
                        }),
                        _c("img", {
                          staticClass: "error",
                          attrs: {
                            slot: "error",
                            src: require("@/assets/images/img-not-found.svg"),
                          },
                          slot: "error",
                        }),
                      ]
                    )
                  }),
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }