var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-reviews-list flex column",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.loading && (_vm.featured.length || _vm.reviews.length),
              expression: "!loading && (featured.length || reviews.length)",
            },
          ],
          staticClass: "reviews-list scrollable only-y box grow",
        },
        [
          _c(
            "div",
            { staticClass: "featured-group" },
            [
              _c("div", { staticClass: "fs-30 mb-20" }, [
                _vm._v("In evidenza"),
              ]),
              !_vm.loading && !_vm.featured.length
                ? _c("div", { staticClass: "review flex align-center" }, [
                    _vm._m(0),
                  ])
                : _vm._e(),
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "list-group",
                    attrs: { tag: "div", handle: ".handle", group: "featured" },
                    on: {
                      start: function ($event) {
                        _vm.drag = true
                      },
                      end: function ($event) {
                        _vm.drag = false
                      },
                    },
                    model: {
                      value: _vm.featured,
                      callback: function ($$v) {
                        _vm.featured = $$v
                      },
                      expression: "featured",
                    },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      attrs: {
                        type: "transition",
                        name: !_vm.drag ? "flip-list" : null,
                      },
                    },
                    _vm._l(_vm.featured, function (r) {
                      return _c("ReviewCard", {
                        key: r._id,
                        attrs: { review: r, handle: true },
                        on: {
                          click: function ($event) {
                            return _vm.goTo(r._id)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "list-group",
                attrs: { tag: "div", handle: ".handle", group: "reviews" },
                on: {
                  start: function ($event) {
                    _vm.drag = true
                  },
                  end: function ($event) {
                    _vm.drag = false
                  },
                },
                model: {
                  value: _vm.reviews,
                  callback: function ($$v) {
                    _vm.reviews = $$v
                  },
                  expression: "reviews",
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                {
                  attrs: {
                    type: "transition",
                    name: !_vm.drag ? "flip-list" : null,
                  },
                },
                _vm._l(_vm.reviews, function (r) {
                  return _c("ReviewCard", {
                    key: r._id,
                    attrs: { review: r, handle: true },
                    on: {
                      click: function ($event) {
                        return _vm.goTo(r._id)
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loading && !_vm.reviews.length && !_vm.featured.length
        ? _c("div", { staticClass: "reviews-list" }, [
            _c(
              "div",
              {
                staticClass: "review flex align-center",
                on: {
                  click: function ($event) {
                    return _vm.goTo()
                  },
                },
              },
              [_vm._m(1)]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box grow info" }, [
      _c("div", { staticClass: "title not-clickable mt-10" }, [
        _vm._v("Non sono presenti recensioni in evidenza"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box grow info" }, [
      _c("div", { staticClass: "title mt-10" }, [
        _vm._v("Non sono presenti recensioni pubblicate, creane una!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }