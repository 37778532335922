<template>
    <div class="url-meta-card" :class="[size]" ref="card">
        <resize-observer @notify="__resizeHandler" />

        <input v-model="urlModel" v-if="showInput" />
        <div class="card flex column card-base card-shadow--small mv-20" v-loading="loading" v-show="isValid">
            <div class="flex box" v-loading="loading" v-show="isValid">
                <div class="image-box box" v-if="meta && meta.image">
                    <vue-load-image>
                        <img slot="image" class="image" :src="meta.image" @click="clickItem('image', meta.image)" />
                        <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                        <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                    </vue-load-image>
                </div>
                <div class="info-box box grow">
                    <div class="site" v-if="meta && meta.site">
                        <span @click="clickItem('site', meta.site)">
                            {{ meta.site }}
                        </span>
                    </div>
                    <div class="title" v-if="meta && meta.title">
                        <span @click="clickItem('title', meta.title)">
                            {{ meta.title }}
                        </span>
                    </div>
                    <div class="description" v-if="meta && meta.description">
                        <span @click="clickItem('description', meta.description)">
                            {{ meta.description }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="box" v-if="meta && meta.images && meta.images.length && meta.images.length > 1">
                <div class="images-list scrollable only-x">
                    <vue-load-image
                        v-for="(img, index) of meta.images"
                        :key="index"
                        class="card-base card-shadow--small"
                    >
                        <img slot="image" class="image" :src="img" @click="clickItem('image', img)" />
                        <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                        <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                    </vue-load-image>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import utils from "@/utils"
import isURL from "validator/lib/isURL"
const VueLoadImage = () => import("vue-load-image")

export default {
    name: "UrlMetaCard",
    props: {
        url: {
            type: String
        },
        data: {
            type: Object
        },
        showInput: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            meta: null,
            urlModel: null,
            size: "large"
        }
    },
    computed: {
        isValid() {
            return isURL(this.urlModel || "")
        }
    },
    watch: {
        url(val) {
            this.urlModel = this.url || ""
        },
        urlModel(val) {
            if (isURL(val || "")) {
                this.getMeta(val)
            } else {
                this.meta = null
            }
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.card) {
                const width = this.$refs.card.offsetWidth

                this.size = "large"
                if (width < 500) {
                    this.size = "small"
                } else if (width < 600) {
                    this.size = "medium"
                }
            }
        },
        getMeta: _.debounce(function (url) {
            this.loading = true

            api.common
                .getUrlMeta(url)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.meta = res.body
                    } else {
                        this.meta = null
                    }
                })
                .catch(err => {
                    this.meta = null
                })
                .finally(() => {
                    this.$emit("update:data", this.meta)
                    this.loading = false
                })
        }, 200),
        clickItem(key, value) {
            this.$emit("click", { key, value })
        }
    },
    created() {},
    mounted() {
        this.urlModel = this.url || ""

        this.calcDim()
    },
    beforeDestroy() {},
    components: { "vue-load-image": VueLoadImage }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.url-meta-card {
    .card {
        position: relative;
        padding: 30px;

        .image-box {
            width: 200px;
            margin-right: 30px;

            .vue-load-image {
                width: 100%;

                img {
                    width: 100%;
                    display: block;
                    border-radius: 6px;
                    cursor: pointer;

                    &.image {
                        transition: all 0.2s;

                        &:hover {
                            box-shadow: 0px 0px 0px 3px $color-primary;
                            border-color: $color-primary;
                        }
                    }
                }
            }
        }

        .info-box {
            .site {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
                opacity: 0.7;
            }

            .title {
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 10px;
            }

            .description {
                font-size: 18px;
            }

            & > div {
                span {
                    position: relative;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        right: 0px;
                        height: 2px;
                        background-color: $color-primary;
                        transform-origin: right bottom;
                        transform: scaleX(0);
                        transition: transform 0.5s ease 0s;
                    }

                    &:hover {
                        &::before {
                            transform-origin: left bottom;
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        .images-list {
            display: block;
            width: 100%;
            white-space: nowrap;
            background: $background-color;
            padding: 20px;
            border-radius: 10px;
            box-sizing: border-box;
            margin-top: 20px;

            .vue-load-image {
                display: inline-block;
                height: 70px;
                margin-right: 20px;
                transition: all 0.2s;
                max-width: 150px;

                img {
                    display: block;
                    height: 100%;

                    &.image {
                        cursor: pointer;
                    }
                }

                &:hover {
                    box-shadow: 0px 0px 0px 2px $color-primary;
                }
            }
        }
    }

    &.medium,
    &.small {
        .card {
            flex-direction: column;
            -webkit-box-orient: vertical;

            .image-box {
                margin-right: 0px;
                margin-bottom: 20px;
            }
        }
    }
    &.small {
        .card {
            .image-box {
                width: 100%;
            }
        }
    }
}
</style>
