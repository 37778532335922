<template>
    <div class="question-attachments-page">
        <AttachmentList itemType="question"></AttachmentList>
    </div>
</template>

<script>
import AttachmentList from "@/components/Qna/AttachmentList"

export default {
    name: "QuestionAttachments",
    components: {
        AttachmentList
    }
}
</script>
