<template>
    <div class="qna-attachment card-base card-shadow--small">
        <div class="wrapper flex column">
            <QnaUser :user="item.user" :size="'small'" class="mb-15"></QnaUser>
            <div class="qna-body-img box grow">
                <vue-load-image>
                    <img slot="image" class="image" :src="item.image" v-img="{ src: item.image }" />
                    <!--                <img slot="preloader" class="preloader" :src="require('@/assets/images/logo_anim.svg')" />-->
                    <div slot="preloader" class="pt-50">
                        <div v-loading="true"></div>
                    </div>
                    <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                </vue-load-image>
            </div>
            <QnaActions :item="item" :itemType="item.itemType" @deleted="$emit('deleted')" class="mt-5"></QnaActions>
        </div>
    </div>
</template>

<script>
import QnaActions from "@/components/Qna/QnaActions"
import QnaUser from "@/components/Qna/QnaUser"
const VueLoadImage = () => import("vue-load-image")

export default {
    name: "QnaAttachment",
    props: {
        item: {
            type: Object
        }
    },
    methods: {},
    components: {
        "vue-load-image": VueLoadImage,
        QnaActions,
        QnaUser
    }
}
</script>

<style lang="scss" scoped>
.qna-attachment {
    margin: 0 0.5%;
    margin-bottom: 0.6%;
    display: inline-block;
    padding: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 19%;
    //aspect-ratio: 3/3.5;
    height: 21vw;
    max-height: 420px;

    .wrapper {
        height: 100%;

        .qna-body-img {
            overflow: hidden;
            margin-bottom: 10px;

            .vue-load-image {
                overflow: hidden;
                height: 100%;
                width: 100%;

                img {
                    border-radius: 6px;
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 1700px) {
        width: 24%;
        height: 26vw;
    }

    @media (max-width: 1400px) {
        width: 32.3333%;
        height: 33vw;
    }

    @media (max-width: 1100px) {
        width: 49%;
        height: 48vw;
    }

    @media (max-width: 1000px) {
        width: 100%;
        height: 100vw;
        max-height: 680px;
        display: block;
        margin: 0 0;
        margin-bottom: 20px;

        .qna-body-img {
            .vue-load-image {
                height: inherit;
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
