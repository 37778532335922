var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "user-cloud",
    },
    [
      _vm.cloud
        ? _c("div", [
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/pregnancy.svg"),
                  },
                }),
                _vm._v(" Gravidanze "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.pregnancies.length) + ")"),
                ]),
              ]),
              _vm.cloud.pregnancies.length
                ? _c(
                    "div",
                    { staticClass: "list pregnancy-list" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.pregnancies, function (pregnancy) {
                          return _c("Pregnancy", {
                            key: pregnancy.id,
                            staticClass: "mr-20 mb-10",
                            attrs: { data: pregnancy },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessuna gravidanza registrata")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/ciclo.svg"),
                  },
                }),
                _vm._v(" Cicli mestruali "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.periods.length) + ")"),
                ]),
              ]),
              _vm.cloud.periods.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.periods, function (period) {
                          return _c("Period", {
                            key: period.id,
                            staticClass: "mr-20",
                            attrs: {
                              data: period,
                              "flow-duration": _vm.user.flow_duration,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun ciclo mestruale registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/sesso.svg"),
                  },
                }),
                _vm._v(" Relazioni sessuali "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.sexualrelations.length) + ")"),
                ]),
              ]),
              _vm.cloud.sexualrelations.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(
                          _vm.cloud.sexualrelations,
                          function (sexualrelation) {
                            return _c("SexualRelation", {
                              key: sexualrelation.id,
                              staticClass: "mr-20",
                              attrs: { data: sexualrelation },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessuna relazione sessuale registrata")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/pressione.svg"),
                  },
                }),
                _vm._v(" Pressione sanguigna "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.bloodpressures.length) + ")"),
                ]),
              ]),
              _vm.cloud.bloodpressures.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(
                          _vm.cloud.bloodpressures,
                          function (bloodpressure) {
                            return _c("BloodPressure", {
                              key: bloodpressure.id,
                              staticClass: "mr-20",
                              attrs: { data: bloodpressure },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessuna pressione sanguigna registrata")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/umore.svg"),
                  },
                }),
                _vm._v(" Umori "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.moods.length) + ")"),
                ]),
              ]),
              _vm.cloud.moods.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.moods, function (mood) {
                          return _c("Mood", {
                            key: mood.id,
                            staticClass: "mr-20",
                            attrs: { data: mood, list: _vm.cloud.moodList },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun umore registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/idratazione.svg"),
                  },
                }),
                _vm._v(" Idratazione "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.hydrations.length) + ")"),
                ]),
              ]),
              _vm.cloud.hydrations.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.hydrations, function (hydration) {
                          return _c("Hydration", {
                            key: hydration.id,
                            staticClass: "mr-20",
                            attrs: { data: hydration },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessuna idratazione registrata")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/evento.svg"),
                  },
                }),
                _vm._v(" Eventi "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.events.length) + ")"),
                ]),
              ]),
              _vm.cloud.events.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.events, function (event) {
                          return _c("Event", {
                            key: event.id,
                            staticClass: "mr-20",
                            attrs: { data: event },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun evento registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/note.svg"),
                  },
                }),
                _vm._v(" Note "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.notes.length) + ")"),
                ]),
              ]),
              _vm.cloud.notes.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.notes, function (note) {
                          return _c("Note", {
                            key: note.id,
                            staticClass: "mr-20",
                            attrs: { data: note },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessuna nota registrata")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-pregnancy-icons/peso.svg"),
                  },
                }),
                _vm._v(" Peso corporeo "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.weights.length) + ")"),
                ]),
              ]),
              _vm.cloud.weights.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.weights, function (weight) {
                          return _c("Weight", {
                            key: weight.id,
                            staticClass: "mr-20",
                            attrs: { data: weight },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun peso corporeo registrato")]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }