<template>
    <div class="msg-form flex column">
        <div class="header">Nuovo messaggio istituzionale</div>

        <div class="rooms-list box flex column" v-loading="loadingRooms">
            <div class="header flex">
                <div>Scegli le stanze</div>
                <div class="mh-10">-</div>
                <div class="clickable" @click="toggleAll(true)" v-if="!isAllSelected">seleziona tutte</div>
                <div class="clickable" @click="toggleAll(false)" v-if="isAllSelected">deseleziona tutte</div>
            </div>
            <div class="list-wrapper box flex wrap gaps">
                <div class="rooms-group box flex column" v-for="(group, index) of roomsFiltered" :key="group.name">
                    <div class="group-name box flex">
                        <div class="name">{{ group.name }}</div>
                        <div class="mh-10">-</div>
                        <div class="clickable" @click="toggleType(group.name, true)" v-if="!isTypeSelected[index]">
                            seleziona tutte
                        </div>
                        <div class="clickable" @click="toggleType(group.name, false)" v-if="isTypeSelected[index]">
                            deseleziona tutte
                        </div>
                    </div>
                    <div class="filter">
                        <el-input
                            size="small"
                            placeholder="Filtra..."
                            clearable
                            v-model="roomsFilters[group.name]"
                        ></el-input>
                    </div>
                    <div class="list box scrollable only-y">
                        <div
                            class="room flex card-base card-shadow--small"
                            v-for="room of group.items"
                            :key="room._id"
                            @click="toggleRoom(room._id)"
                            :class="{ active: room.selected }"
                        >
                            <div class="room-name box grow">{{ room.name }}</div>
                            <div class="room-special">
                                <i class="mdi mdi-star-outline" v-if="room.isspecial"></i>
                            </div>
                            <div class="room-check">
                                <i class="mdi mdi-checkbox-blank-outline" v-if="!room.selected"></i>
                                <i class="mdi mdi-check-box-outline" v-if="room.selected"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="input-box flex">
            <div class="image-el" :style="{ 'background-image': bg }">
                <FileDrop
                    @change="fileChange"
                    :hover="false"
                    :icon="'mdi mdi-image-outline'"
                    style="opacity: 0.4"
                    v-if="!image"
                ></FileDrop>
                <div class="btn-clear-photo" v-if="image" @click="image = ''"><i class="mdi mdi-close"></i></div>
            </div>
            <div class="input-el box grow">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 5 }"
                    placeholder="Messaggio..."
                    v-model="text"
                    ref="textarea"
                    size="medium"
                >
                </el-input>
            </div>

            <div class="send-el box flex column" v-loading="loadingSend">
                <div class="box mb-10">
                    <el-button @click="send" type="primary" plain size="medium" :disabled="!isValid"> Invia </el-button>
                </div>
                <div class="box">
                    <el-button @click="reset" size="medium" plain>Reset</el-button>
                </div>
            </div>
        </div>
        <div class="url-box mt-20">
            <el-input placeholder="Url..." v-model="url" size="medium"></el-input>
        </div>
        <div class="mt-20">
            Stanze selezionate:
            {{ roomsSelected || "nessuna" }}
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import utils from "@/utils"
import FileDrop from "@/components/common/FileDrop"

export default {
    name: "MsgInstitutionalForm",
    data() {
        return {
            rooms: [],
            text: "",
            image: "",
            url: "",
            loadingSend: false,
            loadingRooms: false,
            roomsFilters: {}
        }
    },
    computed: {
        roomsGroups() {
            return _.chain(this.rooms)
                .orderBy([r => r.isspecial, r => r.order], ["desc", "asc"])
                .groupBy("roomType")
                .map(obj => ({ name: obj[0].roomType, items: obj }))
                .value()
        },
        roomsFiltered() {
            return _.chain(this.roomsGroups)
                .map(obj => {
                    const itemsFiltered = _.filter(obj.items, i => {
                        return this.roomsFilters[obj.name]
                            ? i.name.toLowerCase().indexOf(this.roomsFilters[obj.name].toLowerCase()) !== -1
                            : true
                    })
                    return { name: obj.name, items: itemsFiltered }
                })
                .value()
        },
        roomsSelected() {
            return _.chain(this.rooms)
                .filter(r => {
                    return r.selected
                })
                .map(r => _.capitalize(r.roomType) + " / " + r.name)
                .join(", ")
                .value()
        },
        bg() {
            return `url(${this.image}`
        },
        isValid() {
            if (!this.roomsSelected) {
                return false
            }

            if (!_.trim(this.text) && !this.image) {
                return false
            }

            return true
        },
        isTypeSelected() {
            return _.map(this.roomsGroups, g => {
                return g.items.length === _.filter(g.items, i => i.selected).length
            })
        },
        isAllSelected() {
            return this.rooms.length === _.filter(this.rooms, i => i.selected).length
        }
    },
    methods: {
        getRooms() {
            this.loadingRooms = true
            api.chat
                .getRooms()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const rooms = res.body
                        this.rooms = _.map(rooms, r => {
                            r.selected = false
                            return r
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadingRooms = false
                })
        },
        fileChange(file) {
            utils.file2Base64(file).then(base64 => {
                this.image = base64
            })
        },
        toggleRoom(id) {
            const room = _.find(this.rooms, r => r._id === id)
            room.selected = !room.selected
        },
        toggleType(type, check) {
            for (const r of this.rooms) {
                if (r.roomType === type) {
                    r.selected = check
                }
            }
        },
        toggleAll(check) {
            for (const r of this.rooms) {
                r.selected = check
            }
        },
        reset() {
            this.toggleAll(false)
            this.text = ""
            this.image = ""
            this.url = ""
        },
        send() {
            this.$confirm("Sicura di voler inviare questo messaggio ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning"
            })
                .then(() => {
                    this.loadingSend = true
                    this.$message("Sto inviando il messaggio...")

                    const rooms = _.chain(this.rooms)
                        .filter(r => {
                            return r.selected
                        })
                        .map(r => ({ id: r._id, name: r.name, type: r.roomType, online: r.userscount }))
                        .value()
                    const text = this.text || ""
                    const image = this.image || ""
                    const url = this.url || ""

                    return api.chat
                        .saveInstitutionalMessage(rooms, text, image, url)
                        .then(response => {
                            this.getRooms()
                            this.reset()
                            this.$emit("sent")

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Messaggio inviato correttamente`
                            })
                        })
                        .catch(err => {
                            this.$notify.error({
                                title: "Ops...",
                                message: "Errore invio messaggio"
                            })
                        })
                        .finally(() => {
                            this.loadingSend = false
                        })
                })
                .catch(() => {})
        },
        copy(msg) {
            this.text = msg.text
            this.image = msg.image
            this.url = msg.url
        }
    },
    created() {},
    mounted() {
        this.getRooms()
    },
    beforeDestroy() {},
    components: {
        FileDrop
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";

.msg-form {
    @extend .card-base;
    @extend .card-shadow--small;
    padding: 20px;

    & > .header {
        font-size: 20px;
        margin-bottom: 20px;
        user-select: none;
    }

    .rooms-list {
        background: $background-color;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 4px;

        .clickable {
            font-weight: bold;
            cursor: pointer;
            color: $color-primary;

            &:hover {
                text-decoration: underline;
            }
        }

        .header {
            font-size: 18px;
            margin-bottom: 10px;
            padding: 10px;
            user-select: none;
        }

        .list-wrapper {
            width: 100%;
            user-select: none;

            .rooms-group {
                padding: 10px;
                box-sizing: border-box;
                max-width: 100%;
                min-width: 350px;

                .group-name {
                    .name {
                        text-transform: capitalize;
                        font-weight: bold;
                    }
                }
                .filter {
                    margin: 10px 0;
                }
                .list {
                    max-height: 300px;
                    width: 100%;
                    background: rgba($color-primary, 0.05);
                    border-radius: 4px;
                    border: 1px solid #dcdfe6;
                    box-sizing: border-box;

                    .room {
                        margin: 10px 8px;
                        padding: 10px 5px;
                        cursor: pointer;
                        transition: all ease 0.25s;
                        white-space: nowrap;

                        .room-name {
                            font-size: 16px;
                            padding: 0 10px;
                            white-space: nowrap;
                        }
                        .room-special {
                            color: $color-warning;
                            padding: 0 10px;
                            font-size: 18px;
                        }
                        .room-check {
                            color: $color-info;
                            padding: 0 10px;
                            font-size: 18px;
                        }

                        &.active {
                            color: $color-primary;

                            .room-check {
                                color: $color-primary;
                            }
                        }

                        &:hover {
                            @extend .card-shadow--medium;
                        }
                    }
                }
            }
        }
    }

    .input-box {
        .image-el {
            position: relative;
            width: 132px;
            height: 132px;
            margin-right: 20px;
            border-radius: 4px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.2s;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-primary;
                border-color: $color-primary;
            }

            .btn-clear-photo {
                color: #fff;
                cursor: pointer;
                font-size: 16px;
                background: rgba(0, 0, 0, 0.5);
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid rgba(255, 255, 255, 0.5);
                text-align: center;
                line-height: 24px;
                margin: 6px;
                transition: all 0.2s;

                &:hover {
                    background: rgba($color-danger, 0.6);
                }
            }
        }
        .input-el {
            margin-right: 20px;

            :deep {
                textarea {
                    width: 100%;
                    font-family: inherit;
                    font-size: 16px;
                    outline: none;
                    transition: all 0.15s;
                }
            }
        }
        .send-el {
            .el-button {
                width: 100%;
            }
        }
    }

    .url-box {
        :deep {
            input {
                width: 100%;
                font-family: inherit;
                font-size: 16px;
                outline: none;
                transition: all 0.15s;
            }
        }
    }
}

@media (max-width: 900px) {
    .page-chat-institutional {
        .msg-form {
            .rooms-list {
                .list-wrapper {
                    .rooms-group {
                        min-width: 100%;

                        .list {
                            .room {
                                .room-special {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .input-box {
                flex-direction: column;
                -webkit-box-orient: vertical;

                .image-el,
                .input-el {
                    margin-bottom: 20px;
                }
                .input-el {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
