<template>
    <div class="review-form" v-loading="loading" :class="size" ref="component">
        <resize-observer @notify="__resizeHandler" />

        <div v-if="review" class="review-wrapper flex" ref="wrapper">
            <div class="content box grow scrollable only-y">
                <ReviewEditor
                    :review="review"
                    :size="size"
                    :readonly="isReadonly"
                    :textAreaResize="tAreaResize"
                ></ReviewEditor>
            </div>

            <div class="sidebar box scrollable only-y" :class="{ collapsed: sidebarCollapsed }">
                <div class="wrapper card-base card-shadow--small">
                    <div class="block review-status flex">
                        <div class="box grow flex column">
                            <span class="o-050">stato</span>
                            <strong class="success-text" v-if="isPublished">Pubblicato</strong>
                            <strong class="info-text" v-if="isDraft">Bozza</strong>
                        </div>
                        <div class="box collapse-btn">
                            <i
                                class="mdi mdi-chevron-right"
                                v-if="sidebarCollapsed"
                                @click="sidebarCollapsed = false"
                            ></i>
                            <i
                                class="mdi mdi-chevron-down"
                                v-if="!sidebarCollapsed"
                                @click="sidebarCollapsed = true"
                            ></i>
                        </div>
                    </div>
                    <div class="block review-category flex column">
                        <span>categoria</span>
                        <el-select
                            v-model="review.category"
                            placeholder="Donna, Bambino, Famiglia..."
                            :disabled="isReadonly"
                        >
                            <el-option value="woman" label="Donna"></el-option>
                            <el-option value="baby" label="Bambino"></el-option>
                            <el-option value="family" label="Famiglia"></el-option>
                            <el-option value="earlychildhood" label="Prima infanzia"></el-option>
                            <el-option value="newborn" label="New born"></el-option>
                        </el-select>
                        <div v-if="isWoman" class="mt-10">
                            <el-select
                                v-model="womanCategory"
                                placeholder="Fertilità, Post Partum, Gravidanza..."
                                :disabled="isReadonly"
                            >
                                <el-option :value="0" label="Fertilità"></el-option>
                                <el-option :value="99" label="Post Partum"></el-option>
                                <el-option :value="10" label="Gravidanza"></el-option>
                            </el-select>
                        </div>
                        <div v-if="isPregnancy" class="flex mt-10">
                            <div class="box grow mr-5">
                                <el-select v-model="womanPregnancyFrom" placeholder="Mese Da" :disabled="isReadonly">
                                    <el-option
                                        v-for="i in 9"
                                        :key="i"
                                        :value="i"
                                        :label="i"
                                        :disabled="womanPregnancyTo && i > womanPregnancyTo"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="box grow ml-5">
                                <el-select v-model="womanPregnancyTo" placeholder="Mese A" :disabled="isReadonly">
                                    <el-option
                                        v-for="i in 9"
                                        :key="i"
                                        :value="i"
                                        :label="i"
                                        :disabled="womanPregnancyFrom && i < womanPregnancyFrom"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div v-if="isBaby" class="flex mt-10">
                            <div class="box grow mr-5">
                                <el-select v-model="babyMonthFrom" placeholder="Mese Da" :disabled="isReadonly">
                                    <el-option
                                        v-for="i in 48"
                                        :key="i"
                                        :value="i"
                                        :label="i"
                                        :disabled="babyMonthTo && i > babyMonthTo"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="box grow ml-5">
                                <el-select v-model="babyMonthTo" placeholder="Mese A" :disabled="isReadonly">
                                    <el-option
                                        v-for="i in 48"
                                        :key="i"
                                        :value="i"
                                        :label="i"
                                        :disabled="babyMonthFrom && i < babyMonthFrom"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div v-if="isFamily"></div>
                        <div v-if="isEarlychildhood"></div>
                        <div v-if="isNewborn"></div>
                    </div>
                    <div class="block review-genre" v-if="genreAvailable.length">
                        <span>sesso</span>
                        <el-select v-model="review.genre" placeholder="Maschio, Femmina..." :disabled="isReadonly">
                            <el-option
                                v-for="g in genreAvailable"
                                :key="g.value"
                                :value="g.value"
                                :label="g.label"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="block review-type">
                        <span>tipo</span>
                        <el-input v-model="review.type" placeholder="Tipo..." :disabled="isReadonly"></el-input>
                    </div>
                    <div class="block review-color">
                        <span>colore</span>
                        <div class="flex">
                            <div class="box color" :style="{ background: color }" v-if="isValidColor"></div>
                            <div class="box grow">
                                <el-input
                                    v-model="review.color"
                                    placeholder="Colore..."
                                    :disabled="isReadonly"
                                ></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="block review-featured">
                        <span>in evidenza</span>
                        <el-switch v-model="review.featured" :disabled="isReadonly"></el-switch>
                    </div>
                    <!--
                    <div class="block review-amazon">
                        <span>invia ad Amazon</span>
                        <el-switch
                            v-model="review.sendToAmazon"
                            :disabled="isReadonly || disabledSendToAmazon"
                        ></el-switch>
                    </div>
					-->
                    <div class="block review-actions flex column" v-loading="loadingSave">
                        <!--
						<div
							v-pure-tooltip.up.medium="'Presto sarà possibile modificare le recensioni'"
							style="
								background: #f7ba2a52;
								padding: 10px 16px;
								text-align: center;
								border-radius: 4px;
								color: #c79620;
								font-weight: bold;
								cursor: help;
							"
						>
							Work in progress
						</div>
						-->
                        <div class="flex">
                            <el-button
                                class="box grow"
                                plain
                                type="primary"
                                v-if="canSaveMeta && can_PublishReview"
                                @click="updateMeta()"
                            >
                                Salva info
                            </el-button>

                            <el-button
                                class="box grow"
                                plain
                                type="primary"
                                @click="goToReview(haveDraft)"
                                v-if="haveDraft"
                            >
                                Vedi bozza
                            </el-button>
                            <el-button
                                class="box grow"
                                plain
                                type="primary"
                                @click="update(false)"
                                :disabled="!isDraftValid"
                                v-if="!haveDraft"
                            >
                                Salva bozza
                            </el-button>
                            <el-button
                                class="box grow"
                                plain
                                type="success"
                                @click="update(true)"
                                :disabled="!isValid"
                                v-if="can_PublishReview && isDraft"
                            >
                                Pubblica
                            </el-button>
                        </div>
                        <div class="flex mt-10" v-if="isDisposable">
                            <el-button class="box grow" plain type="danger" @click="deleteDraft()">
                                Elimina bozza
                            </el-button>
                        </div>
                        <div v-if="canSaveMeta && can_PublishReview" class="mt-16 fs-14">
                            Cliccando su <strong>"Salva info"</strong> verranno modificati soltanto:
                            <div class="flex mt-8">
                                <div class="box grow">
                                    <strong>- categoria</strong><br />
                                    <strong>- sesso</strong><br />
                                </div>
                                <div class="box grow">
                                    <strong>- colore</strong><br />
                                    <strong>- in evidenza</strong><br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isProductsValid" class="fs-14">
                        Per pubblicare una recensione è necessario creare prodotti validi
                    </div>
                    <div v-if="review.products.length > 15" class="fs-14 mt-10">
                        È possibile caricare un massimo di 20 prodotti
                    </div>
                    <div class="info mt-15" v-if="!isNew">
                        <div class="block review-author" v-if="review.author_id">
                            <span>autore</span>
                            <div class="clickable" @click="openUser(review.author_id)">
                                <strong>@{{ review.author_username }}</strong>
                            </div>
                        </div>
                        <div class="block review-created_at" v-if="review.created_at">
                            <span>creato il</span>
                            <strong>{{ review.created_at | dayjs("format", "DD MMM YYYY HH:mm") }}</strong>
                        </div>
                        <div class="block review-updated_at" v-if="review.updated_at">
                            <span>aggiornato il</span>
                            <strong>{{ review.updated_at | dayjs("format", "DD MMM YYYY HH:mm") }}</strong>
                        </div>
                        <div class="block review-wp_post_url" v-if="review.wp_post_url">
                            <span>blog url</span>
                            <strong>
                                <a :href="review.wp_post_url" target="_blank">link</a>
                            </strong>
                        </div>
                        <div class="block review-mysqlid" v-if="review.id">
                            <div class="flex">
                                <span>versione online</span> <strong class="ml-10">#{{ review.id }}</strong>
                            </div>
                            <div v-if="havePublished" class="clickable" @click="goToReview(havePublished)">
                                <strong>vedi recensione</strong>
                            </div>
                        </div>
                        <!--
						<div class="block review-id" v-if="review._id">
							<span>mongo id</span>
							<strong>{{ review._id }}</strong>
						</div>
						-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import { openUserDialog } from "@/common"
import ReviewEditor from "@/components/Reviews/ReviewEditor"

export default {
    name: "ReviewForm",
    props: ["id"],
    data() {
        return {
            bkp: {
                created_at: "",
                id: 0,
                image: "null",
                order: 0,
                pre: "",
                products: [],
                status: "draft",
                subtitle: "",
                title: "",
                type: "",
                updated_at: "",
                category: "",
                index: "",
                index_to: "",
                genre: "",
                wp_post_url: "",
                color: "",
                featured: false,
                sendToAmazon: false,
                _id: ""
            },
            review: null,
            haveDraft: "",
            havePublished: "",
            loading: true,
            loadingSave: false,
            size: "large",
            tAreaResize: true,
            sidebarCollapsed: false,

            womanCategory: null,
            womanPregnancyFrom: 1,
            womanPregnancyTo: 9,
            babyMonthFrom: 1,
            babyMonthTo: 48,

            disabledSendToAmazon: false
        }
    },
    computed: {
        accountUsername() {
            return this.$store.getters.accountUsername
        },
        accountId() {
            return this.$store.getters.accountId
        },
        can_PublishReview() {
            return this.$store.getters.can_PublishReview
        },
        can_DeleteReviewDraft() {
            return this.$store.getters.can_DeleteReviewDraft
        },
        isNew() {
            return !this.review._id
        },
        isReadonly() {
            return !!this.haveDraft
        },
        isPublished() {
            return this.review.status === "published"
        },
        isDraft() {
            return this.review.status === "draft"
        },
        isValidColor() {
            if (this.review && this.review.color) {
                return !!this.review.color.match(/#?([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/)
            } else {
                return false
            }
        },
        color() {
            if (this.isValidColor) {
                if (this.review.color.indexOf("#") === 0) {
                    return this.review.color
                } else {
                    return "#" + this.review.color
                }
            }
            return ""
        },
        index() {
            if (this.isPregnancy) {
                if (this.womanPregnancyFrom !== null && this.womanPregnancyTo !== null) {
                    return this.womanPregnancyFrom + "-" + this.womanPregnancyTo
                }
                return null
            }

            if (this.isWoman) {
                return this.womanCategory
            }

            if (this.isBaby) {
                if (this.babyMonthFrom !== null && this.babyMonthTo !== null) {
                    return this.babyMonthFrom + "-" + this.babyMonthTo
                }
                return null
            }

            return null
        },
        isWoman() {
            return this.review && this.review.category && this.review.category === "woman"
        },
        isBaby() {
            return this.review && this.review.category && this.review.category === "baby"
        },
        isFamily() {
            return this.review && this.review.category && this.review.category === "family"
        },
        isEarlychildhood() {
            return this.review && this.review.category && this.review.category === "earlychildhood"
        },
        isNewborn() {
            return this.review && this.review.category && this.review.category === "newborn"
        },
        isPregnancy() {
            return this.review && this.review.category && this.review.category === "woman" && this.womanCategory === 10
        },
        genreAvailable() {
            if (this.isPregnancy) {
                return [
                    { value: "unknown", label: "Tutti" },
                    { value: "m", label: "Maschio" },
                    { value: "f", label: "Femmina" },
                    { value: "mm", label: "2 Maschi" },
                    { value: "ff", label: "2 Femmine" },
                    { value: "mf", label: "Un maschio e una femmina" }
                ]
            }

            if (this.isBaby) {
                return [
                    { value: "unknown", label: "Tutti" },
                    { value: "m", label: "Maschio" },
                    { value: "f", label: "Femmina" }
                ]
            }

            return []
        },
        isProductsValid() {
            if (!this.review || !this.review.products) {
                return false
            }

            /*
            if (this.review.products.length !== 4 && this.review.products.length !== 5) {
                return false
            }
			*/

            if (this.review.products.length > 20) {
                return false
            }

            const toCheck = [
                "award",
                "brand",
                "description",
                "headline",
                "image",
                "link",
                "link_ref",
                "summary",
                "title",
                "name"
            ]

            for (const p of this.review.products) {
                for (const field of toCheck) {
                    if (!_.trim(p[field]) || _.trim(p[field]) === "null") {
                        return false
                    }
                }
            }

            return true
        },
        isValid() {
            if (this.index === null && !this.isFamily && !this.isEarlychildhood && !this.isNewborn) {
                return false
            }

            if (!this.isValidColor) {
                return false
            }

            const toCheck = ["image", "pre", "subtitle", "title", "type", "category"]

            for (const field of toCheck) {
                if (!_.trim(this.review[field]) || _.trim(this.review[field]) === "null") {
                    return false
                }
            }

            if ((this.isPregnancy || this.isBaby) && !this.review.genre) {
                return false
            }

            if (!this.isProductsValid) {
                return false
            }

            return true
        },
        isDraftValid() {
            return !!_.trim(this.review.title)
        },
        isDisposable() {
            return !!(this.isDraft && this.review._id && this.can_DeleteReviewDraft)
        },
        canSaveMeta() {
            if (this.isDraft) {
                return false
            }
            if (this.haveDraft) {
                return false
            }

            if (
                this.review.color === this.bkp.color &&
                this.review.genre === this.bkp.genre &&
                this.review.category === this.bkp.category &&
                this.review.featured === this.bkp.featured &&
                this.review.index === this.bkp.index &&
                this.review.index_to === this.bkp.index_to
            ) {
                return false
            }

            return true
        }
    },
    watch: {
        review: {
            deep: true,
            handler() {
                this.$emit("update:dirty", !_.isEqual(this.bkp, this.review))
            }
        },
        "review.category"(val) {
            if (this.review.genre) {
                if (_.findIndex(this.genreAvailable, g => g.value === this.review.genre) === -1) {
                    this.review.genre = ""
                }
            }
        },
        /*womanCategory(val) {
			if (val === 10) {
				if (!this.womanPregnancyFrom) {
					this.womanPregnancyFrom = 1
				}
				if (!this.womanPregnancyTo) {
					this.womanPregnancyTo = 9
				}
			}
		},*/
        index(val) {
            this.review.index = val
            if (val && val.indexOf && val.indexOf("-") !== -1) {
                this.review.index_to = val.split("-")[1]
            } else {
                this.review.index_to = null
            }
        },
        color(val) {
            this.review.color = val
        },
        sidebarCollapsed(val) {
            this.scrollToTop()
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.tAreaResize = false
            setTimeout(() => {
                this.tAreaResize = true
            }, 0)
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.component) {
                const width = this.$refs.component.offsetWidth

                this.size = "large"
                if (width < 750) {
                    this.size = "small"
                } else if (width < 1070) {
                    this.size = "medium"
                }
            }
        },
        getReview() {
            this.loading = true

            api.reviews
                .get(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const review = res.body

                        review.index = review.index || null
                        review.genre = review.genre || null

                        if (review.index_to && review.index_to !== null && review.index_to !== 0) {
                            review.index = review.index + "-" + review.index_to
                        }

                        this.parseIndex(review.category, review.index)
                        review.category = review.category || ""
                        review.featured = !!review.featured
                        review.sendToAmazon = review.sendToAmazon === undefined ? false : !!review.sendToAmazon

                        if (review.color && review.color.indexOf("#") === -1) {
                            review.color = "#" + review.color
                        }

                        if (review.products && review.products.length) {
                            for (const p of review.products) {
                                delete p.collapse
                            }
                        }

                        if (review.id && review.id !== 0 && review.sendToAmazon) {
                            this.disabledSendToAmazon = true
                        }

                        this.review = _.cloneDeep(review)
                        this.bkp = _.cloneDeep(review)

                        if (this.review.status === "published") {
                            this.getReviewDraft(this.id)
                        } else {
                            this.getReviewPublished(this.id)
                        }

                        this.scrollToTop()

                        this.$emit("loaded", this.room)
                    } else {
                        this.$emit("error")
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                    this.$emit("error")
                })
        },
        getReviewDraft(id) {
            api.reviews
                .haveDraft(id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.haveDraft = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        getReviewPublished(id) {
            api.reviews
                .havePublished(id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.havePublished = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        update(publish) {
            this.loadingSave = true
            //console.log(_.isEqual(this.bkp, this.review))
            const review = _.cloneDeep(this.review)
            review.status = publish ? "published" : "draft"
            review.author_username = this.accountUsername
            review.author_id = this.accountId

            if (publish && review.color) {
                review.color = review.color.replace("#", "")
            }

            if (review.index && review.index.indexOf("-") !== -1) {
                const index = review.index
                review.index = index.split("-")[0]
                review.index_to = index.split("-")[1]
            } else {
                review.index_to = null
            }

            for (const i in review.products) {
                review.products[i].order = _.toNumber(i)
            }

            review.genre = review.genre || null

            api.reviews
                .save(review)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        if (!publish && this.bkp.status === "published") {
                            this.getReviewDraft(this.bkp._id)

                            this.$nextTick(() => {
                                this.review = _.cloneDeep(this.review)
                                this.bkp = _.cloneDeep(this.review)
                            })
                        } else {
                            const review = _.cloneDeep(this.review)
                            review.status = _.get(res.body, "status")
                            review.author_username = _.get(res.body, "author_username")
                            review.author_id = _.get(res.body, "author_id")
                            review._id = _.get(res.body, "_id")
                            review.id = _.get(res.body, "id")
                            review.created_at = _.get(res.body, "created_at")
                            review.updated_at = _.get(res.body, "updated_at")
                            review.wp_post_url = _.get(res.body, "wp_post_url")
                            review.image = _.get(res.body, "image")
                            review.products = _.get(res.body, "products")

                            this.$nextTick(() => {
                                this.review = _.cloneDeep(review)
                                this.bkp = _.cloneDeep(review)
                            })
                        }

                        this.$notify.success({
                            title: "Fatto!",
                            message: `Recensione salvata correttamente`
                        })
                    } else {
                        this.$notify.error({
                            title: "Ops...",
                            message: `Errore salvataggio recensione`
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$notify.error({
                        title: "Ops...",
                        message: err?.body?.message || `Errore salvataggio recensione`
                    })
                })
                .finally(() => {
                    this.loadingSave = false
                })
        },
        updateMeta() {
            this.loadingSave = true

            const review = _.cloneDeep(this.review)

            const meta = {
                author_username: this.accountUsername,
                author_id: this.accountId,
                genre: review.genre || null,
                color: review.color ? review.color.replace("#", "") : "",
                featured: review.featured || false,
                category: review.category,
                id: review.id,
                _id: review._id
            }

            if (review.index && review.index.indexOf("-") !== -1) {
                const index = review.index
                meta.index = index.split("-")[0]
                meta.index_to = index.split("-")[1]
            } else {
                meta.index = review.index
                meta.index_to = null
            }

            api.reviews
                .saveReviewMeta(meta)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.$nextTick(() => {
                            this.bkp.genre = this.review.genre
                            this.bkp.color = this.review.color
                            this.bkp.featured = this.review.featured
                            this.bkp.category = this.review.category
                            this.bkp.index = this.review.index
                            this.bkp.index_to = this.review.index_to
                        })

                        this.$notify.success({
                            title: "Fatto!",
                            message: `Info Recensione salvate correttamente`
                        })
                    } else {
                        this.$notify.error({
                            title: "Ops...",
                            message: `Errore salvataggio info recensione`
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore salvataggio info recensione`
                    })
                })
                .finally(() => {
                    this.loadingSave = false
                })
        },
        parseIndex(category, index) {
            if (index !== null) {
                if (category === "woman") {
                    if (index === 0 || index === 99) {
                        this.womanCategory = index
                    } else {
                        this.womanCategory = 10

                        if (index.indexOf("-") !== -1) {
                            this.womanPregnancyFrom = index.split("-")[0]
                            this.womanPregnancyTo = index.split("-")[1]
                        }
                    }
                }
                if (category === "baby") {
                    if (index.indexOf("-") !== -1) {
                        this.babyMonthFrom = index.split("-")[0]
                        this.babyMonthTo = index.split("-")[1]
                    }
                }
            }
        },
        openUser(id) {
            openUserDialog({ id })
        },
        goToReview(id) {
            this.$emit("goToReview", id)
        },
        deleteDraft() {
            this.$confirm("Sicura di voler eliminare questa bozza ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    this.loadingSave = true
                    api.reviews
                        .deleteDraft(this.review._id)
                        .then(res => {
                            if (res.status === 200 && res.body) {
                                this.$emit("deleted")

                                this.$notify.success({
                                    title: "Fatto!",
                                    message: `Bozza eliminata correttamente`
                                })
                            } else {
                                this.$notify.error({
                                    title: "Ops...",
                                    message: `Errore eliminazione bozza`
                                })
                            }
                        })
                        .catch(err => {
                            console.error(err)
                            this.$notify.error({
                                title: "Ops...",
                                message: `Errore eliminazione bozza`
                            })
                        })
                        .finally(() => {
                            this.loadingSave = false
                        })
                })
                .catch(() => {})
        },
        scrollToTop() {
            this.$nextTick(() => {
                if (this.$refs.wrapper) {
                    this.$refs.wrapper.scrollTo(0, -(this.$refs.wrapper?.scrollHeight || 0))
                }
            })
        }
    },
    mounted() {
        this.calcDim()

        if (this.id) {
            this.getReview()
        } else {
            this.review = _.cloneDeep(this.bkp)
            this.loading = false
            this.scrollToTop()
        }
    },
    components: {
        ReviewEditor
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.review-form {
    overflow: hidden;
    position: relative;

    .review-wrapper {
        max-height: 100%;

        .content {
            margin-right: 10px;
            padding-right: 20px;
        }

        .sidebar {
            max-width: 310px;
            min-width: 310px;
            width: 310px;
            padding-right: 4px;
            padding-top: 0;
            padding-bottom: 16px;
            padding-left: 14px;
            box-sizing: border-box;

            .wrapper {
                background: white;
                padding: 20px;
                border-radius: 8px;

                .block {
                    margin-bottom: 20px;

                    & > span {
                        display: block;
                        opacity: 0.5;
                        margin-bottom: 5px;
                    }
                    & > strong {
                        display: block;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .el-select {
                        width: 100%;
                    }

                    .clickable {
                        color: $color-primary;
                        cursor: pointer;
                        text-decoration: underline;
                        text-decoration-color: transparentize($color-primary, 0.7);
                    }

                    &.review-status {
                        .collapse-btn {
                            display: none;
                            font-size: 30px;
                            cursor: pointer;
                        }
                    }
                    &.review-color {
                        .color {
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                            border-radius: 4px;
                            border: 1px solid #dcdfe6;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
    }

    &.medium,
    &.small {
    }
    &.small {
        .review-wrapper {
            flex-direction: column-reverse;
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;

            -webkit-overflow-scrolling: touch !important;
            overflow-x: hidden !important;
            overflow-y: auto !important;

            .content,
            .sidebar {
                flex: none;
                overflow: initial !important;
            }
            .content {
                margin-right: 0;
                padding-right: 0;
            }
            .sidebar {
                max-width: initial;
                width: 100%;
                padding: 0;
                margin-bottom: 10px;

                .wrapper {
                    .block {
                        &.review-status {
                            .collapse-btn {
                                display: inherit;
                            }
                        }
                    }
                }

                &.collapsed {
                    .wrapper {
                        .block:not(.review-status) {
                            display: none;
                        }
                        .block {
                            &.review-status {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
