var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "user",
      staticClass: "user-info",
      class: _vm.size,
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _vm.showError
        ? _c("div", { staticClass: "text-center" }, [
            _vm._m(0),
            _c("h2", [
              _vm._v(_vm._s(_vm.serverError || "L'utente non esiste")),
            ]),
          ])
        : _vm._e(),
      _vm.user && _vm.can_PerformUserActions
        ? _c("UserActions", {
            staticClass: "mb-40",
            attrs: {
              id: _vm.id,
              banned: _vm.user.flags.is_banned,
              muted: _vm.user.flags.is_muted,
              editing: _vm.editMode,
              "save-user-enabled": _vm.saveEnabled,
            },
            on: {
              "edit-user": _vm.editUser,
              "save-user": _vm.saveUser,
              "cancel-save-user": _vm.cancelSaveUser,
              "need-reload": _vm.getUser,
              "user-deleted": _vm.emitUserDeleted,
              "history-open": function ($event) {
                _vm.historyDrawerOpened = true
              },
            },
          })
        : _vm._e(),
      _vm.user
        ? _c("div", { staticClass: "flex user-box" }, [
            !_vm.editMode
              ? _c(
                  "div",
                  { staticClass: "propic" },
                  [
                    _c("vue-load-image", [
                      _c("img", {
                        staticClass: "image",
                        attrs: { slot: "image", src: _vm.user.image },
                        slot: "image",
                      }),
                      _c("img", {
                        staticClass: "preloader",
                        attrs: {
                          slot: "preloader",
                          src: require("@/assets/images/loading.png"),
                        },
                        slot: "preloader",
                      }),
                      _c("img", {
                        staticClass: "error",
                        attrs: {
                          slot: "error",
                          src: require("@/assets/images/img-not-found.svg"),
                        },
                        slot: "error",
                      }),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "info-box", class: { edit: _vm.editMode } },
              [
                _c("div", { staticClass: "name-box" }, [
                  !_vm.editMode
                    ? _c(
                        "div",
                        {
                          staticClass: "name mr-10",
                          class: {
                            banned: _vm.user.flags.is_banned,
                            muted: _vm.user.flags.is_muted,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.user.name) +
                              " " +
                              _vm._s(_vm.user.surname) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c("div", { staticClass: "name edit-mode mr-10" }, [
                        _c("div", { staticClass: "input-block left" }, [
                          _c("div", { staticClass: "label" }, [_vm._v("Nome")]),
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.name,
                                  expression: "form.name",
                                },
                              ],
                              attrs: { placeholder: "Nome" },
                              domProps: { value: _vm.form.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "input-block right" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Cognome"),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.surname,
                                  expression: "form.surname",
                                },
                              ],
                              attrs: { placeholder: "Cognome" },
                              domProps: { value: _vm.form.surname },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "surname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editMode,
                          expression: "!editMode",
                        },
                      ],
                      staticClass: "icons-box",
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: `Bloccato: ${
                              _vm.user.flags.ban_reason || "-"
                            }`,
                            placement: "bottom",
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.user.flags.is_banned,
                                expression: "user.flags.is_banned",
                              },
                            ],
                            staticClass: "mdi mdi-cancel",
                          }),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: `Silenziato fino a: ${
                              _vm.user.flags.muted_until || "-"
                            }`,
                            placement: "bottom",
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.user.flags.is_muted,
                                expression: "user.flags.is_muted",
                              },
                            ],
                            staticClass: "mdi mdi-volume-off",
                          }),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.user.role,
                            placement: "bottom",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "mdi ml-10 roles color",
                            class: {
                              [_vm.user.role]: _vm.user.role !== "user",
                              "mdi-shield": _vm.user.role !== "user",
                              "mdi-account": _vm.user.role === "user",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-end",
                            width: "200",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "vc-donut",
                            _vm._b({}, "vc-donut", _vm.user.rankprops, false),
                            [
                              _c("div", { staticClass: "fs-18" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.user.rank.percentage_achieved) +
                                      "%"
                                  ),
                                ]),
                                _c("span", { staticClass: "ml-10" }, [
                                  _vm._v(
                                    "Livello " + _vm._s(_vm.user.rank.level)
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "mt-6 fs-16" }, [
                                _c("small", [
                                  _vm._v(
                                    _vm._s(_vm.user.rank.points) + " punti"
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c("i", {
                            staticClass: "mdi mdi-trophy-variant ml-10",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ],
                        1
                      ),
                      _vm.user.lang
                        ? _c("vf-icon", {
                            staticClass: "ml-10",
                            attrs: {
                              country: _vm.user.lang,
                              type: "rounded-rectangle",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.editMode,
                        expression: "!editMode",
                      },
                    ],
                    staticClass: "username",
                  },
                  [
                    _vm._v(" @" + _vm._s(_vm.user.username) + " "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "270",
                          trigger: "click",
                        },
                      },
                      [
                        _vm.user.flags.can_update_username
                          ? _c("div", { staticClass: "mb-10 ml-6 mt-6" }, [
                              _c("i", {
                                staticClass: "mdi mdi-lock-open-variant",
                              }),
                              _vm._v(" L'utente può modificare la username "),
                            ])
                          : _vm._e(),
                        !_vm.user.flags.can_update_username
                          ? _c("div", { staticClass: "mb-10 ml-6 mt-6" }, [
                              _c("i", { staticClass: "mdi mdi-lock" }),
                              _vm._v(" L'utente "),
                              _c("strong", [_vm._v("NON")]),
                              _vm._v(" può modificare la username "),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "username-list" },
                          _vm._l(
                            _vm.user.flags.username_history,
                            function (item) {
                              return _c(
                                "div",
                                { key: item.changed, staticClass: "m-6 mb-10" },
                                [
                                  _c("div", [
                                    _vm._v("@" + _vm._s(item.username)),
                                  ]),
                                  _c("div", { staticClass: "o-050" }, [
                                    _vm._v("fino al " + _vm._s(item.changed)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        !_vm.user.flags.username_history.length
                          ? _c("div", { staticClass: "m-6" }, [
                              _vm._v("username mai modificata"),
                            ])
                          : _vm._e(),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.editMode,
                              expression: "!editMode",
                            },
                          ],
                          staticClass: "mdi mdi-history ml-10 fs-16 clickable",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                !_vm.editMode
                  ? _c("div", { staticClass: "user-id" }, [
                      _c("span", [_vm._v("#" + _vm._s(_vm.id))]),
                      _c("span", { staticClass: "ml-12" }, [
                        _vm._v(
                          "Registrato il " + _vm._s(_vm.user.register_date)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.editMode
                  ? _c("div", { staticClass: "username edit-mode" }, [
                      _c("div", { staticClass: "input-block" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Username"),
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.username,
                                expression: "form.username",
                              },
                            ],
                            attrs: { placeholder: "Username" },
                            domProps: { value: _vm.form.username },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "username",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.usernameError)),
                        ]),
                      ]),
                      _c("div", { staticClass: "input-block" }, [
                        _c("div", { staticClass: "label" }),
                        _c("div", { staticClass: "field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.flags.can_update_username,
                                expression: "form.flags.can_update_username",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              placeholder: "Può aggiornare la username",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.form.flags.can_update_username
                              )
                                ? _vm._i(
                                    _vm.form.flags.can_update_username,
                                    null
                                  ) > -1
                                : _vm.form.flags.can_update_username,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.flags.can_update_username,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form.flags,
                                        "can_update_username",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form.flags,
                                        "can_update_username",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.form.flags,
                                    "can_update_username",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.editMode
                  ? _c(
                      "div",
                      { staticClass: "birthdate" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dayjs")(
                                _vm.user.birthdate,
                                "format",
                                "DD MMMM YYYY"
                              )
                            ) +
                            " "
                        ),
                        _vm.user.town
                          ? _c("span", { staticClass: "mh-10" }, [_vm._v("-")])
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.town || "") +
                            " " +
                            _vm._s(_vm.user.province || "") +
                            " " +
                            _vm._s(_vm.user.country || "") +
                            " "
                        ),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "270",
                              trigger: "click",
                            },
                          },
                          [
                            _c("iframe", {
                              staticClass: "user-info__map-frame",
                              attrs: {
                                width: "100%",
                                height: "300",
                                frameborder: "0",
                                scrolling: "no",
                                marginheight: "0",
                                marginwidth: "0",
                                src: _vm.user.map_url,
                              },
                            }),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.editMode && _vm.user.town,
                                  expression: "!editMode && user.town",
                                },
                              ],
                              staticClass: "mdi mdi-map ml-10 fs-16 clickable",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editMode
                  ? _c("div", { staticClass: "birthdate edit-mode" }, [
                      _c("div", { staticClass: "input-block" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Data di nascita"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "field" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                editable: false,
                                format: "dd MMM yyyy",
                                "picker-options": { firstDayOfWeek: 1 },
                                placeholder: "Data di nascita",
                              },
                              model: {
                                value: _vm.form.birthdate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "birthdate", $$v)
                                },
                                expression: "form.birthdate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.editMode
                  ? _c("div", { staticClass: "location edit-mode" }, [
                      _c("div", { staticClass: "input-block span-2-3 left" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("Città")]),
                        _c("div", { staticClass: "field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.town,
                                expression: "form.town",
                              },
                            ],
                            attrs: { placeholder: "Città" },
                            domProps: { value: _vm.form.town },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.form, "town", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "input-block span-2-3 left right" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Provincia"),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.province,
                                  expression: "form.province",
                                },
                              ],
                              attrs: { placeholder: "Provincia" },
                              domProps: { value: _vm.form.province },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "province",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "input-block span-2-3 right" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Nazione"),
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.country,
                                expression: "form.country",
                              },
                            ],
                            attrs: { placeholder: "Nazione" },
                            domProps: { value: _vm.form.country },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "country",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.editMode,
                        expression: "!editMode",
                      },
                    ],
                    staticClass: "email-box",
                  },
                  [
                    _c("span", { staticClass: "email" }, [
                      _vm._v(_vm._s(_vm.user.email)),
                    ]),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom-end",
                          width: "270",
                          trigger: "click",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "email-list" },
                          _vm._l(_vm.user.flags.email_history, function (item) {
                            return _c(
                              "div",
                              { key: item.changed, staticClass: "m-6 mb-10" },
                              [
                                _c("div", [
                                  _vm._v(" " + _vm._s(item.email) + " "),
                                ]),
                                _c("div", { staticClass: "o-050" }, [
                                  _vm._v("fino al " + _vm._s(item.changed)),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                        !_vm.user.flags.email_history.length
                          ? _c("div", [_vm._v("email mai modificata")])
                          : _vm._e(),
                        _c("i", {
                          staticClass: "mdi mdi-history ml-10 fs-16 clickable",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.editMode
                  ? _c("div", { staticClass: "email-box mb-0" }, [
                      _c("div", { staticClass: "email edit-mode" }, [
                        _c("div", { staticClass: "input-block span-2" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Email"),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.email,
                                  expression: "form.email",
                                },
                              ],
                              attrs: { placeholder: "Email" },
                              domProps: { value: _vm.form.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "error" }, [
                            _vm._v(_vm._s(_vm.emailError)),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.editMode
                  ? _c(
                      "div",
                      { staticClass: "phone" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.user.device ? _vm.user.device.phone : ""
                            ) +
                            " "
                        ),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-end",
                              width: "230",
                              trigger: "click",
                            },
                          },
                          [
                            _c("div", { staticClass: "item m-6 fs-16" }, [
                              _vm.user.device.os_at_registration === "android"
                                ? _c("i", { staticClass: "mdi mdi-android" })
                                : _vm._e(),
                              _vm.user.device.os_at_registration !== "android"
                                ? _c("i", { staticClass: "mdi mdi-apple" })
                                : _vm._e(),
                              _c("span", [
                                _vm._v(" Sistema operativo "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.user.device.os_at_registration)
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "item m-6 fs-16" }, [
                              _c("i", {
                                staticClass: "mdi mdi-cellphone-android",
                              }),
                              _c("span", [
                                _vm._v(" Telefono "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.user.device.phone)),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "item m-6 fs-16" }, [
                              _c("i", {
                                staticClass: "mdi mdi-message-processing",
                              }),
                              _c("span", [
                                _vm._v(" Codice SMS "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.user.device.sms_confirmation_token
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("i", {
                              staticClass:
                                "mdi mdi-cellphone ml-10 fs-16 clickable",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editMode
                  ? _c("div", { staticClass: "phone edit-mode" }, [
                      _c("div", { staticClass: "input-block" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Numero di telefono"),
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.device.phone,
                                expression: "form.device.phone",
                              },
                            ],
                            attrs: { placeholder: "Numero di telefono" },
                            domProps: { value: _vm.form.device.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form.device,
                                  "phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm.user
        ? _c("div", { staticClass: "flex flags-box-inline fs-16" }, [
            _c("div", { staticClass: "item mr-22" }, [
              _vm.user.flags.mail_verified
                ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                : _vm._e(),
              !_vm.user.flags.mail_verified
                ? _c("i", { staticClass: "mdi mdi-close-thick" })
                : _vm._e(),
              _c("span", { staticClass: "ml-5" }, [_vm._v("Email verificata")]),
            ]),
            _c("div", { staticClass: "item mr-22" }, [
              _vm.user.flags.sms_confirmed
                ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                : _vm._e(),
              !_vm.user.flags.sms_confirmed
                ? _c("i", { staticClass: "mdi mdi-close-thick" })
                : _vm._e(),
              _c("span", { staticClass: "ml-5" }, [_vm._v("SMS confermato")]),
            ]),
            _c("div", { staticClass: "item" }, [
              _vm.user.flags.can_update_username
                ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                : _vm._e(),
              !_vm.user.flags.can_update_username
                ? _c("i", { staticClass: "mdi mdi-close-thick" })
                : _vm._e(),
              _c("span", { staticClass: "ml-5" }, [
                _vm._v("Può aggiornare la username"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.user
        ? _c("div", { staticClass: "flags-box" }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _c("span", [_vm._v("Permessi")]),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      width: "270",
                      trigger: "click",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flags-list" },
                      _vm._l(
                        _vm.user.flags.flag_history,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "m-6 mb-10" },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(item.flag) +
                                    " (" +
                                    _vm._s(item.updated_flag_type) +
                                    ")"
                                ),
                              ]),
                              _c("div", { staticClass: "o-050" }, [
                                _vm._v("fino al " + _vm._s(item.changed)),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    !_vm.user.flags.flag_history.length
                      ? _c("div", [_vm._v("permessi mai modificati")])
                      : _vm._e(),
                    _c("i", {
                      staticClass: "mdi mdi-history ml-10 fs-16 clickable",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.marketing_owner
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                !_vm.user.flags.marketing_owner
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [
                  _vm._v("Marketing proprietario"),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.marketing_owner_for_thirds
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                !_vm.user.flags.marketing_owner_for_thirds
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [
                  _vm._v("Marketing proprietario per terzi"),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.marketing_thirds
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                !_vm.user.flags.marketing_thirds
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [
                  _vm._v("Marketing terzi"),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.user_considerable_consent === true
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                _vm.user.flags.user_considerable_consent === false
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _vm.user.flags.user_considerable_consent === null
                  ? _c("i", { staticClass: "mdi mdi-help" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [
                  _vm._v("Dati sensibili gravidanza"),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.user_children_data_consent === true
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                _vm.user.flags.user_children_data_consent === false
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _vm.user.flags.user_children_data_consent === null
                  ? _c("i", { staticClass: "mdi mdi-help" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [
                  _vm._v("Dati sensibili bambini"),
                ]),
              ]),
              _vm.user.flags.user_profilation !== undefined
                ? _c("div", { staticClass: "item" }, [
                    _vm.user.flags.user_profilation
                      ? _c("i", {
                          staticClass: "mdi mdi-check-bold success-text",
                        })
                      : _vm._e(),
                    !_vm.user.flags.user_profilation
                      ? _c("i", { staticClass: "mdi mdi-close-thick" })
                      : _vm._e(),
                    _c("span", { staticClass: "ml-5" }, [
                      _vm._v("Profilazione"),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.policy_version_4_0
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                !_vm.user.flags.policy_version_4_0
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [_vm._v("Policy 4.0")]),
              ]),
              _vm.user.flags.privacy_policy_rev_5_accepted !== undefined
                ? _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _vm.user.flags.privacy_policy_rev_5_accepted
                        ? _c("i", {
                            staticClass: "mdi mdi-check-bold success-text",
                          })
                        : _vm._e(),
                      !_vm.user.flags.privacy_policy_rev_5_accepted
                        ? _c("i", { staticClass: "mdi mdi-close-thick" })
                        : _vm._e(),
                      _c("span", { staticClass: "ml-5" }, [
                        _vm._v("Policy rev. 5"),
                      ]),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: _vm
                              .$dayjs(
                                _vm.user.flags.privacy_policy_rev_5_accepted
                              )
                              .format("DD/MM/YYYY HH:mm"),
                            placement: "top-end",
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  typeof _vm.user.flags
                                    .privacy_policy_rev_5_accepted === "string",
                                expression:
                                  "typeof user.flags.privacy_policy_rev_5_accepted === 'string'",
                              },
                            ],
                            staticClass:
                              "mdi mdi-clock-outline ml-6 accent-text",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "item" }, [
                _vm.user.flags.marketing_push_notifications
                  ? _c("i", { staticClass: "mdi mdi-check-bold success-text" })
                  : _vm._e(),
                !_vm.user.flags.marketing_push_notifications
                  ? _c("i", { staticClass: "mdi mdi-close-thick" })
                  : _vm._e(),
                _c("span", { staticClass: "ml-5" }, [_vm._v("Notifiche push")]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "user-info__history-drawer",
            title: "Cronologia Ban/Silence",
            visible: _vm.historyDrawerOpened,
            modal: true,
            "with-header": false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.historyDrawerOpened = $event
            },
          },
        },
        [
          _vm.user && _vm.user.flags && _vm.user.flags.penalties_history
            ? _c("div", { staticClass: "user-info__history-box flex column" }, [
                _c(
                  "div",
                  { staticClass: "box mb-10 p-10 flex justify-space-between" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("Cronologia Ban/Silence"),
                    ]),
                    _c("div", [
                      _c("i", {
                        staticClass:
                          "el-dialog__close el-icon el-icon-close el-drawer__close-btn",
                        on: {
                          click: function ($event) {
                            _vm.historyDrawerOpened = false
                          },
                        },
                      }),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "box grow scrollable only-y" },
                  [
                    _c(
                      "el-timeline",
                      { attrs: { reverse: true } },
                      _vm._l(_vm.user.flags.penalties_history, function (item) {
                        return _c(
                          "el-timeline-item",
                          {
                            key: item.date,
                            attrs: {
                              timestamp: item.date,
                              type: item.color,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-card",
                              { staticClass: "user-info__history-card" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "type",
                                    class: { [item.typeParsed]: true },
                                  },
                                  [_vm._v(_vm._s(item.typeText))]
                                ),
                                _c("div", { staticClass: "desc" }, [
                                  _vm._v(_vm._s(item.reason)),
                                ]),
                                item.until
                                  ? _c("div", { staticClass: "desc" }, [
                                      _vm._v("fino a: " + _vm._s(item.until)),
                                    ])
                                  : _vm._e(),
                                item.from
                                  ? _c("div", { staticClass: "sender flex" }, [
                                      _c("div", { staticClass: "box" }, [
                                        _vm._v("da:"),
                                      ]),
                                      _c("div", { staticClass: "box grow" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "clickable",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUser(
                                                  item.from.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.from.name) +
                                                " " +
                                                _vm._s(item.from.surname) +
                                                " "
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "o-050" },
                                              [
                                                _vm._v(
                                                  "#" + _vm._s(item.from.id)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", { staticClass: "o-050" }, [
                                          _vm._v(_vm._s(item.from.email)),
                                        ]),
                                      ]),
                                    ])
                                  : _vm._e(),
                                item.removed
                                  ? _c(
                                      "div",
                                      { staticClass: "removed mt-20" },
                                      [
                                        _c("div", { staticClass: "mb-5" }, [
                                          _vm._v("rimosso da:"),
                                        ]),
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clickable",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUser(
                                                    item.removed.from.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.removed.from.name
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    item.removed.from.surname
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "o-050" },
                                                [
                                                  _vm._v(
                                                    "#" +
                                                      _vm._s(
                                                        item.removed.from.id
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "o-050" }, [
                                            _vm._v(
                                              _vm._s(item.removed.from.email)
                                            ),
                                          ]),
                                          _c("div", { staticClass: "mt-6" }, [
                                            _vm._v(
                                              "il " + _vm._s(item.removed.date)
                                            ),
                                          ]),
                                          item.removed.reason
                                            ? _c(
                                                "div",
                                                { staticClass: "mt-6" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.removed.reason)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box mt-10 p-10" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { plain: "" },
                        on: {
                          click: function ($event) {
                            _vm.historyDrawerOpened = false
                          },
                        },
                      },
                      [_vm._v("Chiudi")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "mdi mdi-account-alert-outline fs-50" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }