var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.infoAbout
    ? _c("div", { staticClass: "info box grow", class: _vm.size }, [
        _vm.isNumber(_vm.infoAbout.score)
          ? _c("span", [
              _c("span", { staticClass: "label" }, [_vm._v("Punteggio: ")]),
              _c("span", { class: ["icon", _vm.size] }, [
                _c("i", { staticClass: "mdi mdi-trophy-outline" }),
              ]),
              _c("strong", [_vm._v(_vm._s(_vm.infoAbout.score))]),
            ])
          : _vm._e(),
        _vm.isNumber(_vm.infoAbout.comments_count)
          ? _c("span", [
              _c("span", { staticClass: "mh-10" }, [_vm._v("~")]),
              _c("span", { staticClass: "label" }, [
                _vm._v("Numero risposte: "),
              ]),
              _c("span", { class: ["icon", _vm.size] }, [
                _c("i", { staticClass: "mdi mdi-forum-outline" }),
              ]),
              _c("strong", [_vm._v(_vm._s(_vm.infoAbout.comments_count))]),
            ])
          : _vm._e(),
        _vm.isNumber(_vm.infoAbout.likes)
          ? _c("span", [
              _c("span", { staticClass: "mh-10" }, [_vm._v("~")]),
              _c("span", { staticClass: "label" }, [_vm._v("Mi piace: ")]),
              _c("span", { class: ["icon", _vm.size] }, [
                _c("i", { staticClass: "mdi mdi-thumb-up-outline" }),
              ]),
              _c("strong", [_vm._v(_vm._s(_vm.infoAbout.likes))]),
            ])
          : _vm._e(),
        _vm.isNumber(_vm.infoAbout.dislikes)
          ? _c("span", [
              _c("span", { staticClass: "mh-10" }, [_vm._v("~")]),
              _c("span", { staticClass: "label" }, [_vm._v("Non mi piace: ")]),
              _vm._m(0),
              _c("strong", [_vm._v(_vm._s(_vm.infoAbout.dislikes))]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "mdi mdi-thumb-down-outline" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }