<template>
    <div class="info flex column" :class="size" v-if="infoAbout">
        <div class="datetime">
            <span>
                <strong>{{ infoAbout.created | dayjs("format", "DD/MM/YYYY [alle] HH:mm") }}</strong>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "QnaDatetime",
    props: {
        infoAbout: {
            type: Object,
            required: false
        },
        size: {
            type: String,
            default: "large"
        }
    }
}
</script>

<style lang="scss" scoped>
.info.small {
    font-size: 12px;
    .datetime {
        margin-top: 5px;
        margin-left: 5px;
    }
}
</style>