<template>
    <el-dialog
        :visible.sync="dialogRoomVisible"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="700px"
        custom-class="room-dialog"
    >
        <div slot="title" v-if="roomLoaded" class="pt-8">
            <el-tooltip class="item" effect="dark" content="Apri in nuovo tab" placement="bottom-start">
                <span class="go-to-btn mr-10" @click="newTabChat"><i class="mdi mdi-open-in-new"></i></span>
            </el-tooltip>

            <span class="go-to-btn" @click="goToChat">Vedi chat <i class="mdi mdi-arrow-right"></i></span>
        </div>
        <div>
            <RoomInfo
                :id="roomId"
                v-if="roomId && dialogRoomVisible"
                @error="closeRoomDialog"
                @loaded="roomLoaded = true"
            ></RoomInfo>
        </div>
    </el-dialog>
</template>

<script>
import { EventBus } from "@/event-bus"
const RoomInfo = () => import("@/components/Chat/RoomInfo")

export default {
    name: "RoomDialog",
    data() {
        return {
            roomId: null,
            roomLoaded: false,
            dialogRoomVisible: false
        }
    },
    methods: {
        goToChat() {
            this.$router.push(`/chat/rooms/${this.roomId}`).catch(err => {})
            this.closeRoomDialog()
        },
        newTabChat() {
            window.open(`/chat/rooms/${this.roomId}`)
            this.closeRoomDialog()
        },
        openRoomDialog({ id }) {
            this.dialogRoomVisible = true
            this.roomId = id
        },
        closeRoomDialog() {
            this.dialogRoomVisible = false
            this.roomId = null
            this.roomLoaded = false
        }
    },
    mounted() {
        EventBus.$on("open:room", ({ id }) => {
            this.closeRoomDialog()
            console.log("open:room", id)
            setTimeout(() => {
                this.openRoomDialog({ id })
            }, 0)
        })
    },
    components: {
        RoomInfo
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.go-to-btn {
    cursor: pointer;
    background: rgba($text-color, 0.06);
    color: $text-color;
    padding: 4px 10px;
    border-radius: 6px;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
        box-shadow: 0px 0px 0px 2px $text-color;
    }
}
</style>
