<template>
    <div class="user-info" :class="size" v-loading="loading" ref="user">
        <resize-observer @notify="__resizeHandler" />

        <div v-if="showError" class="text-center">
            <div><i class="mdi mdi-account-alert-outline fs-50"></i></div>
            <h2>{{ serverError || "L'utente non esiste" }}</h2>
        </div>

        <UserActions
            v-if="user && can_PerformUserActions"
            :id="id"
            :banned="user.flags.is_banned"
            :muted="user.flags.is_muted"
            :editing="editMode"
            @edit-user="editUser"
            @save-user="saveUser"
            :save-user-enabled="saveEnabled"
            @cancel-save-user="cancelSaveUser"
            @need-reload="getUser"
            @user-deleted="emitUserDeleted"
            @history-open="historyDrawerOpened = true"
            class="mb-40"
        ></UserActions>

        <div class="flex user-box" v-if="user">
            <div class="propic" v-if="!editMode">
                <vue-load-image>
                    <img slot="image" class="image" :src="user.image" />
                    <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                    <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                </vue-load-image>
            </div>

            <div class="info-box" :class="{ edit: editMode }">
                <div class="name-box">
                    <div
                        class="name mr-10"
                        :class="{ banned: user.flags.is_banned, muted: user.flags.is_muted }"
                        v-if="!editMode"
                    >
                        {{ user.name }} {{ user.surname }}
                    </div>
                    <div class="name edit-mode mr-10" v-if="editMode">
                        <div class="input-block left">
                            <div class="label">Nome</div>
                            <div class="field">
                                <input v-model="form.name" placeholder="Nome" />
                            </div>
                        </div>
                        <div class="input-block right">
                            <div class="label">Cognome</div>
                            <div class="field">
                                <input v-model="form.surname" placeholder="Cognome" />
                            </div>
                        </div>
                    </div>
                    <div class="icons-box" v-show="!editMode">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="`Bloccato: ${user.flags.ban_reason || '-'}`"
                            placement="bottom"
                        >
                            <i class="mdi mdi-cancel" v-show="user.flags.is_banned"></i>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="`Silenziato fino a: ${user.flags.muted_until || '-'}`"
                            placement="bottom"
                        >
                            <i class="mdi mdi-volume-off" v-show="user.flags.is_muted"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="user.role" placement="bottom">
                            <i
                                class="mdi ml-10 roles color"
                                :class="{
                                    [user.role]: user.role !== 'user',
                                    'mdi-shield': user.role !== 'user',
                                    'mdi-account': user.role === 'user'
                                }"
                            ></i>
                        </el-tooltip>
                        <el-popover placement="bottom-end" width="200" trigger="hover">
                            <vc-donut v-bind="user.rankprops">
                                <div class="fs-18">
                                    <strong>{{ user.rank.percentage_achieved }}%</strong>
                                    <span class="ml-10">Livello {{ user.rank.level }}</span>
                                </div>
                                <div class="mt-6 fs-16">
                                    <small>{{ user.rank.points }} punti</small>
                                </div>
                            </vc-donut>
                            <i class="mdi mdi-trophy-variant ml-10" slot="reference"></i>
                        </el-popover>

                        <vf-icon v-if="user.lang" :country="user.lang" type="rounded-rectangle" class="ml-10" />
                    </div>
                </div>

                <div class="username" v-show="!editMode">
                    @{{ user.username }}

                    <el-popover placement="bottom" width="270" trigger="click">
                        <div v-if="user.flags.can_update_username" class="mb-10 ml-6 mt-6">
                            <i class="mdi mdi-lock-open-variant"></i> L'utente può modificare la username
                        </div>
                        <div v-if="!user.flags.can_update_username" class="mb-10 ml-6 mt-6">
                            <i class="mdi mdi-lock"></i> L'utente <strong>NON</strong> può modificare la username
                        </div>
                        <div class="username-list">
                            <div v-for="item in user.flags.username_history" :key="item.changed" class="m-6 mb-10">
                                <div>@{{ item.username }}</div>
                                <div class="o-050">fino al {{ item.changed }}</div>
                            </div>
                        </div>
                        <div v-if="!user.flags.username_history.length" class="m-6">username mai modificata</div>
                        <i class="mdi mdi-history ml-10 fs-16 clickable" slot="reference" v-show="!editMode"></i>
                    </el-popover>
                </div>

                <div class="user-id" v-if="!editMode">
                    <span>#{{ id }}</span>
                    <span class="ml-12">Registrato il {{ user.register_date }}</span>
                </div>
                <div class="username edit-mode" v-if="editMode">
                    <div class="input-block">
                        <div class="label">Username</div>
                        <div class="field">
                            <input v-model="form.username" placeholder="Username" />
                        </div>
                        <div class="error">{{ usernameError }}</div>
                    </div>
                    <div class="input-block">
                        <div class="label"></div>
                        <div class="field">
                            <input
                                type="checkbox"
                                v-model="form.flags.can_update_username"
                                placeholder="Può aggiornare la username"
                            />
                        </div>
                    </div>
                </div>

                <div class="birthdate" v-if="!editMode">
                    {{ user.birthdate | dayjs("format", "DD MMMM YYYY") }}
                    <span v-if="user.town" class="mh-10">-</span>
                    {{ user.town || "" }} {{ user.province || "" }} {{ user.country || "" }}
                    <el-popover placement="bottom" width="270" trigger="click">
                        <iframe
                            width="100%"
                            height="300"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            :src="user.map_url"
                            class="user-info__map-frame"
                        ></iframe>

                        <i class="mdi mdi-map ml-10 fs-16 clickable" slot="reference" v-show="!editMode && user.town">
                        </i>
                    </el-popover>
                </div>
                <div class="birthdate edit-mode" v-if="editMode">
                    <div class="input-block">
                        <div class="label">Data di nascita</div>
                        <div class="field">
                            <el-date-picker
                                v-model="form.birthdate"
                                :editable="false"
                                format="dd MMM yyyy"
                                :picker-options="{ firstDayOfWeek: 1 }"
                                placeholder="Data di nascita"
                            />
                        </div>
                    </div>
                </div>

                <!--<div class="location" v-show="!editMode"></div>-->
                <div class="location edit-mode" v-if="editMode">
                    <div class="input-block span-2-3 left">
                        <div class="label">Città</div>
                        <div class="field">
                            <input v-model="form.town" placeholder="Città" />
                        </div>
                    </div>
                    <div class="input-block span-2-3 left right">
                        <div class="label">Provincia</div>
                        <div class="field">
                            <input v-model="form.province" placeholder="Provincia" />
                        </div>
                    </div>
                    <div class="input-block span-2-3 right">
                        <div class="label">Nazione</div>
                        <div class="field">
                            <input v-model="form.country" placeholder="Nazione" />
                        </div>
                    </div>
                </div>

                <div class="email-box" v-show="!editMode">
                    <span class="email">{{ user.email }}</span>
                    <el-popover placement="bottom-end" width="270" trigger="click">
                        <div class="email-list">
                            <div v-for="item in user.flags.email_history" :key="item.changed" class="m-6 mb-10">
                                <div>
                                    {{ item.email }}
                                </div>
                                <div class="o-050">fino al {{ item.changed }}</div>
                            </div>
                        </div>
                        <div v-if="!user.flags.email_history.length">email mai modificata</div>
                        <i class="mdi mdi-history ml-10 fs-16 clickable" slot="reference"></i>
                    </el-popover>
                </div>
                <div class="email-box mb-0" v-if="editMode">
                    <div class="email edit-mode">
                        <div class="input-block span-2">
                            <div class="label">Email</div>
                            <div class="field">
                                <input v-model="form.email" placeholder="Email" />
                            </div>
                            <div class="error">{{ emailError }}</div>
                        </div>
                    </div>
                </div>

                <div class="phone" v-if="!editMode">
                    {{ user.device ? user.device.phone : "" }}
                    <el-popover placement="bottom-end" width="230" trigger="click">
                        <div class="item m-6 fs-16">
                            <i class="mdi mdi-android" v-if="user.device.os_at_registration === 'android'"></i>
                            <i class="mdi mdi-apple" v-if="user.device.os_at_registration !== 'android'"></i>
                            <span>
                                Sistema operativo <strong>{{ user.device.os_at_registration }}</strong>
                            </span>
                        </div>
                        <div class="item m-6 fs-16">
                            <i class="mdi mdi-cellphone-android"></i>
                            <span>
                                Telefono <strong>{{ user.device.phone }}</strong>
                            </span>
                        </div>
                        <div class="item m-6 fs-16">
                            <i class="mdi mdi-message-processing"></i>
                            <span>
                                Codice SMS <strong>{{ user.device.sms_confirmation_token }}</strong>
                            </span>
                        </div>
                        <i class="mdi mdi-cellphone ml-10 fs-16 clickable" slot="reference"></i>
                    </el-popover>
                </div>
                <div class="phone edit-mode" v-if="editMode">
                    <div class="input-block">
                        <div class="label">Numero di telefono</div>
                        <div class="field">
                            <input v-model="form.device.phone" placeholder="Numero di telefono" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="user" class="flex flags-box-inline fs-16">
            <div class="item mr-22">
                <i class="mdi mdi-check-bold success-text" v-if="user.flags.mail_verified"></i>
                <i class="mdi mdi-close-thick" v-if="!user.flags.mail_verified"></i>
                <span class="ml-5">Email verificata</span>
            </div>
            <div class="item mr-22">
                <i class="mdi mdi-check-bold success-text" v-if="user.flags.sms_confirmed"></i>
                <i class="mdi mdi-close-thick" v-if="!user.flags.sms_confirmed"></i>
                <span class="ml-5">SMS confermato</span>
            </div>
            <div class="item">
                <i class="mdi mdi-check-bold success-text" v-if="user.flags.can_update_username"></i>
                <i class="mdi mdi-close-thick" v-if="!user.flags.can_update_username"></i>
                <span class="ml-5">Può aggiornare la username</span>
            </div>
        </div>

        <div v-if="user" class="flags-box">
            <div class="title">
                <span>Permessi</span>

                <el-popover placement="top-start" width="270" trigger="click">
                    <div class="flags-list">
                        <div v-for="(item, index) in user.flags.flag_history" :key="index" class="m-6 mb-10">
                            <div>{{ item.flag }} ({{ item.updated_flag_type }})</div>
                            <div class="o-050">fino al {{ item.changed }}</div>
                        </div>
                    </div>
                    <div v-if="!user.flags.flag_history.length">permessi mai modificati</div>
                    <i slot="reference" class="mdi mdi-history ml-10 fs-16 clickable"></i>
                </el-popover>
            </div>

            <div class="list">
                <div class="item">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.marketing_owner"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.marketing_owner"></i>
                    <span class="ml-5">Marketing proprietario</span>
                </div>
                <div class="item">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.marketing_owner_for_thirds"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.marketing_owner_for_thirds"></i>
                    <span class="ml-5">Marketing proprietario per terzi</span>
                </div>
                <div class="item">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.marketing_thirds"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.marketing_thirds"></i>
                    <span class="ml-5">Marketing terzi</span>
                </div>
                <div class="item">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.user_considerable_consent === true"></i>
                    <i class="mdi mdi-close-thick" v-if="user.flags.user_considerable_consent === false"></i>
                    <i class="mdi mdi-help" v-if="user.flags.user_considerable_consent === null"></i>
                    <span class="ml-5">Dati sensibili gravidanza</span>
                </div>
                <div class="item">
                    <i
                        class="mdi mdi-check-bold success-text"
                        v-if="user.flags.user_children_data_consent === true"
                    ></i>
                    <i class="mdi mdi-close-thick" v-if="user.flags.user_children_data_consent === false"></i>
                    <i class="mdi mdi-help" v-if="user.flags.user_children_data_consent === null"></i>
                    <span class="ml-5">Dati sensibili bambini</span>
                </div>
                <div class="item" v-if="user.flags.user_profilation !== undefined">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.user_profilation"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.user_profilation"></i>
                    <span class="ml-5">Profilazione</span>
                </div>
                <div class="item">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.policy_version_4_0"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.policy_version_4_0"></i>
                    <span class="ml-5">Policy 4.0</span>
                </div>
                <div class="item" v-if="user.flags.privacy_policy_rev_5_accepted !== undefined">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.privacy_policy_rev_5_accepted"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.privacy_policy_rev_5_accepted"></i>
                    <span class="ml-5">Policy rev. 5</span>
                    <el-tooltip
                        :content="$dayjs(user.flags.privacy_policy_rev_5_accepted).format('DD/MM/YYYY HH:mm')"
                        placement="top-end"
                    >
                        <i
                            class="mdi mdi-clock-outline ml-6 accent-text"
                            v-show="typeof user.flags.privacy_policy_rev_5_accepted === 'string'"
                        ></i>
                    </el-tooltip>
                </div>
                <div class="item">
                    <i class="mdi mdi-check-bold success-text" v-if="user.flags.marketing_push_notifications"></i>
                    <i class="mdi mdi-close-thick" v-if="!user.flags.marketing_push_notifications"></i>
                    <span class="ml-5">Notifiche push</span>
                </div>
            </div>
        </div>

        <el-drawer
            custom-class="user-info__history-drawer"
            title="Cronologia Ban/Silence"
            :visible.sync="historyDrawerOpened"
            :modal="true"
            :with-header="false"
            :append-to-body="true"
        >
            <div class="user-info__history-box flex column" v-if="user && user.flags && user.flags.penalties_history">
                <div class="box mb-10 p-10 flex justify-space-between">
                    <div class="title">Cronologia Ban/Silence</div>
                    <div>
                        <i
                            class="el-dialog__close el-icon el-icon-close el-drawer__close-btn"
                            @click="historyDrawerOpened = false"
                        ></i>
                    </div>
                </div>
                <div class="box grow scrollable only-y">
                    <el-timeline :reverse="true">
                        <el-timeline-item
                            v-for="item in user.flags.penalties_history"
                            :key="item.date"
                            :timestamp="item.date"
                            :type="item.color"
                            placement="top"
                        >
                            <el-card class="user-info__history-card">
                                <div class="type" :class="{ [item.typeParsed]: true }">{{ item.typeText }}</div>
                                <div class="desc">{{ item.reason }}</div>
                                <div class="desc" v-if="item.until">fino a: {{ item.until }}</div>
                                <div class="sender flex" v-if="item.from">
                                    <div class="box">da:</div>
                                    <div class="box grow">
                                        <div class="clickable" @click="openUser(item.from.id)">
                                            {{ item.from.name }} {{ item.from.surname }}
                                            <small class="o-050">#{{ item.from.id }}</small>
                                        </div>
                                        <div class="o-050">{{ item.from.email }}</div>
                                    </div>
                                </div>
                                <div class="removed mt-20" v-if="item.removed">
                                    <div class="mb-5">rimosso da:</div>
                                    <div>
                                        <div class="clickable" @click="openUser(item.removed.from.id)">
                                            {{ item.removed.from.name }} {{ item.removed.from.surname }}
                                            <small class="o-050">#{{ item.removed.from.id }}</small>
                                        </div>
                                        <div class="o-050">{{ item.removed.from.email }}</div>
                                        <div class="mt-6">il {{ item.removed.date }}</div>
                                        <div v-if="item.removed.reason" class="mt-6">{{ item.removed.reason }}</div>
                                    </div>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div class="box mt-10 p-10">
                    <el-button plain @click="historyDrawerOpened = false">Chiudi</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import VueLoadImage from "vue-load-image"
import { openUserDialog } from "@/common"
import UserActions from "@/components/User/UserActions"

export default {
    name: "UserInfo",
    props: ["id"],
    data() {
        return {
            user: null,
            loading: true,
            editMode: false,
            historyDrawerOpened: false,
            form: {},
            usernameError: "",
            emailError: "",
            size: "large",
            showError: false,
            serverError: ""
        }
    },
    computed: {
        saveEnabled() {
            return !this.usernameError && !this.emailError
        },
        can_PerformUserActions() {
            return this.$store.getters.can_PerformUserActions
        }
    },
    watch: {
        "form.username"(val) {
            const username = _.trim(val)
            if (!username) {
                this.usernameError = "la username non può essere vuota"
            } else {
                if (username.length < 3) {
                    this.usernameError = "la username deve contenere almeno 3 caratteri"
                } else if (username.length > 15) {
                    this.usernameError = "la username deve contenere meno di 16 caratteri"
                } else if ((username.match(/[a-zA-Z0-9]+/) || [])[0] !== username) {
                    this.usernameError = "Sono consentiti solo lettere e numeri"
                } else {
                    this.usernameError = ""
                }
            }
        },
        "form.email"(val) {
            const email = _.trim(val)
            if (!email) {
                this.emailError = "la email non può essere vuota"
            } else {
                if ((email.match(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/) || [])[0] !== email) {
                    this.emailError = "Email non corretta"
                } else {
                    this.emailError = ""
                }
            }
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.user) {
                const width = this.$refs.user.offsetWidth

                this.size = "large"
                if (width < 350) {
                    this.size = "small"
                } else if (width < 620) {
                    this.size = "medium"
                }
            }
        },
        getUser() {
            this.loading = true

            api.users
                .getInfo(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const user = res.body

                        user.map_url = `https://maps.google.com/maps?width=100%&height=300&hl=it&q=
						${user.town || ""} ${user.province || ""} ${user.country || ""}
						&amp;t=&z=14&ie=UTF&iwloc=B&output=embed`

                        user.rankprops = {
                            size: 100,
                            unit: "%",
                            sections: [{ value: _.get(user, "rank.percentage_achieved") || 0, color: "#5f8fdf" }],
                            thickness: 20,
                            hasLegend: false,
                            background: "white"
                        }

                        if (!user.image) user.image = require("@/assets/images/img-not-found.svg")

                        user.flags.is_muted = user.flags.muted_until
                            ? this.$dayjs(user.flags.muted_until, "DD/MM/YYYY HH:mm:ss").isSameOrAfter()
                            : false

                        user.flags?.penalties_history?.map(p => {
                            p.typeParsed = p.type === "mute" && p.removed ? "unmute" : p.type
                            p.color =
                                p.typeParsed === "mute"
                                    ? "warning"
                                    : p.typeParsed === "ban"
                                    ? "danger"
                                    : p.typeParsed === "unmute"
                                    ? "info"
                                    : "success"

                            p.typeText = ""

                            switch (p.typeParsed) {
                                case "mute":
                                    p.typeText = "Silenziato"
                                    break
                                case "unmute":
                                    p.typeText = "Silenziato"
                                    break
                                case "ban":
                                    p.typeText = "Bloccato"
                                    break
                                case "unban":
                                    p.typeText = "Sbloccato"
                                    break
                                default:
                                    p.typeText = ""
                                    break
                            }

                            return p
                        })

                        this.user = user
                    }
                    this.loading = false
                    this.$emit("loaded", this.user)
                })
                .catch(err => {
                    this.loading = false
                    this.showError = true
                    this.serverError = err?.body?.details || "Server Error"
                    console.error(err)
                    this.$emit("error")
                })
        },
        openUser(id) {
            this.historyDrawerOpened = false
            setTimeout(() => {
                openUserDialog({ id })
            }, 0)
        },
        editUser() {
            this.editMode = true
            this.form = {
                name: this.user.name,
                surname: this.user.surname,
                username: this.user.username,
                birthdate: this.user.birthdate,
                town: this.user.town,
                province: this.user.province,
                country: this.user.country,
                email: this.user.email,
                device: {
                    phone: this.user.device.phone
                },
                flags: {
                    can_update_username: this.user.flags.can_update_username
                }
            }
        },
        getSaveUserError(code) {
            code = code || 0
            const errors = [
                "Errore...",
                "L'email non è cambiata",
                "Il nome utente non è cambiato",
                "Email non valida",
                "Email già presente nel database",
                "Il nome utente deve contenere almeno 3 caratteri",
                "Il nome utente non può contenere più di 15 caratteri",
                "Questo nome utente esiste già",
                "Nome utente non valido"
            ]

            return errors[code]
        },
        saveUser() {
            const diff = {}
            const old_birthdate = this.$dayjs(this.user.birthdate).format("YYYY-MM-DD")
            const new_birthdate = this.$dayjs(this.form.birthdate).format("YYYY-MM-DD")

            if (old_birthdate !== new_birthdate) diff.birthdate = new_birthdate
            if (_.get(this.user, "name") !== _.get(this.form, "name")) diff.name = _.get(this.form, "name")
            if (_.get(this.user, "surname") !== _.get(this.form, "surname")) diff.surname = _.get(this.form, "surname")
            if (_.get(this.user, "username") !== _.get(this.form, "username"))
                diff.username = _.get(this.form, "username")
            if (_.get(this.user, "town") !== _.get(this.form, "town")) diff.town = _.get(this.form, "town")
            if (_.get(this.user, "province") !== _.get(this.form, "province"))
                diff.province = _.get(this.form, "province")
            if (_.get(this.user, "country") !== _.get(this.form, "country")) diff.country = _.get(this.form, "country")
            if (_.get(this.user, "email") !== _.get(this.form, "email")) diff.email = _.get(this.form, "email")
            if (_.get(this.user, "device.phone") !== _.get(this.form, "device.phone"))
                diff.phone = _.get(this.form, "device.phone")
            if (_.get(this.user, "flags.can_update_username") !== _.get(this.form, "flags.can_update_username"))
                diff.can_update_username = _.get(this.form, "flags.can_update_username") ? 1 : 0

            api.users
                .update(this.id, diff)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Utente #${this.id} modificato correttamente`
                    })
                    this.editMode = false
                    this.getUser()
                })
                .catch(err => {
                    console.error(err)
                    this.$notify.error({
                        title: "Ops...",
                        message: this.getSaveUserError(err.body.code)
                    })
                })
        },
        cancelSaveUser() {
            this.editMode = false
        },
        emitUserDeleted() {
            this.$emit("user-deleted")
        }
    },
    mounted() {
        this.calcDim()

        if (this.id) {
            this.getUser()
        }
    },
    components: {
        "vue-load-image": VueLoadImage,
        UserActions
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";

.user-info {
    .propic {
        margin-bottom: 20px;
        margin-right: 10px;

        img {
            //border: 5px solid #fff;
            width: 210px;
            border-radius: 16px;
            //@extend .card-shadow--small;
            outline: none;
        }
    }

    .info-box {
        margin-left: 30px;

        .edit-mode {
            --width: 275px;

            text-decoration: none !important;
            cursor: default !important;
            opacity: 1 !important;
            margin: 0 !important;

            .input-block {
                display: inline-block;
                padding: 4px;
                margin-bottom: 6px;
                vertical-align: middle;
                width: var(--width);
                height: 84px;

                .label {
                    font-size: 14px;
                    padding: 0 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    box-sizing: border-box;
                    height: 20px;
                    text-transform: initial;
                    width: 100%;
                }
                .error {
                    font-size: 14px;
                    line-height: 14px;
                    color: $color-danger;
                    margin-top: 4px;
                    padding: 0 2px;
                }
                .field {
                    width: 100%;

                    & > input {
                        box-sizing: border-box;
                        font-size: 16px;
                        border: 2px solid transparent;
                        outline: none;
                        background: #fff;
                        padding: 10px 14px;
                        border-radius: 8px;
                        display: inline-block;
                        transition: all 0.25s;
                        @extend .card-shadow--small;
                        width: 100%;

                        &[type="checkbox"] {
                            position: relative;
                            cursor: pointer !important;
                            padding: 0;
                            margin: 0;
                            appearance: none;
                            border: none;
                            height: 42px;
                            vertical-align: top;
                            overflow: hidden;

                            &::before {
                                content: "\F0374";
                                width: 42px;
                                height: 42px;
                                background: #f7f7f7;
                                color: #848484;
                                display: inline-block;
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                font-family: "Material Design Icons";
                                font-size: 30px;
                                box-sizing: border-box;
                                text-align: center;
                                line-height: 42px;
                                transition: all 0.2s;
                            }

                            &::after {
                                content: attr(placeholder);
                                width: 10px;
                                height: 10px;
                                display: inline-block;
                                position: absolute;
                                top: 0px;
                                left: 42px;
                                width: calc(100% - 42px);
                                height: 42px;
                                line-height: 26px;
                                box-sizing: border-box;
                                padding: 10px 14px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &:checked {
                                &::before {
                                    content: "\F012C";
                                    background: rgba($color-primary, 0.2);
                                    color: $color-primary;
                                }
                            }
                        }

                        &:hover {
                            border-color: rgba($color-primary, 0.35);
                        }
                        &:focus {
                            border-color: $color-primary;
                        }
                    }

                    :deep {
                        .el-input {
                            width: 100%;
                            @extend .card-shadow--small;
                            border-radius: 8px;
                            font-size: 16px;

                            input.el-input__inner {
                                border-radius: 8px;
                                color: #000;
                                border: 2px solid transparent;
                            }

                            &:hover {
                                input.el-input__inner {
                                    border-color: rgba($color-primary, 0.35);
                                }
                            }
                            &:focus {
                                input.el-input__inner {
                                    border-color: $color-primary;
                                }
                            }
                        }
                    }
                }

                &.left {
                    padding-right: 0;
                    width: calc(var(--width) + 4px);

                    .field {
                        & > input {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
                &.right {
                    padding-left: 0;
                    width: calc(var(--width) + 4px);

                    .field {
                        & > input {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                }
                /*&.center {
					padding-left: 0;
					width: calc(var(--width) + 4px);

					.field {
						& > input {
							border-radius: 0;
							border-bottom-left-radius: 0;
						}
					}
				}*/
                &.span-2 {
                    width: calc(var(--width) * 2 + 8px);
                }
                &.span-2-3 {
                    width: calc(var(--width) / 3 * 2 - 2px);
                }
                &.span-2-3.left {
                    width: calc(var(--width) / 3 * 2 + 2px);
                }
                &.span-2-3.right {
                    width: calc(var(--width) / 3 * 2 + 2px);
                }
                &.span-2-3.left.right {
                    width: calc(var(--width) / 3 * 2 + 4px);
                }
            }
        }

        .name-box {
            font-weight: bold;
            font-size: 24px;
            user-select: text;

            .name {
                word-break: break-word;
                display: inline-block;
            }

            .icons-box {
                display: inline-block;
            }

            .muted {
                color: $color-warning;
            }
            .banned {
                color: $color-danger;
            }
            .mdi-volume-off {
                color: $color-warning;
            }
            .mdi-cancel {
                color: $color-danger;
            }
        }
        .username {
            font-size: 20px;
            margin-bottom: 18px;
            display: inline-block;
            user-select: text;
        }
        .birthdate,
        .location,
        .phone,
        .user-id,
        .email-box {
            font-size: 18px;
            margin-bottom: 8px;
            display: table;
            clear: both;
            user-select: text;
        }

        &.edit {
            margin-left: 0;
        }
    }
    .clickable {
        cursor: pointer;
        outline: none;
        color: $color-primary;
    }

    .flags-box-inline {
        .item {
            span {
                word-break: keep-all;
            }
        }
    }

    .flags-box {
        margin-top: 30px;

        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            line-height: 1;
            margin-top: 20px;
        }
    }

    &.medium,
    &.small {
        .user-box {
            flex-direction: column;
            -webkit-box-orient: vertical;
        }
        .propic {
            width: 100%;
            display: block;

            img {
                width: 100%;
                max-width: 200px;
            }
        }
        .info-box {
            margin-left: 0;
            margin-bottom: 30px;
        }
    }
    &.small {
        .propic {
            img {
                max-width: 100%;
            }
        }
        .info-box {
            .birthdate,
            .location,
            .phone,
            .user-id,
            .email-box {
                font-size: 14px;
            }
        }

        .flags-box {
            .list {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                gap: 18px;
                margin-top: 14px;
            }
        }
    }
}
</style>

<style lang="scss">
@import "../../assets/scss/_variables";

.user-info__map-frame {
    background-image: url(../../assets/images/loading.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.user-info__history-drawer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    --flex-grid: 12;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .el-drawer__body {
        -webkit-box-flex: 1;
        -ms-flex: 1 0;
        flex: 1 0;
        height: 100%;
    }

    .user-info__history-box {
        height: 100%;
        padding: 10px;

        .title {
            color: #72767b;
        }

        .scrollable {
            padding: 10px;
        }

        .el-timeline {
            padding-left: 0px;
        }

        .user-info__history-card {
            .type {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 4px;
                text-transform: uppercase;

                &.unmute {
                    text-decoration: line-through;
                    text-decoration-color: $color-danger;
                }
            }
            .desc {
                margin-bottom: 14px;
            }
            .sender {
                .box.grow {
                    margin-left: 10px;
                }
            }
            .clickable {
                cursor: pointer;
                text-decoration: underline;
                font-weight: bold;
            }

            .removed {
                background: #f5f5f5;
                padding: 10px 16px;
                box-sizing: border-box;
                border-radius: 8px;
            }
        }
    }
}
</style>
