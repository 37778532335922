import { EventBus } from "@/event-bus"

/**
 * @param {Object} params - user info
 * @param {string} params.id - user id
 * @param {boolean=} params.chat - enable chat buttons
 * @param {Object=} params.user - user object
 *
 * @return {void}
 */
export function openUserDialog({ id, chat = false, user = {} }) {
    if (id) {
        EventBus.$emit("open:user", { id, chat, user })
    }
}

/**
 * @param {Object} params - room info
 * @param {string} params.id - room id
 *
 * @return {void}
 */
export function openRoomDialog({ id }) {
    if (id) {
        EventBus.$emit("open:room", { id })
    }
}
