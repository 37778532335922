<template>
    <div class="page-reviews-list flex column" v-loading="loading">
        <div class="reviews-list scrollable only-y box grow" v-show="!loading && (featured.length || reviews.length)">
            <div class="featured-group">
                <div class="fs-30 mb-20">In evidenza</div>
                <div v-if="!loading && !featured.length" class="review flex align-center">
                    <div class="box grow info">
                        <div class="title not-clickable mt-10">Non sono presenti recensioni in evidenza</div>
                    </div>
                </div>
                <draggable
                    class="list-group"
                    tag="div"
                    handle=".handle"
                    v-model="featured"
                    v-bind="dragOptions"
                    group="featured"
                    @start="drag = true"
                    @end="drag = false"
                >
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <ReviewCard
                            v-for="r in featured"
                            :key="r._id"
                            :review="r"
                            :handle="true"
                            @click="goTo(r._id)"
                        ></ReviewCard>
                    </transition-group>
                </draggable>
            </div>

            <draggable
                class="list-group"
                tag="div"
                handle=".handle"
                v-model="reviews"
                v-bind="dragOptions"
                group="reviews"
                @start="drag = true"
                @end="drag = false"
            >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <ReviewCard
                        v-for="r in reviews"
                        :key="r._id"
                        :review="r"
                        :handle="true"
                        @click="goTo(r._id)"
                    ></ReviewCard>
                </transition-group>
            </draggable>
        </div>
        <div class="reviews-list" v-if="!loading && !reviews.length && !featured.length">
            <div class="review flex align-center" @click="goTo()">
                <div class="box grow info">
                    <div class="title mt-10">Non sono presenti recensioni pubblicate, creane una!</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
const draggable = () => import("vuedraggable")
import ReviewCard from "@/components/Reviews/ReviewCard"

export default {
    name: "ReviewsPublished",
    data() {
        return {
            featured: [],
            reviews: [],
            loading: true,
            drag: false,
            dragOptions: {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    watch: {
        drag(val) {
            if (val === false) {
                this.setOrder()
            }
        }
    },
    computed: {},
    methods: {
        getList() {
            this.loading = true
            api.reviews
                .getPublished()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const reviews = res.body
                        this.featured = _.chain(reviews)
                            .filter(r => r.featured)
                            .orderBy([r => r.order, r => r.id], ["asc", "desc"])
                            .value()
                        this.reviews = _.chain(reviews)
                            .filter(r => !r.featured)
                            .orderBy([r => r.order, r => r.id], ["asc", "desc"])
                            .value()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        setOrder() {
            const orderF = _.map(this.featured, r => r.id)
            const orderR = _.map(this.reviews, r => r.id)

            api.reviews
                .setOrder([...orderF, ...orderR])
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.$notify.success({
                            title: "Fatto!",
                            message: `Recensioni ordinate correttamente`
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore modifica ordine`
                    })
                })
        },
        goTo(id) {
            this.$router.push(`/reviews/${id || ""}`).catch(err => {})
        }
    },
    created() {},
    mounted() {
        this.getList()
    },
    beforeDestroy() {},
    components: { draggable, ReviewCard }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-reviews-list {
    user-select: none;
    padding-bottom: 0px !important;

    .reviews-list {
        padding: 0 10px;
        margin-left: -10px;
        margin-right: -15px;

        .featured-group {
            background: rgba($color-warning, 0.3);
            padding: 10px;
            margin: 0 -10px;
            margin-bottom: 50px;
            border-radius: 8px;

            .review-card {
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
</style>
