<template>
	<div class="editor-html">
		<codemirror :value="html" :options="cmOptions" ref="cmEditor" @input="onCmCodeChange" />
		<div v-if="preview">
			<div class="label">Anteprima</div>
			<div class="preview" v-html="html"></div>
		</div>
	</div>
</template>

<script>
import { codemirror } from "vue-codemirror"
import "codemirror/lib/codemirror.css"
import "codemirror/mode/htmlmixed/htmlmixed.js"
import "codemirror/addon/edit/closetag.js"
import "codemirror/addon/edit/matchtags.js"
import "codemirror/addon/selection/active-line.js"

export default {
	name: "EditorHtml",
	props: {
		html: {
			type: String
		},
		preview: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			cmOptions: {
				tabSize: 4,
				indentUnit: 4,
				indentWithTabs: true,
				mode: "text/html",
				lineNumbers: true,
				line: true,
				autoCloseTags: true,
				styleActiveLine: true,
				matchTags: true
			}
		}
	},
	computed: {},
	methods: {
		onCmCodeChange(newCode) {
			this.$emit("update:html", newCode)
		}
	},
	created() {},
	mounted() {},
	beforeDestroy() {},
	components: {
		codemirror
	}
}
</script>

<style lang="scss" scoped>
.editor-html {
	.vue-codemirror {
		border: 1px solid rgba(0, 0, 0, 0.08);
		box-sizing: border-box;
		border-radius: 6px;
		overflow: hidden;
	}
	.label {
		margin-top: 30px;
	}
	.preview {
		padding: 20px;
		border: 1px solid rgba(0, 0, 0, 0.08);
		box-sizing: border-box;
		border-radius: 6px;
		margin-top: 10px;
	}
}
</style>
