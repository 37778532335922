var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "chat",
      staticClass: "flex",
      class: _vm.size,
      attrs: { id: "chat-box" },
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c(
        "div",
        { staticClass: "box flex column" },
        [
          _c("div", { staticClass: "users-total-count" }, [
            _c("span", [_vm._v("Utenti connessi: ")]),
            _c("strong", [_vm._v(_vm._s(_vm.usersCount))]),
          ]),
          _c("RoomSelect", {
            attrs: { roomId: _vm.roomId, roomsGroups: _vm.roomsGroups },
            on: {
              "update:roomId": function ($event) {
                _vm.roomId = $event
              },
              "update:room-id": function ($event) {
                _vm.roomId = $event
              },
              change: function ($event) {
                return _vm.openRoom(_vm.roomId)
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "box scrollable only-y",
              attrs: { id: "rooms-list" },
            },
            _vm._l(_vm.roomsGroups, function (group) {
              return _c(
                "div",
                { key: group.name, staticClass: "rooms-group" },
                [
                  _c("div", { staticClass: "group-name" }, [
                    _vm._v(_vm._s(group.name)),
                  ]),
                  _vm._l(group.items, function (room) {
                    return _c("RoomCard", {
                      key: room._id,
                      attrs: { room: room, active: room._id === _vm.roomId },
                      on: {
                        open: function ($event) {
                          return _vm.openRoom(room._id)
                        },
                      },
                    })
                  }),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
      !_vm.roomId
        ? _c(
            "div",
            {
              staticClass:
                "box grow flex card-base card-shadow--small align-center text-center",
              attrs: { id: "message-box" },
            },
            [
              _c("div", { staticClass: "box grow text-center fs-20 p-30" }, [
                _vm._v("Clicca su una stanza per aprire la chat"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.roomId
        ? _c(
            "div",
            {
              staticClass: "box grow flex column card-base card-shadow--small",
              attrs: { id: "message-box" },
            },
            [
              _vm.currentRoom
                ? _c("RoomHeader", {
                    attrs: {
                      room: _vm.currentRoom,
                      typingList: _vm.typing,
                      typingText: _vm.typingText,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "msgList",
                  staticClass: "box grow scrollable only-y",
                  attrs: { id: "message-list" },
                  on: { scroll: _vm.msgListScrollHandler },
                },
                [
                  _c(
                    "div",
                    { ref: "scrollWrap", staticClass: "scroll-wrap" },
                    _vm._l(_vm.messages, function (msg) {
                      return _c(
                        "div",
                        {
                          key: msg._id,
                          staticClass: "message-row flex align-center",
                          class: {
                            "is-mine": msg.isMine,
                            "justify-flex-start": !msg.isMine,
                            "justify-flex-end": msg.isMine,
                            found: msg._id === _vm.messageToFind,
                          },
                          attrs: { id: msg._id },
                        },
                        [
                          msg._id === _vm.messageToFind && msg.isMine
                            ? _c("div", { staticClass: "msg-found box grow" })
                            : _vm._e(),
                          msg.type !== "info"
                            ? _c(
                                "div",
                                {
                                  staticClass: "msg-box flex align-flex-end",
                                  class: { reverse: msg.isMine },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "msg-propic" },
                                    [
                                      _c("vue-load-image", [
                                        _c("img", {
                                          staticClass: "image",
                                          attrs: {
                                            slot: "image",
                                            src: msg.author.pictureThumb,
                                          },
                                          slot: "image",
                                        }),
                                        _c("img", {
                                          staticClass: "preloader",
                                          attrs: {
                                            slot: "preloader",
                                            src: require("@/assets/images/loading.png"),
                                          },
                                          slot: "preloader",
                                        }),
                                        _c("img", {
                                          staticClass: "error",
                                          attrs: {
                                            slot: "error",
                                            src: require("@/assets/images/img-not-found.svg"),
                                          },
                                          slot: "error",
                                        }),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "msg-content",
                                      style: {
                                        background: _vm.getUserPinnedColor(
                                          msg.author.id
                                        ),
                                      },
                                    },
                                    [
                                      !msg.isMine && !msg.isSpecial
                                        ? _c(
                                            "div",
                                            { staticClass: "msg-action" },
                                            [
                                              _c(
                                                "el-dropdown",
                                                {
                                                  attrs: { trigger: "click" },
                                                  on: {
                                                    command: (command) =>
                                                      _vm.msgActions(
                                                        command,
                                                        msg
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "el-dropdown-link",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-down",
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-dropdown-menu",
                                                    {
                                                      attrs: {
                                                        slot: "dropdown",
                                                      },
                                                      slot: "dropdown",
                                                    },
                                                    [
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          attrs: {
                                                            command: "copyLink",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "mdi mdi-link-variant",
                                                          }),
                                                          _vm._v("Copia link "),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          attrs: {
                                                            command: "reply",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "mdi mdi-reply",
                                                          }),
                                                          _vm._v("Rispondi "),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          staticClass:
                                                            "warning-text",
                                                          attrs: {
                                                            command: "report",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "mdi mdi-alert",
                                                          }),
                                                          _vm._v("Segnala "),
                                                        ]
                                                      ),
                                                      _vm.can_DeleteChatMessage
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              staticClass:
                                                                "danger-text",
                                                              attrs: {
                                                                command:
                                                                  "delete",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "mdi mdi-delete",
                                                              }),
                                                              _vm._v(
                                                                "Elimina messaggio "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      msg.parent
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "msg-parent",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.scrollToMessage(
                                                    msg.parent
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "msg-parent-info",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("in risposta a:"),
                                                  ]),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "clickable",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.openUser(
                                                            msg.parent.author
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " @" +
                                                          _vm._s(
                                                            msg.parent.author
                                                              .username
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              msg.parent.body
                                                ? _c("div", {
                                                    staticClass:
                                                      "msg-parent-body",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        msg.parent.body
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              msg.parent.type === "img" ||
                                              msg.parent.type === "sticker"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "msg-parent-body-img",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: msg.parent
                                                            .imageThumb,
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "msg-info flex" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "msg-author",
                                              style: {
                                                color:
                                                  !msg.isMine &&
                                                  msg.author.color,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUser(
                                                    msg.author
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " @" +
                                                  _vm._s(msg.author.username) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: _vm
                                                  .$dayjs(msg.dateSent)
                                                  .format(
                                                    "DD MMMM YYYY [alle] HH:mm:ss"
                                                  ),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "msg-time" },
                                                [_vm._v(_vm._s(msg.timeAdded))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      msg.body
                                        ? _c("div", {
                                            staticClass: "msg-body",
                                            domProps: {
                                              innerHTML: _vm._s(msg.body),
                                            },
                                          })
                                        : _vm._e(),
                                      msg.type === "img" ||
                                      msg.type === "sticker" ||
                                      (msg.type === "institutional" &&
                                        msg.imageThumb)
                                        ? _c(
                                            "div",
                                            { staticClass: "msg-body-img" },
                                            [
                                              _c("vue-load-image", [
                                                _c("img", {
                                                  staticClass: "image",
                                                  attrs: {
                                                    slot: "image",
                                                    src: msg.imageThumb,
                                                  },
                                                  slot: "image",
                                                }),
                                                _c("img", {
                                                  staticClass: "preloader",
                                                  attrs: {
                                                    slot: "preloader",
                                                    src: require("@/assets/images/loading.png"),
                                                  },
                                                  slot: "preloader",
                                                }),
                                                _c("img", {
                                                  staticClass: "error",
                                                  attrs: {
                                                    slot: "error",
                                                    src: require("@/assets/images/img-not-found.svg"),
                                                  },
                                                  slot: "error",
                                                }),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      msg.url
                                        ? _c(
                                            "div",
                                            { staticClass: "msg-body-url" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    href: msg.url,
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-open-in-new",
                                                  }),
                                                  _vm._v(" Vai al link "),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          msg._id === _vm.messageToFind && !msg.isMine
                            ? _c("div", { staticClass: "msg-found box grow" })
                            : _vm._e(),
                          msg.type === "info"
                            ? _c("div", { staticClass: "msg-type-info" }, [
                                msg.info === "join"
                                  ? _c(
                                      "div",
                                      { staticClass: "msg-info-join" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              background:
                                                _vm.getUserPinnedColor(
                                                  msg.user.id
                                                ),
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "@" + _vm._s(msg.user.username)
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-6" },
                                              [_vm._v("è entrata nella stanza")]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "ml-6 o-050" },
                                              [_vm._v(_vm._s(msg.timeAdded))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                msg.info === "leave"
                                  ? _c(
                                      "div",
                                      { staticClass: "msg-info-leave" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              background:
                                                _vm.getUserPinnedColor(
                                                  msg.user.id
                                                ),
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "@" + _vm._s(msg.user.username)
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-6" },
                                              [_vm._v("ha lasciato la stanza")]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "ml-6 o-050" },
                                              [_vm._v(_vm._s(msg.timeAdded))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                msg.info === "day"
                                  ? _c("div", { staticClass: "mv-10" }, [
                                      _c("span", { staticClass: "o-070" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dayjs")(
                                              msg.day,
                                              "format",
                                              "DD MMM YYYY"
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm.replyMsg
                ? _c(
                    "div",
                    { staticClass: "flex", attrs: { id: "reply-box" } },
                    [
                      _c("div", { staticClass: "reply-info box grow" }, [
                        _c("div", { staticClass: "reply-author" }, [
                          _vm._v("@" + _vm._s(_vm.replyMsg.author.username)),
                        ]),
                        _vm.replyMsg.body
                          ? _c("div", {
                              staticClass: "reply-body",
                              domProps: {
                                innerHTML: _vm._s(_vm.replyMsg.body),
                              },
                            })
                          : _vm._e(),
                        _vm.replyMsg.type === "img" ||
                        _vm.replyMsg.type === "sticker"
                          ? _c("div", { staticClass: "reply-body-img" }, [
                              _c("img", {
                                attrs: { src: _vm.replyMsg.imageThumb },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "reply-action" }, [
                        _c("button", { on: { click: _vm.resetReply } }, [
                          _c("i", { staticClass: "mdi mdi-close" }),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "flex align-center",
                  attrs: { id: "input-box" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-el box grow" },
                    [
                      _c("el-input", {
                        ref: "textarea",
                        attrs: {
                          type: "textarea",
                          autosize: { maxRows: 4 },
                          placeholder: "Messaggio...",
                        },
                        model: {
                          value: _vm.message,
                          callback: function ($$v) {
                            _vm.message = $$v
                          },
                          expression: "message",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "image-el",
                      class: { small: !_vm.image },
                      style: { "background-image": _vm.bg },
                    },
                    [
                      !_vm.image
                        ? _c("FileDrop", {
                            attrs: {
                              hover: false,
                              icon: "mdi mdi-image-outline",
                            },
                            on: { change: _vm.fileChange },
                          })
                        : _vm._e(),
                      _vm.image
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-clear-photo",
                              on: {
                                click: function ($event) {
                                  _vm.image = ""
                                },
                              },
                            },
                            [_c("i", { staticClass: "mdi mdi-close" })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "send-el box flex column center" }, [
                    _c(
                      "button",
                      {
                        attrs: { disabled: !_vm.isValid },
                        on: { click: _vm.send },
                      },
                      [_vm._v("Invia")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.usersPinned.length
        ? _c(
            "transition-group",
            {
              staticClass: "scrollable only-y",
              attrs: { name: "list", id: "users-pinned-list" },
            },
            _vm._l(_vm.usersPinned, function (user) {
              return _c(
                "div",
                {
                  key: user.id,
                  staticClass: "user-card card-base card-shadow--small flex",
                  style: { background: user.color },
                },
                [
                  _c(
                    "div",
                    { staticClass: "propic" },
                    [
                      _c("vue-load-image", [
                        _c("img", {
                          staticClass: "image",
                          attrs: { slot: "image", src: user.pictureThumb },
                          slot: "image",
                        }),
                        _c("img", {
                          staticClass: "preloader",
                          attrs: {
                            slot: "preloader",
                            src: require("@/assets/images/loading.png"),
                          },
                          slot: "preloader",
                        }),
                        _c("img", {
                          staticClass: "error",
                          attrs: {
                            slot: "error",
                            src: require("@/assets/images/img-not-found.svg"),
                          },
                          slot: "error",
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info box grow flex column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "username box grow",
                        on: {
                          click: function ($event) {
                            return _vm.openUser(user, false)
                          },
                        },
                      },
                      [_vm._v("@" + _vm._s(user.username))]
                    ),
                    _c("div", { staticClass: "close-btn" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.removeUserPinned(user.id)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-close" }),
                          _vm._v("rimuovi"),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }