var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "block flex column card-base card-shadow--small",
    },
    [
      _c("div", { staticClass: "label box mb-20" }, [_vm._v("Firma supporto")]),
      _c("div", { staticClass: "value box" }, [
        _c(
          "div",
          { staticClass: "body-editor flex column" },
          [
            _c("EditorHtml", {
              attrs: { html: _vm.supportSignHtml, preview: true },
              on: {
                "update:html": function ($event) {
                  _vm.supportSignHtml = $event
                },
              },
            }),
          ],
          1
        ),
        _vm.can_UpdateSupportSign
          ? _c(
              "div",
              { staticClass: "toolbar-box flex justify-space-between mt-20" },
              [
                _c("div", { staticClass: "box grow mr-20" }, [
                  _c("ul", { staticClass: "pl-15" }, [
                    _c("li", [
                      _vm._v("usa "),
                      _c("strong", {
                        domProps: { innerHTML: _vm._s("{{firstname}}") },
                      }),
                      _vm._v(" per il nome"),
                    ]),
                    _c("li", [
                      _vm._v("usa "),
                      _c("strong", {
                        domProps: { innerHTML: _vm._s("{{lastname}}") },
                      }),
                      _vm._v(" per il cognome"),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "boxt ext-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium", plain: "" },
                        on: { click: _vm.resetSupportSign },
                      },
                      [_vm._v("Reset")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          size: "medium",
                          disabled: !_vm.supportSignHtml,
                        },
                        on: { click: _vm.updateSupportSign },
                      },
                      [_vm._v(" Salva ")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }