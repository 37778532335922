<template>
    <div class="day-hour flex" v-loading="loading">
        <div class="day box grow" v-if="hourSelected">
            <div class="subtitle">Dati giorno</div>
            <div class="title">{{ hourSelected.date | dayjs("format", "dddd D MMMM") }}</div>
            <div class="line online">
                <span>Utenti Online</span>
            </div>
            <div class="content flex">
                <div class="chart box grow">
                    <apexchart
                        type="line"
                        height="90"
                        ref="chartOnline"
                        :options="chartOnline"
                        :series="seriesOnline"
                    ></apexchart>
                </div>
                <div class="info box grow flex justify-space-between">
                    <div class="max flex column">
                        <span>Max</span>
                        <strong>{{ $sugar.Number.format(day.online.max) }}</strong>
                    </div>
                    <div class="avg flex column">
                        <span>Avg</span>
                        <strong>{{ $sugar.Number.format(day.online.avg) }}</strong>
                    </div>
                    <div class="min flex column">
                        <span>Min</span>
                        <strong>{{ $sugar.Number.format(day.online.min) }}</strong>
                    </div>
                </div>
            </div>
            <div class="line messages">
                <span>Messaggi</span>
            </div>
            <div class="content flex">
                <div class="chart box grow">
                    <apexchart
                        type="line"
                        height="90"
                        ref="chartMessages"
                        :options="chartMessages"
                        :series="seriesMessages"
                    ></apexchart>
                </div>
                <div class="info box grow flex justify-space-between">
                    <div class="messages flex column">
                        <span>Testuali</span>
                        <strong>{{ $sugar.Number.format(day.messages.total) }}</strong>
                    </div>
                    <div class="images flex column">
                        <span>Immagini</span>
                        <strong>
                            {{ $sugar.Number.format(day.messages.images) }}<br /><small
                                >({{ day.messages.imgPer }}%)</small
                            >
                        </strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="hour box grow" v-if="hourSelected">
            <div class="subtitle">Dettaglio ora</div>
            <div class="title">{{ hourSelected.date | dayjs("format", "HH:mm") }}</div>
            <div class="line online"></div>
            <div class="content flex">
                <div class="info box grow flex justify-space-between">
                    <div class="online flex column">
                        <span>Online</span>
                        <strong>{{ hour.online.total }}</strong>
                    </div>
                    <div class="box grow">
                        <span class="label">
                            circa <strong>{{ $sugar.Number.format(hour.messages.users) }}</strong> utenti hanno scritto
                            messaggi
                        </span>
                    </div>
                </div>
            </div>
            <div class="line messages"></div>
            <div class="content flex">
                <div class="info box grow flex justify-space-between">
                    <div class="messages flex column">
                        <span>Testuali</span>
                        <strong>{{ $sugar.Number.format(hour.messages.total) }}</strong>
                    </div>
                    <div class="images flex column">
                        <span>Immagini</span>
                        <strong>
                            {{ $sugar.Number.format(hour.messages.images) }}<br />
                            <small>({{ hour.messages.imgPer }}%)</small>
                        </strong>
                    </div>
                    <div class="box grow"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "DayHour",
    props: ["data", "hourSelected", "byRoom"],
    data() {
        return {
            day: {
                online: {
                    max: 0,
                    min: 0,
                    avg: 0
                },
                messages: {
                    total: 0,
                    images: 0,
                    imgPer: 0
                }
            },
            hour: {
                online: {
                    total: 0
                },
                messages: {
                    total: 0,
                    images: 0,
                    imgPer: 0,
                    users: 0
                }
            },
            loading: false,

            seriesOnline: [
                {
                    name: "Online",
                    type: "line",
                    data: [],
                    xaxis: []
                }
            ],
            seriesMessages: [
                {
                    name: "Messages",
                    type: "line",
                    data: [],
                    xaxis: []
                }
            ],

            chartOnline: {
                chart: {
                    id: "day_online",
                    type: "line",
                    sparkline: {
                        enabled: true
                    },
                    dropShadow: {
                        enabled: true,
                        top: 1,
                        left: 1,
                        blur: 2,
                        opacity: 0.2
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: 1
                },
                markers: {
                    size: 0
                },
                colors: ["#34a8f9"],
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                            const date = this.seriesOnline[0].xaxis[dataPointIndex].created_at
                            return this.$sugar.String.titleize(this.$dayjs(date).format(" HH:mm")) + " • " + value
                        },
                        title: {
                            formatter: function formatter(val) {
                                return ""
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },

            chartMessages: {
                chart: {
                    id: "day_messages",
                    type: "line",
                    sparkline: {
                        enabled: true
                    },
                    dropShadow: {
                        enabled: true,
                        top: 1,
                        left: 1,
                        blur: 2,
                        opacity: 0.2
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: 1
                },
                markers: {
                    size: 0
                },
                colors: ["#00E396"],
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                            const date = this.seriesMessages[0].xaxis[dataPointIndex].created_at
                            return this.$sugar.String.titleize(this.$dayjs(date).format(" HH:mm")) + " • " + value
                        },
                        title: {
                            formatter: function formatter(val) {
                                return ""
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            }
        }
    },
    watch: {
        hourSelected(val) {
            //this.reset()
            //this.loading = true
            setTimeout(() => {
                this.parseData()
            }, 200)
        }
    },
    methods: {
        reset() {
            this.day = {
                online: {
                    max: 0,
                    min: 0,
                    avg: 0
                },
                messages: {
                    total: 0,
                    images: 0,
                    imgPer: 0
                }
            }
            this.hour = {
                online: {
                    total: 0
                },
                messages: {
                    total: 0,
                    images: 0,
                    imgPer: 0,
                    users: 0
                }
            }
        },
        parseData() {
            const data = _.filter(this.data, i => this.$dayjs(this.hourSelected.date).isSame(i.created_at, "day"))
            const hour = this.hourSelected

            this.hour = {
                online: {
                    total: hour.total
                },
                messages: {
                    total: hour.messages,
                    images: hour.images,
                    imgPer: hour.images ? _.floor((100 / hour.messages) * hour.images, 2) : 0,
                    users: hour.rooms.reduce((a, o) => a + (o.msg_users || 0), 0)
                }
            }

            const online = this.setOnline(data)
            const onlineChart = this.setOnlineChart(data)
            const messages = this.setMessages(data)
            const messagesChart = this.setMessagesChart(data)

            this.day = {
                online,
                messages
            }

            this.seriesOnline = [
                {
                    name: "Online",
                    type: "line",
                    data: onlineChart,
                    xaxis: data
                }
            ]
            this.seriesMessages = [
                {
                    name: "Messages",
                    type: "line",
                    data: messagesChart,
                    xaxis: data
                }
            ]

            const chartIndex = _.findIndex(data, i => {
                return i.created_at === hour.date
            })

            this.$nextTick(() => {
                this.$refs.chartOnline.updateOptions({
                    annotations: {
                        points: [
                            {
                                x: chartIndex + 1,
                                y: hour.total,
                                marker: {
                                    size: 4,
                                    fillColor: "#f8f9fb",
                                    strokeColor: "#34a8f9",
                                    radius: 1,
                                    cssClass: "apexcharts-custom-class"
                                },
                                label: {
                                    borderColor: "#34a8f9aa",
                                    offsetY: 5,
                                    style: {
                                        color: "#fff",
                                        background: "#34a8f9aa"
                                    },
                                    text: this.$dayjs(hour.date).format("HH:mm")
                                }
                            }
                        ]
                    }
                })
                this.$refs.chartMessages.updateOptions({
                    annotations: {
                        points: [
                            {
                                x: chartIndex + 1,
                                y: hour.messages,
                                marker: {
                                    size: 4,
                                    fillColor: "#f8f9fb",
                                    strokeColor: "#00E396",
                                    radius: 1,
                                    cssClass: "apexcharts-custom-class"
                                },
                                label: {
                                    borderColor: "#00E396aa",
                                    offsetY: 5,
                                    style: {
                                        color: "#fff",
                                        background: "#00E396aa"
                                    },
                                    text: this.$dayjs(hour.date).format("HH:mm")
                                }
                            }
                        ]
                    }
                })
            })

            this.loading = false
        },
        setMessages(data) {
            const messages = _.map(data, i => {
                return i.data.messages || 0
            })
            const images = _.map(data, i => {
                return i.data.images || 0
            })
            const msgTotal = _.sum(messages)
            const imgTotal = _.sum(images)

            return {
                total: msgTotal,
                images: imgTotal,
                imgPer: imgTotal ? _.floor((100 / msgTotal) * imgTotal, 2) : 0
            }
        },
        setOnline(data) {
            const online = _.map(data, i => {
                return i.data.total
            })

            const max = _.toSafeInteger(_.max(online))
            const min = _.toSafeInteger(_.min(online))
            const avg = _.toSafeInteger(_.mean(online))

            return {
                max,
                min,
                avg
            }
        },
        setOnlineChart(data) {
            return _.map(data, i => {
                return i.data.total || 0
            })
        },
        setMessagesChart(data) {
            return _.map(data, i => {
                return i.data.messages || 0
            })
        }
    },
    mounted() {
        if (this.data && this.hourSelected) {
            this.parseData()
        }
    },
    components: {
        apexchart: VueApexCharts
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.day-hour {
    overflow: hidden;

    .title {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 4px;
        padding: 0 10px;
    }

    .subtitle {
        background: darken($background-color, 20%);
        display: inline-block;
        font-size: 12px;
        color: #fff;
        padding: 2px 6px;
        border-radius: 4px;
        margin: 10px;
        margin-top: 20px;
    }

    .line {
        --color: #34a8f9;
        height: 24px;

        &.messages {
            --color: #00e396;
        }

        border-bottom: 2px solid var(--color);
        text-align: left;
        margin: 10px 0;
        max-width: 430px;
        margin-left: auto;

        span {
            background: var(--color);
            color: #fff;
            font-size: 12px;
            padding: 2px 6px;
            position: relative;
            top: 4px;
            left: -4px;
            border-radius: 4px;
            border-bottom-right-radius: 0;
        }
    }

    .content {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
        text-align: center;

        &:last-child {
            margin-bottom: 0;
        }

        .info {
            //max-width: 170px;

            & > div {
                height: 80px;
                padding: 0 10px;

                span {
                    opacity: 0.7;
                    font-size: 14px;

                    &.label {
                        background: #fff;
                        padding: 4px 10px;
                        margin-top: 2px;
                        display: inline-block;
                    }
                }
            }
        }
        .chart {
            margin-top: -15px;
        }
    }

    .day {
        text-align: right;
        padding: 20px;
        padding-right: 10px;
        border-right: 2px dashed lighten($color-info, 30%);

        .line {
            margin-right: -22px;
        }

        .content {
            margin-left: auto;
        }
    }

    .hour {
        text-align: left;
        padding: 20px;
        padding-left: 10px;

        .line {
            margin-left: -22px;
        }
    }

    @media (max-width: 1000px) {
        .title {
            font-size: 16px;
        }
        .content {
            .chart {
                display: none;
            }
        }
    }
    @media (max-width: 500px) {
        .title {
            font-size: 12px;
            height: 40px;
        }
        .content {
            .info {
                & > div {
                    height: 50px;
                    padding: 0 5px;

                    span {
                        font-size: 10px;
                    }
                    strong {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media (max-width: 400px) {
        .content {
            .info {
                & > div {
                    span.label {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>