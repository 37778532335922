var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-chat" },
    [
      _c("Chat", {
        attrs: {
          app: "imamma",
          goToRoom: _vm.$route.params.room,
          goToMessage: _vm.$route.params.message,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }