var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-profile scrollable only-y" },
    [
      _c("div", { staticClass: "account flex align-center" }, [
        _vm.accountPicture
          ? _c(
              "div",
              { staticClass: "propic" },
              [
                _c("vue-load-image", [
                  _c("img", {
                    staticClass: "image",
                    attrs: { slot: "image", src: _vm.accountPicture },
                    slot: "image",
                  }),
                  _c("img", {
                    staticClass: "preloader",
                    attrs: {
                      slot: "preloader",
                      src: require("@/assets/images/loading.png"),
                    },
                    slot: "preloader",
                  }),
                  _c("img", {
                    staticClass: "error",
                    attrs: {
                      slot: "error",
                      src: require("@/assets/images/img-not-found.svg"),
                    },
                    slot: "error",
                  }),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.account
          ? _c("div", { staticClass: "info-box" }, [
              _vm.accountName
                ? _c("div", [
                    _c("span", { staticClass: "o-050 mr-10" }, [
                      _vm._v("Nome"),
                    ]),
                    _c("strong", [_vm._v(_vm._s(_vm.accountName))]),
                  ])
                : _vm._e(),
              _vm.account.username
                ? _c("div", [
                    _c("span", { staticClass: "o-050 mr-10" }, [
                      _vm._v("Username"),
                    ]),
                    _c("strong", [_vm._v(_vm._s(_vm.account.username))]),
                  ])
                : _vm._e(),
              _vm.account.email
                ? _c("div", [
                    _c("span", { staticClass: "o-050 mr-10" }, [
                      _vm._v("Email"),
                    ]),
                    _c("strong", [_vm._v(_vm._s(_vm.account.email))]),
                  ])
                : _vm._e(),
              _vm.account.role
                ? _c("div", [
                    _c("span", { staticClass: "o-050 mr-10" }, [
                      _vm._v("Ruolo"),
                    ]),
                    _c(
                      "strong",
                      { staticClass: "roles color", class: _vm.account.role },
                      [_vm._v(_vm._s(_vm.account.role))]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Modifica password",
            visible: _vm.editPasswordDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPasswordDialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticClass: "mb-20",
            attrs: {
              size: "small",
              placeholder: "Vecchia password",
              "show-password": "",
            },
            model: {
              value: _vm.o_password,
              callback: function ($$v) {
                _vm.o_password = $$v
              },
              expression: "o_password",
            },
          }),
          _c("el-input", {
            staticClass: "mb-20",
            attrs: {
              size: "small",
              placeholder: "Nuova password",
              "show-password": "",
            },
            model: {
              value: _vm.n_password,
              callback: function ($$v) {
                _vm.n_password = $$v
              },
              expression: "n_password",
            },
          }),
          _c("el-input", {
            staticClass: "mb-20",
            attrs: {
              size: "small",
              placeholder: "Conferma password",
              "show-password": "",
            },
            model: {
              value: _vm.c_password,
              callback: function ($$v) {
                _vm.c_password = $$v
              },
              expression: "c_password",
            },
          }),
          _vm.n_password && _vm.c_password && _vm.n_password !== _vm.c_password
            ? _c("div", { staticClass: "warning-text" }, [
                _vm._v(" le password non coincidono "),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: { click: _vm.closeUpdatePassword },
                },
                [_vm._v("Annulla")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    disabled: !_vm.isEditPwdAvailable,
                  },
                },
                [_vm._v("Conferma")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }