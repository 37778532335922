<template>
    <el-dialog
        :visible.sync="dialogUserVisible"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="780px"
        custom-class="user-dialog"
    >
        <div slot="title" v-if="userLoaded">
            <el-tooltip class="item" effect="dark" content="Apri in nuovo tab" placement="bottom-start">
                <span class="go-to-btn mr-10" @click="newTabProfile"><i class="mdi mdi-open-in-new"></i></span>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="Conserva in sidebar" placement="bottom">
                <span class="go-to-btn mr-10" v-show="dialogUserForChat" @click="emitChatUserPin">
                    <i class="mdi mdi-account-arrow-right"></i>
                </span>
            </el-tooltip>

            <span class="go-to-btn" @click="goToProfile">Profilo completo <i class="mdi mdi-arrow-right"></i></span>
        </div>
        <div>
            <UserInfo
                :id="userId"
                v-if="userId && dialogUserVisible"
                @error="userLoaded = false"
                @user-deleted="closeUserDialog"
                @loaded="userLoaded = true"
            ></UserInfo>
        </div>
    </el-dialog>
</template>

<script>
import { EventBus } from "@/event-bus"
const UserInfo = () => import("@/components/User/UserInfo")

export default {
    name: "UserDialog",
    data() {
        return {
            user: null,
            userId: null,
            userLoaded: false,
            dialogUserVisible: false,
            dialogUserForChat: false
        }
    },
    methods: {
        goToProfile() {
            this.$router.push(`/users/${this.userId}`).catch(err => {})
            this.closeUserDialog()
        },
        newTabProfile() {
            window.open(`/users/${this.userId}`)
            this.closeUserDialog()
        },
        emitChatUserPin() {
            EventBus.$emit("chat:pin", { id: this.userId, user: this.user })
            this.closeUserDialog()
        },
        openUserDialog({ id, chat = false, user = null }) {
            this.dialogUserVisible = true
            this.dialogUserForChat = chat
            this.userId = id
            this.user = user
        },
        closeUserDialog() {
            this.dialogUserVisible = false
            this.dialogUserForChat = false
            this.userId = null
            this.user = null
            this.userLoaded = false
        }
    },
    mounted() {
        EventBus.$on("open:user", ({ id, chat = false, user = null }) => {
            this.closeUserDialog()
            console.log("open:user", id)
            setTimeout(() => {
                this.openUserDialog({ id, chat, user })
            }, 0)
        })
    },
    components: {
        UserInfo
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.go-to-btn {
    cursor: pointer;
    background: rgba($text-color, 0.06);
    color: $text-color;
    padding: 4px 10px;
    border-radius: 6px;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
        box-shadow: 0px 0px 0px 2px $text-color;
    }
}
</style>
