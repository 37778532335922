var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.infoAbout
    ? _c("div", { staticClass: "info flex column", class: _vm.size }, [
        _c("div", { staticClass: "datetime" }, [
          _c("span", [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm._f("dayjs")(
                    _vm.infoAbout.created,
                    "format",
                    "DD/MM/YYYY [alle] HH:mm"
                  )
                )
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }