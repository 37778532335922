<template>
    <div class="calendar-ranking flex column" v-loading="loading">
        <div class="flex" :class="{ full }">
            <div class="box grow flex column">
                <el-tooltip effect="dark" content="Media utenti online" placement="top-start">
                    <div class="title">Online</div>
                </el-tooltip>
                <div class="item" v-for="(item, index) of rank.online" :key="item._id" :class="'rank-' + (index + 1)">
                    <span class="num">{{ index + 1 }}°</span>
                    <!--<span class="points">{{ $sugar.Number.format(item.online_avg, 2) }}</span>-->
                    <span class="points">{{ $sugar.String.truncateOnWord(item.name, 30) }}</span>
                    <br />
                </div>
            </div>
            <div class="box grow flex column">
                <el-tooltip effect="dark" content="Totale messaggi inviati" placement="top-start">
                    <div class="title">Messaggi</div>
                </el-tooltip>
                <div class="item" v-for="(item, index) of rank.messages" :key="item._id" :class="'rank-' + (index + 1)">
                    <span class="num">{{ index + 1 }}°</span>
                    <span class="points">{{ $sugar.Number.format(item.messages) }}</span>
                    <br />
                    <span class="name">{{ $sugar.String.truncateOnWord(item.name, 25) }}</span>
                </div>
            </div>
        </div>
        <div class="details" @click="full = !full">
            <span v-if="full">Top 3</span>
            <span v-if="!full">Espandi</span>
        </div>
    </div>
</template>

<script>
import _ from "lodash"

export default {
    name: "Ranking",
    props: ["data"],
    data() {
        return {
            full: false,
            rank: {
                online: [],
                messages: []
            },
            loading: false
        }
    },
    watch: {
        data(val) {
            this.reset()
            this.loading = true
            setTimeout(() => {
                if (this.data && this.data.length) {
                    this.parseData()
                }
            }, 100)
        }
    },
    methods: {
        reset() {
            this.rank = {
                online: [],
                messages: []
            }
        },
        parseData() {
            const data = this.data

            this.rank = this.setRank(data)

            this.loading = false
        },
        setRank(data) {
            const rooms = []
            for (const item of data) {
                const clone_rooms = _.cloneDeep(item.data.rooms)
                for (const room of clone_rooms) {
                    const index = _.findIndex(rooms, { _id: room._id })

                    if (index !== -1) {
                        rooms[index].online.push(_.toSafeInteger(room.online))
                        rooms[index].online_avg = _.mean(rooms[index].online)
                        rooms[index].messages += _.toSafeInteger(room.messages)
                    } else {
                        room.online = [_.toSafeInteger(room.online)]
                        room.online_avg = _.toSafeInteger(room.online)
                        room.messages = _.toSafeInteger(room.messages)
                        rooms.push(room)
                    }
                }
            }

            return {
                online: _.orderBy(rooms, ["online_avg"], ["desc"]),
                messages: _.orderBy(rooms, ["messages"], ["desc"])
            }
        }
    },
    mounted() {
        this.reset()
        this.loading = true
        setTimeout(() => {
            if (this.data && this.data.length) {
                this.parseData()
            }
        }, 100)
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.calendar-ranking {
    max-height: 335px;
    overflow: auto;

    .title {
        font-weight: 700;
        font-size: 18px;
        margin: 8px;
        margin-right: auto;
        text-decoration: underline;
        text-decoration-color: transparentize($text-color, 0.5);
    }
    .item {
        margin: 10px 8px;
        min-height: 70px;
        font-size: 16px;
        word-break: break-word;
        text-align: left;

        .num {
            margin-right: 10px;
            font-weight: 700;
        }

        .points {
            font-weight: 700;
            word-break: break-word;
            text-align: left;
        }

        .name {
            word-break: break-word;
            display: block;
            clear: both;
            text-align: left;
        }

        display: none;

        &.rank-1 {
            display: block;
            .num {
                color: #e4c35e;
            }
        }
        &.rank-2 {
            display: block;
            .num {
                color: #b9b9b9;
            }
        }
        &.rank-3 {
            display: block;
            .num {
                color: #9c7539;
            }
        }
    }

    .details {
        text-align: center;
        margin-top: -5px;
        margin-bottom: 10px;
        color: $text-color;
        text-decoration: underline;
        text-decoration-color: transparentize($text-color, 0.5);
        cursor: pointer;
        opacity: 0.8;
    }

    @media (max-width: 1600px) {
        .title {
            font-size: 16px;
        }
        .item {
            font-size: 14px;
        }
    }

    .full {
        .title {
            font-size: 14px;
        }
        .item {
            font-size: 12px;
            display: block;
            min-height: 55px;
            margin: 5px 8px;
        }
    }
}
</style>