var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "msg-box flex align-flex-end" }, [
    _vm.msg
      ? _c("div", { staticClass: "msg-content card-base card-shadow--small" }, [
          _c("div", { staticClass: "msg-info flex justify-space-between" }, [
            _c("div", { staticClass: "box flex grow" }, [
              _c(
                "div",
                {
                  staticClass: "msg-author",
                  on: {
                    click: function ($event) {
                      return _vm.openUser(_vm.msg.userId)
                    },
                  },
                },
                [_vm._v("@" + _vm._s(_vm.msg.username))]
              ),
              _c("div", { staticClass: "msg-time" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("dayjs")(
                      _vm.msg.date,
                      "format",
                      "DD MMMM YYYY [alle] HH:mm"
                    )
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "box flex actions" }, [
              _c(
                "div",
                {
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.msg)
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-content-copy" })]
              ),
              _c(
                "div",
                {
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.info(_vm.msg)
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-information-outline" })]
              ),
            ]),
          ]),
          _vm.msg.text
            ? _c("div", {
                staticClass: "msg-body",
                domProps: { innerHTML: _vm._s(_vm.msg.text) },
              })
            : _vm._e(),
          _vm.msg.image
            ? _c("div", { staticClass: "msg-body-img" }, [
                _c("img", { attrs: { src: _vm.msg.image } }),
              ])
            : _vm._e(),
          _vm.msg.url
            ? _c("div", { staticClass: "msg-body-url" }, [
                _c("a", { attrs: { target: "_blank", href: _vm.msg.url } }, [
                  _c("i", { staticClass: "mdi mdi-open-in-new" }),
                  _vm._v(" " + _vm._s(_vm.msg.url)),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }