<template>
    <div class="layout-container flex justify-center">
        <vertical-nav
            :position="'left'"
            :collapse-nav="collapseNav"
            :open-sidebar.sync="openSidebar"
            @collapse-nav-toggle="collapseNav = !collapseNav"
            @push-page="closeSidebar"
        />

        <div class="container flex column box grow">
            <div class="header">
                <Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="'right'" />
            </div>

            <div class="main box grow flex">
                <!--<span class="main-out-border--top-left" v-if="roundedCorners"></span>
				<span class="main-out-border--top-right" v-if="roundedCorners"></span>
				<span class="main-out-border--bottom-left" v-if="roundedCorners"></span>
				<span class="main-out-border--bottom-right" v-if="roundedCorners"></span>-->
                <transition :name="viewAnimation" mode="out-in">
                    <router-view :key="$route.fullPath" class="view box grow" />
                </transition>
            </div>

            <Footer v-if="footer === 'below'" :position="footer" />
        </div>

        <Footer v-if="footer === 'above'" :position="footer" />

        <UserDialog></UserDialog>

        <RoomDialog></RoomDialog>
    </div>
</template>

<script>
import api from "@/api"
import utils from "@/utils"
import { io } from "socket.io-client"
import { EventBus } from "@/event-bus"

// const HorizontalNav = () => import("@/components/layout/HorizontalNav")
import VerticalNav from "@/components/layout/VerticalNav"
import Toolbar from "@/components/layout/Toolbar"
import Footer from "@/components/layout/Footer"

const UserDialog = () => import("@/components/User/UserDialog")
const RoomDialog = () => import("@/components/Chat/RoomDialog")

export default {
    name: "Main",
    data() {
        return {
            collapseNav: false,
            openSidebar: false,
            innerWidth: 0,
            rootSocket: null
        }
    },
    computed: {
        footer() {
            return this.$store.getters.footer
        },
        roundedCorners() {
            return this.$store.getters.roundedCorners
        },
        viewAnimation() {
            return this.$store.getters.viewAnimation || "none"
        },
        isLogged() {
            return this.$store.getters.isLogged
        },
        splashScreen() {
            return this.$store.getters.splashScreen
        },
        accountUsername() {
            return this.$store.getters.accountUsername
        }
    },
    methods: {
        resizeOpenNav() {
            this.innerWidth = window.innerWidth
            if (window.innerWidth <= 768) {
                this.collapseNav = false
            }
        },
        closeSidebar() {
            this.openSidebar = false
        },
        connect({ path = "/", socketVar = "rootSocket" }, cb) {
            this[socketVar] = io(api.getDomainUrl() + path, {
                transports: ["websocket"],
                withCredentials: true
            })

            if (cb && typeof cb === "function") cb()
        },
        initRootSocket() {
            const logStyle = ["%cROOT", "color:cyan;font-weight: bold;"]

            this.connect({ path: "/", socketVar: "rootSocket" }, () => {
                this.rootSocket.on("connect", () => {
                    console.log(...logStyle, "connected:", this.rootSocket)
                })

                const that = this

                this.rootSocket.on("chat:mod", res => {
                    if (res.roomName && this.isLogged) {
                        this.$notify.warning({
                            title: "Richiesta di moderazione",
                            dangerouslyUseHTMLString: true,
                            duration: 5000,
                            message: `<span style="cursor:pointer">L'utente <strong>@${res.username}</strong> chiede di moderare una conversazione nella chat <strong>${res.roomName}</strong></span>`,
                            onClick() {
                                const path = `/chat/rooms/${res.roomId}/${res.messageId}`
                                if (that.$route.fullPath !== path) {
                                    that.$router.push(path).catch(err => {})
                                }
                                this.close()
                            }
                        })
                    }
                    console.log(...logStyle, "chat:mod:", res)
                })

                this.rootSocket.on("chat:report", res => {
                    if (res.roomName && this.isLogged && res.username !== this.accountUsername) {
                        this.$notify.warning({
                            title: "Messaggio segnalato",
                            dangerouslyUseHTMLString: true,
                            duration: 5000,
                            message: `<span style="cursor:pointer">L'utente <strong>@${res.username}</strong> ha segnalato il messaggio dell'utente <strong>@${res.author}</strong> nella chat <strong>${res.roomName}</strong></span>`,
                            onClick() {
                                const path = `/chat/rooms/${res.roomId}/${res.messageId}`
                                if (that.$route.fullPath !== path) {
                                    that.$router.push(path).catch(err => {})
                                }
                                this.close()
                            }
                        })
                    }
                    console.log(...logStyle, "chat:report:", res)
                })

                this.rootSocket.on("chat:suspicious", res => {
                    if (res.roomName && this.isLogged && res.author !== this.accountUsername) {
                        this.$notify.warning({
                            title: "Messaggio sospetto",
                            dangerouslyUseHTMLString: true,
                            duration: 5000,
                            message: `<span style="cursor:pointer">L'utente <strong>@${res.author}</strong> ha inviato un messaggio sospetto nella chat <strong>${res.roomName}</strong></span>`,
                            onClick() {
                                const path = `/chat/rooms/${res.roomId}/${res.messageId}`
                                if (that.$route.fullPath !== path) {
                                    that.$router.push(path).catch(err => {})
                                }
                                this.close()
                            }
                        })
                    }
                    console.log(...logStyle, "chat:suspicious:", res)
                })

                this.rootSocket.on("email:new", res => {
                    if (this.isLogged && !utils.isEnvTest()) {
                        const title = res.subject ? "Nuova email" : "Nuove email"
                        const message = res.subject
                            ? `<strong>${res.subject}</strong><br>${res.fromName || res.fromEmail}`
                            : "sono arrivati nuovi messaggi, controlla la posta"

                        this.$notify({
                            title,
                            dangerouslyUseHTMLString: true,
                            message,
                            type: "success",
                            onClick() {
                                const path = `/support/requests`
                                if (that.$route.fullPath !== path) {
                                    that.$router.push(path).catch(err => {})
                                }
                                this.close()
                            }
                        })

                        EventBus.$emit("email:new", { res })
                    }
                    console.log(...logStyle, "email:new:", res)
                })

                this.rootSocket.on("message-bot:sent", res => {
                    if (this.isLogged && !utils.isEnvTest()) {
                        EventBus.$emit("message-bot:sent", { res })
                    }
                    console.log(...logStyle, "message-bot:sent:", res)
                })

                this.rootSocket.on("email:sent", res => {
                    if (this.isLogged && !utils.isEnvTest()) {
                        EventBus.$emit("email:sent", { res })
                    }
                    console.log(...logStyle, "email:sent:", res)
                })

                this.rootSocket.on("common:success", res => {
                    if (res.message && this.isLogged) {
                        this.$notify({
                            title: "Success",
                            message: res.message,
                            type: "success"
                        })
                    }
                    console.log(...logStyle, "common:success:", res)
                })

                this.rootSocket.on("common:error", res => {
                    if (res.message && this.isLogged) {
                        this.$notify({
                            title: "Error",
                            message: res.message,
                            type: "error"
                        })
                    }
                    console.log(...logStyle, "common:error:", res)
                })
            })
        },
        init() {
            this.initRootSocket()
            this.$store.dispatch("getPenaltyReasons", {}).catch(err => {})
        }
    },
    components: {
        // HorizontalNav,
        VerticalNav,
        Toolbar,
        Footer,

        UserDialog,
        RoomDialog
    },
    created() {},
    mounted() {
        this.resizeOpenNav()
        window.addEventListener("resize", this.resizeOpenNav)

        this.init()
    },
    beforeDestroy() {
        if (this.rootSocket) this.rootSocket.disconnect()
        window.removeEventListener("resize", this.resizeOpenNav)
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.layout-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background: $background-color;

    .container {
        overflow: hidden;

        & > .header {
            height: 100px;
            margin-bottom: 20px;
            margin-top: 10px;
            margin-left: 30px;
            margin-right: 30px;
        }

        & > .main {
            position: relative;
            overflow: hidden;
            padding: 0 30px;

            & > .view {
                max-width: 100%;
                padding: 20px;
                padding-bottom: 10px;
                padding-top: 0px;
                box-sizing: border-box;
                transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
                backface-visibility: hidden;
                /*-webkit-perspective: 1000px;*/
            }
        }

        .fade-top-in-out-enter {
            opacity: 0;
            transform: translate(0, 30px);
        }
        .fade-top-in-out-leave-active {
            opacity: 0;
            transform: translate(0, 30px);
        }

        .fade-top-enter {
            opacity: 0;
            transform: translate(0, 30px);
        }
        .fade-top-leave-active {
            opacity: 0;
            transform: translate(0, -30px);
        }

        .fade-bottom-in-out-enter {
            opacity: 0;
            transform: translate(0, -30px);
        }
        .fade-bottom-in-out-leave-active {
            opacity: 0;
            transform: translate(0, -30px);
        }

        .fade-bottom-enter {
            opacity: 0;
            transform: translate(0, -30px);
        }
        .fade-bottom-leave-active {
            opacity: 0;
            transform: translate(0, 30px);
        }

        .fade-left-enter {
            opacity: 0;
            transform: translate(30px, 0);
        }
        .fade-left-leave-active {
            opacity: 0;
            transform: translate(-30px, 0);
        }

        .fade-right-enter {
            opacity: 0;
            transform: translate(-30px, 0);
        }
        .fade-right-leave-active {
            opacity: 0;
            transform: translate(30px, 0);
        }

        .fade-enter {
            opacity: 0;
        }
        .fade-leave-active {
            opacity: 0;
        }

        .main-out-border {
            &--top-left,
            &--top-right {
                background: linear-gradient($background-color, rgba(230, 235, 241, 0));
                height: 16px;
                position: absolute;
                width: 8px;
                z-index: 1;
                top: 4px;
            }
            &--bottom-left,
            &--bottom-right {
                background: linear-gradient(rgba(230, 235, 241, 0), $background-color);
                height: 16px;
                position: absolute;
                width: 8px;
                z-index: 1;
                bottom: 4px;
            }

            &--top-left,
            &--bottom-left {
                left: 42px;

                &::after {
                    content: "";
                    height: 5px;
                    position: absolute;
                    right: -4px;
                    top: -4px;
                    width: 12px;
                    box-shadow: 8px 0px 0px 0px $background-color inset;
                    border-top-left-radius: 5px;
                }
            }
            &--top-right,
            &--bottom-right {
                right: 42px;

                &::after {
                    content: "";
                    height: 5px;
                    left: -4px;
                    position: absolute;
                    top: -4px;
                    width: 12px;
                    box-shadow: -8px 0px 0px 0px $background-color inset;
                    border-top-right-radius: 5px;
                }
            }

            &--bottom-left:after {
                border-radius: 0;
                border-bottom-left-radius: 5px;
            }
            &--bottom-right:after {
                border-radius: 0;
                border-bottom-right-radius: 5px;
            }

            &--bottom-left,
            &--bottom-right {
                &::after {
                    top: initial;
                    bottom: -4px;
                }
            }
        }
    }

    &.boxed {
        max-width: 1300px;
        margin: 0 auto;
        box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    &.footer-above {
        padding-bottom: 40px;
        position: relative;
    }

    &.content-only {
        .container {
            .main-out-border--top-left,
            .main-out-border--top-right,
            .main-out-border--bottom-left,
            .main-out-border--bottom-right {
                display: none;
            }
        }
    }
}

html:not(.ie) {
    .layout-container {
        .container {
            max-width: 1920px;
        }
    }
}

@media (min-width: 1920px) {
    .layout-container:not(.boxed) {
        &.column {
            .container {
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .layout-container {
        .container {
            /*width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;*/

            & > .header {
                height: 70px;
                background: #fff;
                box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.25);
                margin: 0;
                margin-bottom: 5px;

                .toggle-sidebar {
                    box-shadow: none !important;
                }

                .search-box {
                    & > .el-autocomplete {
                        box-shadow: none !important;
                    }
                }
            }

            & > .main {
                padding: 0 5px;

                & > .view {
                    //padding: 5px;
                    max-width: 100%;
                    padding: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            .main-out-border--top-left,
            .main-out-border--top-right,
            .main-out-border--bottom-left,
            .main-out-border--bottom-right {
                display: none;
            }

            & > .footer {
                display: none;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
