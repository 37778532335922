var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "calendar-ranking flex column",
    },
    [
      _c("div", { staticClass: "flex", class: { full: _vm.full } }, [
        _c(
          "div",
          { staticClass: "box grow flex column" },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "Media utenti online",
                  placement: "top-start",
                },
              },
              [_c("div", { staticClass: "title" }, [_vm._v("Online")])]
            ),
            _vm._l(_vm.rank.online, function (item, index) {
              return _c(
                "div",
                {
                  key: item._id,
                  staticClass: "item",
                  class: "rank-" + (index + 1),
                },
                [
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(index + 1) + "°"),
                  ]),
                  _c("span", { staticClass: "points" }, [
                    _vm._v(
                      _vm._s(_vm.$sugar.String.truncateOnWord(item.name, 30))
                    ),
                  ]),
                  _c("br"),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "box grow flex column" },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "Totale messaggi inviati",
                  placement: "top-start",
                },
              },
              [_c("div", { staticClass: "title" }, [_vm._v("Messaggi")])]
            ),
            _vm._l(_vm.rank.messages, function (item, index) {
              return _c(
                "div",
                {
                  key: item._id,
                  staticClass: "item",
                  class: "rank-" + (index + 1),
                },
                [
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(index + 1) + "°"),
                  ]),
                  _c("span", { staticClass: "points" }, [
                    _vm._v(_vm._s(_vm.$sugar.Number.format(item.messages))),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "name" }, [
                    _vm._v(
                      _vm._s(_vm.$sugar.String.truncateOnWord(item.name, 25))
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "details",
          on: {
            click: function ($event) {
              _vm.full = !_vm.full
            },
          },
        },
        [
          _vm.full ? _c("span", [_vm._v("Top 3")]) : _vm._e(),
          !_vm.full ? _c("span", [_vm._v("Espandi")]) : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }