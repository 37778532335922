var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "note card-base card-shadow--small" }, [
    _c("div", { staticClass: "text" }, [
      _vm._v(" " + _vm._s(_vm.data.note) + " "),
    ]),
    _c("div", { staticClass: "date" }, [
      _c("strong", [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.day, "format", "DD MMMM"))),
      ]),
      _c("small", { staticClass: "ml-5" }, [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.day, "format", "YYYY"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }