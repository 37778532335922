var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.question
    ? _c(
        "div",
        {
          staticClass: "question card-base card-shadow--small",
          class: _vm.size,
        },
        [
          _c(
            "div",
            { staticClass: "header flex" },
            [
              _vm.showUser
                ? _c("QnaUser", {
                    staticClass: "mr-10",
                    attrs: { user: _vm.question.user, size: _vm.size },
                  })
                : _vm._e(),
              _c("QnaDatetime", {
                staticClass: "box grow",
                attrs: { infoAbout: _vm.question, size: _vm.size },
              }),
              _c("QnaActions", {
                attrs: {
                  item: _vm.question,
                  showGoto: _vm.showGoto,
                  itemType: "question",
                },
                on: {
                  restored: function ($event) {
                    return _vm.$emit("restored")
                  },
                  deleted: function ($event) {
                    return _vm.$emit("deleted")
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "content" }, [
            _vm.question.content
              ? _c("div", { staticClass: "text" }, [
                  _vm._v(" " + _vm._s(_vm.question.content) + " "),
                ])
              : _vm._e(),
            _vm.question.image
              ? _c("div", { staticClass: "body-image" }, [
                  _c("img", { attrs: { src: _vm.question.image, alt: "" } }),
                ])
              : _vm._e(),
          ]),
          _c("QnaInfo", { attrs: { infoAbout: _vm.question, size: _vm.size } }),
          _c("div", { staticClass: "slot" }, [_vm._t("default")], 2),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }