var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogRoomVisible,
        "close-on-click-modal": true,
        "close-on-press-escape": true,
        width: "700px",
        "custom-class": "room-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogRoomVisible = $event
        },
      },
    },
    [
      _vm.roomLoaded
        ? _c(
            "div",
            { staticClass: "pt-8", attrs: { slot: "title" }, slot: "title" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "Apri in nuovo tab",
                    placement: "bottom-start",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "go-to-btn mr-10",
                      on: { click: _vm.newTabChat },
                    },
                    [_c("i", { staticClass: "mdi mdi-open-in-new" })]
                  ),
                ]
              ),
              _c(
                "span",
                { staticClass: "go-to-btn", on: { click: _vm.goToChat } },
                [
                  _vm._v("Vedi chat "),
                  _c("i", { staticClass: "mdi mdi-arrow-right" }),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.roomId && _vm.dialogRoomVisible
            ? _c("RoomInfo", {
                attrs: { id: _vm.roomId },
                on: {
                  error: _vm.closeRoomDialog,
                  loaded: function ($event) {
                    _vm.roomLoaded = true
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }