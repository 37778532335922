<template>
    <div class="answers-page flex column">
        <AnswerList class="box grow" :show-reported="reported"></AnswerList>
    </div>
</template>

<script>
import AnswerList from "@/components/Qna/AnswerList"

export default {
    name: "AnswersPage",
    data() {
        return {
            reported: false
        }
    },
    mounted() {
        const query_type = _.get(this.$route, "query.type")
        if (query_type === "reported") {
            this.reported = true
        }
    },
    components: {
        AnswerList
    }
}
</script>
