<template>
    <div ref="box" :class="['qna-filter', size]">
        <resize-observer @notify="__resizeHandler" />
        <div class="filter-bar flex justify-space-between align-center" :class="{ hide: !expanded }">
            <el-select
                v-if="showCategory"
                size="mini"
                class="option-select card-shadow--small"
                @change="change"
                v-model="filter.category"
                clearable
                placeholder="Scegli categoria"
            >
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div v-if="!showCategory" class="flex grow"></div>
            <div class="switches flex box">
                <el-switch
                    class="card-base box grow card-shadow--small"
                    @change="change"
                    v-model="filter.suspended"
                    active-text="Solo sospese"
                ></el-switch>
                <el-switch
                    class="card-base box grow card-shadow--small"
                    @change="change"
                    v-model="filter.attachments"
                    active-text="Solo con allegati"
                ></el-switch>
            </div>
        </div>
        <div class="filter-button">
            <span class="action" @click="action">
                <span v-show="!expanded">Filtri</span>
                <span v-show="expanded">Chiudi filtri</span>
                <i v-show="!expanded" class="mdi mdi-chevron-down"></i>
                <i v-show="expanded" class="mdi mdi-chevron-up"></i>
            </span>
        </div>
    </div>
</template>

<script>
import _ from "lodash"

export default {
    name: "QnaFilter",
    props: {
        filter: {
            type: Object,
            default: {
                suspended: false,
                attachments: null,
                category: null
            }
        },
        showCategory: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            size: "large",
            options: [
                {
                    value: "pregnancy",
                    label: "Gravidanza"
                },
                {
                    value: "fertility",
                    label: "Fertilità"
                },
                {
                    value: "post_partum",
                    label: "Post partum"
                },
                {
                    value: "first_childhood",
                    label: "Prima infanzia"
                },
                {
                    value: "family",
                    label: "Famiglia"
                },
                {
                    value: "other",
                    label: "Altro"
                }
            ],
            expanded: true
        }
    },
    methods: {
        change() {
            this.$emit("change", this.filter)
        },
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.box && this.$refs.box.offsetWidth) {
                const width = this.$refs.box.offsetWidth
                if (width < 425) {
                    this.size = "small"
                } else if (width < 620) {
                    this.size = "medium"
                } else {
                    this.size = "large"
                }
            }
        },
        action() {
            this.expanded = !this.expanded
        }
    },
    mounted() {
        this.calcDim()
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.qna-filter {
    margin-bottom: 10px;

    .filter-bar {
        .switches > * {
            padding: 12px 16px;
            min-width: 160px;
        }

        .card-shadow--small {
            &.el-select {
                border-radius: 4px;

                :deep {
                    input {
                        border: none !important;
                        border-color: transparent;
                        height: 44px;

                        &:hover {
                            border-color: #c0c4cc;
                        }

                        &:focus {
                            border-color: $color-primary;
                        }
                    }
                }

                &.el-switch {
                    /*
                    .el-switch__label * {
                    }
					*/

                    border-color: transparent;

                    span {
                        font-size: 12px;
                        color: #c0c4cc;
                    }
                }
            }
        }
    }

    &.large {
        .filter-bar {
            flex-direction: row;

            .switches > *:nth-child(1) {
                margin-right: 5px;
            }
        }

        .filter-button {
            display: none;
        }
    }

    &.medium {
        .filter-bar {
            flex-direction: column;

            .option-select {
                width: 100%;
                margin-bottom: 10px;
            }

            .switches {
                width: 100%;
                display: flex;
                justify-content: center;

                & > * {
                    margin-left: 0;
                    flex-grow: 1;
                }

                :first-child {
                    margin-right: 5px;
                }

                :last-child {
                    margin-left: 5px;
                }
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        .filter-button {
            display: none;
        }
    }

    &.small {
        .filter-bar {
            flex-direction: column;

            .option-select {
                width: 100%;
                margin-bottom: 10px;
            }

            .switches {
                width: 100%;
                display: flex;
                flex-direction: column;

                & > * {
                    margin-left: 0;
                    flex-grow: 1;
                    margin-bottom: 10px;
                }
            }

            &.hide {
                display: none;
            }
        }

        .filter-button {
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-top: -5px;

            .action {
                display: inline-block;
                padding: 3px 10px 3px 13px;
                cursor: pointer;
            }
        }
    }
}
</style>
