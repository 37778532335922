var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "page", staticClass: "page-chat-attachments flex", class: _vm.size },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c("RoomSelect", {
        attrs: { roomId: _vm.roomId, roomsGroups: _vm.roomsGroups },
        on: {
          "update:roomId": function ($event) {
            _vm.roomId = $event
          },
          "update:room-id": function ($event) {
            _vm.roomId = $event
          },
          change: function ($event) {
            return _vm.openRoom(_vm.roomId)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "box scrollable only-y", attrs: { id: "rooms-list" } },
        _vm._l(_vm.roomsGroups, function (group) {
          return _c(
            "div",
            { key: group.name, staticClass: "rooms-group" },
            [
              _c("div", { staticClass: "group-name" }, [
                _vm._v(_vm._s(group.name)),
              ]),
              _vm._l(group.items, function (room) {
                return _c("RoomCard", {
                  key: room._id,
                  attrs: { room: room, active: room._id === _vm.roomId },
                  on: {
                    open: function ($event) {
                      return _vm.openRoom(room._id)
                    },
                  },
                })
              }),
            ],
            2
          )
        }),
        0
      ),
      !_vm.roomId
        ? _c(
            "div",
            {
              staticClass:
                "box grow flex card-base card-shadow--small align-center text-center",
              attrs: { id: "message-box" },
            },
            [
              _c("div", { staticClass: "box grow text-center fs-20 p-30" }, [
                _vm._v("Clicca su una stanza per visualizzare gli allegati"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.roomId
        ? _c(
            "div",
            {
              staticClass: "box grow flex column card-base card-shadow--small",
              attrs: { id: "message-box" },
            },
            [
              _vm.currentRoom
                ? _c("RoomHeader", { attrs: { room: _vm.currentRoom } })
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "msgList",
                  staticClass: "box grow scrollable only-y",
                  attrs: { id: "message-list" },
                  on: { scroll: _vm.msgListScrollHandler },
                },
                [
                  !_vm.messages.length && !_vm.loading
                    ? _c("div", [_vm._v("Non sono presenti allegati")])
                    : _vm._e(),
                  _vm._l(_vm.messages, function (msg) {
                    return _c("MsgAttachment", {
                      key: msg._id,
                      attrs: { msg: msg, id: msg._id },
                      on: {
                        openChat: function ($event) {
                          return _vm.openChat(_vm.roomId, msg._id)
                        },
                        delete: function ($event) {
                          return _vm.msgActions("delete", msg)
                        },
                        report: function ($event) {
                          return _vm.msgActions("report", msg)
                        },
                      },
                    })
                  }),
                  _vm.loading
                    ? _c("div", { staticClass: "msg-box" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: true,
                              expression: "true",
                            },
                          ],
                          staticClass: "msg-content p-20 flex column",
                        }),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }