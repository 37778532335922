var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "years-select" },
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "Anno" },
          on: {
            change: function ($event) {
              return _vm.$emit("update:year", $event)
            },
          },
          model: {
            value: _vm.yearSelected,
            callback: function ($$v) {
              _vm.yearSelected = $$v
            },
            expression: "yearSelected",
          },
        },
        _vm._l(_vm.years, function (year) {
          return _c("el-option", {
            key: year,
            attrs: { label: year, value: year },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }