<template>
    <div class="rooms-select card-base card-shadow--small">
        <el-select
            v-model="propModel"
            :clearable="!!clearable"
            placeholder="Scegli una stanza"
            filterable
            @change="change"
        >
            <el-option-group v-for="group of roomsGroups" :key="group.name" :label="$sugar.String.titleize(group.name)">
                <el-option
                    v-for="room of group.items"
                    :key="room._id"
                    :label="$sugar.String.titleize(group.name) + ' / ' + room.name"
                    :value="room._id"
                >
                    {{ room.name }}
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import _ from "lodash"

export default {
    name: "RoomSelect",
    props: ["roomsGroups", "roomId", "clearable"],
    computed: {
        propModel: {
            get() {
                return this.roomId
            },
            set(value) {
                this.$emit("update:roomId", value)
            }
        }
    },
    methods: {
        change() {
            this.$emit("change")
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: {}
}
</script>

<style lang="scss" scoped>
.rooms-select {
    width: 100%;
    margin-bottom: 20px;

    .el-select {
        width: 100%;

        :deep {
            .el-input__inner {
                border: none;
            }
        }
    }
}
</style>
