<template>
    <div class="toolbar flex align-center justify-space-between">
        <div class="box-left box grow flex">
            <button @click="toggleSidebar" class="toggle-sidebar card-base">
                <img class="logo-mini" src="@/assets/images/icon-mini.png" alt="logo" />
                <i class="mdi mdi-menu" v-if="menuBurger !== 'right'"></i>
            </button>

            <search class="hidden-xs-only"></search>
        </div>
        <div class="box-right flex align-center pl-10">
            <button class="fullscreen-button" @click="toggleFullscreen">
                <i class="mdi mdi-fullscreen" v-if="!fullscreen"></i>
                <i class="mdi mdi-fullscreen-exit" v-if="fullscreen"></i>
            </button>
            <span class="username flex align-center">
                {{ accountName }}
            </span>

            <el-dropdown trigger="click" @command="onCommand" placement="bottom">
                <span class="el-dropdown-link flex align-center">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="accountRole"
                        placement="bottom"
                        :disabled="size !== 'large'"
                    >
                        <i class="mdi mdi-shield ml-10 roles color" :class="accountRole"></i>
                    </el-tooltip>

                    <vue-load-image>
                        <img slot="image" class="avatar" :src="accountPicture" />
                        <img slot="preloader" class="avatar" :src="require('@/assets/images/loading.png')" />
                        <img slot="error" class="avatar" :src="require('@/assets/images/img-not-found.svg')" />
                    </vue-load-image>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="/profilo">
                        <i class="mdi mdi-account mr-10"></i> Profilo
                    </el-dropdown-item>
                    <el-dropdown-item command="/logout"> <i class="mdi mdi-logout mr-10"></i> Logout </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <!--<el-tooltip class="item" effect="dark" :content="accountRole" placement="bottom">
				<i class="mdi mdi-shield ml-10 roles color" :class="accountRole"></i>
			</el-tooltip>

			<vue-load-image>
				<img slot="image" class="avatar" :src="accountPicture" />
				<img slot="preloader" class="avatar" :src="require('@/assets/images/loading.png')" />
				<img slot="error" class="avatar" :src="require('@/assets/images/img-not-found.svg')" />
			</vue-load-image>-->

            <button
                @click="toggleSidebar"
                v-if="menuBurger === 'right'"
                class="toggle-sidebar toggle-sidebar__right card-base card-shadow--small"
            >
                <i class="mdi mdi-menu"></i>
            </button>
        </div>
    </div>
</template>

<script>
import VueLoadImage from "vue-load-image"
import Search from "@/components/common/Search"

export default {
    name: "Toolbar",
    props: ["menuBurger"],
    data() {
        return {
            popoverWidth: 300,
            fullscreen: false,
            lang: "us",
            size: "large"
        }
    },
    computed: {
        accountName() {
            return this.$store.getters.accountName
        },
        accountRole() {
            return this.$store.getters.accountRole
        },
        accountPicture() {
            return this.$store.getters.accountPicture || "-"
        }
    },
    methods: {
        calcDim() {
            const width = window.innerWidth

            this.size = "large"
            if (width < 350) {
                this.size = "small"
            } else if (width < 768) {
                this.size = "medium"
            }
        },
        onCommandLang(lang) {
            if (lang.charAt(0) === "/") this.onCommand(lang)
            else this.lang = lang
        },
        onCommand(path) {
            this.$router.push(path).catch(err => {})
        },
        toggleSidebar() {
            this.$emit("toggle-sidebar")
        },
        resizePopoverWidth() {
            if (window.innerWidth <= 768) {
                this.popoverWidth = 250
            } else {
                this.popoverWidth = 300
            }
        },
        toggleFullscreen() {
            this.$fullscreen.toggle(document.querySelector("body"), {
                wrap: false,
                callback: () => {
                    this.fullscreen = this.$fullscreen.getState()
                }
            })
        }
    },
    components: {
        Search,
        "vue-load-image": VueLoadImage
    },
    mounted() {
        this.calcDim()
        this.fullscreen = this.$fullscreen.getState()
        this.resizePopoverWidth()
        window.addEventListener("resize", this.resizePopoverWidth)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resizePopoverWidth)
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.toolbar {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 16px;
    user-select: none;

    .username {
        margin-left: 20px;
        font-size: 16px;
        font-weight: bold;
        color: $text-color;
        //@include text-bordered-shadow();

        a {
            color: $text-color;
            text-decoration: none;

            &:hover {
                color: $color-primary;
            }
        }
    }

    .avatar {
        border-radius: 50%;
        cursor: pointer;
        width: 50px;
        height: 50px;
        border: 2px solid #fff;
        margin-left: 10px;
        box-sizing: border-box;
        display: block;
        //box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
        transition: box-shadow 0.5s;
        outline: none;

        &:hover {
            //box-shadow: 0px 3px 10px 0 rgba(49, 49, 93, 0.08), 0px 2px 7px 0 rgba(0, 0, 0, 0.08);
        }
    }

    .roles.color {
        //@include text-bordered-shadow();
        margin-right: -28px;
        z-index: 1;
        position: relative;
        top: 21px;
        right: -34px;
        font-size: 20px;
        // text-shadow: 0px 0px 1px #fff, 0px 0px 1px #fff, 0px 0px 1px #fff, 0px 0px 1px #fff, 0px 0px 1px #fff, 0px 0px 1px #fff;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #fff;
    }

    .notification-icon {
        font-size: 20px;
        outline: none;
        padding: 0;
        background: transparent;
        border: none;
        margin-left: 20px;
        //color: #aab7c5;
        color: transparentize($text-color, 0.7);
        //@include text-bordered-shadow();

        &:hover {
            color: $color-primary;
        }
    }

    .toggle-sidebar {
        border: 1px solid transparent;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        box-sizing: border-box;
        text-align: center;
        margin: 0;
        outline: none;
        margin-right: 5px;
        font-size: 24px;
        padding: 0;
        cursor: pointer;
        display: inline-block;
        color: $text-color;
        background: white;
        display: none;
        opacity: 0;
        transition: all 0.5s;

        &__right {
            margin-right: 0px;
            margin-left: 18px;
        }

        &:hover,
        &:focus,
        &:active {
            color: $color-primary;
            border-color: $color-primary;
        }
    }

    .fullscreen-button {
        font-size: 24px;
        cursor: pointer;
        outline: none;
        padding: 0;
        background: transparent;
        border: none;
        margin-left: 20px;
        //color: #aab7c5;
        color: transparentize($text-color, 0.7);
        //@include text-bordered-shadow();

        &:hover {
            color: $color-primary;
        }
    }

    .logo-mini {
        width: 30px;
        height: 30px;
        display: none;
    }

    .el-dropdown {
        .flag-icon {
            box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
            cursor: pointer;
            border: 1px solid lighten($background-color, 20%);
            background-color: lighten($background-color, 20%);
        }
    }
}

@media (max-width: 650px) {
    .toolbar {
        .username {
            //display: none;
        }
    }
}

@media (max-width: 768px) {
    .toolbar {
        padding: 0 20px;

        .avatar {
            width: 35px;
            height: 35px;
        }

        .search-box {
            margin-left: 15px;
        }

        .roles.color {
            font-size: 16px;
            top: 12px;
            right: -24px;
        }

        .toggle-sidebar {
            display: block;
            opacity: 1;
        }

        .fullscreen-button {
            display: none;
        }

        .logo-mini {
            display: inherit;
        }
    }
}
</style>
