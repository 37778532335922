var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-users-export" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "form-box flex column card-base card-shadow--small",
      },
      [
        _c("div", { staticClass: "header" }, [_vm._v("Data Export Tool")]),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c("ExportToolFilters", {
              ref: "exportTool",
              on: {
                updated: function ($event) {
                  _vm.filters = $event
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  loading: _vm.loading,
                  disabled: !_vm.isValid,
                },
                on: {
                  click: function ($event) {
                    return _vm.requestData()
                  },
                },
              },
              [_vm._v(" Export ")]
            ),
            _c(
              "el-button",
              {
                attrs: { loading: _vm.loading },
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [_vm._v(" Reset ")]
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "form-box flex column card-base card-shadow--small mt-20",
      },
      [
        _c("div", { staticClass: "header flex gaps justify-space-between" }, [
          _vm._m(0),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingList,
                      expression: "loadingList",
                    },
                  ],
                  attrs: { size: "mini", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.getExportList()
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-reload fs-14" })]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingList,
                    expression: "loadingList",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.exportList },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "ID", prop: "id", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "Data e ora", prop: "started" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dayjs")(
                                  scope.row.started,
                                  "format",
                                  "DD/MM/YYYY HH:mm:ss"
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "Stato", prop: "status" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "strong",
                            {
                              staticClass: "export-item-status",
                              class: scope.row.status,
                            },
                            [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "Tempo exec.", prop: "execution_time" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.execution_time)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "File", prop: "link" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.link
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: scope.row.link,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Scarica")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v(" Lista richieste export "),
      _c("small", { staticStyle: { opacity: "0.5" } }, [
        _vm._v(" (ultime 48h) "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }