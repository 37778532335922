<template>
    <div class="review-editor" :class="[size]">
        <div class="review-title">
            <input v-model="review.title" placeholder="Titolo..." :disabled="readonly" />
        </div>

        <div class="review-subtitle">
            <textarea-autosize
                :autosize="textAreaResize"
                placeholder="Sottotitolo..."
                :disabled="readonly"
                v-model="review.subtitle"
            ></textarea-autosize>
        </div>

        <div class="review-image" :class="{ disabled: readonly }">
            <vue-load-image>
                <img slot="image" class="image" :src="review.image" />
                <img slot="preloader" class="preloader" :src="require('@/assets/images/loading-ar_3-2.png')" />
                <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
            </vue-load-image>
            <FileDrop
                @change="fileChange(review, 'image', $event)"
                :hover="true"
                :placeholder="'Scegli foto'"
                v-show="!readonly"
            ></FileDrop>
        </div>

        <div class="review-pre">
            <textarea-autosize
                :autosize="textAreaResize"
                placeholder="Descrizione..."
                v-model="review.pre"
                :disabled="readonly"
            ></textarea-autosize>
        </div>

        <draggable
            class="review-products"
            tag="div"
            handle=".handle"
            v-model="review.products"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
        >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div class="product" v-for="(product, index) in review.products" :key="product.id">
                    <i class="mdi mdi-drag handle" v-show="!readonly"></i>
                    <i
                        class="mdi mdi-chevron-right collapse-btn"
                        v-if="product.collapse"
                        @click="toggleCollapse(index)"
                    ></i>
                    <i
                        class="mdi mdi-chevron-down collapse-btn"
                        v-if="!product.collapse"
                        @click="toggleCollapse(index)"
                    ></i>
                    <i class="mdi mdi-delete-outline delete-btn" @click="deleteProduct(index)" v-show="!readonly"></i>

                    <div class="title">
                        <input v-model="product.title" placeholder="Nome prodotto..." :disabled="readonly" />
                    </div>
                    <div class="flex desc-box" v-if="!isCollapsed(index)">
                        <div class="product-image box" :class="{ disabled: readonly }">
                            <vue-load-image>
                                <img slot="image" class="image" :src="product.image" />
                                <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                                <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                            </vue-load-image>
                            <FileDrop
                                @change="fileChange(product, 'image', $event)"
                                :hover="true"
                                :placeholder="'Scegli foto'"
                                v-show="!readonly"
                            ></FileDrop>
                        </div>
                        <div class="description box grow">
                            <textarea-autosize
                                :autosize="textAreaResize"
                                placeholder="Descrizione prodotto..."
                                v-model="product.description"
                                :disabled="readonly"
                            ></textarea-autosize>
                        </div>
                    </div>
                    <div class="info" v-if="!isCollapsed(index)">
                        <div class="award half">
                            <label>Premio</label>
                            <input v-model="product.award" placeholder="Premio..." :disabled="readonly" />
                        </div>
                        <div class="headline half">
                            <label>Titolo</label>
                            <input v-model="product.headline" placeholder="Titolo..." :disabled="readonly" />
                        </div>
                        <div class="summary half">
                            <label>Riepilogo</label>
                            <input v-model="product.summary" placeholder="Riepilogo..." :disabled="readonly" />
                        </div>
                        <div class="brand half">
                            <label>Marca</label>
                            <input v-model="product.brand" placeholder="Marca..." :disabled="readonly" />
                        </div>
                        <div class="link half">
                            <label>Link</label>
                            <input v-model="product.link" placeholder="Link..." :disabled="readonly" />
                        </div>
                        <div class="name half">
                            <label>Nome prodotto</label>
                            <input v-model="product.name" placeholder="Nome prodotto..." :disabled="readonly" />
                        </div>
                        <div class="link_ref wide">
                            <label>Link tracciato</label>
                            <input v-model="product.link_ref" placeholder="Link tracciato..." :disabled="readonly" />
                        </div>
                    </div>
                </div>
            </transition-group>
        </draggable>

        <div class="add-product ml-10">
            <el-button plain type="primary" v-if="review.products.length < 20" @click="addProduct()" v-show="!readonly">
                Aggiungi prodotto
            </el-button>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import utils from "@/utils"
import FileDrop from "@/components/common/FileDrop"
import draggable from "vuedraggable"
import VueLoadImage from "vue-load-image"

export default {
    name: "ReviewEditor",
    props: ["review", "readonly", "textAreaResize", "size"],
    data() {
        return {
            drag: false,
            dragOptions: {
                animation: 200,
                group: "reviews",
                disabled: false,
                ghostClass: "ghost"
            },
            productsCollapsed: []
        }
    },
    methods: {
        toggleCollapse(pIndex) {
            this.$nextTick(() => {
                this.$set(this.productsCollapsed, pIndex, !this.productsCollapsed[pIndex])
            })
        },
        isCollapsed(pIndex) {
            return !!this.productsCollapsed[pIndex]
        },
        addProduct() {
            this.review.products.push({
                award: "",
                brand: "",
                description: "",
                headline: "",
                image: "null",
                link: "",
                link_ref: "",
                order: 0,
                summary: "",
                title: "",
                name: "",
                id: new Date().getTime()
            })
        },
        deleteProduct(pIndex) {
            const review = _.cloneDeep(this.review)
            review.products[pIndex] = null
            review.products = _.compact(review.products)
            this.$nextTick(() => {
                this.$set(this.review, "products", review.products)
            })
        },
        fileChange(obj, param, file) {
            utils.file2Base64(file).then(base64 => {
                obj[param] = base64
            })
        }
    },
    mounted() {},
    components: {
        "vue-load-image": VueLoadImage,
        draggable,
        FileDrop
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/_variables";
@import "@/assets/scss/card-shadow";

.review-editor {
    label {
        padding: 10px;
    }
    textarea,
    input {
        width: 100%;
        font-family: inherit;
        padding: 10px;
        box-sizing: border-box;
        background: transparent;
        border-radius: 8px;
        border: 2px solid transparent;
        outline: none;
        transition: all 0.2s;

        &:not(:disabled) {
            &:hover {
                background: white;
                border-color: $color-primary;
                @extend .card-shadow--small;
            }
        }
        &:disabled,
        &.disabled {
            cursor: not-allowed;
        }
    }

    .review-title {
        margin-bottom: 10px;

        input {
            font-size: 30px;
            font-weight: bold;
        }
    }

    .review-subtitle {
        margin-bottom: 20px;

        textarea {
            font-size: 20px;
        }
    }

    .review-image {
        width: 100%;
        max-width: 500px;
        margin-bottom: 20px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 2px solid transparent;
        transition: all 0.2s;
        position: relative;

        img {
            max-width: 100%;
            border-radius: 8px;
            display: block;
        }

        &:not(.disabled) {
            &:hover {
                background: white;
                border-color: $color-primary;
                @extend .card-shadow--small;
            }
        }
        &.disabled {
            cursor: not-allowed;
        }
    }

    .review-pre {
        margin-bottom: 20px;

        textarea {
            font-size: 18px;
        }
    }

    .review-products {
        .product {
            @extend .card-base;
            @extend .card-shadow--small;
            margin: 10px;
            margin-bottom: 30px;
            padding: 10px;
            position: relative;
            overflow: inherit;

            &.flip-list-move {
                transition: transform 0.1s;
            }
            &.no-move {
                transition: transform 0s;
            }

            .handle {
                @extend .card-base;
                @extend .card-shadow--small;
                position: absolute;
                top: 12px;
                left: -10px;
                font-size: 30px;
                padding: 0 6px;
                cursor: ns-resize;
            }
            .collapse-btn {
                @extend .card-base;
                @extend .card-shadow--small;
                position: absolute;
                top: 12px;
                left: 40px;
                font-size: 30px;
                padding: 0 6px;
                cursor: pointer;
            }
            .delete-btn {
                @extend .card-base;
                @extend .card-shadow--small;
                position: absolute;
                top: 12px;
                left: 90px;
                font-size: 30px;
                padding: 0 6px;
                cursor: pointer;
                color: $color-danger;
            }

            .title {
                margin-bottom: 20px;
                margin-left: 130px;

                input {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
            .desc-box {
                .product-image {
                    width: 100%;
                    max-width: 300px;
                    margin-bottom: 20px;
                    margin-right: 10px;
                    padding: 10px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    border: 2px solid transparent;
                    transition: all 0.2s;
                    position: relative;

                    img {
                        max-width: 100%;
                        border-radius: 8px;
                        display: block;
                    }

                    &:not(.disabled) {
                        &:hover {
                            background: white;
                            border-color: $color-primary;
                            @extend .card-shadow--small;
                        }
                    }
                    &.disabled {
                        cursor: not-allowed;
                    }
                }

                .description {
                    margin-bottom: 20px;

                    textarea {
                        font-size: 16px;
                    }
                }
            }
            .info {
                background: $background-color;
                border-radius: 6px;
                padding: 10px;
                padding-top: 20px;
                box-sizing: border-box;
                margin: 10px;
                margin-top: 0;

                & > div {
                    margin-bottom: 20px;

                    input {
                        font-size: 16px;
                    }
                    label {
                        opacity: 0.7;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .half {
                    width: 50%;
                    display: inline-block;
                }
            }
        }
    }

    &.medium,
    &.small {
        .review-products {
            .product {
                .desc-box {
                    flex-direction: column;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
    &.small {
        .review-products {
            .product {
                .title {
                    margin-left: 0;
                    margin-top: 60px;
                }
                .info {
                    .half {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}
</style>
