<template>
	<el-select
		class="w-full"
		:clearable="true"
		v-model="model"
		:placeholder="placeholder || 'Seleziona'"
		:multiple="true"
	>
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "BabyAgeFilter",
	props: ["value", "placeholder"],
	data() {
		return {
			model: null,
			options: [
				{ value: "0-1", label: "0-1" },
				{ value: "2-3", label: "2-3" },
				{ value: ">4", label: ">4" }
			]
		}
	},
	watch: {
		model(val) {
			this.$emit("update:value", val)
		}
	}
}
</script>
