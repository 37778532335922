<template>
    <div ref="box" class="answer-list flex column" :class="{ nested }">
        <resize-observer @notify="__resizeHandler" />
        <QnaFilter v-if="filtersEnabled" class="box" @change="resetPage" :filter="filter"></QnaFilter>
        <div class="list-wrap box grow flex column" v-loading="loading">
            <div class="list-box box grow scrollable only-y" ref="list">
                <div v-if="!answers.length && !loading">
                    <p>Non sono presenti risposte<span v-if="pagination.page > 1"> in questa pagina</span>.</p>
                </div>
                <Answer
                    @deleted="getAnswers"
                    @restored="getAnswers"
                    v-for="answer of answers"
                    :key="answer.id"
                    :answer="answer"
                    :nested="nested"
                    :size="size"
                    :highlight="targetId === answer.id"
                ></Answer>
            </div>
        </div>

        <el-pagination
            class="card-base box card-shadow--small"
            ref="pagination"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="layout"
            :total="pagination.total"
            @size-change="getAnswers"
            @current-change="getAnswers"
            @prev-click="getAnswers"
            @next-click="getAnswers"
        >
            <ul class="el-pager">
                <li class="number active">{{ pagination.page }}</li>
            </ul>
        </el-pagination>
    </div>
</template>

<script>
import api from "@/api"
import _ from "lodash"

const Answer = () => import("./Answer")
const QnaFilter = () => import("./QnaFilter")

export default {
    name: "AnswerList",
    props: {
        qnaId: {
            type: Number,
            default: 0
        },
        targetId: {
            type: Number,
            default: 0
        },
        nested: {
            type: Boolean,
            default: false
        },
        filtersEnabled: {
            type: Boolean,
            default: true
        },
        user: {
            type: Number,
            required: false
        },
        showReported: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fullPagination: true,
            loading: false,
            answers: [],
            filter: {
                suspended: false,
                attachments: null,
                category: null
            },
            pagination: {
                page: 1,
                size: this.nested ? 5 : 10,
                sizes: [5, 10, 20, 30, 50, 100],
                layout: this.layout,
                small: false
            },
            size: "large",
            searching: false
        }
    },
    computed: {
        layout() {
            if (this.fullPagination && this.size === "large") {
                return "total, ->, prev, pager, next, jumper, sizes"
            } else {
                if (this.size === "small") {
                    return "->, prev, slot, next"
                } else {
                    return "->, prev, pager, next"
                }
            }
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.box) {
                const width = this.$refs.box.offsetWidth
                this.fullPagination = true
                this.size = "large"
                if (width < 700) {
                    this.fullPagination = false
                    this.size = "small"
                } else if (width < 890) {
                    this.size = "medium"
                }
            }
        },
        isFound() {
            if (!this.targetId) {
                return true
            }
            /*if (!this.answers || !this.answers.length) {
                return true
            }*/

            if (_.findIndex(this.answers, a => a.id === this.targetId) !== -1) {
                return true
            }

            return false
        },
        getAnswers() {
            this.loading = true

            const params = {
                ...this.filter,
                pagination: this.pagination.size,
                page: this.pagination.page
            }

            if (this.user) {
                params.user_id = this.user
            }

            api.qna
                .getComments(params, this.qnaId)
                .then(res => {
                    if (res.status === 200 && res.body && res.body.data) {
                        this.answers = res.body.data
                        this.pagination.total = res.body.pagination.totalElements
                        if (this.pagination.page > res.body.pagination.totalPages) {
                            setTimeout(() => {
                                this.$refs.pagination.handleCurrentChange(this.pagination.page)
                            }, 1000)
                        }

                        if (this.$refs.list) {
                            this.$refs.list.scrollTo({ top: 0 })
                        }

                        setTimeout(() => {
                            this.$nextTick(() => {
                                if (this.$refs.pagination) {
                                    if (
                                        this.searching &&
                                        this.pagination.page < this.$refs.pagination.internalPageCount
                                    ) {
                                        if (this.pagination.page < 10) {
                                            this.$refs.pagination.next()
                                        } else {
                                            this.searching = false

                                            this.$notify.error({
                                                title: "Ops...",
                                                message:
                                                    "La risposta che stai cercando è obsoleta, si prega di contattare il supporto"
                                            })
                                        }
                                    }

                                    if (this.targetId && this.isFound()) {
                                        this.$emit("found")
                                        this.searching = false
                                    }
                                }
                            })
                        }, 200)
                    } else {
                        this.answers = []
                    }
                })
                .catch(err => {
                    this.answers = []
                    console.error(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        resetPage() {
            this.pagination.page = 1
            this.getAnswers()
        }
    },
    mounted() {
        this.calcDim()

        this.$nextTick(() => {
            if (!this.isFound()) {
                this.searching = true
            }

            if (this.showReported) {
                this.filter.suspended = true
            }

            this.getAnswers()
        })
    },
    components: {
        Answer,
        QnaFilter
    }
}
</script>

<style lang="scss" scoped>
.answer-list {
    height: 100%;

    .list-wrap {
        overflow: hidden;
        margin-left: -6px;
        margin-right: -11px;

        @media (max-width: 768px) {
            margin-right: -6px;
        }

        .list-box {
            padding: 0 6px;
        }
    }

    .el-pagination {
        margin-top: 10px;
        padding: 10px 20px;
        padding-right: 10px;

        :deep {
            .el-pagination__sizes {
                margin-right: 0;

                .el-input {
                    margin-right: 0;
                }
            }
        }
    }

    &.nested {
        padding: 16px;

        .qna-filter {
            margin-bottom: 30px;

            :deep {
                .el-select {
                    box-shadow: none;
                }
                .el-switch {
                    box-shadow: none;
                }
            }
        }

        .list-wrap {
            margin-right: -6px;

            .list-box {
                & > * {
                    border-bottom: 1px solid #dedede;
                    border-radius: 0;
                    padding: 0;
                    padding-bottom: 20px;

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
        .el-pagination {
            box-shadow: none;
        }
    }
}
</style>
