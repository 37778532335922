<template>
    <div class="editor flex column">
        <editor-menu-bar
            :editor="editor"
            :keep-in-bounds="keepInBounds"
            v-slot="{ commands, isActive, focused, getMarkAttrs }"
        >
            <div class="menubar box" :class="{ 'is-focused': focused && hiding, 'is-hidden': hiding }">
                <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                    <i class="mdi mdi-format-bold"></i>
                </button>

                <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                    <i class="mdi mdi-format-italic"></i>
                </button>

                <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
                    <i class="mdi mdi-format-strikethrough"></i>
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.underline() }"
                    @click="commands.underline"
                >
                    <i class="mdi mdi-format-underline"></i>
                </button>

                <button
                    v-if="heading"
                    class="menubar__button"
                    :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                    @click="commands.heading({ level: 1 })"
                >
                    H1
                </button>

                <button
                    v-if="heading"
                    class="menubar__button"
                    :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                    @click="commands.heading({ level: 2 })"
                >
                    H2
                </button>

                <button
                    v-if="heading"
                    class="menubar__button"
                    :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                    @click="commands.heading({ level: 3 })"
                >
                    H3
                </button>

                <button
                    v-if="list"
                    class="menubar__button"
                    :class="{ 'is-active': isActive.bullet_list() }"
                    @click="commands.bullet_list"
                >
                    <i class="mdi mdi-format-list-bulleted"></i>
                </button>

                <button
                    v-if="list"
                    class="menubar__button"
                    :class="{ 'is-active': isActive.ordered_list() }"
                    @click="commands.ordered_list"
                >
                    <i class="mdi mdi-format-list-bulleted-type"></i>
                </button>

                <button
                    v-if="table"
                    class="menubar__button"
                    @click="commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                >
                    <i class="mdi mdi-table"></i>
                </button>

                <span v-if="isActive.table() && table">
                    <button class="menubar__button" @click="commands.deleteTable">
                        <i class="mdi mdi-table-remove"></i>
                    </button>
                    <button class="menubar__button" @click="commands.addColumnBefore">
                        <i class="mdi mdi-table-column-plus-before"></i>
                    </button>
                    <button class="menubar__button" @click="commands.addColumnAfter">
                        <i class="mdi mdi-table-column-plus-after"></i>
                    </button>
                    <button class="menubar__button" @click="commands.deleteColumn">
                        <i class="mdi mdi-table-column-remove"></i>
                    </button>
                    <button class="menubar__button" @click="commands.addRowBefore">
                        <i class="mdi mdi-table-row-plus-before"></i>
                    </button>
                    <button class="menubar__button" @click="commands.addRowAfter">
                        <i class="mdi mdi-table-row-plus-after"></i>
                    </button>
                    <button class="menubar__button" @click="commands.deleteRow">
                        <i class="mdi mdi-table-row-remove"></i>
                    </button>
                    <button class="menubar__button" @click="commands.toggleCellMerge">
                        <i class="mdi mdi-table-split-cell"></i>
                    </button>
                </span>

                <button v-if="history" class="menubar__button" @click="commands.undo">
                    <i class="mdi mdi-undo"></i>
                </button>

                <button v-if="history" class="menubar__button" @click="commands.redo">
                    <i class="mdi mdi-redo"></i>
                </button>

                <div v-if="link">
                    <form
                        class="menubar__form"
                        v-if="linkMenuIsActive"
                        @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                    >
                        <input
                            class="menubar__input"
                            type="text"
                            v-model="linkUrl"
                            placeholder="https://"
                            ref="linkInput"
                            @keydown.esc="hideLinkMenu"
                        />
                        <button class="menubar__button" @click="setLinkUrl(commands.link, null)" type="button">
                            <i class="mdi mdi-close"></i>
                        </button>
                    </form>

                    <button
                        v-if="!linkMenuIsActive && isSelected"
                        class="menubar__button"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                        :class="{ 'is-active': isActive.link() }"
                    >
                        <i class="mdi mdi-link-variant"></i>
                    </button>
                </div>
            </div>
        </editor-menu-bar>

        <editor-content class="editor__content box grow scrollable only-y" :editor="editor" />
    </div>
</template>

<script>
import _ from "lodash"
import striptags from "striptags"
import { Editor, EditorContent, EditorMenuBar } from "tiptap"
import {
    // list
    OrderedList,
    BulletList,
    ListItem,

    // mix
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Image,
    Placeholder,
    History,
    Link,
    TrailingNode,
    HardBreak,

    // table
    Table,
    TableHeader,
    TableCell,
    TableRow
} from "tiptap-extensions"

export default {
    name: "Editor",
    props: {
        placeholder: {
            type: String,
            default: "Scrivi..."
        },
        heading: {
            type: Boolean,
            default: true
        },
        table: {
            type: Boolean,
            default: false
        },
        list: {
            type: Boolean,
            default: true
        },
        link: {
            type: Boolean,
            default: false
        },
        history: {
            type: Boolean,
            default: false
        },
        hiding: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            keepInBounds: true,
            editor: new Editor({
                extensions: [
                    new BulletList(),
                    new Heading({ levels: [1, 2, 3] }),
                    new ListItem(),
                    new OrderedList(),
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Link(),
                    new Image(),
                    new Table({
                        resizable: true
                    }),
                    new TableHeader(),
                    new TableCell(),
                    new TableRow(),
                    new Placeholder({
                        emptyEditorClass: "is-editor-empty",
                        emptyNodeClass: "is-empty",
                        emptyNodeText: this.placeholder,
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true
                    }),
                    new HardBreak(),
                    new TrailingNode({
                        node: "paragraph",
                        notAfter: ["paragraph"]
                    })
                ],
                onUpdate: ({ getHTML }) => {
                    this.html = getHTML()
                    this.text = _.trim(_.replace(striptags(this.html), /&nbsp;/gi, "")) || ""
                    this.emit()
                }
            }),
            html: "",
            text: "",
            linkUrl: null,
            linkMenuIsActive: false
        }
    },
    computed: {
        isSelected() {
            return this.editor.selection.from !== this.editor.selection.to
        }
    },
    methods: {
        clearContent() {
            this.editor.clearContent(true)
        },
        setContent(text) {
            this.html = text
            this.text = _.trim(_.replace(striptags(text), /&nbsp;/gi, "")) || ""
            this.editor.setContent(text)
            this.emit()
        },
        emit() {
            this.$emit("update:html", this.html)
            this.$emit("update:text", this.text)
        },
        reset() {
            this.clearContent()
        },
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(command, url) {
            command({ href: url })
            this.hideLinkMenu()
        },
        getCtx() {
            return this.editor
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {
        this.editor.destroy()
    },
    components: {
        EditorContent,
        EditorMenuBar
    }
}
</script>

<style lang="scss" scoped>
</style>
