<template>
	<el-select
		class="w-full"
		:clearable="true"
		v-model="model"
		:placeholder="placeholder || 'Seleziona'"
		:multiple="true"
	>
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "GeoAreaFilter",
	props: ["value", "placeholder"],
	data() {
		return {
			model: null,
			options: [
				{ value: "NIELSEN_1", label: "Piemonte, Valle D'Aosta, Liguria, Lombardia" },
				{ value: "NIELSEN_2", label: "Triveneto, Emilia Romagna" },
				{ value: "NIELSEN_3", label: "Toscana, Umbria, Marche, Lazio, Sardegna" },
				{ value: "NIELSEN_4", label: "Abruzzo, Molise, Puglia, Campania, Basilicata, Calabria, Sicilia" }
			]
		}
	},
	watch: {
		model(val) {
			this.$emit("update:value", val)
		}
	}
}
</script>
