<template>
	<div class="review-card flex align-center card-shadow--small" @click="click">
		<div class="box image-box flex align-center">
			<i class="mdi mdi-drag handle card-base card-shadow--small" v-if="handle"></i>
			<vue-load-image>
				<img slot="image" class="image" :src="review.image" />
				<img slot="preloader" class="preloader" :src="require('@/assets/images/loading-ar_3-2.png')" />
				<img slot="error" class="error" :src="require('@/assets/images/img-not-found-ar_3-2.jpg')" />
			</vue-load-image>
		</div>
		<div class="box grow info">
			<div class="title">
				{{ review.title }}
				<small class="o-050">#{{ review.id }}</small>
				<i class="mdi mdi-star-outline warning-text ml-10" v-if="review.featured"></i>
			</div>
			<div class="type">
				<span v-if="review.type">{{ review.type }} - </span>
				<small class="o-070">{{ review.updated_at | dayjs("format", "DD MMM YYYY (HH:mm)") }}</small>
			</div>
		</div>
		<div class="box handle-box" v-if="handle">
			<i class="mdi mdi-drag handle"></i>
		</div>
	</div>
</template>

<script>
import VueLoadImage from "vue-load-image"

export default {
	name: "ReviewCard",
	props: {
		review: {
			type: Object,
			default: {}
		},
		handle: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	},
	watch: {},
	computed: {},
	methods: {
		click() {
			this.$emit("click")
		}
	},
	created() {},
	mounted() {},
	beforeDestroy() {},
	components: { "vue-load-image": VueLoadImage }
}
</script>

<style lang="scss" scoped>
.review-card {
	margin: 18px 0;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;

	&.flip-list-move {
		transition: transform 0.5s;
	}
	&.no-move {
		transition: transform 0s;
	}
	&.ghost {
		//opacity: 0.5;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 6px;
	}

	.image-box {
		width: 170px;
		position: relative;
		box-sizing: border-box;

		.vue-load-image {
			width: 100%;

			img {
				width: 100%;
				display: block;
			}
		}
		.handle {
			display: none;
			font-size: 30px;
			right: -8px;
			position: absolute;
			width: 38px;
			text-align: center;
			height: 40px;
		}
	}

	.info {
		padding: 10px 20px;
		box-sizing: border-box;

		.title {
			font-size: 22px;
			line-height: 1.2;
			font-weight: bold;
			margin-bottom: 10px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}

			&.not-clickable {
				cursor: default;

				&:hover {
					text-decoration: none;
				}
			}
		}
		.type {
			font-size: 18px;
		}
	}

	.handle-box {
		font-size: 30px;
		padding-right: 16px;

		.handle {
			cursor: ns-resize;
		}
	}
}

@media (max-width: 1000px) {
	.review-card {
		flex-direction: column;
		-webkit-box-orient: vertical;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		overflow: inherit;

		.image-box {
			padding: 0 20px;
			padding-top: 20px;
			width: 100%;

			img {
				width: 100%;
				border-radius: 8px;
			}

			.handle {
				display: inline-block;
			}
		}
		.info {
			width: 100%;
			padding: 20px;

			.title {
				font-size: 18px;
			}
			.type {
				font-size: 16px;
			}
		}
		.handle-box {
			display: none;
		}
	}
}
</style>
