var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.loadedData,
          expression: "!loadedData",
        },
      ],
      staticClass: "chat-stats-page scrollable only-y",
    },
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("YearsSelect", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: !_vm.loadedYears,
                expression: "!loadedYears",
              },
            ],
            staticClass: "mb-30",
            attrs: { year: _vm.yearSelected, years: _vm.years },
            on: {
              "update:year": function ($event) {
                _vm.yearSelected = $event
              },
            },
          }),
          _c("RoomSelect", {
            attrs: {
              roomId: _vm.roomId,
              roomsGroups: _vm.roomsGroups,
              clearable: true,
            },
            on: {
              "update:roomId": function ($event) {
                _vm.roomId = $event
              },
              "update:room-id": function ($event) {
                _vm.roomId = $event
              },
            },
          }),
        ],
        1
      ),
      _vm.loadedData
        ? _c("Calendar", {
            staticClass: "mb-60",
            attrs: {
              year: _vm.yearSelected,
              data: _vm.dataParsed,
              "by-room": _vm.byRoom,
            },
          })
        : _vm._e(),
      _vm.yearSelected && _vm.loadedData && !_vm.loadingChart
        ? _c(
            "Chart",
            {
              attrs: { data: _vm.dataParsed, "by-room": _vm.byRoom },
              on: {
                click: function ($event) {
                  return _vm.setHour($event)
                },
              },
            },
            [
              _vm.hourSelected
                ? _c("DayHour", {
                    staticClass: "mb-30",
                    attrs: {
                      data: _vm.dataParsed,
                      "by-room": _vm.byRoom,
                      "hour-selected": _vm.hourSelected,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.byRoom && !_vm.dataParsed.length
        ? _c("h3", [_vm._v("Dati insufficienti per la stanza selezionata")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }