var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "file-drop" }, [
    _c(
      "label",
      {
        class: { drop: _vm.dropped, hover: _vm.hover },
        on: {
          dragover: _vm.dragover,
          dragleave: _vm.dragleave,
          drop: _vm.drop,
        },
      },
      [
        !_vm.icon ? _c("span", [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e(),
        _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
        _c("input", {
          ref: "file",
          attrs: { type: "file", multiple: _vm.multiple, accept: _vm.accept },
          on: { change: _vm.onChange },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }