<template>
	<el-select
		class="w-full"
		:clearable="true"
		v-model="model"
		:placeholder="placeholder || 'Seleziona'"
		:multiple="true"
	>
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "GenreFilter",
	props: ["value", "placeholder"],
	data() {
		return {
			model: null,
			options: [
				{ value: 1, label: "Donna" },
				{ value: 2, label: "Uomo" },
				{ value: 3, label: "Altro" }
			]
		}
	},
	watch: {
		model(val) {
			this.$emit("update:value", val)
		}
	}
}
</script>
