<template>
    <div class="user-children" v-loading="loading">
        <div class="children-list" v-if="children && children.length">
            <Child
                v-for="child in children"
                :key="child.id"
                :data="child"
                :active="active === child.id"
                class="mr-20 mb-10"
                @click="active = child.id"
            ></Child>
        </div>
        <div v-else>Nessun bambino registrato</div>

        <div v-if="!active && children && children.length">Seleziona un bimbo per visualizzare i dettagli</div>

        <div v-if="cloud">
            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/bagnetto.svg" /> Bagnetto
                    <small class="o-070">({{ cloud.baths.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.baths.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="bath in cloud.baths"
                            :key="bath.id"
                            :data="bath"
                            type="bath"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun bagnetto registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/seno.svg" /> Seno
                    <small class="o-070">({{ cloud.breastfeeding.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.breastfeeding.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="breastfeeding in cloud.breastfeeding"
                            :key="breastfeeding.id"
                            :data="breastfeeding"
                            type="breastfeeding"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun allattamento registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/biberon.svg" /> Biberon
                    <small class="o-070">({{ cloud.biberon.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.biberon.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="biberon in cloud.biberon"
                            :key="biberon.id"
                            :data="biberon"
                            type="biberon"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun biberon registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/pannolino.svg" /> Pannolini
                    <small class="o-070">({{ cloud.diapers.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.diapers.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="diaper in cloud.diapers"
                            :key="diaper.id"
                            :data="diaper"
                            type="diaper"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun pannolino registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/sonno.svg" /> Sonno
                    <small class="o-070">({{ cloud.sleeps.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.sleeps.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="sleep in cloud.sleeps"
                            :key="sleep.id"
                            :data="sleep"
                            type="sleep"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun sonno registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/pappa.svg" /> Pappa
                    <small class="o-070">({{ cloud.meals.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.meals.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="meal in cloud.meals"
                            :key="meal.id"
                            :data="meal"
                            type="meal"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun pasto registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-baby-icons/dentini.svg" /> Dentini
                    <small class="o-070">({{ cloud.teeth.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.teeth.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <CloudItem
                            v-for="teeth in cloud.teeth"
                            :key="teeth.id"
                            :data="teeth"
                            type="teeth"
                            class="mr-20"
                        ></CloudItem>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun dentino registrato</div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import Child from "@/components/User/UserChildren/Child"
import CloudItem from "@/components/User/UserChildren/CloudItem"

export default {
    name: "UserChildren",
    props: ["id"],
    data() {
        return {
            children: null,
            loading: true,
            active: null
        }
    },
    computed: {
        cloud() {
            if (this.active) {
                return _.find(this.children, c => c.id === this.active).cloud
            }
            return null
        }
    },
    watch: {},
    methods: {
        getChildren() {
            api.users
                .getChildren(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.children = res.body

                        if (this.children.baths?.length) {
                            this.children.baths.reverse()
                        }
                    }
                    this.loading = false
                    this.$emit("loaded")
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                    this.$emit("error")
                })
        }
    },
    mounted() {
        if (this.id) {
            this.getChildren()
        }
    },
    components: { Child, CloudItem }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/card-shadow";

.user-children {
    width: 100%;
    max-width: 100%;

    .children-list {
        overflow: hidden;
        margin-bottom: 20px;

        & > div {
            float: left;
            cursor: pointer;

            &:hover {
                @extend .card-shadow--small;
            }
        }
    }

    .section {
        margin-bottom: 30px;
        max-width: 100%;
        background: rgba(255, 255, 255, 0.7);
        padding: 20px;
        border-radius: 8px;

        .header {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 20px;

            img {
                width: 30px;
                margin-right: 15px;
            }

            small {
                margin-left: 5px;
            }
        }

        .list {
            width: 100%;
            max-width: 100%;

            &.scrollable.only-x {
                padding: 0 20px;
                padding-bottom: 10px;
                margin: 0 -20px;
                white-space: nowrap;
                vertical-align: top;
            }
        }
    }
}
</style>
