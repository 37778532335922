var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "review-editor", class: [_vm.size] },
    [
      _c("div", { staticClass: "review-title" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.review.title,
              expression: "review.title",
            },
          ],
          attrs: { placeholder: "Titolo...", disabled: _vm.readonly },
          domProps: { value: _vm.review.title },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.review, "title", $event.target.value)
            },
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "review-subtitle" },
        [
          _c("textarea-autosize", {
            attrs: {
              autosize: _vm.textAreaResize,
              placeholder: "Sottotitolo...",
              disabled: _vm.readonly,
            },
            model: {
              value: _vm.review.subtitle,
              callback: function ($$v) {
                _vm.$set(_vm.review, "subtitle", $$v)
              },
              expression: "review.subtitle",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "review-image", class: { disabled: _vm.readonly } },
        [
          _c("vue-load-image", [
            _c("img", {
              staticClass: "image",
              attrs: { slot: "image", src: _vm.review.image },
              slot: "image",
            }),
            _c("img", {
              staticClass: "preloader",
              attrs: {
                slot: "preloader",
                src: require("@/assets/images/loading-ar_3-2.png"),
              },
              slot: "preloader",
            }),
            _c("img", {
              staticClass: "error",
              attrs: {
                slot: "error",
                src: require("@/assets/images/img-not-found.svg"),
              },
              slot: "error",
            }),
          ]),
          _c("FileDrop", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.readonly,
                expression: "!readonly",
              },
            ],
            attrs: { hover: true, placeholder: "Scegli foto" },
            on: {
              change: function ($event) {
                return _vm.fileChange(_vm.review, "image", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "review-pre" },
        [
          _c("textarea-autosize", {
            attrs: {
              autosize: _vm.textAreaResize,
              placeholder: "Descrizione...",
              disabled: _vm.readonly,
            },
            model: {
              value: _vm.review.pre,
              callback: function ($$v) {
                _vm.$set(_vm.review, "pre", $$v)
              },
              expression: "review.pre",
            },
          }),
        ],
        1
      ),
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "review-products",
            attrs: { tag: "div", handle: ".handle" },
            on: {
              start: function ($event) {
                _vm.drag = true
              },
              end: function ($event) {
                _vm.drag = false
              },
            },
            model: {
              value: _vm.review.products,
              callback: function ($$v) {
                _vm.$set(_vm.review, "products", $$v)
              },
              expression: "review.products",
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          _c(
            "transition-group",
            {
              attrs: {
                type: "transition",
                name: !_vm.drag ? "flip-list" : null,
              },
            },
            _vm._l(_vm.review.products, function (product, index) {
              return _c("div", { key: product.id, staticClass: "product" }, [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.readonly,
                      expression: "!readonly",
                    },
                  ],
                  staticClass: "mdi mdi-drag handle",
                }),
                product.collapse
                  ? _c("i", {
                      staticClass: "mdi mdi-chevron-right collapse-btn",
                      on: {
                        click: function ($event) {
                          return _vm.toggleCollapse(index)
                        },
                      },
                    })
                  : _vm._e(),
                !product.collapse
                  ? _c("i", {
                      staticClass: "mdi mdi-chevron-down collapse-btn",
                      on: {
                        click: function ($event) {
                          return _vm.toggleCollapse(index)
                        },
                      },
                    })
                  : _vm._e(),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.readonly,
                      expression: "!readonly",
                    },
                  ],
                  staticClass: "mdi mdi-delete-outline delete-btn",
                  on: {
                    click: function ($event) {
                      return _vm.deleteProduct(index)
                    },
                  },
                }),
                _c("div", { staticClass: "title" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: product.title,
                        expression: "product.title",
                      },
                    ],
                    attrs: {
                      placeholder: "Nome prodotto...",
                      disabled: _vm.readonly,
                    },
                    domProps: { value: product.title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(product, "title", $event.target.value)
                      },
                    },
                  }),
                ]),
                !_vm.isCollapsed(index)
                  ? _c("div", { staticClass: "flex desc-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "product-image box",
                          class: { disabled: _vm.readonly },
                        },
                        [
                          _c("vue-load-image", [
                            _c("img", {
                              staticClass: "image",
                              attrs: { slot: "image", src: product.image },
                              slot: "image",
                            }),
                            _c("img", {
                              staticClass: "preloader",
                              attrs: {
                                slot: "preloader",
                                src: require("@/assets/images/loading.png"),
                              },
                              slot: "preloader",
                            }),
                            _c("img", {
                              staticClass: "error",
                              attrs: {
                                slot: "error",
                                src: require("@/assets/images/img-not-found.svg"),
                              },
                              slot: "error",
                            }),
                          ]),
                          _c("FileDrop", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.readonly,
                                expression: "!readonly",
                              },
                            ],
                            attrs: { hover: true, placeholder: "Scegli foto" },
                            on: {
                              change: function ($event) {
                                return _vm.fileChange(product, "image", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "description box grow" },
                        [
                          _c("textarea-autosize", {
                            attrs: {
                              autosize: _vm.textAreaResize,
                              placeholder: "Descrizione prodotto...",
                              disabled: _vm.readonly,
                            },
                            model: {
                              value: product.description,
                              callback: function ($$v) {
                                _vm.$set(product, "description", $$v)
                              },
                              expression: "product.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.isCollapsed(index)
                  ? _c("div", { staticClass: "info" }, [
                      _c("div", { staticClass: "award half" }, [
                        _c("label", [_vm._v("Premio")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.award,
                              expression: "product.award",
                            },
                          ],
                          attrs: {
                            placeholder: "Premio...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.award },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "award", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "headline half" }, [
                        _c("label", [_vm._v("Titolo")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.headline,
                              expression: "product.headline",
                            },
                          ],
                          attrs: {
                            placeholder: "Titolo...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.headline },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "headline", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "summary half" }, [
                        _c("label", [_vm._v("Riepilogo")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.summary,
                              expression: "product.summary",
                            },
                          ],
                          attrs: {
                            placeholder: "Riepilogo...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.summary },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "summary", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "brand half" }, [
                        _c("label", [_vm._v("Marca")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.brand,
                              expression: "product.brand",
                            },
                          ],
                          attrs: {
                            placeholder: "Marca...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.brand },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "brand", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "link half" }, [
                        _c("label", [_vm._v("Link")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.link,
                              expression: "product.link",
                            },
                          ],
                          attrs: {
                            placeholder: "Link...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "link", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "name half" }, [
                        _c("label", [_vm._v("Nome prodotto")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.name,
                              expression: "product.name",
                            },
                          ],
                          attrs: {
                            placeholder: "Nome prodotto...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "link_ref wide" }, [
                        _c("label", [_vm._v("Link tracciato")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: product.link_ref,
                              expression: "product.link_ref",
                            },
                          ],
                          attrs: {
                            placeholder: "Link tracciato...",
                            disabled: _vm.readonly,
                          },
                          domProps: { value: product.link_ref },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(product, "link_ref", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "add-product ml-10" },
        [
          _vm.review.products.length < 20
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.readonly,
                      expression: "!readonly",
                    },
                  ],
                  attrs: { plain: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addProduct()
                    },
                  },
                },
                [_vm._v(" Aggiungi prodotto ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }