var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "room flex card-base card-shadow--small",
      class: { active: _vm.active },
      on: { click: _vm.open },
    },
    [
      _c("div", { staticClass: "room-users-count" }, [
        _vm._v(_vm._s(_vm.room.userscount || _vm.room.usersCount || 0)),
      ]),
      _c("div", { staticClass: "room-name box grow" }, [
        _vm._v(_vm._s(_vm.room.name)),
      ]),
      _vm.room.isspecial || _vm.room.special
        ? _c("div", { staticClass: "room-special" }, [
            _c("i", { staticClass: "mdi mdi-star-outline" }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "room-info",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.openRoomInfo(_vm.room._id)
            },
          },
        },
        [_c("i", { staticClass: "mdi mdi-information-outline" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }