var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.replyLoading,
          expression: "replyLoading",
        },
      ],
      staticClass: "reply-box flex column",
      class: [_vm.size, { opened: _vm.opened }],
    },
    [
      _c(
        "div",
        {
          staticClass: "template-box flex column",
          class: { opened: !!_vm.replySearch },
        },
        [
          _vm.replySearch
            ? _c(
                "div",
                { staticClass: "template-list box grow scrollable only-y" },
                _vm._l(_vm.filteredTemplates, function (t) {
                  return _c(
                    "div",
                    {
                      key: t._id,
                      staticClass: "template",
                      on: {
                        click: function ($event) {
                          return _vm.appendTemplate(t)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex header" }, [
                        _c("div", { staticClass: "box grow tags" }, [
                          _vm._v(_vm._s(t.tagsString)),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "body",
                        domProps: { innerHTML: _vm._s(t.body) },
                      }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("div", { staticClass: "template-search flex box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.replySearch,
                  expression: "replySearch",
                },
              ],
              staticClass: "box grow",
              attrs: { type: "text", placeholder: "Cerca una risposta..." },
              domProps: { value: _vm.replySearch },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.replySearch = $event.target.value
                },
              },
            }),
            _vm.replySearch
              ? _c(
                  "button",
                  {
                    staticClass: "box",
                    on: {
                      click: function ($event) {
                        _vm.replySearch = ""
                      },
                    },
                  },
                  [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "editor-box box grow scrollable only-y" },
        [
          _c("Editor", {
            ref: "editor",
            attrs: {
              html: _vm.replyHtml,
              text: _vm.replyText,
              list: true,
              table: false,
              hiding: false,
              placeholder: "Rispondi...",
            },
            on: {
              "update:html": function ($event) {
                _vm.replyHtml = $event
              },
              "update:text": function ($event) {
                _vm.replyText = $event
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "toolbar-box text-right flex align-center" }, [
        _c(
          "div",
          { staticClass: "attachments box grow" },
          _vm._l(_vm.replyAttachments, function (attc, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "block",
                on: {
                  click: function ($event) {
                    return _vm.removeAttachment(index)
                  },
                },
              },
              [
                attc.isImage
                  ? _c("div", {
                      staticClass: "icon image",
                      style: { backgroundImage: `url(${attc.content})` },
                    })
                  : _vm._e(),
                !attc.isImage
                  ? _c(
                      "div",
                      {
                        staticClass: "icon ext",
                        style: { backgroundColor: attc.color },
                      },
                      [_vm._v(" " + _vm._s(attc.ext) + " ")]
                    )
                  : _vm._e(),
                _vm._m(0, true),
              ]
            )
          }),
          0
        ),
        !_vm.disabledAttachmentUpload
          ? _c(
              "div",
              { staticClass: "box" },
              [
                _c("FileDrop", {
                  attrs: {
                    accept: "*",
                    hover: false,
                    icon: "mdi mdi-attachment",
                  },
                  on: { change: _vm.fileChange },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "box" }, [
          _c("button", { on: { click: _vm.reset } }, [_vm._v("Reset")]),
        ]),
        _c("div", { staticClass: "box" }, [
          _c(
            "button",
            { attrs: { disabled: !_vm.replyText }, on: { click: _vm.send } },
            [_vm._v("Invia")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "del-btn" }, [
      _c("i", { staticClass: "mdi mdi-close-circle-outline" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }