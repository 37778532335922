<template>
	<el-select
		class="w-full"
		:clearable="true"
		v-model="model"
		:placeholder="placeholder || 'Seleziona'"
		:multiple="true"
	>
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "StatusFilter",
	props: ["value", "placeholder"],
	data() {
		return {
			model: null,
			options: [
				{ value: "FERTILITY", label: "Fertilità" },
				{ value: "PREGNANCY", label: "Gravidanza" },
				{ value: "PREGNANCY_1", label: "Gravidanza 1° mese" },
				{ value: "PREGNANCY_2", label: "Gravidanza 2° mese" },
				{ value: "PREGNANCY_3", label: "Gravidanza 3° mese" },
				{ value: "PREGNANCY_4", label: "Gravidanza 4° mese" },
				{ value: "PREGNANCY_5", label: "Gravidanza 5° mese" },
				{ value: "PREGNANCY_6", label: "Gravidanza 6° mese" },
				{ value: "PREGNANCY_7", label: "Gravidanza 7° mese" },
				{ value: "PREGNANCY_8", label: "Gravidanza 8° mese" },
				{ value: "PREGNANCY_9", label: "Gravidanza 9° mese" },
				{ value: "POST_PARTUM", label: "Post Parto" },
				{ value: "POST_ABORTUM", label: "Post Aborto" },
				{ value: "UNKNOWN", label: "Sconosciuto" }
			]
		}
	},
	watch: {
		model(val) {
			this.$emit("update:value", val)
		}
	}
}
</script>
