<template>
    <div
        class="page-users-list column scrollable only-y"
        :class="{ flex: !isMobile, overflow: isMobile, [size]: true }"
        ref="page"
    >
        <resize-observer @notify="handleResize" />

        <div class="table-box card-base card-shadow--small box grow flex column" id="table-wrapper">
            <el-table
                :data="list"
                style="width: 100%"
                :max-height="height"
                v-loading="loading"
                v-if="ready"
                @row-click="viewItem"
            >
                <el-table-column label="Id" prop="id" :fixed="!pagination.small"></el-table-column>
                <el-table-column label="Username" min-width="160" prop="username"></el-table-column>
                <el-table-column label="Email" min-width="300" prop="email"></el-table-column>
                <el-table-column label="Nome" min-width="120" prop="name"></el-table-column>
                <el-table-column label="Cognome" min-width="120" prop="surname"></el-table-column>
                <el-table-column label="Telefono" min-width="180" prop="phone"></el-table-column>
                <el-table-column label="Ruolo" min-width="80" prop="role">
                    <template slot-scope="scope">
                        <span class="roles color" :class="scope.row.role">{{ scope.row.role }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                class="box"
                v-if="ready"
                :small="pagination.small"
                :current-page.sync="pagination.page"
                :page-sizes="pagination.sizes"
                :page-size.sync="pagination.size"
                :layout="pagination.layout"
                :total="total"
            ></el-pagination>
        </div>

        <el-drawer
            custom-class="users-list__filters-drawer"
            title="Filtri"
            :visible.sync="fitersDrawerOpened"
            :modal="true"
            :append-to-body="true"
        >
            <div class="filters-box">
                <el-input placeholder="..." v-model="id">
                    <template slot="prepend"><div class="prepend-wrapper">Id</div></template>
                </el-input>

                <el-input placeholder="..." v-model="username">
                    <template slot="prepend"><div class="prepend-wrapper">Username</div></template>
                </el-input>

                <el-input placeholder="..." v-model="email">
                    <template slot="prepend"><div class="prepend-wrapper">Email</div></template>
                </el-input>

                <el-input placeholder="..." v-model="name">
                    <template slot="prepend"><div class="prepend-wrapper">Nome</div></template>
                </el-input>

                <el-input placeholder="..." v-model="surname">
                    <template slot="prepend"><div class="prepend-wrapper">Cognome</div></template>
                </el-input>

                <el-input placeholder="..." v-model="phone">
                    <template slot="prepend"><div class="prepend-wrapper">Telefono</div></template>
                </el-input>

                <el-button plain @click="resetFilters">Cancella</el-button>
            </div>
        </el-drawer>

        <div class="drawer-picker" @click="fitersDrawerOpened = true" v-if="showDrawerPicker">
            <div class="notify-badge" v-if="isFiltered"></div>
            <div class="icon-box">
                <i class="mdi mdi-cog"></i>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

export default {
    name: "UsersListPage",
    data() {
        return {
            isMobile: false,
            width: 0,
            height: "auto",
            loading: false,
            ready: false,
            pagination: {
                page: 1,
                size: 30,
                sizes: [10, 15, 20, 30, 50, 100],
                layout: "total, ->, prev, pager, next, jumper, sizes",
                small: false
            },
            size: "large",
            list: [],
            total: 0,
            fitersDrawerOpened: false,
            id: "",
            email: "",
            name: "",
            surname: "",
            phone: "",
            username: "",
            showDrawerPicker: true
        }
    },
    computed: {
        currentPage() {
            return this.pagination.page
        },
        itemPerPage() {
            return this.pagination.size
        },
        isFiltered() {
            return !!(this.id || this.email || this.name || this.surname || this.phone || this.username)
        }
    },
    watch: {
        currentPage(val) {
            this.getData()
        },
        itemPerPage(val) {
            this.getData()
        },
        id(val) {
            this.getDataDebounce()
        },
        email(val) {
            this.getDataDebounce()
        },
        name(val) {
            this.getDataDebounce()
        },
        surname(val) {
            this.getDataDebounce()
        },
        phone(val) {
            this.getDataDebounce()
        },
        username(val) {
            this.getDataDebounce()
        }
    },
    methods: {
        resetFilters() {
            this.id = ""
            this.email = ""
            this.name = ""
            this.surname = ""
            this.phone = ""
            this.username = ""
        },
        viewItem(item) {
            this.$router.push(`users/${item.id}`).catch(err => {})
        },
        calcDims() {
            const el = document.getElementById("table-wrapper")
            if (el) {
                this.width = el.clientWidth

                if (!this.isMobile) {
                    this.height = el.clientHeight - 44
                }

                if (this.width < 480) {
                    this.pagination.small = true
                    this.pagination.layout = "prev, pager, next"
                } else if (this.width >= 480 && this.width < 700) {
                    this.pagination.small = false
                    this.pagination.layout = "prev, pager, next, ->, sizes"
                } else {
                    this.pagination.small = false
                    this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
                }

                this.ready = true

                this.calcSize()
            }
        },
        calcSize() {
            if (this.$refs.page) {
                const width = this.$refs.page.offsetWidth

                this.size = "large"
                if (width < 350) {
                    this.size = "small"
                } else if (width < 850) {
                    this.size = "medium"
                }
            }
        },
        handleResize: _.throttle(function (e) {
            setTimeout(this.calcDims, 1000)
        }, 500),
        getDataDebounce: _.debounce(function (e) {
            this.getData()
        }, 750),
        getData: _.debounce(function (e) {
            this.list = []
            this.loading = true

            const filters = {
                id: this.id,
                email: this.email,
                name: this.name,
                surname: this.surname,
                phone: this.phone,
                username: this.username
            }

            api.users
                .getAll({ pagination: this.itemPerPage, page: this.currentPage, filters })
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.list = res.body.data
                        this.total = res.body.total
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                })
        }, 200),
        closeDialog(payload) {
            this.dialogItemVisible = false
        },

        init() {
            this.getData()

            if (window.innerWidth <= 768) this.isMobile = true
        }
    },
    created() {
        this.init()
        this.showDrawerPicker = true
    },
    mounted() {
        //ie fix
        if (!window.Number.parseInt) window.Number.parseInt = parseInt

        this.calcDims()
    },
    beforeRouteLeave(to, from, next) {
        this.showDrawerPicker = false
        next()
    },
    components: {}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-users-list {
    user-select: none;

    &.overflow {
        overflow: auto;
    }

    .table-box {
        overflow: hidden;

        &.hidden {
            visibility: hidden;
        }

        :deep {
            .el-table {
                font-size: 16px;
            }
            .el-table th.is-leaf,
            .el-table td {
                border-bottom: 1px solid rgba(235, 238, 245, 0.3);
            }
            .el-table th {
                background-color: lighten(darken($background-color, 1%), 2%);
            }
            .el-table-column--selection .cell {
                padding-left: 10px;
                padding-right: 10px;
            }

            .el-table__row {
                cursor: pointer;
            }

            input[type="checkbox"].styled,
            .checkbox {
                background: white;
                -webkit-appearance: none;
                width: 16px;
                height: 16px;
                border: 3px solid transparentize($text-color, 0.8);
                border-radius: 50%;
                box-sizing: border-box;
                outline: none;
                display: block;

                &:checked,
                &.checked {
                    background: transparentize($color-primary, 0.2);
                    border-color: rgba(255, 255, 255, 0.4);
                }
            }

            .el-pagination {
                padding: 8px;

                .el-pagination__sizes {
                    margin: 0;

                    .el-input {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .clickable {
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
    }

    .sel-string {
        .sel {
            background: transparentize($text-color, 0.8);
            border-radius: 5px;
            //text-transform: uppercase;
        }
        strong.sel {
            color: #3f5377;
            font-weight: 800;
            background: transparent;
            border-radius: 0;
        }
    }

    .drawer-picker {
        width: 60px;
        height: 60px;
        padding: 15px;
        position: fixed;
        right: -45px;
        top: 50%;
        transition: all 0.5s;
        overflow: hidden;
        z-index: 1000;
        cursor: pointer;

        .notify-badge {
            background: $color-primary;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 5px solid $background-color;
            position: absolute;
            top: 0;
            transform: translate(2px, 12px);
        }

        .icon-box {
            background: lighten($background-color, 20%);
            border-radius: 50%;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08), 0px 2px 7px 0px rgba(0, 0, 0, 0.02);
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 40px;

            i::before {
                //opacity: 0.3;
                animation: spin 5s infinite linear;
                color: $color-primary;
            }
        }

        &:hover {
            width: 100px;
        }

        animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s; /* don't forget to set a duration! */
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }
}
</style>

<style lang="scss">
.users-list__filters-drawer {
    user-select: none;

    .filters-box {
        padding: 20px;

        .el-input {
            margin-bottom: 20px;

            .prepend-wrapper {
                min-width: 70px;
            }
        }
    }
}
</style>
