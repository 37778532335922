var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "footer flex align-center justify-space-between",
      class: { above: _vm.position === "above" },
    },
    [
      _c("div", [
        _vm._v(" iMamma "),
        _c("span", { staticClass: "accent" }, [_vm._v("Panel")]),
        _c("small", { staticClass: "ml-8 o-050" }, [
          _vm._v("v" + _vm._s(_vm.version)),
        ]),
      ]),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }