var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.loaded,
          expression: "!loaded",
        },
      ],
      ref: "box",
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _vm.list && _vm.list.length
        ? _c(
            "div",
            { staticClass: "main-list scrollable only-y", class: [_vm.size] },
            _vm._l(_vm.list, function (item) {
              return _c("div", { key: item.id, staticClass: "item flex" }, [
                _c(
                  "div",
                  { staticClass: "flex column propic" },
                  [
                    _c("vue-load-image", [
                      _c("img", {
                        staticClass: "image",
                        attrs: { slot: "image", src: item.profilePic },
                        slot: "image",
                      }),
                      _c("img", {
                        staticClass: "preloader",
                        attrs: {
                          slot: "preloader",
                          src: require("@/assets/images/loading.png"),
                        },
                        slot: "preloader",
                      }),
                      _c("img", {
                        staticClass: "error",
                        attrs: {
                          slot: "error",
                          src: require("@/assets/images/person.png"),
                        },
                        slot: "error",
                      }),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex column box grow" }, [
                  _c(
                    "div",
                    { staticClass: "item-from box flex justify-space-between" },
                    [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          _vm._s(item.user.name) +
                            " " +
                            _vm._s(item.user.surname)
                        ),
                      ]),
                      _c("div", { staticClass: "item-date box" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dayjs")(
                                item.created,
                                "format",
                                "DD/MM/YYYY [alle] HH:mm"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("QnaActions", {
                        attrs: { item: item, itemType: "question" },
                        on: { restored: _vm.init, deleted: _vm.init },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "item-date box" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dayjs")(
                            item.created,
                            "format",
                            "DD/MM/YYYY [alle] HH:mm"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  item.content
                    ? _c("div", { staticClass: "item-content box flex" }, [
                        _vm._v(" " + _vm._s(item.content) + " "),
                      ])
                    : _vm._e(),
                  item.image
                    ? _c("div", { staticClass: "item-image box flex" }, [
                        _c("img", { attrs: { src: item.image } }),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      !_vm.list || !_vm.list.length
        ? _c("div", [_vm._v("Non sono presenti domande sospese")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }