var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("transition", { attrs: { name: "fade-zoom" } }, [
        _vm.splashScreen
          ? _c("div", { staticClass: "splash-screen" }, [
              _c("div", { staticClass: "wrap" }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: "/logo_anim.svg",
                    loading: "lazy",
                    alt: "logo",
                  },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }