var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template card-base card-shadow--small" }, [
    _c("div", { staticClass: "flex header" }, [
      _c("div", { staticClass: "box grow tags" }, [
        _vm._v(_vm._s(_vm.template.tagsString)),
      ]),
      _c("div", { staticClass: "box buttons" }, [
        _c(
          "button",
          {
            staticClass: "primary",
            on: {
              click: function ($event) {
                return _vm.updateTemplate(_vm.template)
              },
            },
          },
          [_c("i", { staticClass: "mdi mdi-pencil-outline" })]
        ),
        _c(
          "button",
          {
            staticClass: "danger",
            on: {
              click: function ($event) {
                return _vm.deleteTemplate(_vm.template._id)
              },
            },
          },
          [_c("i", { staticClass: "mdi mdi-delete-outline" })]
        ),
      ]),
    ]),
    _c("div", {
      staticClass: "body",
      domProps: { innerHTML: _vm._s(_vm.template.body) },
    }),
    _c("div", { staticClass: "date" }, [
      _c("span", [_vm._v(" ultima modifica: ")]),
      _c("strong", [
        _vm._v(
          " " +
            _vm._s(
              _vm._f("dayjs")(
                _vm.template.updated_at,
                "format",
                "DD MMM YYYY, HH:mm"
              )
            ) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }