var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-page flex" }, [
    _vm._m(0),
    _c("div", { staticClass: "scrollable align-vertical side-box box-right" }, [
      _c("div", { staticClass: "align-vertical-middle wrapper" }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "form-box card-base card-shadow--extraLarge",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.login()
              },
            },
          },
          [
            _c("img", {
              staticClass: "image-logo",
              attrs: {
                src: require("@/assets/images/logo_h_mini.png"),
                alt: "logo-app",
              },
            }),
            _c("float-label", { staticClass: "styled" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                attrs: {
                  type: "email",
                  name: "email",
                  placeholder: "Email",
                  autocomplete: "email",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
            ]),
            _c("float-label", { staticClass: "styled" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                attrs: {
                  type: "password",
                  name: "password",
                  placeholder: "Password",
                  autocomplete: "password",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
            _c("float-label", { staticClass: "styled" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.otp,
                    expression: "otp",
                  },
                ],
                attrs: { type: "text", name: "otp", placeholder: "OTP Code" },
                domProps: { value: _vm.otp },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.otp = $event.target.value
                  },
                },
              }),
            ]),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "flex text-center center pt-30 pb-10" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn tada animated",
                    attrs: {
                      plain: "",
                      size: "small",
                      type: "primary",
                      "native-type": "submit",
                      disabled: !_vm.isValid,
                    },
                  },
                  [_vm._v(" ENTRA ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "scrollable align-vertical side-box box-left" },
      [_c("div", { staticClass: "align-vertical-middle wrapper text-center" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hint text-right" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://obpanel.imamma.net",
            rel: "noopener noreferrer nofollow",
            target: "_blank",
          },
        },
        [_vm._v(" come ottenere il codice OTP ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }