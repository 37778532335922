<template>
    <div class="page-dashboard scrollable only-y">
        <resize-observer @notify="__resizeHandler" />

        <div class="mt-10">
            <el-row :gutter="30">
                <el-col
                    :xs="24"
                    :sm="can_ViewReviewsCountWidget ? 12 : 8"
                    :md="can_ViewReviewsCountWidget ? 12 : 8"
                    :lg="can_ViewReviewsCountWidget ? 6 : 8"
                    :xl="can_ViewReviewsCountWidget ? 6 : 8"
                >
                    <div class="widget p-20 mb-10 clickable" @click="goTo('/users')" v-loading="!loadedInfo">
                        <div class="text-uppercase flex">
                            <div class="icon-box accent-text">
                                <i class="mdi mdi-account-outline"></i>
                            </div>
                            <div class="box grow">
                                <div class="title">Utenti</div>
                                <div class="value">{{ info.users_count }}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col
                    :xs="24"
                    :sm="can_ViewReviewsCountWidget ? 12 : 8"
                    :md="can_ViewReviewsCountWidget ? 12 : 8"
                    :lg="can_ViewReviewsCountWidget ? 6 : 8"
                    :xl="can_ViewReviewsCountWidget ? 6 : 8"
                >
                    <div
                        class="widget p-20 mb-10 clickable"
                        @click="goTo('/chat/reports?type=reported')"
                        v-loading="!loadedReports"
                    >
                        <div class="text-uppercase flex">
                            <div class="icon-box accent-text">
                                <i class="mdi mdi-message-alert-outline"></i>
                            </div>
                            <div class="box grow">
                                <div class="title">Segnalazioni</div>
                                <div class="value">{{ info.reportedCount }}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col
                    :xs="24"
                    :sm="can_ViewReviewsCountWidget ? 12 : 8"
                    :md="can_ViewReviewsCountWidget ? 12 : 8"
                    :lg="can_ViewReviewsCountWidget ? 6 : 8"
                    :xl="can_ViewReviewsCountWidget ? 6 : 8"
                >
                    <div
                        class="widget p-20 mb-10 clickable"
                        @click="goTo('/chat/reports?type=suspicious')"
                        v-loading="!loadedReports"
                    >
                        <div class="text-uppercase flex">
                            <div class="icon-box accent-text">
                                <i class="mdi mdi-comment-search-outline"></i>
                            </div>
                            <div class="box grow">
                                <div class="title">Sospetti</div>
                                <div class="value">{{ info.suspiciousCount }}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-if="can_ViewReviewsCountWidget">
                    <div
                        class="widget p-20 mb-10 clickable"
                        @click="goTo('/reviews/published')"
                        v-loading="!loadedInfo"
                    >
                        <div class="text-uppercase flex">
                            <div class="icon-box accent-text">
                                <i class="mdi mdi-file-check-outline"></i>
                            </div>
                            <div class="box grow">
                                <div class="title">Recensioni</div>
                                <div class="value">{{ info.reviews }}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <h3 class="mt-40 mb-0">
                    Segnalazioni in evidenza
                    <small v-if="pinnedCount" class="o-050">({{ pinnedCount }})</small>
                    <span v-if="pinnedCount"> - </span>
                    <span class="text-clickable" v-if="pinnedCount" @click="goTo('/chat/reports?type=pinned')">
                        vedi tutte
                    </span>
                </h3>
                <div class="widget p-20 card-base card-alt mt-10 card-shadow--small">
                    <ReportsPinned :count.sync="pinnedCount"></ReportsPinned>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <h3 class="mt-40 mb-0" v-if="!hideOnTest">
                    Ultime Richieste non lette
                    <small v-if="emailsCount" class="o-050">({{ emailsCount }})</small>
                    <span v-if="emailsCount"> - </span>
                    <span class="text-clickable" v-if="emailsCount" @click="goTo('/support/requests?type=notSeen')">
                        vedi tutte
                    </span>
                </h3>
                <div class="widget p-20 card-base card-alt mt-10 card-shadow--small" v-if="!hideOnTest">
                    <EmailsNotSeen :count.sync="emailsCount"></EmailsNotSeen>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <h3 class="mt-40 mb-0">
                    Domande segnalate
                    <small v-if="questionsReportedCount" class="o-050">({{ questionsReportedCount }})</small>
                    <span v-if="questionsReportedCount"> - </span>
                    <span
                        class="text-clickable"
                        v-if="questionsReportedCount"
                        @click="goTo('/qna/questions?type=reported')"
                    >
                        vedi tutte
                    </span>
                </h3>
                <div class="widget p-20 card-base card-alt mt-10 card-shadow--small">
                    <QuestionsReported :count.sync="questionsReportedCount"></QuestionsReported>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <h3 class="mt-40 mb-0">
                    Risposte segnalate
                    <small v-if="answersReportedCount" class="o-050">({{ answersReportedCount }})</small>
                    <span v-if="answersReportedCount"> - </span>
                    <span
                        class="text-clickable"
                        v-if="answersReportedCount"
                        @click="goTo('/qna/answers?type=reported')"
                    >
                        vedi tutte
                    </span>
                </h3>
                <div class="widget p-20 card-base card-alt mt-10 card-shadow--small">
                    <AnswersReported :count.sync="answersReportedCount"></AnswersReported>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <h3 class="mt-40 mb-0">Utenti online in chat</h3>
                <div class="widget card-base card-alt mt-10 card-shadow--small">
                    <ChatChart></ChatChart>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import utils from "@/utils"
import ChatChart from "@/components/Widgets/ChatChart"
import ReportsPinned from "@/components/Widgets/ReportsPinned"
import EmailsNotSeen from "@/components/Widgets/EmailsNotSeen"
import QuestionsReported from "@/components/Widgets/QuestionsReported"
import AnswersReported from "@/components/Widgets/AnswersReported"

export default {
    name: "Dashboard",
    data() {
        return {
            resized: false,
            loadedInfo: false,
            loadedReports: false,
            info: {},
            emailsCount: 0,
            pinnedCount: 0,
            questionsReportedCount: 0,
            answersReportedCount: 0,
            hideOnTest: utils.isEnvTest()
        }
    },
    computed: {
        can_ViewReviewsCountWidget() {
            return this.$store.getters.can_ViewReviewsCountWidget
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            if (this.resized) {
            }
            this.resized = true
        }, 700),
        init() {
            this.getStats()
            this.getReportsStats()
        },
        getStats() {
            api.common
                .getStats()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.info.users_count = _.get(res, "body.users") || 0
                        this.info.reviews = _.get(res, "body.reviews") || 0
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadedInfo = true
                })
        },
        getReportsStats() {
            api.common
                .getReportsStats()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.info.reportedCount = _.get(res, "body.reportedCount") || 0
                        this.info.suspiciousCount = _.get(res, "body.suspiciousCount") || 0
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadedReports = true
                })
        },

        goTo(path) {
            this.$router.push(path).catch(err => {})
        }
    },
    created() {},
    mounted() {
        this.init()
    },
    beforeDestroy() {},
    components: {
        ChatChart,
        ReportsPinned,
        QuestionsReported,
        AnswersReported,
        EmailsNotSeen
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/card-shadow";

.page-dashboard {
    user-select: none;

    .widget {
        @extend .card-base;
        @extend .card-shadow--small;
        transition: all 0.2s;

        & > div {
            & > div {
                overflow: hidden;
            }
        }

        .icon-box {
            font-size: 30px;
            line-height: 55px;
            text-align: center;
            width: 55px;
            max-width: 55px;
            min-width: 55px;
            margin-right: 20px;
            background: rgba($color-primary, 0.05);
            border-radius: 4px;
        }

        .title {
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .value {
            font-size: 24px;
            font-weight: bold;
        }

        &.clickable {
            cursor: pointer;

            &:hover {
                @extend .card-shadow--large;
            }
        }
    }

    .text-clickable {
        cursor: pointer;
        color: $color-primary;

        &:hover {
            opacity: 0.7;
        }
    }
}
</style>
