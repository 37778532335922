var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pregnancy card-base card-shadow--small",
      class: {
        ended: this.data.ended,
        "in-progress": !this.data.ended,
        [this.data.ended_reason]: true,
      },
    },
    [
      _vm.data
        ? _c("div", { staticClass: "content flex" }, [
            _c("div", { staticClass: "label" }, [
              !this.data.ended
                ? _c("div", { staticClass: "text" }, [_vm._v("in corso")])
                : _vm._e(),
              this.data.ended
                ? _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(this.endedReason)),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "float-info" }, [
              _vm._v("#" + _vm._s(this.data.id)),
            ]),
            _c("div", { staticClass: "col box grow flex column" }, [
              _c(
                "div",
                { staticClass: "row mt-5" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.type,
                        placement: "top-start",
                      },
                    },
                    [
                      _c("div", { staticClass: "type-icon" }, [
                        _vm.gnrFirst === "M"
                          ? _c("i", { staticClass: "mdi mdi-human-male" })
                          : _vm._e(),
                        _vm.gnrFirst === "F"
                          ? _c("i", { staticClass: "mdi mdi-human-female" })
                          : _vm._e(),
                        _vm.gnrSecond === "M"
                          ? _c("i", { staticClass: "mdi mdi-human-male" })
                          : _vm._e(),
                        _vm.gnrSecond === "F"
                          ? _c("i", { staticClass: "mdi mdi-human-female" })
                          : _vm._e(),
                        !_vm.gnrFirst &&
                        !_vm.gnrSecond &&
                        _vm.data.type !== "singola"
                          ? _c("i", { staticClass: "mdi mdi-account o-050" })
                          : _vm._e(),
                        !_vm.gnrFirst && !_vm.gnrSecond
                          ? _c("i", {
                              staticClass: "mdi mdi-account-question o-050",
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm.names
                    ? _c("div", { staticClass: "names" }, [
                        _vm._v(_vm._s(_vm.names)),
                      ])
                    : _vm._e(),
                  !_vm.names
                    ? _c("div", { staticClass: "names o-040" }, [
                        _vm._v(_vm._s("nome non scelto")),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row mt-5 ml-5" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.dateType,
                        placement: "bottom",
                      },
                    },
                    [
                      _c("div", { staticClass: "start-date" }, [
                        _vm._v(_vm._s(_vm.startDate)),
                      ]),
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.endDateTip,
                        placement: "bottom",
                      },
                    },
                    [
                      _vm.endDate
                        ? _c("div", { staticClass: "end-date" }, [
                            _c("span", { staticClass: "mh-10" }, [
                              _vm._v(" - "),
                            ]),
                            _vm._v(_vm._s(_vm.endDate)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "row flex align-center mt-10 ml-5" }, [
                _c(
                  "div",
                  { staticClass: "badge-box flex" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          title: "Calci",
                          width: "200",
                          trigger: "click",
                          "popper-class": "pregnancy-popover",
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "items-list" },
                          [
                            _vm._l(_vm.data.kicks, function (item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "item" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dayjs")(
                                          item.date,
                                          "format",
                                          "DD MMM YYYY [alle] HH:mm"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            !_vm.data.kicks.length
                              ? _c("li", { staticClass: "item" }, [
                                  _vm._v("nessun calcio registrato"),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "badge clickable",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("div", { staticClass: "left" }, [
                              _c("i", { staticClass: "mdi mdi-foot-print" }),
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _vm._v(_vm._s(_vm.data.kicks.length)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          title: "Contrazioni",
                          width: "300",
                          trigger: "click",
                          "popper-class": "pregnancy-popover",
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "items-list" },
                          [
                            _vm._l(_vm.data.contractions, function (item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "item" },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.duration(item.duration)) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "o-070 ml-10" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dayjs")(
                                            item.date,
                                            "format",
                                            "DD MMM YYYY [alle] HH:mm"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            !_vm.data.contractions.length
                              ? _c("li", { staticClass: "item" }, [
                                  _vm._v("nessuna contrazione registrata"),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "badge clickable",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("div", { staticClass: "left" }, [
                              _c("i", {
                                staticClass: "mdi mdi-alert-octagram-outline",
                              }),
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _vm._v(_vm._s(_vm.data.contractions.length)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buttons-box" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          title: "Esami",
                          width: "350",
                          trigger: "click",
                          "popper-class": "pregnancy-popover",
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "items-list" },
                          [
                            _vm._l(_vm.data.exams, function (item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "item" },
                                [
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(item.exam.title) + " "),
                                  ]),
                                  _c("br"),
                                  _c("span", { staticClass: "o-070" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dayjs")(
                                            item.updated_at,
                                            "format",
                                            "DD MMM YYYY [alle] HH:mm"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            !_vm.data.exams.length
                              ? _c("li", { staticClass: "item" }, [
                                  _vm._v("nessun esame effettuato"),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn clickable ml-4",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-clipboard-text-outline",
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          title: "Peso",
                          width: "250",
                          trigger: "click",
                          "popper-class": "pregnancy-popover",
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "items-list" },
                          [
                            _vm._l(_vm.data.weights, function (item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "item" },
                                [
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(item.weight) + " g "),
                                  ]),
                                  _c("span", { staticClass: "o-070 ml-10" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("dayjs")(
                                            item.date,
                                            "format",
                                            "DD MMM YYYY [alle] HH:mm"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            !_vm.data.weights.length
                              ? _c("li", { staticClass: "item" }, [
                                  _vm._v("nessun peso registrato"),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn clickable ml-16",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [_c("i", { staticClass: "mdi mdi-scale-balance" })]
                        ),
                      ]
                    ),
                    _vm.can_ViewPregnancyDetails
                      ? _c(
                          "div",
                          {
                            staticClass: "btn clickable ml-16",
                            on: {
                              click: function ($event) {
                                _vm.dialogItemVisible = true
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-information-outline",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Dettaglio gravidanza",
            visible: _vm.dialogItemVisible,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogItemVisible = $event
            },
          },
        },
        [
          _c("print-object", {
            attrs: {
              "printable-object": this.data,
              "initial-collapsed": [
                "kicks",
                "contractions",
                "exams",
                "weights",
              ],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }