var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cloud-item card-base card-shadow--small" }, [
    _c("div", { staticClass: "date" }, [
      _c("strong", { staticClass: "text-capitalized" }, [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "DD MMMM"))),
      ]),
      _c("span", { staticClass: "ml-5" }, [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "YYYY"))),
      ]),
      _c("span", { staticClass: "ml-5" }, [_vm._v("alle")]),
      _c("strong", { staticClass: "text-capitalized ml-5" }, [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "HH:mm"))),
      ]),
    ]),
    _c("div", { staticClass: "info" }, [
      _vm.type === "bath"
        ? _c("div", [
            _c("strong", { staticClass: "text-capitalized" }, [
              _vm._v(_vm._s(_vm.bathType)),
            ]),
          ])
        : _vm._e(),
      _vm.type === "biberon"
        ? _c("div", [_c("strong", [_vm._v(_vm._s(_vm.data.amount) + " ml")])])
        : _vm._e(),
      _vm.type === "breastfeeding"
        ? _c("div", [
            _c("span", [_vm._v("Seno ")]),
            _c("strong", [_vm._v(_vm._s(_vm.breast))]),
            _c("span", [_vm._v(" per ")]),
            _c("strong", [_vm._v(_vm._s(_vm.breastDuration))]),
          ])
        : _vm._e(),
      _vm.type === "diaper"
        ? _c("div", [
            _c("strong", { staticClass: "text-capitalized" }, [
              _vm._v(_vm._s(_vm.diaperType)),
            ]),
          ])
        : _vm._e(),
      _vm.type === "meal"
        ? _c("div", [
            _c("strong", { staticClass: "text-capitalized" }, [
              _vm._v(_vm._s(_vm.mealType)),
            ]),
            _c("span", [_vm._v(", ")]),
            _c("strong", { staticClass: "text-capitalized" }, [
              _vm._v(_vm._s(_vm.mealCategory)),
            ]),
          ])
        : _vm._e(),
      _vm.type === "sleep"
        ? _c("div", [
            _c("span", [_vm._v("fino alle ")]),
            _c("strong", [
              _vm._v(
                _vm._s(_vm._f("dayjs")(_vm.data.date_end, "format", "HH:mm"))
              ),
            ]),
            _c("span", [_vm._v(" per ")]),
            _c("strong", [_vm._v(_vm._s(_vm.sleepDuration))]),
          ])
        : _vm._e(),
      _vm.type === "teeth"
        ? _c("div", [
            _c("span", [_vm._v("Arcata ")]),
            _c("strong", [_vm._v(_vm._s(_vm.teethType))]),
            _c("span", [_vm._v(", ")]),
            _c("strong", [_vm._v(_vm._s(_vm.teethPosition))]),
          ])
        : _vm._e(),
    ]),
    _vm.data.notes
      ? _c("div", { staticClass: "notes" }, [
          _vm._v(" " + _vm._s(_vm.data.notes) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }