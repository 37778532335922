<template>
    <div v-loading="!loadedChat">
        <div class="chat-chart pl-5 pr-10 pt-20" v-show="chat.online && chat.online.length">
            <div id="chart-line2">
                <apexchart type="area" height="365" ref="chart" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div id="chart-line">
                <apexchart
                    type="area"
                    height="130"
                    ref="map"
                    :options="chartOptionsLine"
                    :series="seriesLine"
                    v-if="loadedChat"
                ></apexchart>
            </div>
        </div>
        <div v-if="!chat.online || !chat.online.length" class="p-20">Non sono ancora presenti dati</div>

        <el-dialog
            :visible.sync="dialogRoomsDataVisible"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            width="550px"
            custom-class="rooms-data-dialog"
        >
            <div slot="title" class="pt-8">
                Utenti connessi per stanza giorno
                <strong>{{ currentRoomsData.date | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}</strong>
            </div>
            <div>
                <div class="fs-18">
                    Totale utenti connessi: <strong class="accent-text">{{ currentRoomsData.total }}</strong>
                </div>
                <div class="rooms-list mt-20">
                    <div class="mv-6 fs-16" v-for="room in currentRoomsData.rooms" :key="room._id">
                        <span class="text-capitalized">{{ room.roomType }}</span>
                        <span class="mh-10">/</span>
                        <strong class="break-word">
                            {{ room.name }}: <span class="accent-text">{{ room.online }}</span>
                        </strong>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import VueApexCharts from "vue-apexcharts"
import it from "apexcharts/dist/locales/it.json"

export default {
    name: "ChatChart",
    data() {
        return {
            loadedChat: false,
            chat: {},
            dialogRoomsDataVisible: false,
            currentRoomsData: {},

            series: [
                {
                    name: "Online",
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    id: "area-datetime",
                    defaultLocale: "it",
                    locales: [it],
                    type: "area",
                    //height: 365,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: [
                                {
                                    icon: "<span class='large'>1 Giorno</span><span class='small'>1G</span>",
                                    //index: 4,
                                    title: "1 Giorno",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("one_day")
                                    }
                                },
                                {
                                    icon: "<span class='large'>1 Settimana</span><span class='small'>1S</span>",
                                    //index: 4,
                                    title: "1 Settimana",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("one_week")
                                    }
                                },
                                {
                                    icon: "<span class='large'>1 Mese</span><span class='small'>1M</span>",
                                    //index: 4,
                                    title: "1 Mese",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("one_month")
                                    }
                                }
                                /*{
									icon: "<span class='large'>6 Mesi</span><span class='small'>6M</span>",
									//index: 4,
									title: "6 Mesi",
									class: "custom-icon hide-mobile",
									click: (chart, options, e) => {
										this.updateData("six_months")
									}
								},
                                {
                                    icon: "<span class='large'>Tutto</span><span class='small'>Tutto</span>",
                                    //index: 4,
                                    title: "Tutto",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("all")
                                    }
                                }*/
                            ]
                        }
                    },
                    events: {
                        markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
                            this.dialogRoomsDataVisible = true
                            this.currentRoomsData = this.chat.online[dataPointIndex]
                        }
                    }
                },
                labels: ["Online"],
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: "hollow"
                },
                yaxis: {
                    labels: {
                        formatter: val => parseInt(val)
                    },
                    forceNiceScale: true,
                    tickAmount: 5,
                    min: 0,
                    max: 0
                },
                xaxis: {
                    type: "datetime",
                    //min: new Date("01 Mar 2012").getTime(),
                    //tickAmount: 6
                    labels: {
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {
                        format: "dd MMM yyyy @ HH:mm"
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
                noData: {
                    text: "Caricamento..."
                }
            },

            seriesLine: [
                {
                    data: []
                }
            ],

            chartOptionsLine: {
                chart: {
                    id: "chart1",
                    height: 130,
                    type: "area",
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    brush: {
                        target: "area-datetime",
                        enabled: true
                    },
                    selection: {
                        enabled: true
                        /*xaxis: {
                            min: new Date("19 Jun 2017").getTime(),
                            max: new Date("14 Aug 2021").getTime()
                        }*/
                    }
                },
                colors: ["#008FFB"],
                fill: {
                    type: "gradient",
                    gradient: {
                        opacityFrom: 0.91,
                        opacityTo: 0.1
                    }
                },
                xaxis: {
                    type: "datetime",
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    forceNiceScale: false,
                    tickAmount: 1
                }
            }
        }
    },
    methods: {
        init() {
            this.getChatStats()
        },
        getChatStats() {
            api.common
                .getChatStats()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const online = _.sortBy(_.get(res, "body.online") || [], obj => obj.date, "desc")
                        this.chat = { online }

                        const dataTot = _.map(online, d => [this.$dayjs(d.date).valueOf(), d.total])
                        this.series = [
                            {
                                name: "Online",
                                data: dataTot
                            }
                        ]
                        this.seriesLine = [
                            {
                                data: dataTot
                            }
                        ]

                        const max = _.max(online.map(d => d.total))

                        setTimeout(() => {
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.chartOptions.yaxis.max = max
                                    this.$set(this.chartOptions, "yaxis.max", max)

                                    if (this.$refs.chart) {
                                        this.$refs.chart.updateOptions(
                                            {
                                                yaxis: {
                                                    max
                                                }
                                            },
                                            true,
                                            true,
                                            true
                                        )
                                        this.$refs.chart.refresh()
                                    }
                                    if (this.$refs.map) {
                                        this.$refs.map.refresh()
                                    }
                                }, 100)
                            })
                        }, 100)

                        setTimeout(() => {
                            this.$nextTick(() => {
                                this.updateData("one_week")
                                setTimeout(() => {
                                    this.$nextTick(() => {
                                        this.loadedChat = true
                                    })
                                }, 500)
                            })
                        }, 1000)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        updateData(timeline) {
            if (this.$refs.chart) {
                const firstTime = this.series[0].data[0][0]
                const lastTime = this.series[0].data[this.series[0].data.length - 1][0]

                let time = firstTime

                switch (timeline) {
                    case "one_day":
                        time = this.$dayjs(lastTime).subtract(1, "day").valueOf()
                        break
                    case "one_week":
                        time = this.$dayjs(lastTime).subtract(1, "week").valueOf()
                        break
                    case "one_month":
                        time = this.$dayjs(lastTime).subtract(1, "month").valueOf()
                        break
                    case "six_months":
                        time = this.$dayjs(lastTime).subtract(6, "month").valueOf()
                        break
                    case "all":
                    default:
                        time = firstTime
                        break
                }
                this.$refs.chart.zoomX(time > firstTime ? time : firstTime, lastTime)
            }
        }
    },
    created() {},
    mounted() {
        this.init()
    },
    beforeDestroy() {
        if (this.$refs.chart) {
            this.$refs.chart.destroy()
        }
        if (this.$refs.map) {
            this.$refs.map.destroy()
        }
    },
    components: {
        apexchart: VueApexCharts
    }
}
</script>

<style lang="scss" scoped>
.chat-chart {
    :deep {
        .apexcharts-toolbar {
            max-width: inherit;

            .custom-icon {
                width: auto;
                padding: 0 8px;
                display: inline-block;
                background: #e8edf1;
                font-weight: bold;
                margin-left: 10px;
                font-size: 12px;
                line-height: 22px;
                border-radius: 4px;

                &:hover {
                    color: #000;
                }

                .small {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .chat-chart {
        :deep {
            .apexcharts-toolbar {
                .custom-icon {
                    .small {
                        display: inline;
                    }
                    .large {
                        display: none;
                    }
                }
                .apexcharts-element-hidden {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .chat-chart {
        :deep {
            .apexcharts-toolbar {
                .custom-icon {
                    &.hide-mobile {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
