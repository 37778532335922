<template>
    <div class="user-cloud" v-loading="loading">
        <div v-if="cloud">
            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/pregnancy.svg" /> Gravidanze
                    <small class="o-070">({{ cloud.pregnancies.length }})</small>
                </div>
                <div class="list pregnancy-list" v-if="cloud.pregnancies.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Pregnancy
                            v-for="pregnancy in cloud.pregnancies"
                            :key="pregnancy.id"
                            :data="pregnancy"
                            class="mr-20 mb-10"
                        ></Pregnancy>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessuna gravidanza registrata</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/ciclo.svg" /> Cicli mestruali
                    <small class="o-070">({{ cloud.periods.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.periods.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Period
                            v-for="period in cloud.periods"
                            :key="period.id"
                            :data="period"
                            :flow-duration="user.flow_duration"
                            class="mr-20"
                        ></Period>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun ciclo mestruale registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/sesso.svg" /> Relazioni sessuali
                    <small class="o-070">({{ cloud.sexualrelations.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.sexualrelations.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <SexualRelation
                            v-for="sexualrelation in cloud.sexualrelations"
                            :key="sexualrelation.id"
                            :data="sexualrelation"
                            class="mr-20"
                        ></SexualRelation>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessuna relazione sessuale registrata</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/pressione.svg" /> Pressione sanguigna
                    <small class="o-070">({{ cloud.bloodpressures.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.bloodpressures.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <BloodPressure
                            v-for="bloodpressure in cloud.bloodpressures"
                            :key="bloodpressure.id"
                            :data="bloodpressure"
                            class="mr-20"
                        ></BloodPressure>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessuna pressione sanguigna registrata</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/umore.svg" /> Umori
                    <small class="o-070">({{ cloud.moods.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.moods.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Mood
                            v-for="mood in cloud.moods"
                            :key="mood.id"
                            :data="mood"
                            :list="cloud.moodList"
                            class="mr-20"
                        ></Mood>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun umore registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/idratazione.svg" /> Idratazione
                    <small class="o-070">({{ cloud.hydrations.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.hydrations.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Hydration
                            v-for="hydration in cloud.hydrations"
                            :key="hydration.id"
                            :data="hydration"
                            class="mr-20"
                        ></Hydration>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessuna idratazione registrata</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/evento.svg" /> Eventi
                    <small class="o-070">({{ cloud.events.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.events.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Event v-for="event in cloud.events" :key="event.id" :data="event" class="mr-20"></Event>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun evento registrato</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/note.svg" /> Note
                    <small class="o-070">({{ cloud.notes.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.notes.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Note v-for="note in cloud.notes" :key="note.id" :data="note" class="mr-20"></Note>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessuna nota registrata</div>
            </div>

            <div class="section">
                <div class="header flex align-center">
                    <img src="../../assets/images/tools-pregnancy-icons/peso.svg" /> Peso corporeo
                    <small class="o-070">({{ cloud.weights.length }})</small>
                </div>
                <div class="list scrollable only-x" v-if="cloud.weights.length">
                    <XyzTransitionGroup class="" appear xyz="fade small appear-stagger">
                        <Weight v-for="weight in cloud.weights" :key="weight.id" :data="weight" class="mr-20"></Weight>
                    </XyzTransitionGroup>
                </div>
                <div v-else>Nessun peso corporeo registrato</div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import Pregnancy from "@/components/User/UserCloud/Pregnancy"
import Period from "@/components/User/UserCloud/Period"
import SexualRelation from "@/components/User/UserCloud/SexualRelation"
import BloodPressure from "@/components/User/UserCloud/BloodPressure"
import Mood from "@/components/User/UserCloud/Mood"
import Hydration from "@/components/User/UserCloud/Hydration"
import Event from "@/components/User/UserCloud/Event"
import Note from "@/components/User/UserCloud/Note"
import Weight from "@/components/User/UserCloud/Weight"

export default {
    name: "UserCloud",
    props: ["id", "flowDuration"],
    data() {
        return {
            cloud: null,
            user: {},
            loading: true
        }
    },
    computed: {},
    watch: {},
    methods: {
        getCloud() {
            api.users
                .getCloud(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.cloud = res.body

                        if (this.cloud.pregnancies?.length) {
                            for (const p of this.cloud.pregnancies) {
                                p.kicks = _.filter(this.cloud.kicks, k => k.pregnancy_id === p.id)
                                p.contractions = _.filter(this.cloud.contractions, c => c.pregnancy_id === p.id)
                                p.exams = _.filter(this.cloud.exams, e => e.pregnancy_id === p.id && e.done)
                                p.weights = _.filter(this.cloud.weights, w => w.pregnancy_id === p.id)
                            }
                        }

                        if (this.cloud.periods?.length) {
                            this.cloud.periods = _.filter(this.cloud.periods, p => p.fake === 0).reverse()
                        }

                        if (this.cloud.sexualrelations?.length) {
                            this.cloud.sexualrelations.reverse()
                        }

                        if (this.cloud.hydrations?.length) {
                            this.cloud.hydrations.reverse()
                        }

                        if (this.cloud.events?.length) {
                            this.cloud.events.reverse()
                        }

                        if (this.cloud.notes?.length) {
                            this.cloud.notes.reverse()
                        }

                        if (this.cloud.bloodpressures?.length) {
                            this.cloud.bloodpressures.reverse()
                        }

                        if (this.cloud.weights?.length) {
                            this.cloud.weights.reverse()
                        }

                        if (this.cloud.moods?.length) {
                            this.cloud.moods = _.filter(this.cloud.moods, m => m.mood_ids).reverse()
                        }
                    }
                    this.loading = false
                    this.$emit("loaded")
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                    this.$emit("error")
                })
        },
        getInfo() {
            api.users
                .getInfo(this.id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.user = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    },
    mounted() {
        if (this.id) {
            this.getCloud()
        }

        if (this.flowDuration === undefined) {
            this.getInfo()
        } else {
            this.user.flow_duration = this.flowDuration
        }
    },
    components: { Pregnancy, Period, SexualRelation, BloodPressure, Mood, Hydration, Event, Note, Weight }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.user-cloud {
    width: 100%;
    max-width: 100%;

    .section {
        margin-bottom: 30px;
        max-width: 100%;
        background: rgba(255, 255, 255, 0.7);
        padding: 20px;
        border-radius: 8px;

        .header {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 10px;

            img {
                width: 30px;
                margin-right: 15px;
            }

            small {
                margin-left: 5px;
            }
        }

        .list {
            width: 100%;
            max-width: 100%;

            &.pregnancy-list {
                overflow: hidden;
            }

            &.scrollable.only-x {
                padding: 0 20px;
                padding-bottom: 10px;
                margin: 0 -20px;
                white-space: nowrap;
            }
        }
    }
}
</style>
