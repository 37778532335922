var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "msg-box" }, [
    _c("div", { staticClass: "msg-content flex column" }, [
      _c("div", { staticClass: "msg-info box" }, [
        _c(
          "div",
          {
            staticClass: "msg-author",
            style: { color: _vm.msg.author.color },
            on: {
              click: function ($event) {
                return _vm.openUser(_vm.msg.author)
              },
            },
          },
          [_vm._v(" @" + _vm._s(_vm.msg.author.username) + " ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "msg-body-img box grow" },
        [
          _c("vue-load-image", [
            _c("img", {
              directives: [
                {
                  name: "img",
                  rawName: "v-img",
                  value: { src: _vm.msg.image, title: _vm.msg.body },
                  expression: "{ src: msg.image, title: msg.body }",
                },
              ],
              staticClass: "image",
              attrs: { slot: "image", src: _vm.msg.imageThumb },
              slot: "image",
            }),
            _c("img", {
              staticClass: "preloader",
              attrs: {
                slot: "preloader",
                src: require("@/assets/images/loading.png"),
              },
              slot: "preloader",
            }),
            _c("img", {
              staticClass: "error",
              attrs: {
                slot: "error",
                src: require("@/assets/images/img-not-found.svg"),
              },
              slot: "error",
            }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "msg-icons box" },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                content: _vm
                  .$dayjs(_vm.msg.date_sent)
                  .format("DD MMMM YYYY [alle] HH:mm"),
              },
            },
            [
              _c("div", { staticClass: "msg-time info-text" }, [
                _c("i", { staticClass: "mdi mdi-history" }),
              ]),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "Testo: " + _vm.msg.body,
                placement: "bottom-end",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.msg.body,
                      expression: "msg.body",
                    },
                  ],
                  staticClass: "has-body info-text",
                },
                [_c("i", { staticClass: "mdi mdi-message-text-outline" })]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "Vedi il messaggio in chat",
                placement: "bottom-end",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn-actions info-text",
                  on: { click: _vm.openChat },
                },
                [_c("i", { staticClass: "mdi mdi-comment-eye-outline" })]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Segnala", placement: "bottom-end" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.msg.reported,
                      expression: "!msg.reported",
                    },
                  ],
                  staticClass: "btn-actions info-text",
                  on: { click: _vm.report },
                },
                [_c("i", { staticClass: "mdi mdi-alert-outline" })]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Già segnalato", placement: "bottom-end" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.msg.reported,
                      expression: "msg.reported",
                    },
                  ],
                  staticClass: "is-reported warning-text",
                },
                [_c("i", { staticClass: "mdi mdi-alert-outline" })]
              ),
            ]
          ),
          _vm.can_DeleteChatMessage
            ? _c(
                "el-tooltip",
                { attrs: { content: "Elimina", placement: "bottom-end" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "btn-actions danger-text",
                      on: { click: _vm.deleteMsg },
                    },
                    [_c("i", { staticClass: "mdi mdi-delete-outline" })]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }