<template>
    <div ref="box" class="question-single-page flex column">
        <resize-observer @notify="__resizeHandler" />
        <div class="buttons-box">
            <div @click="goBack" class="go-back-btn mr-30"><i class="mdi mdi-arrow-left"></i> indietro</div>
        </div>
        <div class="list-wrap box grow flex column" v-loading="loading">
            <div class="list-box box grow scrollable only-y" ref="list">
                <span v-if="!question && !loading">Domanda non trovata</span>
                <Question @deleted="deleted" v-if="question" :question="question" :size="size" :show-goto="false">
                    <AnswerList
                        class="mt-20"
                        :nested="true"
                        v-if="question.comments_count"
                        :full-pagination="true"
                        :qnaId="question.id"
                        :targetId="targetId"
                        @found="scrollToAnswer"
                    ></AnswerList>
                </Question>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import Question from "@/components/Qna/Question"
import AnswerList from "@/components/Qna/AnswerList"

export default {
    name: "QuestionSinglePage",
    components: {
        Question,
        AnswerList
    },
    data() {
        return {
            question: null,
            loading: true,
            size: "large"
        }
    },
    computed: {
        targetId() {
            return this.$route.params.answer ? parseInt(this.$route.params.answer) : 0
        }
    },
    mounted() {
        this.getQuestion()
        this.calcDim()
    },
    methods: {
        getQuestion() {
            const qId = this.$route.params.id

            api.qna
                .getOne(qId)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.question = res.body
                    } else {
                        this.question = null
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleted() {
            this.goBack()
        },
        goBack() {
            if (window.history.state !== null) {
                this.$router.go(-1)
            } else {
                this.$router.replace("/qna/questions").catch(err => {})
            }
        },
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.box) {
                const width = this.$refs.box.offsetWidth
                this.fullPagination = true
                this.size = "large"
                if (width < 768) {
                    this.size = "small"
                    this.fullPagination = false
                } else if (width < 960) {
                    this.size = "medium"
                }
            }
        },
        scrollToAnswer() {
            // scroll to answer
            const el = document.querySelector(`[data-answer-id='${this.targetId}']`)
            if (el && this.$refs.list) {
                this.$refs.list.scrollTo({ top: el.offsetTop })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.question-single-page {
    overflow: hidden;

    .list-wrap {
        overflow: hidden;
        padding: 0 20px;
        margin: 0 -20px;

        .list-box {
            padding: 0 20px;
            margin: 0 -20px;
        }
    }

    .go-back-btn {
        cursor: pointer;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    .buttons-box {
        margin-bottom: 20px;
    }
}
</style>
