import { render, staticRenderFns } from "./RequestsList.vue?vue&type=template&id=2f523afc&scoped=true&"
import script from "./RequestsList.vue?vue&type=script&lang=js&"
export * from "./RequestsList.vue?vue&type=script&lang=js&"
import style0 from "./RequestsList.vue?vue&type=style&index=0&id=2f523afc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f523afc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/myfamilyplace/web/imamma-community-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f523afc')) {
      api.createRecord('2f523afc', component.options)
    } else {
      api.reload('2f523afc', component.options)
    }
    module.hot.accept("./RequestsList.vue?vue&type=template&id=2f523afc&scoped=true&", function () {
      api.rerender('2f523afc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/support/RequestsList.vue"
export default component.exports