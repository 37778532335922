<template>
	<div class="msg-box flex align-flex-end">
		<div class="msg-content card-base card-shadow--small" v-if="msg">
			<div class="msg-info flex justify-space-between">
				<div class="box flex grow">
					<div class="msg-author" @click="openUser(msg.userId)">@{{ msg.username }}</div>
					<div class="msg-time">{{ msg.date | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}</div>
				</div>
				<div class="box flex actions">
					<div class="action" @click="copy(msg)">
						<i class="mdi mdi-content-copy"></i>
					</div>
					<div class="action" @click="info(msg)">
						<i class="mdi mdi-information-outline"></i>
					</div>
				</div>
			</div>
			<div class="msg-body" v-if="msg.text" v-html="msg.text"></div>
			<div class="msg-body-img" v-if="msg.image">
				<img :src="msg.image" />
			</div>
			<div class="msg-body-url" v-if="msg.url">
				<a target="_blank" :href="msg.url"><i class="mdi mdi-open-in-new"></i> {{ msg.url }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import { openUserDialog } from "@/common"

export default {
	name: "MsgInstitutional",
	props: ["msg"],
	data() {
		return {}
	},
	methods: {
		copy(msg) {
			this.$emit("copy", msg)
		},
		info(msg) {
			this.$emit("info", msg)
		},
		openUser(id) {
			openUserDialog({ id })
		}
	},
	created() {},
	mounted() {},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.msg-box {
	max-width: 100%;
	margin-bottom: 20px;

	.msg-content {
		width: 100%;
		text-align: left;

		.msg-body {
			padding: 10px;
			font-size: 16px;
		}
		.msg-body-img {
			padding: 10px;

			img {
				border-radius: 6px;
				display: block;
				max-width: 300px;
				width: 100%;
			}
		}
		.msg-body-url {
			padding: 10px;
		}

		.msg-info {
			padding: 10px;
			padding-bottom: 0;
			justify-content: flex-start;
			font-size: 14px;

			.msg-author {
				font-weight: bold;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
			.msg-time {
				opacity: 0.5;
				margin-left: 10px;
			}
			.actions {
				.action {
					font-size: 20px;
					margin-left: 14px;
					color: $color-primary;
					cursor: pointer;
					transition: all 0.2s;

					&:hover {
						text-shadow: 0px 2px 3px rgba($color-primary, 0.3);
					}
				}
			}
		}
	}
}
</style>