var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-configuration scrollable only-y" }, [
    _c("div", { staticClass: "section" }, [_c("StopWords")], 1),
    _c("div", { staticClass: "section" }, [_c("BotToken")], 1),
    _c("div", { staticClass: "section" }, [_c("ThreadLabels")], 1),
    _c("div", { staticClass: "section" }, [_c("SupportSign")], 1),
    _c("div", { staticClass: "text-right" }, [
      _c("small", { staticClass: "o-050" }, [
        _vm._v("v" + _vm._s(_vm.version)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }