<template>
	<div class="sexualrelation card-base card-shadow--small">
		<div class="flex">
			<div class="box flex column">
				<div class="mb-8">
					<strong class="text-capitalized">{{ data.day | dayjs("format", "DD MMMM") }}</strong>
					<span class="ml-5">{{ data.day | dayjs("format", "YYYY") }}</span>
				</div>
				<div :class="{ 'o-050': !protection }">
					{{ protect }}
				</div>
			</div>
			<div class="box flex column ml-30">
				<div class="mb-8">
					Desiderio: <strong>{{ data.desire ? "Si" : "No" }}</strong>
				</div>
				<div>
					Masturbazione: <strong>{{ data.masturbation ? "Si" : "No" }}</strong>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const SexualRelation = function (
	id,
	user_id,
	day,
	_protected,
	_unprotected,
	desire,
	masturbation,
	created_at,
	updated_at
) {
	this.id = id
	this.user_id = user_id
	this.day = day
	this.protected = _protected
	this.unprotected = _unprotected
	this.desire = desire
	this.masturbation = masturbation
	this.created_at = created_at
	this.updated_at = updated_at
}

export default {
	name: "SexualRelation",
	props: {
		data: [SexualRelation, Object]
	},
	data() {
		return {}
	},
	computed: {
		protect() {
			const arr = []
			if (this.data.protected) arr.push("Protetto")
			if (this.data.unprotected) arr.push("Non protetto")

			return !arr.length ? "Nessun rapporto" : arr.join(" / ")
		},
		protection() {
			return this.data.protected || this.data.unprotected
		}
	},
	watch: {},
	methods: {},
	mounted() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.sexualrelation {
	display: inline-block;
	padding: 10px 20px;
}
</style>
