<template>
	<div class="thread-card flex card-base card-shadow--small" data-card="thread" @click="open(thread._id)">
		<div class="new" v-if="!thread.isSeen"></div>

		<div class="type" v-if="thread.type && thread.type === 'ticket'">
			<i class="mdi mdi-ticket-confirmation-outline"></i>
		</div>
		<div class="type" v-if="!thread.type || thread.type === 'email'">
			<i class="mdi mdi-email-outline"></i>
		</div>

		<div class="flex column pl-20">
			<div class="initials" :style="{ backgroundColor: thread.color }">{{ thread.initials }}</div>
			<div v-if="thread.hasAttachments" class="attachments">
				<i class="mdi mdi-attachment"></i>
			</div>
		</div>
		<div class="flex column box grow">
			<div class="thread-card-date box">
				{{ thread.date | dayjs("format", "DD MMM YYYY HH:mm") }}
			</div>
			<div class="thread-card-from break-word box">
				{{ thread.from.name || thread.from.address }}
			</div>
			<div class="thread-card-subject break-word box flex">
				{{ thread.subject }}
			</div>
			<div class="thread-card-labels">
				<span v-for="label in thread.labels" :key="label.name" :style="{ backgroundColor: label.color }">
					{{ label.name }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ThreadCard",
	props: ["thread"],
	data() {
		return {}
	},
	computed: {},
	watch: {},
	methods: {
		open(id) {
			this.$emit("open", id)
		}
	},
	mounted() {},
	created() {},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/card-shadow";

.thread-card {
	cursor: pointer;
	margin-bottom: 16px;
	position: relative;
	overflow: initial !important;
	min-height: 90px;
	user-select: none;

	&:hover {
		transition: all ease 0.25s;
	}

	.initials {
		--size: 40px;
		margin-right: 0;
		margin-top: 26px;
		width: var(--size);
		height: var(--size);
		color: #fff;
		font-weight: bold;
		font-size: 14px;
		box-sizing: border-box;
		padding-top: 1px;
		border-radius: 50%;
		text-align: center;
		line-height: var(--size);
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
	}

	.new {
		background: $color-primary;
		width: 14px;
		height: 14px;
		position: absolute;
		border-radius: 50%;
		left: -7px;
		top: 7px;
		box-shadow: 0px 0px 5px 0px $color-primary;
	}

	.type {
		color: $color-info;
		position: absolute;
		right: 9px;
		bottom: 4px;
	}

	.attachments {
		text-align: center;
		font-size: 24px;
		opacity: 0.7;
		margin-top: 6px;
		margin-bottom: 4px;
	}

	.thread-card-date {
		text-align: right;
		font-size: 12px;
		padding-top: 10px;
		padding-right: 10px;
		opacity: 0.7;
	}
	.thread-card-from {
		font-weight: bold;
		padding: 0 20px;
		padding-bottom: 4px;
	}
	.thread-card-subject {
		padding: 0px 20px;
		font-size: 14px;
		padding-bottom: 20px;
	}

	.thread-card-labels {
		font-size: 12px;
		font-weight: bold;
		padding: 0px 15px;
		margin-top: -10px;
		padding-bottom: 20px;

		span {
			color: rgba(0, 0, 0, 0.5);
			padding: 2px 6px;
			border-radius: 4px;
			margin: 5px;
		}
	}

	&:hover {
		@extend .card-shadow--medium;
	}

	&.active {
		background-color: $color-primary;
		color: #fff;

		.new {
			background: #fff;
			box-shadow: 0px 0px 5px 0px $color-primary;
		}
		.type {
			color: #fff;
		}
	}
}
</style>
