var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "page",
      staticClass: "page-requests-list flex",
      class: [_vm.size, { opened: !!_vm.threadId }],
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c(
        "div",
        { staticClass: "box flex column", attrs: { id: "sidebar" } },
        [
          _c("ThreadSidebarHeader", {
            attrs: { count: _vm.count },
            on: {
              "update:count": function ($event) {
                _vm.count = $event
              },
            },
          }),
          _c("ThreadSidebarFilters", {
            attrs: {
              filters: _vm.filters,
              tooltipHidden: _vm.size !== "large",
            },
          }),
          _c(
            "div",
            {
              ref: "threadList",
              staticClass: "thread-list box grow scrollable only-y",
              on: { scroll: _vm.listScrollHandler },
            },
            [
              _vm._l(_vm.filtered, function (thread) {
                return _c("ThreadCard", {
                  key: thread._id,
                  class: { active: thread._id === _vm.threadId },
                  attrs: { thread: thread },
                  on: {
                    open: function ($event) {
                      return _vm.openThread(thread._id)
                    },
                  },
                })
              }),
              _c(
                "div",
                { staticClass: "loading-box" },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  }),
                  !_vm.loading
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.getNewPage()
                            },
                          },
                        },
                        [_vm._v(" Carica altre ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      !_vm.threadId
        ? _c(
            "div",
            {
              staticClass:
                "box grow flex card-base card-shadow--small align-center text-center",
              attrs: { id: "thread-box" },
            },
            [
              _c("div", { staticClass: "box grow text-center fs-20 p-30" }, [
                _vm._v(
                  "Clicca su una richiesta per visualizzare la conversazione"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.threadId && _vm.currentThread
        ? _c(
            "div",
            {
              staticClass: "box grow flex column card-base card-shadow--small",
              attrs: { id: "thread-box" },
            },
            [
              _c("ThreadHeader", {
                staticClass: "box",
                attrs: {
                  isFirst: _vm.isFirst,
                  index: _vm.index,
                  count: _vm.count,
                  size: _vm.size,
                },
                on: {
                  swipeLeft: _vm.swipeLeft,
                  swipeRight: _vm.swipeRight,
                  openPrev: _vm.openPrev,
                  openNext: _vm.openNext,
                  reset: function ($event) {
                    _vm.threadId = null
                  },
                },
              }),
              _vm.can_PerformRequestActions
                ? _c("ThreadToolbar", {
                    ref: "threadToolbar",
                    class: { shadow: _vm.childrenScrolled },
                    attrs: {
                      size: _vm.size,
                      tooltipHidden: _vm.size !== "large",
                      thread: _vm.currentThread,
                    },
                    on: {
                      swipeLeft: _vm.swipeLeft,
                      swipeRight: _vm.swipeRight,
                      updateThread: _vm.updateThread,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "touch",
                      rawName: "v-touch:swipe.left",
                      value: _vm.swipeLeft,
                      expression: "swipeLeft",
                      arg: "swipe",
                      modifiers: { left: true },
                    },
                    {
                      name: "touch",
                      rawName: "v-touch:swipe.right",
                      value: _vm.swipeRight,
                      expression: "swipeRight",
                      arg: "swipe",
                      modifiers: { right: true },
                    },
                  ],
                  ref: "children",
                  staticClass: "children box grow scrollable only-y",
                  on: { scroll: _vm.childrenScrollHandler },
                },
                [
                  _c("div", { staticClass: "header bb" }, [
                    _vm._v(
                      _vm._s(_vm.currentThread.subject || "(Senza oggetto)")
                    ),
                  ]),
                  _vm._l(_vm.currentThread.children, function (child) {
                    return _c("ThreadChild", {
                      key: child.uid,
                      class: { "is-admin": child.admin },
                      attrs: { child: child, size: _vm.size },
                    })
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "loading-box",
                      class: { close: !_vm.loadingThread },
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingThread,
                            expression: "loadingThread",
                          },
                        ],
                      }),
                    ]
                  ),
                  _vm.can_ReplyToRequest
                    ? _c("div", { staticClass: "reply-btn-box" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.replyBoxOpened = true
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "mdi mdi-reply" }),
                            _c("span", [_vm._v(" Rispondi ")]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _vm.can_ReplyToRequest
                ? _c("ThreadReplyBox", {
                    ref: "reply",
                    staticClass: "bt",
                    attrs: {
                      opened: _vm.replyBoxOpened,
                      thread: _vm.currentThread,
                      size: _vm.size,
                    },
                    on: {
                      "update:opened": function ($event) {
                        _vm.replyBoxOpened = $event
                      },
                      sent: _vm.sent,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }