// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/loading.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user-info__map-frame {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n}\n.user-info__history-drawer {\n  display: flex;\n  --flex-grid: 12;\n  flex-direction: column;\n}\n.user-info__history-drawer .el-drawer__body {\n    flex: 1 0;\n    height: 100%;\n}\n.user-info__history-drawer .user-info__history-box {\n    height: 100%;\n    padding: 10px;\n}\n.user-info__history-drawer .user-info__history-box .title {\n      color: #72767b;\n}\n.user-info__history-drawer .user-info__history-box .scrollable {\n      padding: 10px;\n}\n.user-info__history-drawer .user-info__history-box .el-timeline {\n      padding-left: 0px;\n}\n.user-info__history-drawer .user-info__history-box .user-info__history-card .type {\n      font-weight: bold;\n      font-size: 16px;\n      margin-bottom: 4px;\n      text-transform: uppercase;\n}\n.user-info__history-drawer .user-info__history-box .user-info__history-card .type.unmute {\n        text-decoration: line-through;\n        -webkit-text-decoration-color: #ec2020;\n                text-decoration-color: #ec2020;\n}\n.user-info__history-drawer .user-info__history-box .user-info__history-card .desc {\n      margin-bottom: 14px;\n}\n.user-info__history-drawer .user-info__history-box .user-info__history-card .sender .box.grow {\n      margin-left: 10px;\n}\n.user-info__history-drawer .user-info__history-box .user-info__history-card .clickable {\n      cursor: pointer;\n      text-decoration: underline;\n      font-weight: bold;\n}\n.user-info__history-drawer .user-info__history-box .user-info__history-card .removed {\n      background: #f5f5f5;\n      padding: 10px 16px;\n      box-sizing: border-box;\n      border-radius: 8px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
