var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "child", class: [_vm.size] },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c("div", { staticClass: "header flex justify-space-between" }, [
        _c("div", { staticClass: "user flex" }, [
          _c("div", { staticClass: "flex column" }, [
            _c(
              "div",
              {
                staticClass: "initials",
                style: { backgroundColor: _vm.child.color },
              },
              [_vm._v(" " + _vm._s(_vm.child.initials) + " ")]
            ),
          ]),
          _c("div", { staticClass: "flex column pl-20 break-word" }, [
            _c("div", [
              _vm.child && _vm.child.from
                ? _c("strong", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.child.from.name || _vm.child.from.address) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.child.userData && _vm.child.userData.id
                ? _c(
                    "span",
                    {
                      staticClass: "clickable username",
                      on: {
                        click: function ($event) {
                          return _vm.openUser(_vm.child.userData)
                        },
                      },
                    },
                    [_vm._v(" @" + _vm._s(_vm.child.userData.username) + " ")]
                  )
                : _vm._e(),
            ]),
            _vm.child.from && _vm.child.from.name && _vm.child.from.address
              ? _c("span", { staticClass: "address" }, [
                  _vm._v(" " + _vm._s(_vm.child.from.address) + " "),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "datetime" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("dayjs")(
                    _vm.child.date,
                    "format",
                    "DD MMMM YYYY HH:mm"
                  )
                ) +
                " "
            ),
          ]),
          _vm.child.attachments && _vm.child.attachments.length
            ? _c("div", { staticClass: "has-attachments" }, [
                _c("strong", [_vm._v(_vm._s(_vm.child.attachments.length))]),
                _c("i", { staticClass: "mdi mdi-attachment" }),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.body
        ? _c("div", { staticClass: "content" }, [
            _c("iframe", {
              ref: "iframe",
              style: {
                height: _vm.iframeHeight + "px",
              },
            }),
          ])
        : _vm._e(),
      _vm.child.attachments && _vm.child.attachments.length
        ? _c(
            "div",
            { staticClass: "attachments" },
            _vm._l(_vm.child.attachments, function (attc) {
              return _c(
                "a",
                {
                  key: attc.checksum,
                  staticClass: "block",
                  attrs: { href: attc.url, target: "_blank" },
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "preview box" }, [
                      attc.isImage
                        ? _c("div", {
                            staticClass: "icon image",
                            style: { backgroundImage: `url(${attc.url})` },
                          })
                        : _vm._e(),
                      !attc.isImage
                        ? _c(
                            "div",
                            {
                              staticClass: "icon ext",
                              style: { backgroundColor: attc.color },
                            },
                            [_vm._v(" " + _vm._s(attc.ext) + " ")]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "info box grow flex column justify-space-between",
                      },
                      [
                        _c("div", { staticClass: "filename" }, [
                          _vm._v(" " + _vm._s(attc.filename) + " "),
                        ]),
                        _c("div", { staticClass: "size" }, [
                          _vm._v(_vm._s(attc.size)),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }