<template>
	<div class="block flex column card-base card-shadow--small" v-loading="loading">
		<div class="flex">
			<div class="label box">Etichette supporto</div>
			<div class="value box grow labels-list">
				<div class="label" v-for="label in threadLabels" :key="label.name" :style="{ background: label.color }">
					<button @click="delThreadLabel(label.name)" v-if="can_ManageThreadLabels">
						<i class="mdi mdi-close"></i>
					</button>
					<span>
						{{ label.name }}
					</span>
				</div>
				<div class="label input" v-if="can_ManageThreadLabels">
					<div class="flex">
						<input v-model="labelName" placeholder="Label..." />
						<v-swatches
							v-model="labelColor"
							:swatches="swatches"
							:row-length="4"
							:max-height="200"
							:swatch-size="24"
							:shapes="'circles'"
							:trigger-style="{ width: '18px', height: '18px', border: '1px solid #ccc' }"
						></v-swatches>
						<button @click="addThreadLabel" :disabled="!labelName.trim() || !labelColor.trim()">
							Salva
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import VSwatches from "vue-swatches"

export default {
	name: "ThreadLabels",
	data() {
		return {
			loading: false,
			threadLabels: [],
			labelName: "",
			labelColor: "",
			swatches: [
				["#ff020d", "#ff6566", "#feadad", "#ffd4d5"],
				["#fa8a00", "#feb65b", "#ffd6a5", "#ffe9cf"],
				["#f6ff09", "#f9ff6c", "#fdffb7", "#feffda"],
				["#33ff0c", "#88ff70", "#cafebf", "#e2ffdb"],
				["#00e0f6", "#55f1ff", "#9af6ff", "#c8faff"],
				["#015ff9", "#5798ff", "#a0c3fe", "#ccdfff"],
				["#2b05ff", "#7f6afe", "#bcb2ff", "#dcd7ff"],
				["#ff11ff", "#ff7bff", "#fec6fe", "#ffe2fe"],
				["#bfbfbf", "#d9d9d9", "#e9e9e9", "#f7f7f7"]
			]
		}
	},
	computed: {
		can_ManageThreadLabels() {
			return this.$store.getters.can_ManageThreadLabels
		}
	},
	methods: {
		getThreadLabels() {
			this.loading = true

			api.common
				.getThreadLabels()
				.then(res => {
					if (res.status === 200 && res.body) {
						this.threadLabels = res.body
					}
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.loading = false
				})
		},
		addThreadLabel() {
			const name = _.trim(this.labelName)
			const color = this.labelColor
			if (
				name &&
				!_.includes(
					_.map(this.threadLabels, l => l.name),
					name
				)
			) {
				api.common
					.addThreadLabel(name, color)
					.then(res => {
						if (res.status === 200 && res.body) {
							this.getThreadLabels()
							this.labelName = ""
							this.labelColor = ""
						}
					})
					.catch(err => {
						console.error(err)
					})
			}
		},
		delThreadLabel(name) {
			api.common
				.delThreadLabel(name)
				.then(res => {
					if (res.status === 200 && res.body) {
						this.getThreadLabels()
					}
				})
				.catch(err => {
					console.error(err)
				})
		}
	},
	created() {},
	mounted() {
		this.getThreadLabels()
	},
	beforeDestroy() {},
	components: { VSwatches }
}
</script>

<style lang="scss" scoped>
.block {
	padding: 20px;
	border-radius: 8px;

	.label {
		margin-right: 20px;
	}

	overflow: inherit;

	.labels-list {
		.label {
			font-size: 16px;
			display: inline-block;
			padding: 6px;
			border-radius: 6px;
			font-weight: bold;
			color: rgba(0, 0, 0, 0.4);
			margin-right: 10px;
			margin-bottom: 10px;
			min-height: 35px;
			box-sizing: border-box;

			span {
				padding: 0 4px;
			}

			button,
			input {
				font-weight: bold;
				border: none;
				outline: none;
				border-radius: 4px;
				background: rgba(0, 0, 0, 0.1);
				color: rgba(0, 0, 0, 0.4);
				padding: 2px 4px;
			}
			button {
				cursor: pointer;

				&:disabled {
					cursor: not-allowed;
					opacity: 0.6;
				}
			}

			&.input {
				background: #f3f3f3;

				& > .flex {
					position: relative;
					top: 1px;
				}

				input {
					font-size: 16px;
					padding: 0;
					background: transparent;
					padding-left: 6px;
					max-width: 140px;
				}

				.vue-swatches {
					padding: 0 10px;
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

@media (max-width: 1000px) {
	.block {
		flex-direction: column;
		-webkit-box-orient: vertical;

		.label {
			margin-bottom: 20px;
		}
	}
}
</style>
