var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "day-hour flex",
    },
    [
      _vm.hourSelected
        ? _c("div", { staticClass: "day box grow" }, [
            _c("div", { staticClass: "subtitle" }, [_vm._v("Dati giorno")]),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm._f("dayjs")(
                    _vm.hourSelected.date,
                    "format",
                    "dddd D MMMM"
                  )
                )
              ),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "content flex" }, [
              _c(
                "div",
                { staticClass: "chart box grow" },
                [
                  _c("apexchart", {
                    ref: "chartOnline",
                    attrs: {
                      type: "line",
                      height: "90",
                      options: _vm.chartOnline,
                      series: _vm.seriesOnline,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "info box grow flex justify-space-between" },
                [
                  _c("div", { staticClass: "max flex column" }, [
                    _c("span", [_vm._v("Max")]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$sugar.Number.format(_vm.day.online.max))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "avg flex column" }, [
                    _c("span", [_vm._v("Avg")]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$sugar.Number.format(_vm.day.online.avg))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "min flex column" }, [
                    _c("span", [_vm._v("Min")]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$sugar.Number.format(_vm.day.online.min))
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "content flex" }, [
              _c(
                "div",
                { staticClass: "chart box grow" },
                [
                  _c("apexchart", {
                    ref: "chartMessages",
                    attrs: {
                      type: "line",
                      height: "90",
                      options: _vm.chartMessages,
                      series: _vm.seriesMessages,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "info box grow flex justify-space-between" },
                [
                  _c("div", { staticClass: "messages flex column" }, [
                    _c("span", [_vm._v("Testuali")]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$sugar.Number.format(_vm.day.messages.total))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "images flex column" }, [
                    _c("span", [_vm._v("Immagini")]),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$sugar.Number.format(_vm.day.messages.images)
                          )
                      ),
                      _c("br"),
                      _c("small", [
                        _vm._v("(" + _vm._s(_vm.day.messages.imgPer) + "%)"),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.hourSelected
        ? _c("div", { staticClass: "hour box grow" }, [
            _c("div", { staticClass: "subtitle" }, [_vm._v("Dettaglio ora")]),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm._f("dayjs")(_vm.hourSelected.date, "format", "HH:mm")
                )
              ),
            ]),
            _c("div", { staticClass: "line online" }),
            _c("div", { staticClass: "content flex" }, [
              _c(
                "div",
                { staticClass: "info box grow flex justify-space-between" },
                [
                  _c("div", { staticClass: "online flex column" }, [
                    _c("span", [_vm._v("Online")]),
                    _c("strong", [_vm._v(_vm._s(_vm.hour.online.total))]),
                  ]),
                  _c("div", { staticClass: "box grow" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(" circa "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$sugar.Number.format(_vm.hour.messages.users)
                          )
                        ),
                      ]),
                      _vm._v(" utenti hanno scritto messaggi "),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "line messages" }),
            _c("div", { staticClass: "content flex" }, [
              _c(
                "div",
                { staticClass: "info box grow flex justify-space-between" },
                [
                  _c("div", { staticClass: "messages flex column" }, [
                    _c("span", [_vm._v("Testuali")]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$sugar.Number.format(_vm.hour.messages.total)
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "images flex column" }, [
                    _c("span", [_vm._v("Immagini")]),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$sugar.Number.format(_vm.hour.messages.images)
                          )
                      ),
                      _c("br"),
                      _c("small", [
                        _vm._v("(" + _vm._s(_vm.hour.messages.imgPer) + "%)"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "box grow" }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line online" }, [
      _c("span", [_vm._v("Utenti Online")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line messages" }, [
      _c("span", [_vm._v("Messaggi")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }