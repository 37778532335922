var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "page",
      staticClass: "page-chat-reports flex column",
      class: _vm.size,
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c("div", { staticClass: "flex box report-toolbar" }, [
        _c(
          "div",
          {
            staticClass:
              "card-base card-shadow--small box grow flex align-center card-stats",
          },
          [
            _c("div", { staticClass: "count box" }, [
              _vm._v(_vm._s(_vm.count)),
            ]),
            _c("div", { staticClass: "content box grow" }, [
              _c(
                "div",
                { staticClass: "header" },
                [
                  _c("span", [_vm._v("MESSAGGI DA CONTROLLARE")]),
                  _vm.can_PerformReportsActions
                    ? _c(
                        "el-popover",
                        {
                          ref: "clearDialog",
                          attrs: { width: "270", trigger: "click" },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "break-word" }, [
                              _vm._v(
                                " Stai per segnare come non sospetti tutti i messaggi di tutte le stanze "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mv-10" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "warning", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.clearDialog.showPopper = false
                                        _vm.removeBulk(_vm.reports, false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Elimina tutti "),
                                    _c("strong", [_vm._v("TRANNE")]),
                                    _vm._v(" quelli in "),
                                    _c("strong", [_vm._v("evidenza")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mv-10" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "warning", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.clearDialog.showPopper = false
                                        _vm.removeBulk(_vm.reports, true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Elimina tutti, "),
                                    _c("strong", [_vm._v("ANCHE")]),
                                    _vm._v(" quelli in "),
                                    _c("strong", [_vm._v("evidenza")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.clearDialog.showPopper = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Annulla ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "pure-tooltip",
                                  rawName: "v-pure-tooltip.down-right",
                                  value: "Elimina tutti i report",
                                  expression: "'Elimina tutti i report'",
                                  modifiers: { "down-right": true },
                                },
                              ],
                              staticClass: "ml-10 clickable",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [_c("i", { staticClass: "mdi mdi-broom" })]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "info flex" }, [
                _c("div", { staticClass: "box grow flex" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "box grow flex column" }, [
                    _c("span", [_vm._v("Segnalati")]),
                    _c("strong", [_vm._v(_vm._s(_vm.reportedCount))]),
                  ]),
                ]),
                _c("div", { staticClass: "box grow flex" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "box grow flex column" }, [
                    _c("span", [_vm._v("Sospetti")]),
                    _c("strong", [_vm._v(" " + _vm._s(_vm.suspiciousCount))]),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "card-base card-shadow--small box grow flex align-center",
          },
          [
            _c(
              "el-select",
              {
                attrs: {
                  filterable: "",
                  placeholder: "Seleziona utente",
                  clearable: "",
                  "popper-class": "chat-reports-select",
                },
                model: {
                  value: _vm.userSelected,
                  callback: function ($$v) {
                    _vm.userSelected = $$v
                  },
                  expression: "userSelected",
                },
              },
              _vm._l(_vm.users, function (user) {
                return _c(
                  "el-option",
                  {
                    key: user.username,
                    staticClass: "users-option",
                    attrs: { value: user.username },
                  },
                  [
                    _c("strong", [_vm._v("(" + _vm._s(user.count) + ")")]),
                    _c("span", [_vm._v("@" + _vm._s(user.username))]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "card-base card-shadow--small box grow flex align-center",
          },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "Scegli tipologia", clearable: "" },
                model: {
                  value: _vm.typeSelected,
                  callback: function ($$v) {
                    _vm.typeSelected = $$v
                  },
                  expression: "typeSelected",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "Segnalazioni", value: "reported" },
                }),
                _c("el-option", {
                  attrs: { label: "Sospetti", value: "suspicious" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "card-base card-shadow--small box grow flex align-center",
          },
          [
            _c("el-switch", {
              attrs: { "active-text": "Solo in evidenza" },
              model: {
                value: _vm.onlyPinned,
                callback: function ($$v) {
                  _vm.onlyPinned = $$v
                },
                expression: "onlyPinned",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          ref: "roomsList",
          staticClass: "rooms-list scrollable only-y box grow",
        },
        _vm._l(_vm.rooms, function (room) {
          return _c(
            "div",
            {
              key: room.id,
              staticClass: "room-box",
              class: { collapsed: _vm.isRoomCollapsed(room.id) },
            },
            [
              _c(
                "div",
                { staticClass: "room-header flex" },
                [
                  _c("div", { staticClass: "room-report-count" }, [
                    _vm._v(_vm._s(room.reports.length)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "room-name",
                      on: {
                        click: function ($event) {
                          return _vm.toggleCollapseRoom(room.id)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(room.type))]),
                      _c("span", [_vm._v("/")]),
                      _c("span", [_vm._v(_vm._s(room.name))]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "room-info",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openRoomInfo(room.id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "mdi mdi-information-outline" })]
                  ),
                  _c(
                    "el-popover",
                    {
                      ref: `room-${room.id}`,
                      refInFor: true,
                      attrs: { width: "270", trigger: "click" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "break-word" }, [
                          _vm._v(
                            " Stai per segnare come non sospetti tutti i messaggi di questa stanza "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mv-10" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs[
                                      `room-${room.id}`
                                    ][0].showPopper = false
                                    _vm.removeBulk(room.reports, false)
                                  },
                                },
                              },
                              [
                                _vm._v(" Elimina tutti "),
                                _c("strong", [_vm._v("TRANNE")]),
                                _vm._v(" quelli in "),
                                _c("strong", [_vm._v("evidenza")]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mv-10" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs[
                                      `room-${room.id}`
                                    ][0].showPopper = false
                                    _vm.removeBulk(room.reports, true)
                                  },
                                },
                              },
                              [
                                _vm._v(" Elimina tutti, "),
                                _c("strong", [_vm._v("ANCHE")]),
                                _vm._v(" quelli in "),
                                _c("strong", [_vm._v("evidenza")]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs[
                                      `room-${room.id}`
                                    ][0].showPopper = false
                                  },
                                },
                              },
                              [_vm._v(" Annulla ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm.can_PerformReportsActions
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover",
                              value: `room-${room.id}`,
                              expression: "`room-${room.id}`",
                            },
                            {
                              name: "pure-tooltip",
                              rawName: "v-pure-tooltip.down",
                              value: "Elimina report stanza",
                              expression: "'Elimina report stanza'",
                              modifiers: { down: true },
                            },
                          ],
                          staticClass: "clickable",
                        },
                        [_c("i", { staticClass: "mdi mdi-broom" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isRoomCollapsed(room.id),
                      expression: "!isRoomCollapsed(room.id)",
                    },
                  ],
                  staticClass: "reports-list",
                },
                _vm._l(room.reports, function (report) {
                  return _c(
                    "div",
                    {
                      key: report._id,
                      staticClass: "report-box",
                      class: {
                        "show-context": report.showBeforeAfter,
                        found: "#" + report._id === _vm.$route.hash,
                      },
                      attrs: { id: "r-" + report._id },
                    },
                    [
                      report.before.length && report.showBeforeAfter
                        ? _c(
                            "div",
                            { staticClass: "before-box" },
                            _vm._l(report.before, function (before) {
                              return _c(
                                "div",
                                { key: before._id, staticClass: "msg-box" },
                                [
                                  _c("div", { staticClass: "msg-content" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "msg-info flex justify-space-between",
                                      },
                                      [
                                        before.author
                                          ? _c(
                                              "div",
                                              { staticClass: "flex box" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "msg-author",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openUser(
                                                          before.author
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " @" +
                                                        _vm._s(
                                                          before.author.username
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "msg-time" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("dayjs")(
                                                            before.date_sent,
                                                            "format",
                                                            "DD MMMM YYYY [alle] HH:mm"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "msg-actions box flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      report.showBeforeAfter,
                                                    expression:
                                                      "report.showBeforeAfter",
                                                  },
                                                ],
                                                staticClass: "action",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleContext(
                                                      report._id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "Nascondi il contesto del messaggio",
                                                      placement: "top-end",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "mdi mdi-eye-off-outline",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    before.body
                                      ? _c("div", {
                                          staticClass: "msg-body",
                                          domProps: {
                                            innerHTML: _vm._s(before.body),
                                          },
                                        })
                                      : _vm._e(),
                                    before.type === "img" ||
                                    before.type === "sticker"
                                      ? _c(
                                          "div",
                                          { staticClass: "msg-body-img" },
                                          [
                                            _c("img", {
                                              attrs: { src: before.imageThumb },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "msg-box",
                          attrs: { id: "m-" + report.message_id },
                        },
                        [
                          _c("div", { staticClass: "msg-content" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "msg-info flex justify-space-between",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex box" },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "270",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          report.reporters,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: item._id,
                                                staticClass: "m-6 mb-10",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "@" + _vm._s(item.username)
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "o-050" },
                                                  [
                                                    _vm._v(
                                                      " il " +
                                                        _vm._s(
                                                          _vm._f("dayjs")(
                                                            report.dates_report[
                                                              index
                                                            ],
                                                            "format",
                                                            "DD MMM YYYY [alle] HH:mm"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        !report.reporters.length
                                          ? _c("div", { staticClass: "m-6" }, [
                                              _vm._v(
                                                " messaggio segnalato automaticamente "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "msg-report-count",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(report.reports_count) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "msg-author",
                                        style: {
                                          color: report.message.author.color,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openUser(
                                              report.message.author
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " @" +
                                            _vm._s(
                                              report.message.author.username
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "msg-time" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dayjs")(
                                              report.message.date_sent,
                                              "format",
                                              "DD MMMM YYYY [alle] HH:mm"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "box flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "msg-actions flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "action",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openDetailDialog(
                                                report
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "Vedi dettaglio segnalazione",
                                                placement: "top-end",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-information-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: report.showBeforeAfter,
                                              expression:
                                                "report.showBeforeAfter",
                                            },
                                          ],
                                          staticClass: "action",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleContext(
                                                report._id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "Nascondi il contesto del messaggio",
                                                placement: "top-end",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-eye-off-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !report.showBeforeAfter,
                                              expression:
                                                "!report.showBeforeAfter",
                                            },
                                          ],
                                          staticClass: "action hide-mobile",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleContext(
                                                report._id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "Vedi il contesto del messaggio",
                                                placement: "top-end",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-eye-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "action",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openChat(
                                                room.id,
                                                report.message._id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "Vedi il messaggio in chat",
                                                placement: "top-end",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-comment-eye-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "action",
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyReportUrl(
                                                report._id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Copia link report",
                                                placement: "top-end",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-link-variant",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeReport(
                                                    report
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Messaggio non sospetto",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-comment-check-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeMessage(
                                                    report
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Elimina il messaggio",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-delete-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !report.isMuted &&
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delMuteReport(
                                                    report
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Elimina il messaggio e silenzia l'utente",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-volume-off",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      report.isMuted &&
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            { staticClass: "action disabled" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Utente già silenziato",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-volume-off",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !report.isBanned &&
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delBanReport(
                                                    report
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Elimina il messaggio e blocca l'utente",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-cancel",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      report.isBanned &&
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            { staticClass: "action disabled" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Utente già bloccato",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-cancel",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !report.pinned &&
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.togglePin(
                                                    report._id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Metti la segnalazione in evidenza",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-pin-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      report.pinned &&
                                      _vm.can_PerformReportsActions
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "action",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.togglePin(
                                                    report._id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "Togli segnalazione in evidenza",
                                                    placement: "top-end",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "mdi mdi-pin-off-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("div", { staticClass: "msg-flags flex" }, [
                                    report.pinned
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "action hide-on-hover",
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    "Segnalazione in evidenza",
                                                  placement: "top-end",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mdi mdi-pin-outline warning-text",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    report.isReported
                                      ? _c(
                                          "div",
                                          { staticClass: "action" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    "Messaggio segnalato",
                                                  placement: "top-end",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mdi mdi-alert-outline danger-text",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    report.isSuspicious
                                      ? _c(
                                          "div",
                                          { staticClass: "action" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content: "Messaggio sospetto",
                                                  placement: "top-end",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mdi mdi-comment-search-outline warning-text",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            ),
                            report.message.body
                              ? _c("div", {
                                  staticClass: "msg-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$options.filters.selected(
                                        report.message.body,
                                        report.stop_words
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            report.message.type === "img" ||
                            report.message.type === "sticker"
                              ? _c(
                                  "div",
                                  { staticClass: "msg-body-img" },
                                  [
                                    _c("vue-load-image", [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "img",
                                            rawName: "v-img",
                                            value: {
                                              src: report.message.image,
                                              title: report.message.body,
                                            },
                                            expression:
                                              "{ src: report.message.image, title: report.message.body }",
                                          },
                                        ],
                                        staticClass: "image",
                                        attrs: {
                                          slot: "image",
                                          src: report.message.imageThumb,
                                        },
                                        slot: "image",
                                      }),
                                      _c("img", {
                                        staticClass: "preloader",
                                        attrs: {
                                          slot: "preloader",
                                          src: require("@/assets/images/loading.png"),
                                        },
                                        slot: "preloader",
                                      }),
                                      _c("img", {
                                        staticClass: "error",
                                        attrs: {
                                          slot: "error",
                                          src: require("@/assets/images/img-not-found.svg"),
                                        },
                                        slot: "error",
                                      }),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      report.after.length && report.showBeforeAfter
                        ? _c(
                            "div",
                            { staticClass: "after-box" },
                            _vm._l(report.after, function (after) {
                              return _c(
                                "div",
                                { key: after._id, staticClass: "msg-box" },
                                [
                                  _c("div", { staticClass: "msg-content" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "msg-info flex justify-space-between",
                                      },
                                      [
                                        after.author
                                          ? _c(
                                              "div",
                                              { staticClass: "flex box" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "msg-author",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openUser(
                                                          after.author
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " @" +
                                                        _vm._s(
                                                          after.author.username
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "msg-time" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("dayjs")(
                                                            after.date_sent,
                                                            "format",
                                                            "DD MMMM YYYY [alle] HH:mm"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "msg-actions box flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      report.showBeforeAfter,
                                                    expression:
                                                      "report.showBeforeAfter",
                                                  },
                                                ],
                                                staticClass: "action",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleContext(
                                                      report._id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "Nascondi il contesto del messaggio",
                                                      placement: "top-end",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "mdi mdi-eye-off-outline",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    after.body
                                      ? _c("div", {
                                          staticClass: "msg-body",
                                          domProps: {
                                            innerHTML: _vm._s(after.body),
                                          },
                                        })
                                      : _vm._e(),
                                    after.type === "img" ||
                                    after.type === "sticker"
                                      ? _c(
                                          "div",
                                          { staticClass: "msg-body-img" },
                                          [
                                            _c("img", {
                                              attrs: { src: after.imageThumb },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Dettaglio segnalazione",
            visible: _vm.dialogReportVisible,
            width: "300",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogReportVisible = $event
            },
          },
        },
        [
          _vm.currentReport
            ? _c("div", { staticClass: "report-dialog" }, [
                _c("div", { staticClass: "data-info" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("Stanza:")]),
                  _c("strong", { staticClass: "value room" }, [
                    _c("span", [_vm._v(_vm._s(_vm.currentReport.room_type))]),
                    _c("span", [_vm._v("/")]),
                    _c("span", [_vm._v(_vm._s(_vm.currentReport.room_name))]),
                  ]),
                ]),
                _c("div", { staticClass: "data-info" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("Autore:")]),
                  _c(
                    "strong",
                    {
                      staticClass: "value clickable",
                      style: { color: _vm.currentReport.message.author.color },
                      on: {
                        click: function ($event) {
                          return _vm.openUser(_vm.currentReport.message.author)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "@" + _vm._s(_vm.currentReport.message.author.username)
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "data-info" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Data/ora messaggio:"),
                  ]),
                  _c("strong", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dayjs")(
                            _vm.currentReport.message.date_sent,
                            "format",
                            "DD MMMM YYYY [alle] HH:mm"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "data-info" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Data/ora prima segnalazione:"),
                  ]),
                  _c("strong", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dayjs")(
                            _vm.currentReport.date_insert,
                            "format",
                            "DD MMMM YYYY [alle] HH:mm"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "data-info" }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Data/ora ultima segnalazione:"),
                  ]),
                  _c("strong", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dayjs")(
                            _vm.currentReport.date_update,
                            "format",
                            "DD MMMM YYYY [alle] HH:mm"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                !_vm.currentReport.reporters ||
                !_vm.currentReport.reporters.length
                  ? _c("div", { staticClass: "data-info" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Messaggio segnalato automaticamente"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.currentReport.reporters &&
                _vm.currentReport.reporters.length
                  ? _c("div", { staticClass: "data-info" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Lista segnalazioni:"),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "value" },
                        _vm._l(
                          _vm.currentReport.reporters,
                          function (item, index) {
                            return _c(
                              "li",
                              { key: item._id, staticClass: "m-6" },
                              [
                                _c(
                                  "strong",
                                  {
                                    staticClass: "value clickable",
                                    style: { color: item.color },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUser(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" @" + _vm._s(item.username) + " ")]
                                ),
                                _c("span", { staticClass: "o-050 ml-10" }, [
                                  _vm._v(
                                    " il " +
                                      _vm._s(
                                        _vm._f("dayjs")(
                                          _vm.currentReport.dates_report[index],
                                          "format",
                                          "DD MMM YYYY [alle] HH:mm"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                !_vm.currentReport.stop_words ||
                !_vm.currentReport.stop_words.length
                  ? _c("div", { staticClass: "data-info" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Il messaggio "),
                        _c("strong", [_vm._v("NON")]),
                        _vm._v(" contiene stop words"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.currentReport.stop_words &&
                _vm.currentReport.stop_words.length
                  ? _c("div", { staticClass: "data-info" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Contiene le seguenti stop words:"),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "value" },
                        _vm._l(_vm.currentReport.stop_words, function (word) {
                          return _c("li", { key: word }, [
                            _c("strong", [_vm._v(_vm._s(word))]),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogReportVisible = false
                    },
                  },
                },
                [_vm._v("Chiudi")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Elimina il messaggio e silenzia l'utente",
            visible: _vm.dialogDelMuteVisible,
            width: "300",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDelMuteVisible = $event
            },
          },
        },
        [
          _vm.currentReport
            ? _c("div", { staticClass: "report-dialog" }, [
                _c("div", { staticClass: "mb-20" }, [
                  _vm._v(
                    " @" +
                      _vm._s(_vm.currentReport.message.author.username) +
                      " (#" +
                      _vm._s(_vm.currentReport.message.author.id) +
                      ") "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "text-left mb-10" },
                  [
                    _c("div", { staticClass: "o-080" }, [
                      _vm._v("Durata in ore"),
                    ]),
                    _c("el-input-number", {
                      attrs: { min: 1 },
                      model: {
                        value: _vm.muteDuration,
                        callback: function ($$v) {
                          _vm.muteDuration = $$v
                        },
                        expression: "muteDuration",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Motivazione", clearable: "" },
                        model: {
                          value: _vm.muteReason,
                          callback: function ($$v) {
                            _vm.muteReason = $$v
                          },
                          expression: "muteReason",
                        },
                      },
                      _vm._l(_vm.penaltyReasons, function (item) {
                        return _c("el-option", {
                          key: item.penalty,
                          attrs: { label: item.penalty, value: item.penalty },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogDelMuteVisible = false
                    },
                  },
                },
                [_vm._v("Chiudi")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: "small",
                    disabled: _vm.muteDuration <= 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.mute(_vm.currentReport)
                    },
                  },
                },
                [_vm._v("Silenzia")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Elimina il messaggio e blocca l'utente",
            visible: _vm.dialogDelBanVisible,
            width: "300",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDelBanVisible = $event
            },
          },
        },
        [
          _vm.currentReport
            ? _c("div", { staticClass: "report-dialog" }, [
                _c("div", { staticClass: "mb-20" }, [
                  _vm._v(
                    " @" +
                      _vm._s(_vm.currentReport.message.author.username) +
                      " (#" +
                      _vm._s(_vm.currentReport.message.author.id) +
                      ") "
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Motivazione", clearable: "" },
                        model: {
                          value: _vm.banReason,
                          callback: function ($$v) {
                            _vm.banReason = $$v
                          },
                          expression: "banReason",
                        },
                      },
                      _vm._l(_vm.penaltyReasons, function (item) {
                        return _c("el-option", {
                          key: item.penalty,
                          attrs: { label: item.penalty, value: item.penalty },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogDelBanVisible = false
                    },
                  },
                },
                [_vm._v("Chiudi")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.ban(_vm.currentReport)
                    },
                  },
                },
                [_vm._v("Blocca")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box mr-10" }, [
      _c("i", { staticClass: "mdi mdi-alert-outline fs-22" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box mr-10" }, [
      _c("i", { staticClass: "mdi mdi-comment-search-outline fs-22" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }