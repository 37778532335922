<template>
	<div class="header">{{ count }} richieste, {{ notSeenCount }} non lette</div>
</template>

<script>
import api from "@/api"
import { EventBus } from "@/event-bus"

export default {
	name: "ThreadSidebarHeader",
	data() {
		return {
			count: 0,
			notSeenCount: 0
		}
	},
	computed: {},
	watch: {},
	methods: {
		getEmailsCount(notSeen = false) {
			const method = notSeen ? "getNotSeenCount" : "getCount"
			const param = notSeen ? "notSeenCount" : "count"
			api.emails[method]()
				.then(res => {
					if (res.status === 200 && res.body && res.body.count) {
						this[param] = res.body.count
						this.$emit(`update:${param}`, res.body.count)
					}
				})
				.catch(err => {
					console.error(err)
				})
		}
	},
	mounted() {
		this.getEmailsCount(false)
		this.getEmailsCount(true)

		EventBus.$on("email:new", email => {
			this.getEmailsCount(false)
			this.getEmailsCount(true)
		})
	},
	created() {},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.header {
	font-weight: bold;
	opacity: 0.7;
	padding: 0 10px;
	font-size: 14px;
	position: relative;
	top: 3px;
}
</style>
