<template>
	<div class="page-chat-institutional scrollable only-y">
		<MsgInstitutionalForm @sent="getInstitutionalMessages" ref="form"></MsgInstitutionalForm>

		<div class="history" v-loading="loadingMessages">
			<div class="header fs-20 mt-50">Storico messaggi</div>

			<div v-if="!messages.length && !loadingMessages">Non hai ancora inviato messaggi istituzionali</div>

			<MsgInstitutional v-for="m in messages" :key="m._id" :msg="m" @copy="copy" @info="info"></MsgInstitutional>
		</div>

		<el-dialog
			:visible.sync="dialogRoomsDataVisible"
			:close-on-click-modal="true"
			:close-on-press-escape="true"
			width="550px"
			custom-class="rooms-institutional-data-dialog"
		>
			<div slot="title" class="pt-8">
				Utenti raggiunti :
				<strong v-if="currentRoomsData" class="accent-text">{{ currentRoomsData.total }}</strong>
			</div>
			<div v-if="currentRoomsData">
				<div class="rooms-list">
					<div class="mv-6 fs-16" v-for="room in currentRoomsData.rooms" :key="room._id">
						<span class="text-capitalized">{{ room.type }}</span>
						<span class="mh-6">/</span>
						<strong class="break-word">
							{{ room.name }}: <span class="accent-text">{{ room.online }}</span>
						</strong>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import api from "@/api"
import MsgInstitutionalForm from "@/components/Chat/MsgInstitutionalForm"
import MsgInstitutional from "@/components/Chat/MsgInstitutional"

export default {
	name: "ChatInstitutional",
	data() {
		return {
			messages: [],
			loadingMessages: false,
			dialogRoomsDataVisible: false,
			currentRoomsData: null
		}
	},
	methods: {
		getInstitutionalMessages() {
			this.loadingMessages = true
			api.chat
				.getInstitutionalMessages()
				.then(res => {
					if (res.status === 200 && res.body) {
						const messages = res.body
						this.messages = messages.reverse()
					}
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.loadingMessages = false
				})
		},
		copy(msg) {
			if (this.$refs.form) {
				this.$refs.form.copy(msg)
			}
		},
		info(msg) {
			const rooms = msg.rooms
			let total = 0

			for (const r of rooms) {
				total += r.online
			}
			this.dialogRoomsDataVisible = true
			this.currentRoomsData = {
				total,
				rooms
			}
		}
	},
	created() {},
	mounted() {
		this.getInstitutionalMessages()
	},
	beforeDestroy() {},
	components: {
		MsgInstitutionalForm,
		MsgInstitutional
	}
}
</script>

<style lang="scss" scoped>
.page-chat-institutional {
	.history {
		.header {
			margin-bottom: 20px;
			user-select: none;
		}
	}
}
</style>