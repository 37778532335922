<template>
	<div class="mood card-base card-shadow--small">
		<div class="bb pv-10 ph-20 text-capitalized">
			<strong>{{ data.day | dayjs("format", "DD MMMM") }}</strong>
			<span class="ml-5">{{ data.day | dayjs("format", "YYYY") }}</span>
		</div>
		<div class="pv-10 ph-20 moods-list">
			<div v-for="m in emoji" :key="m.id" class="item">
				<div class="image">
					<vue-load-image>
						<img slot="image" :src="require(`@/assets/images/${m.image}`)" />
						<img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
						<img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
					</vue-load-image>
				</div>
				<div class="name">
					<strong>{{ m.mood }}</strong>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import VueLoadImage from "vue-load-image"

const AssetsMoods = [
	"amused",
	"angry",
	"bored",
	"calm",
	"confused",
	"focused",
	"happy",
	"hungry",
	"love",
	"pain",
	"sad",
	"sick",
	"sleepy",
	"surprised"
]

const Mood = function (id, user_id, day, mood_ids, created_at, updated_at) {
	this.id = id
	this.user_id = user_id
	this.day = day
	this.mood_ids = mood_ids
	this.created_at = created_at
	this.updated_at = updated_at
}

export default {
	name: "Mood",
	props: {
		data: [Mood, Object],
		list: Array
	},
	data() {
		return {}
	},
	computed: {
		emoji() {
			const list = _.split(this.data.mood_ids, ",").map(m => _.toNumber(m))
			const filtered = _.compact(_.map(list, m => _.find(this.list, l => l.id === m)))
			return _.map(filtered, e => {
				e.image = _.includes(AssetsMoods, e.mood) ? `moods/ic_mood_${e.mood}.webp` : "img-not-found.svg"
				return e
			})
		}
	},
	watch: {},
	methods: {},
	mounted() {},
	components: {
		"vue-load-image": VueLoadImage
	}
}
</script>

<style lang="scss" scoped>
.mood {
	display: inline-block;

	.moods-list {
		.item {
			display: inline-block;
			margin-right: 20px;
			width: 130px;
			height: 120px;
			text-align: center;
			text-transform: capitalize;

			.image {
				margin: 0px auto;
				width: 100px;
				height: 90px;
				padding: 0 10px;
				box-sizing: border-box;

				img {
					width: 100%;
					border-radius: 50%;
					overflow: hidden;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
</style>
