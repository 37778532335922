<template>
    <div class="template card-base card-shadow--small">
        <div class="flex header">
            <div class="box grow tags">{{ template.tagsString }}</div>
            <div class="box buttons">
                <button @click="updateTemplate(template)" class="primary">
                    <i class="mdi mdi-pencil-outline"></i>
                </button>
                <button @click="deleteTemplate(template._id)" class="danger">
                    <i class="mdi mdi-delete-outline"></i>
                </button>
            </div>
        </div>

        <div class="body" v-html="template.body"></div>

        <div class="date">
            <span> ultima modifica: </span>
            <strong>
                {{ template.updated_at | dayjs("format", "DD MMM YYYY, HH:mm") }}
            </strong>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReplyTemplate",
    props: ["template"],
    data() {
        return {}
    },
    computed: {},
    methods: {
        updateTemplate(template) {
            this.$emit("edit", template)
        },
        deleteTemplate(id) {
            this.$emit("delete", id)
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.template {
    margin-bottom: 30px;
    transition: all ease 0.2s;

    .header {
        padding: 20px;

        .tags {
            font-weight: bold;
            font-size: 20px;
        }
        .buttons {
            margin-right: -5px;

            button {
                font-size: 20px;
                margin-left: 14px;
                color: $color-primary;
                cursor: pointer;
                transition: all 0.2s;
                background: transparent;
                border: none;
                outline: none;

                &:hover {
                    text-shadow: 0px 2px 3px rgba($color-primary, 0.3);
                }

                &.danger {
                    color: $color-danger;

                    &:hover {
                        text-shadow: 0px 2px 3px rgba($color-danger, 0.3);
                    }
                }
            }
        }
    }

    .body {
        padding: 20px;
        background: rgba($background-color, 0.9);

        :deep {
            img {
                max-width: 100%;
                border-radius: 6px;
            }
        }
    }

    .date {
        background: rgba($background-color, 0.9);
        padding: 20px;
        text-align: right;
        font-size: 14px;
        padding-top: 0;
        color: rgba($text-color, 0.7);
        user-select: none;
    }

    &.closed {
        height: 0;
        margin: 0;
    }
}
</style>
