var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.room
    ? _c("div", { staticClass: "room-info" }, [
        _c("div", [
          _c("span", [_vm._v(_vm._s(_vm.room.roomType))]),
          _c("span", [_vm._v("/")]),
          _c("strong", [_vm._v(_vm._s(_vm.room.name))]),
          _c("span", { staticClass: "badges" }, [
            _c("span", { staticClass: "users-count" }, [
              _vm._v(_vm._s(_vm.room.usersCount || _vm.room.userscount)),
            ]),
            _vm.room.special || _vm.room.isspecial
              ? _c("i", { staticClass: "mdi mdi-star-outline" })
              : _vm._e(),
            _c("i", {
              staticClass: "mdi mdi-information-outline",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.openRoomInfo(_vm.room._id)
                },
              },
            }),
          ]),
        ]),
        _vm.typingList && _vm.typingList.length
          ? _c("div", { staticClass: "mt-10" }, [
              _vm._v(" " + _vm._s(_vm.typingText) + " "),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }