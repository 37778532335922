var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "page", staticClass: "page-channel-telegram scrollable only-y" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingForm,
              expression: "loadingForm",
            },
          ],
          staticClass: "form-box flex column card-base card-shadow--small",
        },
        [
          !_vm.currentTemplateId
            ? _c("div", { staticClass: "header" }, [_vm._v("Nuovo messaggio")])
            : _vm._e(),
          _vm.currentTemplateId
            ? _c("div", { staticClass: "header" }, [
                _vm._v(" Modifica modello "),
                _vm.tags && _vm.tags.length
                  ? _c("strong", [_vm._v(" " + _vm._s(_vm.tags[0]))])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("el-input", {
            attrs: { placeholder: "Url..." },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
          _c("UrlMetaCard", {
            attrs: { url: _vm.url, data: _vm.meta },
            on: {
              "update:data": function ($event) {
                _vm.meta = $event
              },
              click: _vm.copy,
            },
          }),
          _c(
            "div",
            { staticClass: "flex fields-box" },
            [
              _c(
                "div",
                {
                  staticClass: "image-el box",
                  style: { "background-image": _vm.bg },
                },
                [
                  !_vm.image
                    ? _c("FileDrop", {
                        staticStyle: { opacity: "0.4" },
                        attrs: { hover: false, icon: "mdi mdi-image-outline" },
                        on: { change: _vm.fileChange },
                      })
                    : _vm._e(),
                  _vm.image
                    ? _c(
                        "div",
                        {
                          staticClass: "btn-clear-photo",
                          on: {
                            click: function ($event) {
                              _vm.image = ""
                            },
                          },
                        },
                        [_c("i", { staticClass: "mdi mdi-close" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("Editor", {
                ref: "editor",
                staticClass: "box grow",
                attrs: {
                  html: _vm.html,
                  text: _vm.text,
                  list: false,
                  table: false,
                  link: true,
                  heading: false,
                  hiding: false,
                  placeholder: "Scrivi messaggio...",
                },
                on: {
                  "update:html": function ($event) {
                    _vm.html = $event
                  },
                  "update:text": function ($event) {
                    _vm.text = $event
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "buttons-layout" },
                [
                  _c("ChannelMessageButtons", {
                    ref: "buttons",
                    on: { change: (res) => (_vm.buttons = res) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._m(0),
          _c("div", { staticClass: "toolbar-box flex justify-space-between" }, [
            _c("div", { staticClass: "box grow mr-20" }, [
              _c("ul", { staticClass: "legend" }, [
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.appendTag("site")
                      },
                    },
                  },
                  [
                    _c("strong", {
                      domProps: { innerHTML: _vm._s("{{site}}") },
                    }),
                    _vm._v(" : nome sito"),
                  ]
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.appendTag("title")
                      },
                    },
                  },
                  [
                    _c("strong", {
                      domProps: { innerHTML: _vm._s("{{title}}") },
                    }),
                    _vm._v(" : titolo pagina web/prodotto "),
                  ]
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.appendTag("description")
                      },
                    },
                  },
                  [
                    _c("strong", {
                      domProps: { innerHTML: _vm._s("{{description}}") },
                    }),
                    _vm._v(" : descrizione pagina web/prodotto "),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "box text-right" },
              [
                _vm.meta
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "medium", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.copy({ key: null, value: _vm.meta })
                          },
                        },
                      },
                      [_vm._v(" Copia info ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium", plain: "" },
                    on: { click: _vm.reset },
                  },
                  [_vm._v("Reset")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "medium", disabled: !_vm.text },
                    on: { click: _vm.save },
                  },
                  [_vm._v("Salva modello")]
                ),
                !_vm.currentTemplateId
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          size: "medium",
                          disabled: !_vm.isValid,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.send(true)
                          },
                        },
                      },
                      [_vm._v(" Invia su Test ")]
                    )
                  : _vm._e(),
                _c(
                  "el-popconfirm",
                  {
                    attrs: {
                      title: "Sicura di voler procedere ?",
                      confirmButtonText: "Si",
                      cancelButtonText: "No",
                    },
                    on: {
                      confirm: function ($event) {
                        return _vm.send(false)
                      },
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.currentTemplateId,
                            expression: "!currentTemplateId",
                          },
                        ],
                        staticClass: "ml-10",
                        attrs: {
                          slot: "reference",
                          type: "success",
                          plain: "",
                          size: "medium",
                          disabled: !_vm.isValid,
                        },
                        slot: "reference",
                      },
                      [_vm._v(" Invia su Canale ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-popover",
                  {
                    attrs: { width: "220", trigger: "click" },
                    model: {
                      value: _vm.cronPopoverVisible,
                      callback: function ($$v) {
                        _vm.cronPopoverVisible = $$v
                      },
                      expression: "cronPopoverVisible",
                    },
                  },
                  [
                    _c("div", { staticClass: "cron-msg__popover-prompt" }, [
                      _c(
                        "div",
                        { staticClass: "mb-20" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              format: "dd MMMM yyyy HH:mm",
                              placeholder: "Seleziona data e ora",
                              align: "right",
                              "popper-class": "channel-message-datatime",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.cronDate,
                              callback: function ($$v) {
                                _vm.cronDate = $$v
                              },
                              expression: "cronDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "text" },
                              on: { click: _vm.cronPopoverClose },
                            },
                            [_vm._v("Annulla")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "mini",
                                disabled: !_vm.isCronDateValid,
                              },
                              on: { click: _vm.setCron },
                            },
                            [_vm._v(" Programma ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.currentTemplateId,
                            expression: "!currentTemplateId",
                          },
                        ],
                        staticClass: "ml-10",
                        attrs: {
                          slot: "reference",
                          type: "success",
                          plain: "",
                          size: "medium",
                          disabled: !_vm.isValid,
                        },
                        slot: "reference",
                      },
                      [
                        _vm._v(" Programma su Canale "),
                        _c("i", { staticClass: "mdi mdi-clock-outline" }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "list-container flex gaps" }, [
        _c("div", { staticClass: "wrapper box grow" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingTemplates,
                  expression: "loadingTemplates",
                },
              ],
              staticClass: "list",
            },
            [
              _c("div", { staticClass: "header fs-20 mt-50" }, [
                _vm._v("Lista modelli"),
              ]),
              !_vm.templates.length && !_vm.loadingTemplates
                ? _c("div", [_vm._v("Non hai ancora un modello")])
                : _vm._e(),
              _vm._l(_vm.templates, function (t) {
                return _c("ChannelMessageTemplate", {
                  key: t._id,
                  class: { closed: t._id === _vm.currentTemplateId },
                  attrs: { template: t },
                  on: {
                    copy: function ($event) {
                      return _vm.copyTemplate(t)
                    },
                    edit: function ($event) {
                      return _vm.updateTemplate(t)
                    },
                    delete: function ($event) {
                      return _vm.deleteTemplate(t._id)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "wrapper box grow" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingCron,
                  expression: "loadingCron",
                },
              ],
              staticClass: "list",
            },
            [
              _c("div", { staticClass: "header fs-20 mt-50" }, [
                _vm._v(" Messaggi programmati "),
                _vm.cronList.length
                  ? _c("small", { staticClass: "o-050" }, [
                      _vm._v("(" + _vm._s(_vm.cronList.length) + ")"),
                    ])
                  : _vm._e(),
              ]),
              !_vm.cronList.length && !_vm.loadingCron
                ? _c("div", [_vm._v("Non sono presenti messaggi programmati")])
                : _vm._e(),
              _vm._l(_vm.cronList, function (msg) {
                return _c("ChannelMessageCron", {
                  key: msg._id,
                  attrs: { message: msg },
                  on: {
                    delete: function ($event) {
                      return _vm.deleteCronMessage(msg._id)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hint text-info fs-14 mt-10" }, [
      _c("i", { staticClass: "mdi mdi-information-outline" }),
      _c("span", { staticClass: "ml-5" }, [
        _vm._v(
          " La foto deve pesare al massimo 10 MB. La somma della larghezza e dell'altezza non deve superare 10.000 px. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }