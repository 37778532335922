<template>
    <div class="page-requests-list flex" :class="[size, { opened: !!threadId }]" ref="page">
        <resize-observer @notify="__resizeHandler" />

        <div id="sidebar" class="box flex column">
            <ThreadSidebarHeader :count.sync="count"></ThreadSidebarHeader>

            <ThreadSidebarFilters :filters="filters" :tooltipHidden="size !== 'large'"></ThreadSidebarFilters>

            <div class="thread-list box grow scrollable only-y" ref="threadList" @scroll="listScrollHandler">
                <ThreadCard
                    v-for="thread of filtered"
                    :key="thread._id"
                    :thread="thread"
                    @open="openThread(thread._id)"
                    :class="{ active: thread._id === threadId }"
                >
                </ThreadCard>
                <div class="loading-box">
                    <div v-loading="loading"></div>
                    <el-button @click="getNewPage()" v-if="!loading"> Carica altre </el-button>
                </div>
            </div>
        </div>

        <div
            v-if="!threadId"
            id="thread-box"
            class="box grow flex card-base card-shadow--small align-center text-center"
        >
            <div class="box grow text-center fs-20 p-30">Clicca su una richiesta per visualizzare la conversazione</div>
        </div>

        <div v-if="threadId && currentThread" id="thread-box" class="box grow flex column card-base card-shadow--small">
            <ThreadHeader
                class="box"
                @swipeLeft="swipeLeft"
                @swipeRight="swipeRight"
                @openPrev="openPrev"
                @openNext="openNext"
                @reset="threadId = null"
                :isFirst="isFirst"
                :index="index"
                :count="count"
                :size="size"
            ></ThreadHeader>

            <ThreadToolbar
                :class="{ shadow: childrenScrolled }"
                @swipeLeft="swipeLeft"
                @swipeRight="swipeRight"
                @updateThread="updateThread"
                :size="size"
                :tooltipHidden="size !== 'large'"
                :thread="currentThread"
                ref="threadToolbar"
                v-if="can_PerformRequestActions"
            ></ThreadToolbar>

            <div
                class="children box grow scrollable only-y"
                ref="children"
                @scroll="childrenScrollHandler"
                v-touch:swipe.left="swipeLeft"
                v-touch:swipe.right="swipeRight"
            >
                <div class="header bb">{{ currentThread.subject || "(Senza oggetto)" }}</div>

                <ThreadChild
                    v-for="child in currentThread.children"
                    :key="child.uid"
                    :child="child"
                    :size="size"
                    :class="{ 'is-admin': child.admin }"
                >
                </ThreadChild>

                <div class="loading-box" :class="{ close: !loadingThread }">
                    <div v-loading="loadingThread"></div>
                </div>

                <div class="reply-btn-box" v-if="can_ReplyToRequest">
                    <button @click="replyBoxOpened = true">
                        <i class="mdi mdi-reply"></i>
                        <span> Rispondi </span>
                    </button>
                </div>
            </div>

            <ThreadReplyBox
                class="bt"
                v-if="can_ReplyToRequest"
                ref="reply"
                :opened.sync="replyBoxOpened"
                :thread="currentThread"
                :size="size"
                @sent="sent"
            ></ThreadReplyBox>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import { EventBus } from "@/event-bus"
import { openUserDialog } from "@/common"

import stc from "string-to-color"
import prettyBytes from "pretty-bytes"

import ThreadCard from "@/components/Support/ThreadCard"
import ThreadChild from "@/components/Support/ThreadChild"
import ThreadSidebarFilters from "@/components/Support/ThreadSidebarFilters"
import ThreadSidebarHeader from "@/components/Support/ThreadSidebarHeader"
import ThreadHeader from "@/components/Support/ThreadHeader"
import ThreadToolbar from "@/components/Support/ThreadToolbar"
import ThreadReplyBox from "@/components/Support/ThreadReplyBox"

export default {
    name: "RequestsList",
    data() {
        return {
            threads: [],
            threadId: null,
            lastId: null,
            stopGet: false,
            loading: false,
            loadingThread: false,
            size: "large",
            lastClickedThread: false,
            filters: { notSeen: false, search: "", label: "" },
            replyBoxOpened: false,
            childrenScrolled: false,
            count: 0
        }
    },
    computed: {
        currentThread() {
            return _.find(this.threads, e => e._id === this.threadId)
        },
        isFirst() {
            return this.threadId ? _.findIndex(this.threads, e => e._id === this.threadId) === 0 : false
        },
        index() {
            return (this.threadId ? _.findIndex(this.threads, e => e._id === this.threadId) : -1) + 1
        },
        filtered() {
            return _.chain(this.threads)
                .filter(e => {
                    let flag = true

                    if (flag) {
                        if (this.filters.notSeen) {
                            flag = !e.isSeen
                        } else {
                            flag = true
                        }
                    }

                    if (flag) {
                        if (this.filters.search) {
                            if (
                                e.from.address.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1 ||
                                e.from.name.toLowerCase().indexOf(this.filters.search.toLowerCase()) !== -1
                            ) {
                                flag = true
                            } else {
                                flag = false
                            }
                        } else {
                            flag = true
                        }
                    }

                    if (flag) {
                        if (this.filters.label) {
                            flag = _.some(e.labels, l => l.name === this.filters.label)
                        } else {
                            flag = true
                        }
                    }

                    return flag
                })
                .value()
        },
        can_ReplyToRequest() {
            return this.$store.getters.can_ReplyToRequest
        },
        can_PerformRequestActions() {
            return this.$store.getters.can_PerformRequestActions
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    //if (this.$refs.threadList.lastElementChild.offsetTop < this.$refs.threadList.offsetHeight) {
                    if (this.isAtEnd(this.$refs.threadList)) {
                        this.getNewPage()
                    }
                })
            }
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.page) {
                const width = this.$refs.page.offsetWidth

                this.size = "large"
                if (width < 768) {
                    this.size = "small"
                } else if (width < 850) {
                    this.size = "medium"
                }
            }
        },
        getUserInitials(text) {
            let str = "-"

            if (text.indexOf(" ") !== -1) {
                const name = text.split(" ")
                const fn = (name[0].match(/\w+/) || []).join("")
                const ln = (name[name.length - 1].match(/\w+/) || []).join("")

                if (ln) {
                    str = (fn.charAt(0) || "-") + (ln.charAt(0) || "-")
                } else {
                    str = (fn.charAt(0) || "-") + (fn.charAt(1) || "-")
                }
            } else {
                str = (text.charAt(0) || "-") + (text.charAt(1) || "-")
            }

            return str.toUpperCase()
        },
        parseEmails(emails) {
            return _.map(emails, e => {
                e.hasAttachments = false
                e.color = stc(e.from.address)
                e.initials = this.getUserInitials(e.from.name || e.from.address)

                for (const child of e.children) {
                    if (child.from) {
                        child.color = stc(child.from.address)
                        child.initials = this.getUserInitials(child.from.name || child.from.address)
                    }

                    if (child.attachments.length) {
                        e.hasAttachments = true

                        for (const attachment of child.attachments) {
                            attachment.isImage =
                                attachment.contentType && attachment.contentType.indexOf("image") !== -1

                            attachment.ext = "-"
                            if (attachment.filename && attachment.filename.indexOf(".") !== -1) {
                                const parts = attachment.filename.split(".")
                                attachment.ext = parts[parts.length - 1].toUpperCase()
                            }
                            attachment.color = stc(attachment.ext)

                            attachment.size = prettyBytes(parseInt(attachment.size) || 0)
                        }

                        child.attachments = _.uniqBy(child.attachments, "checksum")
                    }
                }

                e.children = _.uniqBy(e.children, "uid")
                e.attachments = _.uniqBy(e.attachments, "checksum")
                return e
            })
        },
        getEmails() {
            this.loading = true

            api.emails
                .get({ lastId: this.lastId })
                .then(res => {
                    if (res.status === 200 && res.body && res.body.length) {
                        const bkp = _.cloneDeep(this.threads)
                        const emails = this.parseEmails(res.body)
                        bkp.push(...emails)
                        this.threads = _.uniqBy(bkp, "_id")

                        this.lastId = this.threads[this.threads.length - 1]._id || ""

                        setTimeout(() => {
                            //if (this.$refs.threadList.lastElementChild.offsetTop < this.$refs.threadList.offsetHeight) {
                            if (this.isAtEnd(this.$refs.threadList)) {
                                this.getNewPage()
                            }
                        }, 500)
                    } else {
                        this.stopGet = true
                    }

                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.error(err)
                })
        },
        getNewEmails() {
            api.emails
                .get({ lastId: "" })
                .then(res => {
                    if (res.status === 200 && res.body && res.body.length) {
                        const emails = this.parseEmails(res.body)
                        const firstID = this.threads.length ? this.threads[0]._id : null

                        const nEmails = []

                        for (const e of emails) {
                            if (e._id !== firstID) {
                                nEmails.push(e)
                            } else {
                                break
                            }
                        }

                        this.threads = [...nEmails, ...this.threads]
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        openThread(id) {
            if (id !== this.threadId) {
                this.threadId = id

                this.getThread(id)
                this.$nextTick(() => {
                    if (this.$refs.reply) {
                        this.$refs.reply.reset()
                    }
                })
            }
        },
        getThread: _.debounce(function (id) {
            this.loadingThread = true

            api.emails
                .getThread(id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const email = this.parseEmails([res.body])[0]

                        const index = this.threads.findIndex(e => e._id === id)
                        this.$set(this.threads, index, email)
                    }

                    this.loadingThread = false
                })
                .catch(err => {
                    this.loadingThread = false
                    console.error(err)
                })
        }, 200),
        setCardInView() {
            this.$nextTick(() => {
                const el = document.querySelector(".thread-card.active")
                if (el) {
                    const boxOffsetHeight = this.$refs.threadList.offsetHeight
                    const boxScrollTop = this.$refs.threadList.scrollTop
                    const elOffsetTop = el.offsetTop
                    const elOffsetHeight = el.offsetHeight

                    const diff = elOffsetTop - boxScrollTop
                    // if (diff > boxOffsetHeight || diff < 0) {
                    const scroll = elOffsetTop - boxOffsetHeight / 2 + elOffsetHeight / 2
                    if (this.$refs.threadList) {
                        this.$refs.threadList.scrollTo({ top: scroll, behavior: "smooth" })
                    }
                    //}
                }
            })
        },
        getNewPage: _.debounce(function () {
            if (!this.stopGet) {
                this.getEmails()
            }
        }, 100),
        listScrollHandler(ev) {
            const canGet = this.isAtEnd(ev.target)

            if (canGet) {
                this.getNewPage()
            }
        },
        childrenScrollHandler(ev) {
            this.childrenScrolled = !!ev.target.scrollTop
        },
        openUser(user) {
            if (user.id) {
                openUserDialog({ id: user.id, user: user })
            }
        },
        openNext() {
            const index = this.filtered.findIndex(e => e._id === this.threadId)

            if (index !== -1 && this.filtered[index + 1]) {
                this.openThread(this.filtered[index + 1]._id)
                this.setCardInView()
            }
        },
        openPrev() {
            const index = this.filtered.findIndex(e => e._id === this.threadId)
            if (index !== -1 && this.filtered[index - 1]) {
                this.openThread(this.filtered[index - 1]._id)
                this.setCardInView()
            }
        },
        keyEvent(event) {
            if (this.threadId && this.lastClickedThread) {
                event.preventDefault()

                if (event.code === "ArrowDown") {
                    this.openNext()
                }
                if (event.code === "ArrowUp") {
                    this.openPrev()
                }
            }
        },
        getElPath(target) {
            const path = []
            let currentElem = target
            while (currentElem) {
                path.push(currentElem)
                currentElem = currentElem.parentElement
            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document)
            if (path.indexOf(window) === -1) path.push(window)
            return path
        },
        setClickedEl(event) {
            this.lastClickedThread = false
            if (event && event.target) {
                const path = this.getElPath(event.target)

                if (path) {
                    for (const el of path) {
                        if (_.get(el, "dataset.card") === "thread") {
                            this.lastClickedThread = true
                        }
                    }
                }
            }
        },
        updateThread() {
            // const id = this.threadId
            // this.getThread(id)
        },
        swipeLeft() {
            if (this.size !== "large") {
                this.openNext()
            }
        },
        swipeRight() {
            if (this.size !== "large") {
                this.openPrev()
            }
        },
        isAtEnd(target) {
            if (!target) {
                return false
            }
            return (target?.scrollHeight || 0) - (target?.offsetHeight || 0) <= (target?.scrollTop || 0)
        },
        sent({ type, email }) {
            // if (type !== "ticket") {
            this.setSeen()
            // }
        },
        setSeen() {
            if (this.$refs.threadToolbar) {
                this.$refs.threadToolbar.setSeenLoading(true)
            }
            const id = this.threadId
            api.emails
                .setSeen(id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.updateThread()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (this.$refs.threadToolbar) {
                        this.$refs.threadToolbar.setSeenLoading(false)
                    }
                })
        }
    },
    mounted() {
        this.calcDim()
        this.getEmails()

        if (this.$route.hash) {
            this.threadId = this.$route.hash.substring(1)
            setTimeout(() => {
                this.setCardInView()
            }, 1000)
        }

        const query_type = _.get(this.$route, "query.type")
        if (query_type === "notSeen") {
            this.filters.notSeen = true
        }

        EventBus.$on("email:new", email => {
            this.getNewEmails()

            if (this.threadId) {
                this.getThread(this.threadId)
            }
        })

        EventBus.$on("email:sent", email => {
            if (this.threadId) {
                this.getThread(this.threadId)
            }
        })
    },
    created() {
        document.addEventListener("keydown", this.keyEvent)
        document.addEventListener("click", this.setClickedEl)
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.keyEvent)
        document.removeEventListener("click", this.setClickedEl)
    },
    components: {
        ThreadCard,
        ThreadChild,
        ThreadSidebarFilters,
        ThreadSidebarHeader,
        ThreadHeader,
        ThreadToolbar,
        ThreadReplyBox
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-requests-list {
    #sidebar {
        width: 400px;
        margin-right: 20px;
        margin-left: -10px;
        margin-top: -5px;

        .thread-list {
            box-sizing: border-box;
            padding: 0 10px;
            width: 100%;
            position: relative;

            & > .thread-card {
                :deep {
                    &.active {
                        background-color: $color-primary;
                        color: #fff;

                        .new {
                            background: #fff;
                            box-shadow: 0px 0px 5px 0px $color-primary;
                        }
                    }
                }
            }

            .loading-box {
                height: 40px;
                position: relative;
                padding-top: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    #thread-box {
        position: relative;

        & > .toolbar {
            &.shadow {
                box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
            }
        }

        & > .children {
            & > .header {
                padding: 20px;
                font-weight: bold;
                font-size: 22px;
            }

            :deep {
                .child {
                    &.is-admin {
                        box-shadow: 0px 0px 0px 3px $color-primary;
                    }
                }
            }

            .loading-box {
                padding: 20px;
                box-sizing: border-box;
                transition: all 0.2s;

                &.close {
                    padding: 0;
                }
            }

            .reply-btn-box {
                display: none;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 20px;

                button {
                    padding: 16px 22px;
                    border: none;
                    outline: none;
                    background: transparent;
                    text-transform: uppercase;
                    font-family: inherit;
                    color: $color-primary;
                    font-weight: bold;
                    cursor: pointer;
                    border-radius: 6px;
                    background: rgba($color-primary, 0.1);

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &.medium,
    &.small {
        #sidebar {
            width: calc(100% + 25px);
            margin-right: -15px;
        }

        #thread-box {
            & > .children {
                .reply-btn-box {
                    display: block;
                }
            }
        }

        &:not(.opened) {
            #sidebar {
                display: inherit;
            }
            #thread-box {
                display: none;
            }
        }
        &.opened {
            #sidebar {
                display: none;
            }
            #thread-box {
                display: inherit;
            }
        }
    }
    &.small {
        #sidebar {
            width: calc(100% + 20px);
            margin-right: -10px;
        }

        #thread-box {
            min-height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            z-index: 2;

            .children {
                :deep {
                    .child {
                        &.is-admin {
                            background: rgba($color-primary, 0.07);
                            box-shadow: none;

                            .content {
                                .hide-text {
                                    background-image: linear-gradient(
                                        180deg,
                                        hsla(0, 0%, 100%, 0) 0,
                                        #f3f9fe 90%,
                                        #f3f9fe
                                    ) !important;
                                }
                                .read-more-button {
                                    span {
                                        background: #f3f9fe;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
