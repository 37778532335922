var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vertical-nav",
      class: { "nav-collapsed": _vm.collapseNav, open: _vm.openSidebar },
    },
    [
      _c("div", {
        staticClass: "sidebar-mask",
        class: { open: _vm.openSidebar },
        on: { click: _vm.closeNav },
      }),
      _c(
        "div",
        {
          staticClass: "sidebar flex column",
          class: { open: _vm.openSidebar, ["pos-" + _vm.position]: true },
        },
        [
          _c("logo", {
            attrs: { "collapse-nav": _vm.collapseNav },
            on: { "collapse-nav-toggle": _vm.collapseNavToggle },
          }),
          _c(
            "div",
            { staticClass: "box-nav box grow scrollable only-y" },
            [
              _c("Nav", {
                attrs: { "is-collapse": _vm.collapseNav },
                on: {
                  "push-page": _vm.pushPage,
                  "collapse-nav-toggle": _vm.collapseNavToggle,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }