var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "box", staticClass: "qna-attachment-list flex column" },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "list-wrap box grow flex column",
        },
        [
          _c(
            "div",
            { ref: "list", staticClass: "list-box box grow scrollable only-y" },
            [
              !_vm.items.length && !_vm.loading
                ? _c("div", [_vm._v("Non sono presenti allegati")])
                : _vm._e(),
              _vm._l(_vm.items, function (item) {
                return _c("QnaAttachment", {
                  key: item.uid,
                  attrs: { item: item },
                  on: {
                    deleted: function ($event) {
                      return _vm.deleteItem(item.uid)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-pagination",
        {
          ref: "pagination",
          staticClass: "card-base box card-shadow--small",
          attrs: {
            small: _vm.pagination.small,
            "current-page": _vm.pagination.page,
            "page-sizes": _vm.pagination.sizes,
            "page-size": _vm.pagination.size,
            layout: _vm.layout,
            total: _vm.pagination.total,
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.pagination, "page", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.pagination, "page", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.pagination, "size", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.pagination, "size", $event)
            },
            "size-change": _vm.loadAttachments,
            "current-change": _vm.loadAttachments,
            "prev-click": _vm.loadAttachments,
            "next-click": _vm.loadAttachments,
          },
        },
        [
          _c("ul", { staticClass: "el-pager" }, [
            _c("li", { staticClass: "number active" }, [
              _vm._v(_vm._s(_vm.pagination.page)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }