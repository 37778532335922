<template>
    <div ref="box" class="question-list flex column">
        <resize-observer @notify="__resizeHandler" />
        <QnaFilter
            v-if="filtersEnabled"
            class="box"
            @change="resetPage"
            :filter="filter"
            :showCategory="true"
        ></QnaFilter>
        <div class="list-wrap box grow flex column" v-loading="loading">
            <div class="list-box box grow scrollable only-y" ref="list">
                <div v-if="!questions.length && !loading">
                    <p>Non sono presenti domande <span v-if="pagination.page > 1">in questa pagina</span>.</p>
                </div>
                <Question
                    @deleted="getQuestions"
                    @restored="getQuestions"
                    v-for="question of questions"
                    :question="question"
                    :key="question.id"
                    :size="size"
                ></Question>
            </div>
        </div>

        <el-pagination
            class="card-base box card-shadow--small"
            ref="pagination"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="layout"
            :total="pagination.total"
            @size-change="getQuestions"
            @current-change="getQuestions"
            @prev-click="getQuestions"
            @next-click="getQuestions"
        >
            <ul class="el-pager">
                <li class="number active">{{ pagination.page }}</li>
            </ul>
        </el-pagination>
    </div>
</template>

<script>
import api from "@/api"
import _ from "lodash"

const AnswerList = () => import("./AnswerList")
const Question = () => import("./Question")
const QnaFilter = () => import("./QnaFilter")

export default {
    name: "QuestionList",
    props: {
        user: {
            type: Number,
            required: false
        },
        showReported: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filtersEnabled: true,
            fullPagination: true,
            loading: false,
            filter: {
                suspended: false,
                attachments: null,
                category: null
            },
            questions: [],
            pagination: {
                page: 1,
                size: 10,
                sizes: [10, 15, 20, 30, 50, 100],
                layout: this.layout,
                small: false
            },
            size: "large"
        }
    },
    computed: {
        layout() {
            if (this.fullPagination && this.size === "large") {
                return "total, ->, prev, pager, next, jumper, sizes"
            } else {
                if (this.size === "small") {
                    return "->, prev, slot, next"
                } else {
                    return "->, prev, pager, next"
                }
            }
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.box) {
                const width = this.$refs.box.offsetWidth
                this.fullPagination = true
                this.size = "large"
                if (width < 700) {
                    this.size = "small"
                    this.fullPagination = false
                } else if (width < 890) {
                    this.size = "medium"
                }
            }
        },
        getQuestions() {
            this.loading = true

            const params = {
                ...this.filter,
                pagination: this.pagination.size,
                page: this.pagination.page
            }

            if (this.user) {
                params.user_id = this.user
            }

            api.qna
                .getAll(params)
                .then(res => {
                    if (res.status === 200 && res.body && res.body.data) {
                        this.questions = res.body.data
                        this.pagination.total = res.body.pagination.totalElements
                        if (this.pagination.page > res.body.pagination.totalPages) {
                            setTimeout(() => {
                                this.$refs.pagination.handleCurrentChange(this.pagination.page)
                            }, 1000)
                        }

                        if (this.$refs.list) {
                            this.$refs.list.scrollTo({ top: 0 })
                        }
                    } else {
                        this.questions = []
                    }
                })
                .catch(err => {
                    this.questions = []
                    console.error(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        resetPage() {
            this.pagination.page = 1
            this.getQuestions()
        }
    },
    mounted() {
        this.calcDim()

        this.$nextTick(() => {
            if (this.showReported) {
                this.filter.suspended = true
            }

            this.getQuestions()
        })
    },
    components: {
        Question,
        AnswerList,
        QnaFilter
    }
}
</script>

<style lang="scss" scoped>
.question-list {
    height: 100%;

    .list-wrap {
        overflow: hidden;
        margin-left: -6px;
        margin-right: -11px;

        @media (max-width: 768px) {
            margin-right: -6px;
        }

        .list-box {
            padding: 0 6px;
        }
    }

    .el-pagination {
        margin-top: 10px;
        padding: 10px 20px;
        padding-right: 10px;

        :deep {
            .el-pagination__sizes {
                margin-right: 0;

                .el-input {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
