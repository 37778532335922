<template>
	<div>
		<div v-for="filter in filters" :key="filter.key" class="filter-box">
			<div class="label">{{ filter.label }}</div>
			<div class="field">
				<component :is="filter.key" :value.sync="filter.value" class="component" />
				<el-button @click="delFilter(filter.key)" type="danger" plain>
					<i class="mdi mdi-delete-outline fs-20"></i>
				</el-button>
			</div>
		</div>
		<el-select
			v-if="availableFilters.length"
			class="w-full"
			placeholder="Aggiungi filtro"
			v-model="newFilter"
			@change="addFilter()"
			clearable
			no-data-text="Nessun filtro disponibile"
		>
			<el-option
				v-for="item in availableFilters"
				:key="item.key"
				:label="item.label"
				:value="item.key"
			></el-option>
		</el-select>
		<!--
			<pre> {{ filters }} </pre>
			<pre> {{ availableFilters }} </pre>
		-->
	</div>
</template>

<script>
const filtersComponents = [
	{ key: "genre", label: "Genere", component: () => import("@/components/ExportTool/filters/Genre") },
	{ key: "status", label: "Status", component: () => import("@/components/ExportTool/filters/Status") },
	{ key: "geoArea", label: "Geo Area", component: () => import("@/components/ExportTool/filters/GeoArea") },
	{ key: "age", label: "Età", component: () => import("@/components/ExportTool/filters/Age") },
	{ key: "baby", label: "Con figli", component: () => import("@/components/ExportTool/filters/Baby") },
	{ key: "babyAge", label: "Età figli", component: () => import("@/components/ExportTool/filters/BabyAge") },
	{
		key: "marketingThirds",
		label: "Utenti con marketing verso terzi",
		component: () => import("@/components/ExportTool/filters/MarketingThirds")
	},
	{
		key: "marketingOwner",
		label: "Utenti con marketing diretto",
		component: () => import("@/components/ExportTool/filters/MarketingOwner")
	},
	{
		key: "MarketingOwnerForThirds",
		label: "Utenti con marketing indiretto",
		component: () => import("@/components/ExportTool/filters/MarketingOwnerForThirds")
	},
	{
		key: "profiling",
		label: "Utenti con profilazione",
		component: () => import("@/components/ExportTool/filters/Profiling")
	},
	{
		key: "verifiedEmail",
		label: "Utenti con email verificata",
		component: () => import("@/components/ExportTool/filters/VerifiedEmail")
	}
]

export default {
	name: "ExportToolFilters",
	data() {
		return {
			newFilter: "",
			filtersList: filtersComponents.map(({ key, label }) => ({ key, label })),
			filters: []
		}
	},
	computed: {
		availableFilters: {
			cache: false,
			get() {
				return this.filtersList.filter(f => !this.filters.map(k => k.key).includes(f.key))
			}
		}
	},
	watch: {
		filters: {
			handler: function (value) {
				this.$emit("updated", value)
			},
			deep: true
		}
	},
	methods: {
		addFilter() {
			if (this.newFilter) {
				const filter = this.filtersList.find(f => f.key === this.newFilter)
				this.filters.push({
					key: this.newFilter,
					label: filter.label,
					value: ""
				})
				this.newFilter = null
			}
		},
		delFilter(filterKey) {
			this.filters = this.filters.filter(f => f.key !== filterKey)
		},
		reset() {
			this.filters = []
		}
	},
	components: filtersComponents.reduce((acc, cur) => {
		acc[cur.key] = cur.component
		return acc
	}, {})
}
</script>

<style scoped lang="scss">
.filter-box {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	gap: 5px;
	.field {
		display: flex;
		gap: 10px;
		align-items: center;

		.component {
			flex-grow: 1;
		}

		& > .el-button {
			padding-top: 0;
			padding-bottom: 0;
			height: 40px;
		}
	}
}
</style>
