var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-container flex justify-center" },
    [
      _c("vertical-nav", {
        attrs: {
          position: "left",
          "collapse-nav": _vm.collapseNav,
          "open-sidebar": _vm.openSidebar,
        },
        on: {
          "update:openSidebar": function ($event) {
            _vm.openSidebar = $event
          },
          "update:open-sidebar": function ($event) {
            _vm.openSidebar = $event
          },
          "collapse-nav-toggle": function ($event) {
            _vm.collapseNav = !_vm.collapseNav
          },
          "push-page": _vm.closeSidebar,
        },
      }),
      _c(
        "div",
        { staticClass: "container flex column box grow" },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("Toolbar", {
                attrs: { "menu-burger": "right" },
                on: {
                  "toggle-sidebar": function ($event) {
                    _vm.openSidebar = !_vm.openSidebar
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main box grow flex" },
            [
              _c(
                "transition",
                { attrs: { name: _vm.viewAnimation, mode: "out-in" } },
                [
                  _c("router-view", {
                    key: _vm.$route.fullPath,
                    staticClass: "view box grow",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.footer === "below"
            ? _c("Footer", { attrs: { position: _vm.footer } })
            : _vm._e(),
        ],
        1
      ),
      _vm.footer === "above"
        ? _c("Footer", { attrs: { position: _vm.footer } })
        : _vm._e(),
      _c("UserDialog"),
      _c("RoomDialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }