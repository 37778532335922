var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "room",
      staticClass: "room-info",
      class: _vm.size,
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _vm.room
        ? _c("div", { staticClass: "flex room-detail" }, [
            _c(
              "div",
              { staticClass: "room-image" },
              [
                _c("vue-load-image", [
                  _c("img", {
                    staticClass: "image",
                    attrs: { slot: "image", src: _vm.room.image },
                    slot: "image",
                  }),
                  _c("img", {
                    staticClass: "preloader",
                    attrs: {
                      slot: "preloader",
                      src: require("@/assets/images/loading.png"),
                    },
                    slot: "preloader",
                  }),
                  _c("img", {
                    staticClass: "error",
                    attrs: {
                      slot: "error",
                      src: require("@/assets/images/img-not-found.svg"),
                    },
                    slot: "error",
                  }),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "info-box" }, [
              _c("div", { staticClass: "name mr-10" }, [
                _vm._v(_vm._s(_vm.room.name)),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("span", { staticClass: "type" }, [
                  _vm._v(" " + _vm._s(_vm.room.roomType) + " "),
                ]),
                _c("span", { staticClass: "app" }, [
                  _vm._v("(" + _vm._s(_vm.room.applist) + ")"),
                ]),
                _c(
                  "span",
                  { staticClass: "icons-box" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "Pubblica",
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.room.ispublic,
                              expression: "room.ispublic",
                            },
                          ],
                          staticClass: "mdi mdi-web ml-10",
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "Speciale",
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.room.isspecial,
                              expression: "room.isspecial",
                            },
                          ],
                          staticClass: "mdi mdi-star-outline ml-10",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "date" }, [
                _c("span", [_vm._v("Creata il: ")]),
                _c("strong", [_vm._v(_vm._s(_vm.room.datetime))]),
              ]),
              _c("div", { staticClass: "description" }, [
                _vm._v(" " + _vm._s(_vm.room.description) + " "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.room
        ? _c("div", { staticClass: "users-list-count" }, [
            _vm._v(" Utenti connessi: "),
            _c("strong", [_vm._v(_vm._s(_vm.room.userscount))]),
          ])
        : _vm._e(),
      _vm.room && _vm.room.userscount
        ? _c(
            "div",
            { staticClass: "users-list scrollable only-y" },
            _vm._l(_vm.users, function (user) {
              return _c("div", { key: user._id, staticClass: "user" }, [
                _c("div", { staticClass: "flex align-center" }, [
                  _c(
                    "div",
                    { staticClass: "propic" },
                    [
                      _c("vue-load-image", [
                        _c("img", {
                          staticClass: "image",
                          attrs: { slot: "image", src: user.image },
                          slot: "image",
                        }),
                        _c("img", {
                          staticClass: "preloader",
                          attrs: {
                            slot: "preloader",
                            src: require("@/assets/images/loading.png"),
                          },
                          slot: "preloader",
                        }),
                        _c("img", {
                          staticClass: "error",
                          attrs: {
                            slot: "error",
                            src: require("@/assets/images/img-not-found.svg"),
                          },
                          slot: "error",
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "box info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "username",
                        style: { color: user.color },
                        on: {
                          click: function ($event) {
                            return _vm.openUser(user)
                          },
                        },
                      },
                      [_vm._v(" @" + _vm._s(user.username) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "icons-box" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: `Bloccato: ${user.ban_reason || "-"}`,
                              placement: "bottom",
                              disabled: _vm.size !== "large",
                            },
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: user.isBanned,
                                  expression: "user.isBanned",
                                },
                              ],
                              staticClass: "mdi mdi-cancel mr-10",
                            }),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: `Silenziato fino a: ${
                                user.muted_date || "-"
                              }`,
                              placement: "bottom",
                              disabled: _vm.size !== "large",
                            },
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: user.isMuted,
                                  expression: "user.isMuted",
                                },
                              ],
                              staticClass: "mdi mdi-volume-off mr-10",
                            }),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "admin",
                              placement: "top",
                              disabled: _vm.size !== "large",
                            },
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: user.admin,
                                  expression: "user.admin",
                                },
                              ],
                              staticClass: "mdi mdi-shield-outline mr-10",
                            }),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "pure-tooltip",
                                rawName: "v-pure-tooltip.up-right",
                                value: "iOS",
                                expression: "'iOS'",
                                modifiers: { "up-right": true },
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: user.os === "ios",
                                expression: "user.os === 'ios'",
                              },
                            ],
                            staticClass: "mr-10",
                          },
                          [_c("i", { staticClass: "mdi mdi-apple" })]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "pure-tooltip",
                                rawName: "v-pure-tooltip.up-right",
                                value: "Android",
                                expression: "'Android'",
                                modifiers: { "up-right": true },
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: user.os === "android",
                                expression: "user.os === 'android'",
                              },
                            ],
                            staticClass: "mr-10",
                          },
                          [_c("i", { staticClass: "mdi mdi-android" })]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "pure-tooltip",
                                rawName: "v-pure-tooltip.up-right",
                                value: "Web",
                                expression: "'Web'",
                                modifiers: { "up-right": true },
                              },
                              {
                                name: "show",
                                rawName: "v-show",
                                value: user.os === "web",
                                expression: "user.os === 'web'",
                              },
                            ],
                            staticClass: "mr-10",
                          },
                          [_c("i", { staticClass: "mdi mdi-web" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }