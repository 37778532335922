<template>
    <div v-loading="loading" class="card-base card-shadow--small">
        <div class="charts-box" v-show="data && data.length">
            <div class="slot">
                <slot></slot>
            </div>
            <div id="chart-online" class="pl-5 pr-10">
                <apexchart
                    type="area"
                    height="365"
                    ref="chartOnline"
                    :options="chartOnline"
                    :series="seriesOnline"
                ></apexchart>
            </div>
            <div id="chart-messages" class="pl-5 pr-10 pt-20">
                <apexchart
                    type="line"
                    height="180"
                    ref="chartMessages"
                    :options="chartMessages"
                    :series="seriesMessages"
                ></apexchart>
            </div>
            <div id="chart-line" class="pl-5 pr-10 pt-15">
                <apexchart type="area" height="130" ref="chartMap" :options="chartMap" :series="seriesMap"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import dayjs from "dayjs"
import VueApexCharts from "vue-apexcharts"
import it from "apexcharts/dist/locales/it.json"
import { ma, dma, ema, sma, wma } from "moving-averages"
import smoothish from "smoothish"

export default {
    name: "Chart",
    props: ["data", "byRoom"],
    data() {
        return {
            chart: {},
            seriesOnline: [
                {
                    name: "Online",
                    type: "area",
                    data: []
                },
                {
                    name: "Media • EMA(24)",
                    type: "line",
                    data: []
                }
            ],
            seriesMessages: [
                {
                    name: "Messaggi",
                    type: "line",
                    data: []
                },
                {
                    name: "Immagini",
                    type: "line",
                    data: []
                }
            ],
            seriesMap: [
                {
                    data: []
                }
            ],

            chartOnline: {
                chart: {
                    id: "online",
                    group: "main",
                    defaultLocale: "it",
                    locales: [it],
                    type: "area",
                    height: 365,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        autoScaleYaxis: false
                    },
                    toolbar: {
                        // show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    },
                    events: {
                        markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
                            this.emit(this.chart.data[dataPointIndex])
                        }
                    }
                },
                colors: ["#34a8f9", "#ec2061"],
                annotations: {
                    yaxis: [
                        {
                            y: 0,
                            borderColor: "#ec2061",
                            label: {
                                borderColor: "#ec2061",
                                style: {
                                    color: "#fff",
                                    background: "#ec2061"
                                },
                                text: "MIN"
                            }
                        },
                        {
                            y: 0,
                            borderColor: "#ec2061",
                            label: {
                                borderColor: "#ec2061",
                                style: {
                                    color: "#fff",
                                    background: "#ec2061"
                                },
                                text: "MAX"
                            }
                        },
                        {
                            y: 0,
                            borderColor: "#ec2061",
                            label: {
                                borderColor: "#ec2061",
                                style: {
                                    color: "#fff",
                                    background: "#ec2061"
                                },
                                text: "AVG"
                            }
                        }
                    ]
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: "hollow"
                },
                yaxis: {
                    labels: {
                        minWidth: 45,
                        formatter: val => parseInt(val)
                    },
                    forceNiceScale: true,
                    tickAmount: 5,
                    min: 0,
                    max: 0
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        show: false,
                        datetimeUTC: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                tooltip: {
                    x: {
                        format: "dd MMM @ HH:mm"
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: [4, 2]
                },
                fill: {
                    type: ["gradient", "solid"],
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
                noData: {
                    text: "Caricamento..."
                },
                title: {
                    text: "Utenti online",
                    offsetX: 20,
                    style: {
                        fontWeight: 700,
                        fontFamily: '"Nunito Sans", Helvetica, Arial, sans-serif'
                    }
                }
            },

            chartMessages: {
                chart: {
                    id: "messages",
                    group: "main",
                    defaultLocale: "it",
                    locales: [it],
                    type: "line",
                    height: 180,
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        autoScaleYaxis: false
                    },
                    toolbar: {
                        // show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    },
                    events: {
                        markerClick: (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
                            this.emit(this.chart.data[dataPointIndex])
                        }
                    }
                },
                colors: ["#00E396", "#f7ba2a"],
                stroke: {
                    curve: "straight",
                    width: 2
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: "hollow"
                },
                yaxis: {
                    labels: {
                        minWidth: 45,
                        formatter: val => parseInt(val)
                    },
                    forceNiceScale: true,
                    tickAmount: 5,
                    min: 0,
                    max: 0
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        datetimeUTC: false
                    }
                },
                tooltip: {
                    x: {
                        format: "dd MMM @ HH:mm"
                    }
                },
                fill: {
                    type: "solid"
                },
                legend: {
                    offsetY: 5
                },
                noData: {
                    text: "Caricamento..."
                },
                title: {
                    text: "Messaggi inviati",
                    offsetX: 20,
                    style: {
                        fontWeight: 700,
                        fontFamily: '"Nunito Sans", Helvetica, Arial, sans-serif'
                    }
                },
                selection: {
                    enabled: false
                }
            },

            chartMap: {
                chart: {
                    id: "map",
                    height: 130,
                    defaultLocale: "it",
                    locales: [it],
                    type: "area",
                    animations: {
                        enabled: false
                    },
                    brush: {
                        targets: ["online", "messages"],
                        //target: "main",
                        enabled: true,
                        autoScaleYaxis: false
                    },
                    selection: {
                        enabled: true
                    },
                    toolbar: {
                        show: true,
                        // offsetY: -25,
                        tools: {
                            download: false,
                            selection: true,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: [
                                {
                                    icon: "<span class='large'>1 Giorno</span><span class='small'>1G</span>",
                                    // index: 2,
                                    title: "1 Giorno",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("one_day")
                                    }
                                },
                                {
                                    icon: "<span class='large'>1 Settimana</span><span class='small'>1S</span>",
                                    //   index: 3,
                                    title: "1 Settimana",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("one_week")
                                    }
                                },
                                {
                                    icon: "<span class='large'>1 Mese</span><span class='small'>1M</span>",
                                    //  index: 4,
                                    title: "1 Mese",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("one_month")
                                    }
                                },
                                {
                                    icon: "<span class='large'>6 Mesi</span><span class='small'>6M</span>",
                                    //   index: 5,
                                    title: "6 Mesi",
                                    class: "custom-icon hide-mobile",
                                    click: (chart, options, e) => {
                                        this.updateData("six_months")
                                    }
                                },
                                {
                                    icon: "<span class='large'>Tutto</span><span class='small'>Tutto</span>",
                                    //    index: 6,
                                    title: "Tutto",
                                    class: "custom-icon",
                                    click: (chart, options, e) => {
                                        this.updateData("all")
                                    }
                                }
                            ]
                        },
                        export: {
                            csv: {
                                filename: "chat",
                                headerCategory: "Data e ora",
                                dateFormatter(timestamp) {
                                    return dayjs(timestamp).format("DD/MM/YYYY HH:mm")
                                }
                            },
                            svg: {
                                filename: "chat"
                            },
                            png: {
                                filename: "chat"
                            }
                        }
                    }
                },
                colors: ["#008FFB"],
                fill: {
                    type: "gradient",
                    gradient: {
                        opacityFrom: 0.91,
                        opacityTo: 0.1
                    }
                },
                xaxis: {
                    type: "datetime",
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    forceNiceScale: false,
                    tickAmount: 1,
                    min: 0
                }
            },

            loading: false
        }
    },
    watch: {
        data(val) {
            //  this.reset()
            this.loading = true
            setTimeout(() => {
                if (this.data && this.data.length) {
                    this.parseData()
                }
            }, 100)
        }
    },
    methods: {
        emit(data) {
            this.$emit("click", data)
        },
        parseData() {
            const data = _.map(this.data, d => ({
                date: d.created_at,
                total: d.data.total || 0,
                messages: d.data.messages || 0,
                images: d.data.images || 0,
                rooms: d.data.rooms
            }))
            const total = data.map(d => d.total || 0)

            this.chart.data = data

            const online = _.map(data, d => [this.$dayjs(d.date).valueOf(), d.total || 0])
            this.seriesOnline[0].data = online

            //const ma_data = ema(total, 48)
            const ma_data = smoothish(total, { radius: 24, algorithm: "movingAverage" /*falloff: "step"*/ })

            const avgLine = []
            for (const d in online) {
                avgLine.push([online[d][0], ma_data[d]] || 0)
            }
            this.seriesOnline[1].data = avgLine

            this.seriesMessages[0].data = _.map(data, d => [this.$dayjs(d.date).valueOf(), d.messages || 0])
            this.seriesMessages[1].data = _.map(data, d => [this.$dayjs(d.date).valueOf(), d.images || 0])
            this.seriesMap[0].data = _.map(data, d => [this.$dayjs(d.date).valueOf(), d.total || 0])
            this.chartMap.yaxis.max = _.max(total)
            this.chartOnline.yaxis.max = _.max(total)
            this.chartMessages.yaxis.max = _.max(data.map(d => d.messages || 0))

            const min = _.toSafeInteger(_.min(total))
            const max = _.toSafeInteger(_.max(total))
            const avg = _.toSafeInteger(_.mean(total))
            this.chartOnline.annotations.yaxis[0].y = min
            this.chartOnline.annotations.yaxis[0].label.text = "MIN " + min
            this.chartOnline.annotations.yaxis[1].y = max
            this.chartOnline.annotations.yaxis[1].label.text = "MAX " + max
            this.chartOnline.annotations.yaxis[2].y = avg
            this.chartOnline.annotations.yaxis[2].label.text = "AVG " + avg

            setTimeout(() => {
                this.updateData("one_week")
            }, 500)

            this.loading = false
        },
        updateData(timeline) {
            if (this.$refs.chartOnline) {
                const firstTime = this.seriesMap[0].data[0][0]
                const lastTime = this.seriesMap[0].data[this.seriesMap[0].data.length - 1][0]

                let time = firstTime

                switch (timeline) {
                    case "one_day":
                        time = this.$dayjs(lastTime).subtract(1, "day").valueOf()
                        break
                    case "one_week":
                        time = this.$dayjs(lastTime).subtract(1, "week").valueOf()
                        break
                    case "one_month":
                        time = this.$dayjs(lastTime).subtract(1, "month").valueOf()
                        break
                    case "six_months":
                        time = this.$dayjs(lastTime).subtract(6, "month").valueOf()
                        break
                    case "all":
                    default:
                        time = firstTime
                        break
                }
                this.$refs.chartOnline.zoomX(time > firstTime ? time : firstTime, lastTime)
            }
        }
    },
    mounted() {
        if (this.data && this.data.length) {
            this.parseData()
        }
    },
    components: {
        apexchart: VueApexCharts
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.charts-box {
    :deep {
        .apexcharts-toolbar {
            max-width: inherit;

            .apexcharts-selection-icon {
                display: none;
            }

            .custom-icon {
                width: auto;
                padding: 0 8px;
                display: inline-block;
                background: #e8edf1;
                font-weight: bold;
                margin-left: 10px;
                font-size: 12px;
                line-height: 22px;
                border-radius: 4px;

                &:hover {
                    color: #000;
                }

                .small {
                    display: none;
                }
            }
        }
    }

    #chart-line {
        background: $background-color;
    }
    .slot {
        background: darken($background-color, 0%);
        margin: 20px;
        border-radius: 4px;
    }
}

@media (max-width: 1400px) {
    .charts-box {
        :deep {
            .apexcharts-toolbar {
                .custom-icon {
                    .small {
                        display: inline;
                    }
                    .large {
                        display: none;
                    }
                }
                .apexcharts-element-hidden {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .charts-box {
        :deep {
            .apexcharts-toolbar {
                .custom-icon {
                    &.hide-mobile {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
