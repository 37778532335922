<template>
	<div class="header flex" :class="[size]" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
		<div @click="resetThread" class="clickable box go-to-back"><i class="mdi mdi-arrow-left"></i> indietro</div>
		<div class="center box grow"></div>
		<div class="arrows box">
			<span class="counter">{{ index }} / {{ count }}</span>
			<button @click="openPrev" :disabled="isFirst">
				<i class="mdi mdi-chevron-left"></i>
			</button>
			<button @click="openNext">
				<i class="mdi mdi-chevron-right"></i>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "ThreadHeader",
	props: ["threadId", "isFirst", "index", "count", "size"],
	methods: {
		swipeLeft() {
			this.$emit("swipeLeft")
		},
		swipeRight() {
			this.$emit("swipeRight")
		},
		openPrev() {
			this.$emit("openPrev")
		},
		openNext() {
			this.$emit("openNext")
		},
		resetThread() {
			this.$emit("reset")
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	.clickable {
		cursor: pointer;
	}

	.go-to-back {
		padding: 20px;
		font-weight: bold;
		display: none;
	}

	.arrows {
		display: none;
		margin-right: 10px;

		.counter {
			line-height: 63px;
			font-weight: bold;
			font-size: 13px;
		}

		button {
			margin: 10px 0;
			font-size: 30px;
			cursor: pointer;
			background: transparent;
			border: none;
			outline: none;
			padding: 0;
			width: 38px;
			text-align: center;
		}
	}

	&.medium,
	&.small {
		.clickable {
			cursor: pointer;
		}

		.go-to-back {
			display: inherit;
		}

		.arrows {
			display: inherit;
		}
	}
}
</style>
