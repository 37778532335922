<template>
	<div class="file-drop">
		<label :class="{ drop: dropped, hover }" @dragover="dragover" @dragleave="dragleave" @drop="drop">
			<span v-if="!icon">{{ placeholder }}</span>
			<i v-if="icon" :class="icon"></i>
			<input type="file" :multiple="multiple" @change="onChange" ref="file" :accept="accept" />
		</label>
	</div>
</template>

<script>
export default {
	name: "FileDrop",
	props: {
		placeholder: {
			type: String,
			default: "Scegli"
		},
		icon: {
			type: String
		},
		accept: {
			type: String,
			default: ".jpg,.jpeg,.png,.webp"
		},
		multiple: {
			type: Boolean,
			default: false
		},
		hover: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dropped: false
		}
	},
	computed: {},
	watch: {},
	methods: {
		onChange() {
			if (this.multiple) {
				this.$emit("change", this.$refs.file.files)
			} else {
				this.$emit("change", this.$refs.file.files[0])
			}
		},
		dragover(event) {
			event.preventDefault()
			this.dropped = true
			this.$emit("dragover", event)
		},
		dragleave(event) {
			event.preventDefault()
			this.dropped = false
			this.$emit("dragleave", event)
		},
		drop(event) {
			event.preventDefault()
			this.$refs.file.files = event.dataTransfer.files
			this.onChange()
			this.dropped = false
			this.$emit("drop", event)
		}
	},
	mounted() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.file-drop {
	label {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		cursor: pointer;
		transition: all 0.2s;

		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		--flex-grid: 12;

		span {
			margin: auto;
			padding: 16px 20px;
			transition: all 0.2s;
			background: rgba(0, 0, 0, 0.4);
			border-radius: 8px;
			font-size: 16px;
			font-weight: bold;
			color: #fff;
			border: 2px solid rgba(255, 255, 255, 0.7);
		}

		i {
			margin: auto;
			font-size: 60px;
			color: #fff;
			text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
		}

		&.hover {
			opacity: 0;

			&:hover {
				opacity: 1;
			}
		}

		&.drop {
			opacity: 1;
			background: rgba(52, 167, 249, 0.3);

			span {
				background: rgba(0, 0, 0, 0.2);
			}
		}

		input {
			display: none;
		}
	}
}
</style>
