var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:swipe.left",
          value: _vm.swipeLeft,
          expression: "swipeLeft",
          arg: "swipe",
          modifiers: { left: true },
        },
        {
          name: "touch",
          rawName: "v-touch:swipe.right",
          value: _vm.swipeRight,
          expression: "swipeRight",
          arg: "swipe",
          modifiers: { right: true },
        },
      ],
      staticClass: "header flex",
      class: [_vm.size],
    },
    [
      _c(
        "div",
        {
          staticClass: "clickable box go-to-back",
          on: { click: _vm.resetThread },
        },
        [_c("i", { staticClass: "mdi mdi-arrow-left" }), _vm._v(" indietro")]
      ),
      _c("div", { staticClass: "center box grow" }),
      _c("div", { staticClass: "arrows box" }, [
        _c("span", { staticClass: "counter" }, [
          _vm._v(_vm._s(_vm.index) + " / " + _vm._s(_vm.count)),
        ]),
        _c(
          "button",
          { attrs: { disabled: _vm.isFirst }, on: { click: _vm.openPrev } },
          [_c("i", { staticClass: "mdi mdi-chevron-left" })]
        ),
        _c("button", { on: { click: _vm.openNext } }, [
          _c("i", { staticClass: "mdi mdi-chevron-right" }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }