<template>
    <div
        class="pregnancy card-base card-shadow--small"
        :class="{ ended: this.data.ended, 'in-progress': !this.data.ended, [this.data.ended_reason]: true }"
    >
        <div v-if="data" class="content flex">
            <div class="label">
                <div class="text" v-if="!this.data.ended">in corso</div>
                <div class="text" v-if="this.data.ended">{{ this.endedReason }}</div>
            </div>
            <div class="float-info">#{{ this.data.id }}</div>
            <div class="col box grow flex column">
                <div class="row mt-5">
                    <el-tooltip class="item" effect="dark" :content="type" placement="top-start">
                        <div class="type-icon">
                            <i class="mdi mdi-human-male" v-if="gnrFirst === 'M'"></i>
                            <i class="mdi mdi-human-female" v-if="gnrFirst === 'F'"></i>
                            <i class="mdi mdi-human-male" v-if="gnrSecond === 'M'"></i>
                            <i class="mdi mdi-human-female" v-if="gnrSecond === 'F'"></i>
                            <i
                                class="mdi mdi-account o-050"
                                v-if="!gnrFirst && !gnrSecond && data.type !== 'singola'"
                            ></i>
                            <i class="mdi mdi-account-question o-050" v-if="!gnrFirst && !gnrSecond"></i>
                        </div>
                    </el-tooltip>
                    <div class="names" v-if="names">{{ names }}</div>
                    <div class="names o-040" v-if="!names">{{ "nome non scelto" }}</div>
                </div>
                <div class="row mt-5 ml-5">
                    <el-tooltip class="item" effect="dark" :content="dateType" placement="bottom">
                        <div class="start-date">{{ startDate }}</div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="endDateTip" placement="bottom">
                        <div class="end-date" v-if="endDate"><span class="mh-10"> - </span>{{ endDate }}</div>
                    </el-tooltip>
                </div>

                <div class="row flex align-center mt-10 ml-5">
                    <div class="badge-box flex">
                        <el-popover
                            placement="left"
                            title="Calci"
                            width="200"
                            trigger="click"
                            popper-class="pregnancy-popover"
                        >
                            <ul class="items-list">
                                <li class="item" v-for="item in data.kicks" :key="item.id">
                                    {{ item.date | dayjs("format", "DD MMM YYYY [alle] HH:mm") }}
                                </li>
                                <li class="item" v-if="!data.kicks.length">nessun calcio registrato</li>
                            </ul>
                            <div class="badge clickable" slot="reference">
                                <div class="left"><i class="mdi mdi-foot-print"></i></div>
                                <div class="right">{{ data.kicks.length }}</div>
                            </div>
                        </el-popover>
                        <el-popover
                            placement="left"
                            title="Contrazioni"
                            width="300"
                            trigger="click"
                            popper-class="pregnancy-popover"
                        >
                            <ul class="items-list">
                                <li class="item" v-for="item in data.contractions" :key="item.id">
                                    <strong>
                                        {{ duration(item.duration) }}
                                    </strong>
                                    <span class="o-070 ml-10">
                                        {{ item.date | dayjs("format", "DD MMM YYYY [alle] HH:mm") }}
                                    </span>
                                </li>
                                <li class="item" v-if="!data.contractions.length">nessuna contrazione registrata</li>
                            </ul>
                            <div class="badge clickable" slot="reference">
                                <div class="left">
                                    <i class="mdi mdi-alert-octagram-outline"></i>
                                </div>
                                <div class="right">{{ data.contractions.length }}</div>
                            </div>
                        </el-popover>
                    </div>
                    <div class="buttons-box">
                        <el-popover
                            placement="left"
                            title="Esami"
                            width="350"
                            trigger="click"
                            popper-class="pregnancy-popover"
                        >
                            <ul class="items-list">
                                <li class="item" v-for="item in data.exams" :key="item.id">
                                    <strong> {{ item.exam.title }} </strong>
                                    <br />
                                    <span class="o-070">
                                        {{ item.updated_at | dayjs("format", "DD MMM YYYY [alle] HH:mm") }}
                                    </span>
                                </li>
                                <li class="item" v-if="!data.exams.length">nessun esame effettuato</li>
                            </ul>
                            <div class="btn clickable ml-4" slot="reference">
                                <i class="mdi mdi-clipboard-text-outline"></i>
                            </div>
                        </el-popover>
                        <el-popover
                            placement="left"
                            title="Peso"
                            width="250"
                            trigger="click"
                            popper-class="pregnancy-popover"
                        >
                            <ul class="items-list">
                                <li class="item" v-for="item in data.weights" :key="item.id">
                                    <strong> {{ item.weight }} g </strong>
                                    <span class="o-070 ml-10">
                                        {{ item.date | dayjs("format", "DD MMM YYYY [alle] HH:mm") }}
                                    </span>
                                </li>
                                <li class="item" v-if="!data.weights.length">nessun peso registrato</li>
                            </ul>
                            <div class="btn clickable ml-16" slot="reference">
                                <i class="mdi mdi-scale-balance"></i>
                            </div>
                        </el-popover>
                        <div
                            class="btn clickable ml-16"
                            v-if="can_ViewPregnancyDetails"
                            @click="dialogItemVisible = true"
                        >
                            <i class="mdi mdi-information-outline"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            title="Dettaglio gravidanza"
            :visible.sync="dialogItemVisible"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            width="600px"
        >
            <print-object
                :printable-object="this.data"
                :initial-collapsed="['kicks', 'contractions', 'exams', 'weights']"
            ></print-object>
        </el-dialog>
    </div>
</template>

<script>
import PrintObject from "vue-print-object"

const Pregnancy = function (
    id,
    user_id,
    start_date,
    date_type,
    ridatazione_ecografica,
    assumed_delivery_date,
    fixed_delivery_date,
    type,
    genre,
    name_first_child,
    name_second_child,
    ended,
    ended_date,
    ended_reason,
    created_at,
    updated_at,
    kicks,
    contractions,
    exams,
    weights
) {
    this.id = id
    this.user_id = user_id
    this.start_date = start_date
    this.date_type = date_type
    this.ridatazione_ecografica = ridatazione_ecografica
    this.assumed_delivery_date = assumed_delivery_date
    this.fixed_delivery_date = fixed_delivery_date
    this.type = type
    this.genre = genre
    this.name_first_child = name_first_child
    this.name_second_child = name_second_child
    this.ended = ended
    this.ended_date = ended_date
    this.ended_reason = ended_reason
    this.created_at = created_at
    this.updated_at = updated_at
    this.kicks = kicks
    this.contractions = contractions
    this.exams = exams
    this.weights = weights
}

export default {
    name: "Pregnancy",
    props: {
        data: [Pregnancy, Object]
    },
    data() {
        return {
            dialogItemVisible: false
        }
    },
    computed: {
        can_ViewPregnancyDetails() {
            return this.$store.getters.can_ViewPregnancyDetails
        },
        type() {
            const parsed = this.data.type.replace(/_/g, " ")
            const gnre = []
            if (this.gnrFirst) gnre.push(this.gnrFirst === "M" ? "Machio" : "Femmina")
            if (this.gnrSecond) gnre.push(this.gnrSecond === "M" ? "Machio" : "Femmina")
            return parsed + (gnre.length ? ": " + gnre.join(", ") : ": sesso sconosciuto")
        },
        names() {
            const names = []
            if (this.data.name_first_child) names.push(this.data.name_first_child)
            if (this.data.name_second_child) names.push(this.data.name_second_child)
            return names.join(", ")
        },
        gnrFirst() {
            return this.data?.genre?.charAt(0)
        },
        gnrSecond() {
            return this.data?.genre?.charAt(1)
        },
        startDate() {
            return this.$dayjs(this.data.start_date).format("DD MMM YYYY")
        },
        endDate() {
            const assumed = this.data.assumed_delivery_date
                ? this.$dayjs(this.data.assumed_delivery_date)
                      .add(this.data.ridatazione_ecografica || 0, "d")
                      .toDate()
                : null
            const fixed = this.data.fixed_delivery_date ? this.$dayjs(this.data.fixed_delivery_date).toDate() : null
            const end = this.data.ended_date ? this.$dayjs(this.data.ended_date).toDate() : null
            const date = end || fixed || assumed

            return date ? this.$dayjs(date).format("DD MMM YYYY") : null
        },
        endedReason() {
            switch (this.data.ended_reason) {
                case "abortion":
                    return "aborto"
                case "birth":
                    return "nascita"
                default:
                    return "sconosciuto"
            }
        },
        endDateTip() {
            const type = this.data.ended_date
                ? "end"
                : this.data.fixed_delivery_date
                ? "Parto programmato"
                : this.data.assumed_delivery_date
                ? "Data presunto parto"
                : null

            switch (type) {
                case "end":
                    return `Data fine: ${this.endedReason}`
                default:
                    return type
            }
        },
        dateType() {
            switch (this.data.date_type) {
                case "last_period":
                    return "ultima mestruazione"
                case "conception_date":
                    return "concepimento"
                case "by_delivery_date":
                    return "in base alla data del parto"
                default:
                    return ""
            }
        }
    },
    watch: {},
    methods: {
        duration(s) {
            const duration = this.$dayjs.duration(s, "s")
            const hou = duration.$d.hours
            const min = duration.$d.minutes
            const sec = duration.$d.seconds

            let str = ""

            if (hou === 1) str += "un'ora "
            if (hou > 1) str += `${hou} ore `

            if (min === 1) str += "un minuto e "
            if (min > 1) str += `${min} minuto e `

            if (hou > 0 && min === 0) str += "0 minuti e "

            if (sec === 1) str += "un secondo"
            else str += `${sec} secondi`

            return str
        }
    },
    mounted() {},
    components: { PrintObject }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.pregnancy {
    --card-color: #17acc7;
    --card-color-rgb: 23, 172, 199;
    --border-width: 4px;

    //display: inline-block;
    float: left;
    overflow: visible !important;
    margin-top: 40px;
    border: var(--border-width) solid var(--card-color);
    box-sizing: border-box;
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;

    &.in-progress {
        display: table;
        margin-bottom: 30px;
    }
    &.unknown {
        --card-color: rgb(192, 192, 192);
        --card-color-rgb: 192, 192, 192;
    }
    &.birth {
        --card-color: rgb(130, 209, 130);
        --card-color-rgb: 130, 209, 130;
    }
    &.abortion {
        --card-color: rgb(247, 102, 102);
        --card-color-rgb: 247, 102, 102;
    }

    .content {
        padding: 10px;
        overflow: visible;
        position: relative;

        .float-info {
            position: absolute;
            top: -20px;
            right: 4px;
            color: $color-info;
            font-size: 12px;
        }

        .label {
            position: absolute;
            top: -29px;
            left: 0px;
            background: white;
            box-sizing: border-box;
            padding: 0px 10px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            //border: 2px solid red;
            //border-bottom-color: white;
            box-shadow: 0px 8px 0px 0px white, 0px 0px 0px var(--border-width) var(--card-color),
                0 3px 6px 0 rgba(40, 40, 90, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.065);
            color: var(--card-color);

            .text {
                position: relative;
                top: 4px;
                font-weight: bold;
            }

            &::after {
                content: "";
                width: 12px;
                height: 12px;
                background: transparent;
                display: block;
                position: absolute;
                right: -16px;
                bottom: -3px;
                border-radius: 50%;
                box-shadow: -4px 4px 0px 0px var(--card-color), -10px 9px 0px -2px #fff;
            }
        }

        .type-icon {
            font-size: 22px;
            display: inline-block;
            margin: 0 4px;

            .mdi-human-male {
                color: #63bcfd;
            }
            .mdi-human-female {
                color: #f991ff;
            }
        }
        .names {
            margin: 0 8px;
            font-size: 20px;
            font-weight: bold;
            display: inline-block;
            position: relative;
            top: -2px;
        }
        .start-date,
        .end-date {
            text-transform: capitalize;
            margin: 4px 8px;
            margin-bottom: 2px;
            display: inline-block;
        }
        .start-date {
            margin-left: 0;
        }
        .end-date {
            margin-left: 0;
        }

        .badge-box {
            .badge {
                display: inline-block;
                border-radius: 8px;
                overflow: hidden;
                border: 2px solid rgba(var(--card-color-rgb), 0.2);
                margin-right: 8px;

                .left {
                    background: rgba(var(--card-color-rgb), 0.2);
                    display: inline-block;
                    padding: 1px 5px;
                }
                .right {
                    padding: 1px 8px;
                    display: inline-block;
                }

                &.clickable {
                    cursor: pointer;
                    outline: none;
                }
            }
        }

        .buttons-box {
            height: 30px;

            .btn {
                display: inline-block;
                font-size: 18px;

                &.clickable {
                    cursor: pointer;
                    outline: none;
                }
            }
        }
    }
}
.pregnancy-popover {
    .items-list {
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch !important;
        list-style: none;
        margin: 0;
        padding: 0;
        padding-left: 4px;

        li {
            box-shadow: -6px 0px 0px -2px rgba($color-primary, 0.5);
            padding: 0;
            margin: 10px 0;
            padding-left: 6px;
            list-style: none;

            strong {
                font-size: 16px;
                word-break: break-word;
            }
        }
    }
}
</style>
