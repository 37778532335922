var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sexualrelation card-base card-shadow--small" },
    [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "box flex column" }, [
          _c("div", { staticClass: "mb-8" }, [
            _c("strong", { staticClass: "text-capitalized" }, [
              _vm._v(
                _vm._s(_vm._f("dayjs")(_vm.data.day, "format", "DD MMMM"))
              ),
            ]),
            _c("span", { staticClass: "ml-5" }, [
              _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.day, "format", "YYYY"))),
            ]),
          ]),
          _c("div", { class: { "o-050": !_vm.protection } }, [
            _vm._v(" " + _vm._s(_vm.protect) + " "),
          ]),
        ]),
        _c("div", { staticClass: "box flex column ml-30" }, [
          _c("div", { staticClass: "mb-8" }, [
            _vm._v(" Desiderio: "),
            _c("strong", [_vm._v(_vm._s(_vm.data.desire ? "Si" : "No"))]),
          ]),
          _c("div", [
            _vm._v(" Masturbazione: "),
            _c("strong", [_vm._v(_vm._s(_vm.data.masturbation ? "Si" : "No"))]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }