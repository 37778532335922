var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-actions" },
    [
      _c(
        "div",
        [
          _c(
            "el-tooltip",
            { attrs: { content: "Scarica PDF", placement: "top-start" } },
            [
              _vm.can_SaveUserPDF
                ? _c("button", { on: { click: _vm.savePDF } }, [
                    _c("i", { staticClass: "mdi mdi-file-download-outline" }),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "Invia email reset password",
                placement: "top-start",
              },
            },
            [
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    title: "Sicura di voler procedere ?",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                  },
                  on: { confirm: _vm.sendForgotPasswordEmail },
                },
                [
                  _c(
                    "button",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [_c("i", { staticClass: "mdi mdi-email-lock" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "Generazione link reset password",
                placement: "top",
              },
            },
            [
              _c("button", { on: { click: _vm.getForgotPasswordLink } }, [
                _c("i", { staticClass: "mdi mdi-link-lock" }),
              ]),
            ]
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Modifica", placement: "top" } },
            [
              _vm.can_EditUser
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editing,
                          expression: "!editing",
                        },
                      ],
                      staticClass: "primary",
                      on: { click: _vm.editUser },
                    },
                    [_c("i", { staticClass: "mdi mdi-pencil-outline" })]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content:
                  "Salva" + (!_vm.saveUserEnabled ? ": form non valido" : ""),
                placement: "top",
              },
            },
            [
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    title: "Sicura di voler procedere ?",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                  },
                  on: { confirm: _vm.saveUser, cancel: _vm.cancelSaveUser },
                },
                [
                  _vm.can_SaveUser
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editing,
                              expression: "editing",
                            },
                          ],
                          staticClass: "success",
                          attrs: {
                            slot: "reference",
                            disabled: !_vm.saveUserEnabled,
                          },
                          slot: "reference",
                        },
                        [_c("i", { staticClass: "mdi mdi-check" })]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Silenzia", placement: "top" } },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: "300", trigger: "click" },
                  model: {
                    value: _vm.mutePopoverVisible,
                    callback: function ($$v) {
                      _vm.mutePopoverVisible = $$v
                    },
                    expression: "mutePopoverVisible",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "user-actions__popover-prompt" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-left mb-10" },
                        [
                          _c("div", { staticClass: "o-080" }, [
                            _vm._v("Durata in ore"),
                          ]),
                          _c("el-input-number", {
                            attrs: { min: 1 },
                            model: {
                              value: _vm.muteDuration,
                              callback: function ($$v) {
                                _vm.muteDuration = $$v
                              },
                              expression: "muteDuration",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-20" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "Motivazione",
                                clearable: "",
                              },
                              model: {
                                value: _vm.muteReason,
                                callback: function ($$v) {
                                  _vm.muteReason = $$v
                                },
                                expression: "muteReason",
                              },
                            },
                            _vm._l(_vm.penaltyReasons, function (item) {
                              return _c("el-option", {
                                key: item.penalty,
                                attrs: {
                                  label: item.penalty,
                                  value: item.penalty,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.mutePopoverClose },
                        },
                        [_vm._v("Annulla")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            size: "mini",
                            disabled: _vm.muteDuration <= 0,
                          },
                          on: { click: _vm.mute },
                        },
                        [_vm._v(" Silenzia ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.muted,
                          expression: "!muted",
                        },
                      ],
                      staticClass: "warning",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_c("i", { staticClass: "mdi mdi-volume-off" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Desilenzia", placement: "top" } },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: "300", trigger: "click" },
                  model: {
                    value: _vm.unmutePopoverVisible,
                    callback: function ($$v) {
                      _vm.unmutePopoverVisible = $$v
                    },
                    expression: "unmutePopoverVisible",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "user-actions__popover-prompt" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Motivazione" },
                        model: {
                          value: _vm.muteReason,
                          callback: function ($$v) {
                            _vm.muteReason = $$v
                          },
                          expression: "muteReason",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.mutePopoverClose },
                        },
                        [_vm._v("Annulla")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: { click: _vm.unmute },
                        },
                        [_vm._v("Desilenzia")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.muted,
                          expression: "muted",
                        },
                      ],
                      staticClass: "success",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_c("i", { staticClass: "mdi mdi-volume-high" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Blocca", placement: "top-end" } },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: "300", trigger: "click" },
                  model: {
                    value: _vm.banPopoverVisible,
                    callback: function ($$v) {
                      _vm.banPopoverVisible = $$v
                    },
                    expression: "banPopoverVisible",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "user-actions__popover-prompt" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Motivazione", clearable: "" },
                          model: {
                            value: _vm.banReason,
                            callback: function ($$v) {
                              _vm.banReason = $$v
                            },
                            expression: "banReason",
                          },
                        },
                        _vm._l(_vm.penaltyReasons, function (item) {
                          return _c("el-option", {
                            key: item.penalty,
                            attrs: { label: item.penalty, value: item.penalty },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.banPopoverClose },
                        },
                        [_vm._v("Annulla")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: { click: _vm.ban },
                        },
                        [_vm._v("Blocca")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.banned,
                          expression: "!banned",
                        },
                      ],
                      staticClass: "danger",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_c("i", { staticClass: "mdi mdi-cancel" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Sblocca", placement: "top-end" } },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: "300", trigger: "click" },
                  model: {
                    value: _vm.unbanPopoverVisible,
                    callback: function ($$v) {
                      _vm.unbanPopoverVisible = $$v
                    },
                    expression: "unbanPopoverVisible",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "user-actions__popover-prompt" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Motivazione" },
                        model: {
                          value: _vm.banReason,
                          callback: function ($$v) {
                            _vm.banReason = $$v
                          },
                          expression: "banReason",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.banPopoverClose },
                        },
                        [_vm._v("Annulla")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: { click: _vm.unban },
                        },
                        [_vm._v("Sblocca")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.banned,
                          expression: "banned",
                        },
                      ],
                      staticClass: "success",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_c("i", { staticClass: "mdi mdi-account-check-outline" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            { attrs: { content: "Elimina", placement: "top-end" } },
            [
              _vm.can_DeleteUser
                ? _c(
                    "button",
                    {
                      staticClass: "danger",
                      on: { click: _vm.openDeleteUserDialog },
                    },
                    [_c("i", { staticClass: "mdi mdi-delete-outline" })]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "Cronologia Ban/Silence",
                placement: "top-end",
              },
            },
            [
              _c("button", { on: { click: _vm.emitHistoryOpen } }, [
                _c("i", { staticClass: "mdi mdi-history" }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `Elimina utente #${this.id}`,
            visible: _vm.deleteUserDialogVisible,
            width: "30%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteUserDialogVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              "Inserisci la tua password ed il codice OTP per confermare l'operazione"
            ),
          ]),
          _c("el-input", {
            staticClass: "mb-20",
            attrs: {
              size: "small",
              placeholder: "Password",
              "show-password": "",
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
          _c("el-input", {
            staticClass: "mb-20",
            attrs: { size: "small", placeholder: "OTP Code" },
            model: {
              value: _vm.otp,
              callback: function ($$v) {
                _vm.otp = $$v
              },
              expression: "otp",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: { click: _vm.closeDeleteUserDialog },
                },
                [_vm._v("Chiudi")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    plain: "",
                    disabled: !_vm.password || !_vm.otp,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteUser()
                    },
                  },
                },
                [_vm._v("Elimina")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }