<template>
	<el-select class="w-full" :clearable="true" v-model="model" :placeholder="placeholder || 'Seleziona'">
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "BabyFilter",
	props: ["value", "placeholder"],
	data() {
		return {
			model: null,
			options: [
				{ value: "1", label: "Si" },
				{ value: "0", label: "No" }
			]
		}
	},
	watch: {
		model(val) {
			this.$emit("update:value", val)
		}
	}
}
</script>
