var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "qna-user flex align-center",
      class: _vm.size,
      on: {
        click: function ($event) {
          return _vm.open(_vm.user.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "user-pic" },
        [
          _c("vue-load-image", [
            _c("img", {
              staticClass: "image",
              attrs: { slot: "image", src: _vm.profilePic },
              slot: "image",
            }),
            _c("img", {
              staticClass: "preloader",
              attrs: {
                slot: "preloader",
                src: require("@/assets/images/loading.png"),
              },
              slot: "preloader",
            }),
            _c("img", {
              staticClass: "error",
              attrs: {
                slot: "error",
                src: require("@/assets/images/person.png"),
              },
              slot: "error",
            }),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "flex column justify-center pl-10 user-info" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.user.name) + " " + _vm._s(_vm.user.surname)),
        ]),
        _c("div", { staticClass: "username" }, [
          _vm._v("@" + _vm._s(_vm.user.username)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }