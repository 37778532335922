<template>
    <div class="page-profile scrollable only-y">
        <div class="account flex align-center">
            <div v-if="accountPicture" class="propic">
                <vue-load-image>
                    <img slot="image" class="image" :src="accountPicture" />
                    <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                    <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                </vue-load-image>
            </div>
            <div class="info-box" v-if="account">
                <div v-if="accountName">
                    <span class="o-050 mr-10">Nome</span><strong>{{ accountName }}</strong>
                </div>
                <div v-if="account.username">
                    <span class="o-050 mr-10">Username</span><strong>{{ account.username }}</strong>
                </div>
                <div v-if="account.email">
                    <span class="o-050 mr-10">Email</span><strong>{{ account.email }}</strong>
                </div>
                <div v-if="account.role">
                    <span class="o-050 mr-10">Ruolo</span>
                    <strong class="roles color" :class="account.role">{{ account.role }}</strong>
                </div>
            </div>
        </div>

        <!--
		<el-button plain @click="editPasswordDialogVisible = true">Modifica password</el-button>
		-->

        <el-dialog title="Modifica password" :visible.sync="editPasswordDialogVisible" width="30%">
            <el-input
                v-model="o_password"
                size="small"
                placeholder="Vecchia password"
                show-password
                class="mb-20"
            ></el-input>
            <el-input
                v-model="n_password"
                size="small"
                placeholder="Nuova password"
                show-password
                class="mb-20"
            ></el-input>
            <el-input
                v-model="c_password"
                size="small"
                placeholder="Conferma password"
                show-password
                class="mb-20"
            ></el-input>

            <div v-if="n_password && c_password && n_password !== c_password" class="warning-text">
                le password non coincidono
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="closeUpdatePassword" plain>Annulla</el-button>
                <el-button type="primary" plain :disabled="!isEditPwdAvailable">Conferma</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const VueLoadImage = () => import("vue-load-image")

export default {
    name: "Profile",
    data() {
        return {
            editPasswordDialogVisible: false,
            o_password: "",
            n_password: "",
            c_password: ""
        }
    },
    computed: {
        isEditPwdAvailable() {
            if (this.o_password && this.n_password && this.c_password && this.n_password === this.c_password) {
                return true
            } else {
                return false
            }
        },
        account() {
            return this.$store.getters.account
        },
        accountName() {
            return this.$store.getters.accountName
        },
        accountPicture() {
            return this.$store.getters.accountPicture || "-"
        }
    },
    methods: {
        closeUpdatePassword() {
            this.editPasswordDialogVisible = false
            this.o_password = ""
            this.n_password = ""
            this.c_password = ""
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: {
        "vue-load-image": VueLoadImage
    }
}
</script>

<style lang="scss" scoped>
.page-profile {
    overflow: hidden;

    .propic {
        margin-right: 20px;

        img {
            width: 100px;
            border-radius: 16px;
            outline: none;
        }
    }

    .info-box {
        div {
            margin-bottom: 5px;

            span {
                min-width: 80px;
                display: inline-block;
            }
        }
    }
}
@media (max-width: 900px) {
    .page-profile {
        .account {
            flex-direction: column;
            -webkit-box-orient: vertical;

            .propic {
                width: 100%;
                margin: 0;
                text-align: center;
                margin-bottom: 30px;

                img {
                    width: 100%;
                    max-width: 250px;
                }
            }

            .info-box {
                max-width: 100%;
                overflow: hidden;
                word-wrap: break-word;

                & > div {
                    margin-bottom: 20px;
                }
                span {
                    display: block;
                    clear: both;
                }
            }
        }
    }
}
</style>
