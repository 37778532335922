<template>
    <div class="user-actions">
        <div>
            <el-tooltip content="Scarica PDF" placement="top-start">
                <button v-if="can_SaveUserPDF" @click="savePDF">
                    <i class="mdi mdi-file-download-outline"></i>
                </button>
            </el-tooltip>

            <el-tooltip content="Invia email reset password" placement="top-start">
                <el-popconfirm
                    title="Sicura di voler procedere ?"
                    confirmButtonText="Si"
                    cancelButtonText="No"
                    @confirm="sendForgotPasswordEmail"
                >
                    <button slot="reference">
                        <i class="mdi mdi-email-lock"></i>
                    </button>
                </el-popconfirm>
            </el-tooltip>

            <el-tooltip content="Generazione link reset password" placement="top">
                <button @click="getForgotPasswordLink">
                    <i class="mdi mdi-link-lock"></i>
                </button>
            </el-tooltip>

            <el-tooltip content="Modifica" placement="top">
                <button v-if="can_EditUser" @click="editUser" class="primary" v-show="!editing">
                    <i class="mdi mdi-pencil-outline"></i>
                </button>
            </el-tooltip>

            <el-tooltip :content="'Salva' + (!saveUserEnabled ? ': form non valido' : '')" placement="top">
                <el-popconfirm
                    title="Sicura di voler procedere ?"
                    confirmButtonText="Si"
                    cancelButtonText="No"
                    @confirm="saveUser"
                    @cancel="cancelSaveUser"
                >
                    <button
                        v-if="can_SaveUser"
                        class="success"
                        v-show="editing"
                        slot="reference"
                        :disabled="!saveUserEnabled"
                    >
                        <i class="mdi mdi-check"></i>
                    </button>
                </el-popconfirm>
            </el-tooltip>

            <el-tooltip content="Silenzia" placement="top">
                <el-popover width="300" trigger="click" v-model="mutePopoverVisible">
                    <div class="user-actions__popover-prompt">
                        <div class="text-left mb-10">
                            <div class="o-080">Durata in ore</div>
                            <el-input-number v-model="muteDuration" :min="1"></el-input-number>
                        </div>
                        <div class="mb-20">
                            <el-select v-model="muteReason" placeholder="Motivazione" clearable>
                                <el-option
                                    v-for="item of penaltyReasons"
                                    :key="item.penalty"
                                    :label="item.penalty"
                                    :value="item.penalty"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <el-button @click="mutePopoverClose" size="mini" type="text">Annulla</el-button>
                        <el-button @click="mute" type="warning" size="mini" :disabled="muteDuration <= 0">
                            Silenzia
                        </el-button>
                    </div>
                    <button class="warning" slot="reference" v-show="!muted"><i class="mdi mdi-volume-off"></i></button>
                </el-popover>
            </el-tooltip>

            <el-tooltip content="Desilenzia" placement="top">
                <el-popover width="300" trigger="click" v-model="unmutePopoverVisible">
                    <div class="user-actions__popover-prompt">
                        <el-input placeholder="Motivazione" v-model="muteReason"></el-input>
                        <el-button @click="mutePopoverClose" size="mini" type="text">Annulla</el-button>
                        <el-button @click="unmute" type="success" size="mini">Desilenzia</el-button>
                    </div>
                    <button class="success" slot="reference" v-show="muted">
                        <i class="mdi mdi-volume-high"></i>
                    </button>
                </el-popover>
            </el-tooltip>

            <el-tooltip content="Blocca" placement="top-end">
                <el-popover width="300" trigger="click" v-model="banPopoverVisible">
                    <div class="user-actions__popover-prompt">
                        <el-select v-model="banReason" placeholder="Motivazione" clearable>
                            <el-option
                                v-for="item of penaltyReasons"
                                :key="item.penalty"
                                :label="item.penalty"
                                :value="item.penalty"
                            >
                            </el-option>
                        </el-select>
                        <el-button @click="banPopoverClose" size="mini" type="text">Annulla</el-button>
                        <el-button @click="ban" type="danger" size="mini">Blocca</el-button>
                    </div>
                    <button class="danger" slot="reference" v-show="!banned"><i class="mdi mdi-cancel"></i></button>
                </el-popover>
            </el-tooltip>

            <el-tooltip content="Sblocca" placement="top-end">
                <el-popover width="300" trigger="click" v-model="unbanPopoverVisible">
                    <div class="user-actions__popover-prompt">
                        <el-input placeholder="Motivazione" v-model="banReason"></el-input>
                        <el-button @click="banPopoverClose" size="mini" type="text">Annulla</el-button>
                        <el-button @click="unban" type="success" size="mini">Sblocca</el-button>
                    </div>
                    <button class="success" slot="reference" v-show="banned">
                        <i class="mdi mdi-account-check-outline"></i>
                    </button>
                </el-popover>
            </el-tooltip>

            <el-tooltip content="Elimina" placement="top-end">
                <button v-if="can_DeleteUser" @click="openDeleteUserDialog" class="danger">
                    <i class="mdi mdi-delete-outline"></i>
                </button>
            </el-tooltip>

            <el-tooltip content="Cronologia Ban/Silence" placement="top-end">
                <button @click="emitHistoryOpen">
                    <i class="mdi mdi-history"></i>
                </button>
            </el-tooltip>
        </div>

        <el-dialog
            :title="`Elimina utente #${this.id}`"
            :visible.sync="deleteUserDialogVisible"
            width="30%"
            append-to-body
        >
            <p>Inserisci la tua password ed il codice OTP per confermare l'operazione</p>
            <el-input v-model="password" size="small" placeholder="Password" show-password class="mb-20"></el-input>
            <el-input v-model="otp" size="small" placeholder="OTP Code" class="mb-20"></el-input>

            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDeleteUserDialog" plain>Chiudi</el-button>
                <el-button type="danger" plain :disabled="!password || !otp" @click="deleteUser()">Elimina</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import { saveAs } from "file-saver"
import { EventBus } from "@/event-bus"

export default {
    name: "UserActions",
    props: ["id", "banned", "muted", "editing", "saveUserEnabled"],
    data() {
        return {
            banPopoverVisible: false,
            unbanPopoverVisible: false,
            mutePopoverVisible: false,
            unmutePopoverVisible: false,
            deleteUserDialogVisible: false,

            banReason: "",
            muteReason: "",
            muteDuration: 0,

            password: "",
            otp: ""
        }
    },
    computed: {
        can_SaveUserPDF() {
            return this.$store.getters.can_SaveUserPDF
        },
        can_EditUser() {
            return this.$store.getters.can_EditUser
        },
        can_SaveUser() {
            return this.$store.getters.can_SaveUser
        },
        can_DeleteUser() {
            return this.$store.getters.can_DeleteUser
        },
        accountEmail() {
            return this.$store.getters.accountEmail
        },
        penaltyReasons() {
            return this.$store.getters.penaltyReasons
        }
    },
    methods: {
        savePDF() {
            this.$message("Creazione PDF...")

            return api.users
                .getPdf(this.id)
                .then(response => {
                    const blob = new Blob([response.data], { type: "application/pdf" })
                    saveAs(blob, `user-${this.id}.pdf`)

                    this.$message.success("PDF creato correttamente")
                })
                .catch(err => {
                    this.$message.error("Errore creazione PDF")
                })
        },
        getForgotPasswordLink() {
            this.$message("Generazione link cambio password...")

            return api.users
                .getForgotPasswordLink(this.id)
                .then(response => {
                    this.$prompt("Per copiarlo puoi usare il pulsante 'Copia'", "Link cambio password", {
                        confirmButtonText: "Copia",
                        cancelButtonText: "Chiudi",
                        inputValue: response.data.link
                    })
                        .then(({ value }) => {
                            this.$clipboard(response.data.link)
                            this.$message.success("Link copiato")
                        })
                        .catch(() => {})
                })
                .catch(err => {
                    this.$message.error("Errore Generazione link cambio password")
                })
        },
        sendForgotPasswordEmail() {
            this.$message("Invio email cambio password...")

            return api.users
                .sendForgotPasswordEmail(this.id)
                .then(response => {
                    this.$message.success("Email cambio password inviata correttamente")
                })
                .catch(err => {
                    this.$message.error("Errore Invio email cambio password")
                })
        },
        ban() {
            this.banPopoverClose()
            this.$message("Sto bloccando l'utente...")

            return api.users
                .ban(this.id, this.banReason)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Utente #${this.id} bloccato correttamente`
                    })
                    this.banPopoverReset()
                    this.emitNeedReload()
                    EventBus.$emit("update:user", { id: this.id, banned: true })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore blocco utente #${this.id}`
                    })
                })
        },
        unban() {
            this.banPopoverClose()
            this.$message("Sto sbloccando l'utente...")

            return api.users
                .unban(this.id, this.banReason)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Utente #${this.id} sbloccato correttamente`
                    })
                    this.banPopoverReset()
                    this.emitNeedReload()
                    EventBus.$emit("update:user", { id: this.id, banned: false })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore sblocco utente #${this.id}`
                    })
                })
        },
        mute() {
            if (this.muteDuration) {
                this.mutePopoverClose()
                this.$message("Sto silenziando l'utente...")

                return api.users
                    .mute(this.id, this.muteReason, this.muteDuration)
                    .then(response => {
                        this.$notify.success({
                            title: "Fatto!",
                            message: `Utente #${this.id} silenziato correttamente`
                        })
                        this.mutePopoverReset()
                        this.emitNeedReload()
                        EventBus.$emit("update:user", { id: this.id, muted: true })
                    })
                    .catch(err => {
                        this.$notify.error({
                            title: "Ops...",
                            message: `Errore silenziamento utente #${this.id}`
                        })
                    })
            }
            return false
        },
        unmute() {
            this.mutePopoverClose()
            this.$message("Sto desilenziando l'utente...")

            return api.users
                .unmute(this.id, this.muteReason)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Utente #${this.id} desilenziato correttamente`
                    })
                    this.mutePopoverReset()
                    this.emitNeedReload()
                    EventBus.$emit("update:user", { id: this.id, muted: false })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore desilenziamento utente #${this.id}`
                    })
                })
        },
        deleteUser() {
            if (this.password && this.otp) {
                api.auth
                    .login({ email: this.accountEmail, password: this.password, otp: this.otp })
                    .then(res => {
                        if (!_.isEmpty(res.body)) {
                            api.users
                                .delete(this.id)
                                .then(response => {
                                    this.emitUserDeleted()
                                    this.$notify.success({
                                        title: "Fatto!",
                                        message: `Utente #${this.id} eliminato`
                                    })
                                })
                                .catch(err => {
                                    this.$notify.error({
                                        title: "Ops...",
                                        message: `Errore eliminazione utente #${this.id}`
                                    })
                                })
                        } else {
                            this.$alert("Password errata", "Errore conferma", {
                                confirmButtonText: "OK",
                                type: "error"
                            })
                        }
                    })
                    .catch(err => {
                        this.$alert("Password errata", "Errore conferma", {
                            confirmButtonText: "OK",
                            type: "error"
                        })
                    })
            }
        },
        openDeleteUserDialog() {
            this.deleteUserDialogVisible = true
        },
        closeDeleteUserDialog() {
            this.deleteUserDialogVisible = false
            this.password = ""
            this.otp = ""
        },
        editUser() {
            this.$emit("edit-user")
        },
        saveUser() {
            this.$emit("save-user")
        },
        cancelSaveUser() {
            this.$emit("cancel-save-user")
        },
        banPopoverClose() {
            this.banPopoverVisible = false
            this.unbanPopoverVisible = false
        },
        mutePopoverClose() {
            this.mutePopoverVisible = false
            this.unmutePopoverVisible = false
        },
        banPopoverReset() {
            this.banReason = ""
        },
        mutePopoverReset() {
            this.muteReason = ""
            this.muteDuration = 0
        },
        emitNeedReload() {
            this.$emit("need-reload")
        },
        emitHistoryOpen() {
            this.$emit("history-open")
        },
        emitUserDeleted() {
            this.$emit("user-deleted")
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.user-actions {
    span {
        display: inline-block;
        //margin-right: 10px;
    }

    button:not(.el-button) {
        display: inline-block;
        cursor: pointer;
        background: rgba($text-color, 0.06);
        color: $text-color;
        padding: 0;
        border-radius: 6px;
        width: 50px;
        height: 50px;
        text-align: center;
        box-sizing: border-box;
        transition: all 0.2s;
        margin-right: 10px;
        margin-bottom: 10px;
        border: none;
        outline: none;
        font-size: 26px;

        &:hover {
            box-shadow: 0px 0px 0px 2px $text-color;
        }

        &.primary {
            background: rgba($color-primary, 0.1);
            color: $color-primary;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-primary;
            }
        }

        &.info {
            background: rgba($color-info, 0.1);
            color: $color-info;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-info;
            }
        }

        &.warning {
            background: rgba($color-warning, 0.1);
            color: $color-warning;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-warning;
            }
        }

        &.danger {
            background: rgba($color-danger, 0.1);
            color: $color-danger;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-danger;
            }
        }

        &.success {
            background: rgba($color-success, 0.1);
            color: $color-success;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-success;
            }
        }

        &:disabled {
            opacity: 0.6;
            cursor: default;

            &:hover {
                box-shadow: none;
            }
        }
    }
}

.user-actions__popover-prompt {
    text-align: right;

    .el-input {
        margin-bottom: 10px;
    }

    .el-select {
        margin-bottom: 10px;
        width: 100%;
    }
}
</style>
