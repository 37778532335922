<template>
    <div class="question card-base card-shadow--small" :class="size" v-if="question">
        <div class="header flex">
            <QnaUser :user="question.user" v-if="showUser" :size="size" class="mr-10"></QnaUser>
            <QnaDatetime :infoAbout="question" :size="size" class="box grow"></QnaDatetime>
            <QnaActions
                :item="question"
                :showGoto="showGoto"
                itemType="question"
                @restored="$emit('restored')"
                @deleted="$emit('deleted')"
            ></QnaActions>
        </div>
        <div class="content">
            <div class="text" v-if="question.content">
                {{ question.content }}
            </div>
            <div class="body-image" v-if="question.image">
                <img :src="question.image" alt="" />
            </div>
        </div>

        <QnaInfo :infoAbout="question" :size="size"></QnaInfo>
        <div class="slot">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import QnaUser from "./QnaUser"
import QnaInfo from "./QnaInfo"
import QnaActions from "./QnaActions"
import QnaDatetime from "./QnaDatetime"

export default {
    name: "Question",
    props: {
        question: {
            type: Object,
            required: true
        },
        showUser: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            required: true
        },
        showGoto: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {}
    },
    methods: {},
    components: {
        QnaUser,
        QnaInfo,
        QnaActions,
        QnaDatetime
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.question {
    margin-bottom: 30px;
    padding: 16px;

    .content {
        flex-direction: column;

        .text {
            margin-top: 20px;
            background-color: $background-color;
            color: $color-black;
            padding: 15px 20px;
            border-radius: 8px;
            margin-bottom: 5px;
        }

        .body-image {
            margin-top: 10px;
            padding: 15px 15px;
            background-color: $background-color;
            margin-bottom: 5px;
            border-radius: 8px;
            display: inline-block;
            box-sizing: border-box;

            img {
                border-radius: 6px;
                max-height: 300px;
                max-width: 300px;
                display: block;
            }

            @media (max-width: 450px) {
                max-width: 100%;
                width: 100%;

                img {
                    max-height: inherit;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }

    .slot {
        background: $background-color;
        margin-top: 10px;
        border-radius: 8px;
    }

    &.small {
        .header {
            flex-direction: column;

            .info {
                min-width: unset;
                margin-bottom: 15px;
                margin-left: 30px;
            }
        }
    }
}
</style>
