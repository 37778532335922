<template>
    <div class="page-channel-telegram scrollable only-y" ref="page">
        <div class="form-box flex column card-base card-shadow--small" v-loading="loadingForm">
            <div class="header" v-if="!currentTemplateId">Nuovo messaggio</div>
            <div class="header" v-if="currentTemplateId">
                Modifica modello
                <strong v-if="tags && tags.length"> {{ tags[0] }}</strong>
            </div>

            <el-input placeholder="Url..." v-model="url"></el-input>

            <UrlMetaCard :url="url" :data.sync="meta" @click="copy"></UrlMetaCard>

            <div class="flex fields-box">
                <div class="image-el box" :style="{ 'background-image': bg }">
                    <FileDrop
                        @change="fileChange"
                        :hover="false"
                        :icon="'mdi mdi-image-outline'"
                        style="opacity: 0.4"
                        v-if="!image"
                    ></FileDrop>
                    <div class="btn-clear-photo" v-if="image" @click="image = ''"><i class="mdi mdi-close"></i></div>
                </div>

                <Editor
                    class="box grow"
                    :html.sync="html"
                    :text.sync="text"
                    :list="false"
                    :table="false"
                    :link="true"
                    :heading="false"
                    :hiding="false"
                    :placeholder="'Scrivi messaggio...'"
                    ref="editor"
                ></Editor>

                <div class="buttons-layout">
                    <ChannelMessageButtons @change="res => (buttons = res)" ref="buttons"></ChannelMessageButtons>
                </div>
            </div>

            <div class="hint text-info fs-14 mt-10">
                <i class="mdi mdi-information-outline"></i>
                <span class="ml-5">
                    La foto deve pesare al massimo 10 MB. La somma della larghezza e dell'altezza non deve superare
                    10.000 px.
                </span>
            </div>

            <div class="toolbar-box flex justify-space-between">
                <div class="box grow mr-20">
                    <ul class="legend">
                        <li @click="appendTag('site')"><strong v-html="'{{site}}'"></strong> : nome sito</li>
                        <li @click="appendTag('title')">
                            <strong v-html="'{{title}}'"></strong> : titolo pagina web/prodotto
                        </li>
                        <li @click="appendTag('description')">
                            <strong v-html="'{{description}}'"></strong> : descrizione pagina web/prodotto
                        </li>
                    </ul>
                </div>
                <div class="box text-right">
                    <el-button size="medium" plain @click="copy({ key: null, value: meta })" v-if="meta">
                        Copia info
                    </el-button>
                    <el-button size="medium" plain @click="reset">Reset</el-button>
                    <el-button plain size="medium" :disabled="!text" @click="save">Salva modello</el-button>
                    <el-button
                        type="primary"
                        plain
                        size="medium"
                        :disabled="!isValid"
                        @click="send(true)"
                        v-if="!currentTemplateId"
                    >
                        Invia su Test
                    </el-button>
                    <el-popconfirm
                        title="Sicura di voler procedere ?"
                        confirmButtonText="Si"
                        cancelButtonText="No"
                        @confirm="send(false)"
                    >
                        <el-button
                            slot="reference"
                            type="success"
                            class="ml-10"
                            plain
                            size="medium"
                            :disabled="!isValid"
                            v-show="!currentTemplateId"
                        >
                            Invia su Canale
                        </el-button>
                    </el-popconfirm>

                    <el-popover width="220" trigger="click" v-model="cronPopoverVisible">
                        <div class="cron-msg__popover-prompt">
                            <div class="mb-20">
                                <el-date-picker
                                    v-model="cronDate"
                                    type="datetime"
                                    format="dd MMMM yyyy HH:mm"
                                    placeholder="Seleziona data e ora"
                                    align="right"
                                    popper-class="channel-message-datatime"
                                    :picker-options="pickerOptions"
                                >
                                </el-date-picker>
                            </div>
                            <div class="text-right">
                                <el-button @click="cronPopoverClose" size="mini" type="text">Annulla</el-button>
                                <el-button @click="setCron" type="success" size="mini" :disabled="!isCronDateValid">
                                    Programma
                                </el-button>
                            </div>
                        </div>

                        <el-button
                            slot="reference"
                            type="success"
                            class="ml-10"
                            plain
                            size="medium"
                            :disabled="!isValid"
                            v-show="!currentTemplateId"
                        >
                            Programma su Canale
                            <i class="mdi mdi-clock-outline"></i>
                        </el-button>
                    </el-popover>
                </div>
            </div>
        </div>

        <div class="list-container flex gaps">
            <div class="wrapper box grow">
                <div class="list" v-loading="loadingTemplates">
                    <div class="header fs-20 mt-50">Lista modelli</div>

                    <div v-if="!templates.length && !loadingTemplates">Non hai ancora un modello</div>

                    <ChannelMessageTemplate
                        v-for="t in templates"
                        :key="t._id"
                        :class="{ closed: t._id === currentTemplateId }"
                        :template="t"
                        @copy="copyTemplate(t)"
                        @edit="updateTemplate(t)"
                        @delete="deleteTemplate(t._id)"
                    >
                    </ChannelMessageTemplate>
                </div>
            </div>
            <div class="wrapper box grow">
                <div class="list" v-loading="loadingCron">
                    <div class="header fs-20 mt-50">
                        Messaggi programmati <small v-if="cronList.length" class="o-050">({{ cronList.length }})</small>
                    </div>

                    <div v-if="!cronList.length && !loadingCron">Non sono presenti messaggi programmati</div>

                    <ChannelMessageCron
                        v-for="msg in cronList"
                        :key="msg._id"
                        :message="msg"
                        @delete="deleteCronMessage(msg._id)"
                    >
                    </ChannelMessageCron>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import utils from "@/utils"
import Editor from "@/components/common/Editor"
import ChannelMessageTemplate from "@/components/Channels/ChannelMessageTemplate"
import ChannelMessageCron from "@/components/Channels/ChannelMessageCron"
import ChannelMessageButtons from "@/components/Channels/ChannelMessageButtons"
import FileDrop from "@/components/common/FileDrop"
import UrlMetaCard from "@/components/common/UrlMetaCard"
import isURL from "validator/lib/isURL"
import dayjs from "dayjs"
import { EventBus } from "@/event-bus"

export default {
    name: "ChannelTelegram",
    data() {
        return {
            templates: [],
            cronList: [],
            loadingForm: false,
            loadingTemplates: false,
            loadingCron: false,
            currentTemplateId: null,
            html: "",
            text: "",
            image: "",
            url: "",
            meta: null,
            buttons: [],
            tags: [],
            cronPopoverVisible: false,
            pickerOptions: {
                disabledDate(time) {
                    const checkTime = dayjs(time).format("YYYY-MM-DD")
                    const nowTime = dayjs().format("YYYY-MM-DD")
                    return checkTime < nowTime
                },
                selectableRange: dayjs().format("HH:mm:ss") + " - 23:59:59",
                firstDayOfWeek: 1,
                shortcuts: [
                    {
                        text: "19:00",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 19)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "19:30",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 19, 30)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "20:00",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 20)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "20:30",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 20, 30)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "21:00",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "21:10",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 10)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "21:20",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 20)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "21:30",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 30)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "21:40",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 40)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "21:50",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 50)
                            picker.$emit("pick", date)
                        }
                    },
                    {
                        text: "22:00",
                        onClick(picker) {
                            const now = new Date()
                            const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22)
                            picker.$emit("pick", date)
                        }
                    }
                ]
            },
            cronDate: ""
        }
    },
    computed: {
        bg() {
            return `url(${this.image}`
        },
        isBtnValid() {
            for (const r of this.buttons) {
                if (r.length) {
                    for (const b of r) {
                        if (!b.text || !b.url) {
                            return false
                        }
                        if (b.url && !isURL(b.url || "")) {
                            return false
                        }
                    }
                }
            }
            return true
        },
        isValid() {
            return this.text && this.isBtnValid
        },
        isCronDateValid() {
            return this.cronDate && dayjs(this.cronDate).unix() > dayjs().unix()
        }
    },
    watch: {
        cronDate(val) {
            let selectableRange = dayjs().format("HH:mm:ss") + " - 23:59:59"
            const current = dayjs(dayjs(this.cronDate).format("YYYY-MM-DD")).unix()
            const now = dayjs(dayjs().format("YYYY-MM-DD")).unix()

            if (!this.cronDate || current > now) {
                selectableRange = "00:00:00 - 23:59:59"
            }

            this.$set(this.pickerOptions, "selectableRange", selectableRange)
        }
    },
    methods: {
        getTemplates() {
            this.loadingTemplates = true

            api.common
                .getChannelMessageTemplates()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.templates = res.body.length ? res.body.reverse() : []
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadingTemplates = false
                })
        },
        getCronMessages() {
            this.loadingCron = true

            api.common
                .getChannelMessageCron()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.cronList = res.body.length ? res.body.reverse() : []
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadingCron = false
                })
        },
        reset() {
            this.$refs.editor.reset()
            this.currentTemplateId = null
            this.image = ""
            this.url = ""
            this.cronDate = ""
            this.meta = null
            this.buttons = []
            this.$refs.buttons.reset()
            this.tags = []
        },
        updateTemplate(template) {
            this.currentTemplateId = template._id
            this.$refs.editor.setContent(template.body)

            if (template.meta && template.meta.buttons) {
                this.buttons = template.meta.buttons
            } else {
                this.$refs.buttons.reset()
            }

            if (template.tags) {
                this.tags = template.tags
            } else {
                this.tags = []
            }

            this.$refs.page.scrollTo({ top: 0, behavior: "smooth" })
        },
        deleteTemplate(id) {
            this.$confirm("Sicura di voler eliminare questo modello ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    this.$message("Sto eliminando il modello...")

                    return api.common
                        .deleteChannelMessageTemplates(id)
                        .then(response => {
                            this.getTemplates()

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Modello eliminato correttamente`
                            })
                        })
                        .catch(err => {
                            this.$notify.error({
                                title: "Ops...",
                                message: "Errore eliminazione modello"
                            })
                        })
                })
                .catch(() => {})
        },
        deleteCronMessage(id) {
            this.$confirm("Sicura di voler eliminare questo messaggio programmato ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    this.$message("Sto eliminando il messaggio...")

                    return api.common
                        .deleteChannelMessageCron(id)
                        .then(response => {
                            this.getCronMessages()

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Messaggio eliminato correttamente`
                            })
                        })
                        .catch(err => {
                            this.$notify.error({
                                title: "Ops...",
                                message: "Errore eliminazione messaggio"
                            })
                        })
                })
                .catch(() => {})
        },
        save() {
            this.$prompt("Inserisci un nome al modello", "Salva modello", {
                confirmButtonText: "Salva",
                cancelButtonText: "Chiudi",
                inputValue: this.tags[0] || ""
            })
                .then(({ value }) => {
                    this.loadingForm = true
                    this.$message("Sto salvando il modello...")

                    const method = this.currentTemplateId
                        ? "updateChannelMessageTemplates"
                        : "addChannelMessageTemplates"

                    const template = {
                        body: this.html,
                        tags: [value],
                        meta: {
                            buttons: this.buttons
                        }
                    }

                    return api.common[method](template, this.currentTemplateId)
                        .then(response => {
                            this.getTemplates()
                            this.reset()

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Modello salvato correttamente`
                            })
                        })
                        .catch(err => {
                            this.$notify.error({
                                title: "Ops...",
                                message: "Errore salvataggio modello"
                            })
                        })
                        .finally(() => {
                            this.loadingForm = false
                        })
                })
                .catch(() => {})
        },
        send(test) {
            this.loadingForm = true
            this.$message("Sto inviando il messaggio...")

            const botName = test ? "channel-test" : "channel-prod"

            const buttons = _.chain(this.buttons)
                .map(r => {
                    return r && _.isArray(r) && r.length ? r : null
                })
                .compact()
                .value()

            return api.bot
                .sendBotMessage(botName, {
                    text: this.html,
                    image: this.image,
                    buttons: buttons.length ? buttons : null
                })
                .then(response => {
                    if (!test) {
                        this.reset()
                    }

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Messaggio inviato correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Errore invio messaggio"
                    })
                })
                .finally(() => {
                    this.loadingForm = false
                })
        },
        setCron() {
            const now = new Date().getTime() - 1000 * 60
            const cron = new Date(this.cronDate).getTime()

            if (cron < now) {
                this.$notify.error({
                    title: "Ops...",
                    message: "Non è possibile programmare un messaggio nel passato"
                })
                return false
            }

            this.loadingForm = true
            this.$message("Sto programmando il messaggio...")

            const buttons = _.chain(this.buttons)
                .map(r => {
                    return r && _.isArray(r) && r.length ? r : null
                })
                .compact()
                .value()

            return api.common
                .addChannelMessageCron(this.cronDate, {
                    text: this.html,
                    image: this.image,
                    buttons: buttons.length ? buttons : null
                })
                .then(response => {
                    this.reset()
                    this.cronPopoverClose()

                    this.getCronMessages()

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Messaggio programmato correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Errore programmazione messaggio"
                    })
                })
                .finally(() => {
                    this.loadingForm = false
                })
        },
        copyTemplate(template) {
            this.$refs.editor.setContent(template.body)
            if (template.meta && template.meta.buttons) {
                this.buttons = template.meta.buttons
            } else {
                this.$refs.buttons.reset()
            }
        },
        fileChange(file) {
            utils.file2Base64(file).then(base64 => {
                this.image = base64
            })
        },
        copy({ key, value }) {
            let meta = {}

            if (key) {
                meta[key] = value
            } else {
                meta = value
            }

            this.replaceText(meta)
        },
        replaceText(meta) {
            let html = this.html

            if (meta.site) {
                html = html.replace(/{{site}}/gim, meta.site)
            }
            if (meta.title) {
                html = html.replace(/{{title}}/gim, meta.title)
            }
            if (meta.description) {
                html = html.replace(/{{description}}/gim, meta.description)
            }
            if (meta.image) {
                this.image = meta.image
            }

            this.$refs.editor.setContent(html)
        },
        appendTag(tag) {
            const transaction = this.$refs.editor.getCtx().state.tr.insertText(` {{${tag}}}`)
            this.$refs.editor.getCtx().view.dispatch(transaction)
        },
        cronPopoverClose() {
            this.cronPopoverVisible = false
            this.cronDate = ""
        }
    },
    created() {},
    mounted() {
        this.getTemplates()
        this.getCronMessages()

        EventBus.$on("message-bot:sent", () => {
            this.getCronMessages()
        })
    },
    beforeDestroy() {},
    components: {
        Editor,
        FileDrop,
        UrlMetaCard,
        ChannelMessageTemplate,
        ChannelMessageCron,
        ChannelMessageButtons
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-channel-telegram {
    .form-box {
        padding: 20px;

        & > .header {
            font-size: 20px;
            margin-bottom: 20px;
            user-select: none;
        }

        .image-el {
            position: relative;
            width: 132px;
            height: 132px;
            margin-right: 20px;
            margin-top: 20px;
            border-radius: 4px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.2s;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;

            &:hover {
                box-shadow: 0px 0px 0px 2px $color-primary;
                border-color: $color-primary;
            }

            .btn-clear-photo {
                color: #fff;
                cursor: pointer;
                font-size: 16px;
                background: rgba(0, 0, 0, 0.5);
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid rgba(255, 255, 255, 0.5);
                text-align: center;
                line-height: 24px;
                margin: 6px;
                transition: all 0.2s;

                &:hover {
                    background: rgba($color-danger, 0.6);
                }
            }
        }

        .editor {
            border: solid 1px #e4e7ed;
            border-radius: 4px;
            padding: 8px;
            box-sizing: border-box;
            margin-top: 20px;
        }

        .buttons-layout {
            width: 600px;
            margin-left: 20px;
            margin-top: 20px;
            border: solid 1px #e4e7ed;
            border-radius: 4px;
            padding: 4px 6px;
        }

        .toolbar-box {
            margin-top: 20px;

            .el-button {
                margin-bottom: 10px;
            }

            .legend {
                margin: 0;
                padding: 0;
                padding-left: 18px;
                user-select: none;

                li {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $color-primary;
                    }
                }
            }
        }
    }

    .list-container {
        --flex-gap: 80px;

        .list {
            & > .header {
                margin-bottom: 20px;
                user-select: none;
            }

            :deep {
                .template {
                    &.closed {
                        height: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media (max-width: 1900px) {
    .page-channel-telegram {
        .form-box {
            .fields-box {
                .buttons-layout {
                    width: 400px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .page-channel-telegram {
        .form-box {
            .fields-box {
                flex-direction: column;
                -webkit-box-orient: vertical;

                .buttons-layout {
                    width: 100%;
                    margin-left: 0px;
                }
            }
            .toolbar-box {
                -webkit-box-orient: vertical;
                -webkit-box-direction: reverse;
                -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;

                .box {
                    margin-bottom: 20px;

                    &:first-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .list-container {
            --flex-gap: 0px;
            flex-direction: column-reverse;
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
        }
    }
}
</style>
<style lang="scss">
.channel-message-datatime {
    .el-picker-panel__body-wrapper {
        .el-picker-panel__sidebar {
            border-top-left-radius: 4px;

            .el-picker-panel__shortcut {
                text-align: center;
                padding: 3px 5px;
            }
        }
    }
    .el-picker-panel__footer {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        .el-button--text {
            display: none;
        }
    }
}
</style>
