<template functional>
    <div class="not-found flex align-center scrollable">
        <div class="msg-box">
            <h1 class="animated fadeInDown">404</h1>
            <h2 class="animated fadeInUp">Oops!<br />Pagina non trovata.</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.not-found {
    background: $background-color;
    width: 100%;
    height: 100%;

    .msg-box {
        max-width: 340px;
        margin: 50px auto;
        text-align: center;

        h1 {
            @include text-bordered-shadow();
            font-size: 130px;
        }
    }
}
</style>
