<template>
	<div class="filters flex center">
		<div class="box grow">
			<div class="search-input flex">
				<input v-model="filters.search" type="text" placeholder="Cerca..." class="box grow" />
				<button @click="filters.search = ''" v-if="filters.search" class="box">
					<i class="mdi mdi-close-circle-outline"></i>
				</button>
			</div>
		</div>
		<div class="box">
			<el-tooltip
				class="item"
				effect="dark"
				:open-delay="500"
				content="Mostra non letti"
				placement="top-end"
				:disabled="tooltipHidden"
			>
				<button @click="filters.notSeen = !filters.notSeen" :class="{ active: filters.notSeen }">
					<i class="mdi mdi-filter-variant"></i>
				</button>
			</el-tooltip>
		</div>
		<div class="box">
			<el-popover
				placement="bottom-end"
				width="240"
				trigger="click"
				popper-class="thread-labels-select"
				ref="filterLabels"
			>
				<button
					v-for="label in threadLabels"
					:key="label.name"
					:style="{ backgroundColor: label.color }"
					@click="
						filters.label = label.name
						$refs.filterLabels.showPopper = false
					"
				>
					<span>{{ label.name }}</span>
				</button>

				<div slot="reference">
					<el-tooltip
						class="item"
						effect="dark"
						:open-delay="500"
						content="Filtra per etichette"
						placement="top-end"
						:disabled="tooltipHidden"
					>
						<button v-show="!labelSelected">
							<i class="mdi mdi-label-outline"></i>
						</button>
					</el-tooltip>
				</div>
			</el-popover>
			<button
				class="label-selected"
				v-if="labelSelected"
				@click="filters.label = ''"
				:style="{ backgroundColor: labelSelected.color }"
			>
				<i class="mdi mdi-close"></i>
				<span>{{ labelSelected.name }}</span>
			</button>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

export default {
	name: "ThreadSidebarFilters",
	props: {
		// filters: { notSeen: false, search: "", label: "" },
		filters: {
			type: Object,
			required: true
		},
		tooltipHidden: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			threadLabels: []
		}
	},
	computed: {
		labelSelected() {
			return this.filters.label ? _.find(this.threadLabels, l => l.name === this.filters.label) : null
		}
	},
	watch: {},
	methods: {
		getThreadLabels() {
			api.common
				.getThreadLabels()
				.then(res => {
					if (res.status === 200 && res.body) {
						this.threadLabels = res.body
					}
				})
				.catch(err => {
					console.error(err)
				})
		}
	},
	mounted() {
		this.getThreadLabels()
	},
	created() {},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.filters {
	padding: 5px;
	padding-right: 10px;
	margin-bottom: 10px;

	& > .box {
		padding: 5px;

		input,
		button {
			background: #fff;
			border: none;
			outline: none;
			width: 100%;
			padding: 8px 12px;
			box-sizing: border-box;
			font-size: 16px;
			border-radius: 4px;
			height: 38px;

			&.active {
				color: $color-primary;
			}

			&.label-selected {
				font-weight: bold;
				font-size: 16px;
				position: relative;
				top: -1px;
				color: rgba(0, 0, 0, 0.5);
				overflow: hidden;

				i {
					padding-right: 8px;
					position: relative;
					top: 1px;
					box-sizing: border-box;
				}
			}
		}
		button {
			cursor: pointer;
		}

		.search-input {
			background: #fff;
			border: none;
			outline: none;
			width: 100%;
			border-radius: 4px;

			button {
				width: 30px;
				opacity: 0.4;
				padding: 8px 2px;
				padding-right: 10px;
			}
		}
	}
}
</style>

<style lang="scss">
.thread-labels-select {
	button {
		font-size: 14px;
		display: inline-block;
		padding: 4px 8px;
		border-radius: 6px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
		margin-right: 10px;
		margin-bottom: 10px;
		box-sizing: border-box;
		cursor: pointer;
		border: none;
		outline: none;
	}
}
</style>
