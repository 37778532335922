var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "calendar",
    },
    [
      _c("div", { staticClass: "hint mb-10 text-right text-info fs-12" }, [
        _c("i", { staticClass: "mdi mdi-information-outline" }),
        _c("span", { staticClass: "ml-5" }, [
          _vm._v("Dati salvati ogni ora"),
          !_vm.byRoom ? _c("span", [_vm._v(" per ogni stanza")]) : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data && _vm.data.length,
              expression: "data && data.length",
            },
          ],
          staticClass: "header flex gaps",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass:
                "info box grow card-base card-shadow--small flex column",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "online flex column justify-space-between box grow",
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Utenti online"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex justify-space-around mh-10" },
                    [
                      _c("div", { staticClass: "box col-4 flex column" }, [
                        _c("span", [_vm._v("max")]),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$sugar.Number.format(_vm.online.max))
                          ),
                        ]),
                        _vm.online.maxDate
                          ? _c("small", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.online.maxDate.split(" @ ")[0])
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.online.maxDate.split(" @ ")[1])
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.online.maxDate.split(" @ ")[2])
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "box col-4 flex column" }, [
                        _c("span", [_vm._v("avg")]),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$sugar.Number.format(_vm.online.avg))
                          ),
                        ]),
                        _c("small", { staticClass: "only-cap" }, [
                          _vm._v("Media utenti online ogni ora"),
                        ]),
                      ]),
                      _c("div", { staticClass: "box col-4 flex column" }, [
                        _c("span", [_vm._v("min")]),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$sugar.Number.format(_vm.online.min))
                          ),
                        ]),
                        _vm.online.minDate
                          ? _c("small", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.online.minDate.split(" @ ")[0])
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.online.minDate.split(" @ ")[1])
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.online.minDate.split(" @ ")[2])
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "box grow" }),
                  _c("div", { staticClass: "chart box" }, [
                    _c(
                      "div",
                      { staticClass: "chart-wrap" },
                      [
                        _c("apexchart", {
                          attrs: {
                            type: "area",
                            options: _vm.chartOnline,
                            height: "150",
                            series: _vm.seriesOnline,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass:
                "info box grow card-base card-shadow--small flex column",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "messages flex column justify-space-between box grow",
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("Messaggi")]),
                  _c(
                    "div",
                    { staticClass: "flex justify-space-around mh-10" },
                    [
                      _c("div", { staticClass: "box col-4 flex column" }, [
                        _c("span", [_vm._v("Testo")]),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$sugar.Number.format(_vm.messages.total))
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "box col-4 flex column" }, [
                        _c("span", [_vm._v("Immagini")]),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$sugar.Number.format(_vm.messages.images)
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "box col-4 flex column" }, [
                        _c("span", [_vm._v("TXT / IMG")]),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.messages.imgPer) + "%"),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "box grow" }),
                  _c("div", { staticClass: "chart box" }, [
                    _c(
                      "div",
                      { staticClass: "chart-wrap" },
                      [
                        _c("apexchart", {
                          ref: "chartMessages",
                          attrs: {
                            type: "bar",
                            options: _vm.chartMessages,
                            height: "200",
                            series: _vm.seriesMessages,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
          !_vm.byRoom
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass:
                    "info box grow card-base card-shadow--small flex column",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "ranking flex column justify-space-between box grow",
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Classifica Stanze"),
                      ]),
                      !_vm.byRoom
                        ? _c(
                            "div",
                            { staticClass: "box grow flex column" },
                            [
                              _c("Ranking", {
                                staticClass: "box grow",
                                attrs: { data: _vm.data },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.months !== null
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data && _vm.data.length,
                  expression: "data && data.length",
                },
              ],
              staticClass: "months",
            },
            _vm._l(_vm.months, function (month) {
              return _c(
                "div",
                { key: month.id, staticClass: "month", class: month.label },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "wrapper card-base card-shadow--small flex column",
                      class: { "no-room": _vm.byRoom },
                    },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(month.label)),
                      ]),
                      _c("div", { staticClass: "online flex column" }, [
                        _vm._m(0, true),
                        _c("div", { staticClass: "max" }, [
                          _c("span", [_vm._v("max")]),
                          _c("strong", [
                            _vm._v(" " + _vm._s(month.online.max) + " "),
                          ]),
                        ]),
                        _c("div", { staticClass: "avg" }, [
                          _c("span", [_vm._v("avg")]),
                          _c("strong", [
                            _vm._v(" " + _vm._s(month.online.avg) + " "),
                          ]),
                        ]),
                        _c("div", { staticClass: "min" }, [
                          _c("span", [_vm._v("min")]),
                          _c("strong", [
                            _vm._v(" " + _vm._s(month.online.min) + " "),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "messages flex column" }, [
                        _vm._m(1, true),
                        _c("div", { staticClass: "msg" }, [
                          _c("span", [_vm._v("msg")]),
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$sugar.Number.format(month.messages.total)
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "img" }, [
                          _c("span", [_vm._v("img")]),
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$sugar.Number.format(
                                    month.messages.images
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      !_vm.byRoom
                        ? _c(
                            "div",
                            { staticClass: "rooms" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    width: "280",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "label text-capitalized mt-0 ml-8 mb-12 info-text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(month.label) +
                                          " " +
                                          _vm._s(_vm.year) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("Ranking", {
                                    attrs: { data: month.data },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [_vm._v("Stanze")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "label" }, [_c("span", [_vm._v("Online")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("Messaggi")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }