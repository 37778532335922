var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "channel-message-buttons flex column" },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "list-group",
            attrs: { tag: "div", handle: ".handle", group: "featured" },
            on: { start: _vm.startDrag, end: _vm.endDrag },
            model: {
              value: _vm.bkp,
              callback: function ($$v) {
                _vm.bkp = $$v
              },
              expression: "bkp",
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          _c(
            "transition-group",
            {
              attrs: {
                type: "transition",
                name: !_vm.drag ? "flip-list" : null,
              },
            },
            _vm._l(_vm.bkp, function (row) {
              return _c(
                "div",
                { key: row.id, staticClass: "row flex" },
                [
                  row.items.length
                    ? _c("div", { staticClass: "row-del" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.delRow(row.id)
                              },
                            },
                          },
                          [_c("i", { staticClass: "mdi mdi-delete-outline" })]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "box grow flex",
                        attrs: {
                          list: row.items,
                          tag: "div",
                          handle: ".handle",
                          group: { name: "row" },
                        },
                        on: { start: _vm.startDrag, end: _vm.endDrag },
                        model: {
                          value: row.items,
                          callback: function ($$v) {
                            _vm.$set(row, "items", $$v)
                          },
                          expression: "row.items",
                        },
                      },
                      "draggable",
                      _vm.dragOptions,
                      false
                    ),
                    [
                      _c(
                        "transition-group",
                        {
                          staticClass: "box grow flex",
                          attrs: {
                            type: "transition",
                            name: !_vm.drag ? "flip-list" : null,
                          },
                        },
                        _vm._l(row.items, function (btn) {
                          return _c(
                            "div",
                            { key: btn.id, staticClass: "btn box grow" },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "flex column box" }, [
                                  _c("button", { staticClass: "handle" }, [
                                    _c("i", {
                                      staticClass: "mdi mdi-drag handle",
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex column box grow" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: btn.text,
                                          expression: "btn.text",
                                        },
                                      ],
                                      attrs: { placeholder: "Testo..." },
                                      domProps: { value: btn.text },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            btn,
                                            "text",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: btn.url,
                                          expression: "btn.url",
                                        },
                                      ],
                                      attrs: { placeholder: "Url..." },
                                      domProps: { value: btn.url },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            btn,
                                            "url",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c("div", { staticClass: "flex column box" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.delBtn(row.id, btn.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "mdi mdi-close-circle-outline",
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  row.items.length
                    ? _c("div", { staticClass: "btn-add box" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.addBtn(row.id)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-plus-circle-outline",
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  row.items.length
                    ? _c("div", { staticClass: "btn-handle box" }, [
                        _c("button", { staticClass: "handle" }, [
                          _c("i", { staticClass: "mdi mdi-drag" }),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row-add" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _vm.addRow()
              },
            },
          },
          [
            _c("i", { staticClass: "mdi mdi-plus-circle-outline" }),
            _c("span", [_vm._v("Riga pulsanti")]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }