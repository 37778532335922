var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "page",
      staticClass: "page-logs-list column scrollable only-y",
      class: { flex: !_vm.isMobile, overflow: _vm.isMobile, [_vm.size]: true },
    },
    [
      _c("resize-observer", { on: { notify: _vm.handleResize } }),
      _c("div", { staticClass: "toolbar-box flex align-center" }, [
        _c(
          "div",
          { staticClass: "box grow mr-5 wide" },
          [
            _c("el-date-picker", {
              staticClass: "card-shadow--small",
              attrs: {
                type: "daterange",
                align: "right",
                "unlink-panels": "",
                size: "mini",
                "range-separator": "To",
                "start-placeholder": "Data inizio",
                "end-placeholder": "Data fine",
                "default-time": ["00:00:00", "23:59:59"],
                "picker-options": _vm.datePickerOptions,
              },
              model: {
                value: _vm.daterange,
                callback: function ($$v) {
                  _vm.daterange = $$v
                },
                expression: "daterange",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box grow mr-5 half" },
          [
            _c(
              "el-select",
              {
                staticClass: "card-shadow--small",
                attrs: { size: "mini", clearable: "", placeholder: "Metodo" },
                model: {
                  value: _vm.method,
                  callback: function ($$v) {
                    _vm.method = $$v
                  },
                  expression: "method",
                },
              },
              _vm._l(_vm.methodList, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box grow mr-5 half" },
          [
            _c(
              "el-select",
              {
                staticClass: "card-shadow--small",
                attrs: {
                  size: "mini",
                  filterable: "",
                  clearable: "",
                  "allow-create": "",
                  placeholder: "Url",
                },
                model: {
                  value: _vm.url,
                  callback: function ($$v) {
                    _vm.url = $$v
                  },
                  expression: "url",
                },
              },
              _vm._l(_vm.urlList, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box grow mr-5 half" },
          [
            _c(
              "el-select",
              {
                staticClass: "card-shadow--small",
                attrs: {
                  size: "mini",
                  filterable: "",
                  clearable: "",
                  placeholder: "Utente",
                },
                model: {
                  value: _vm.userName,
                  callback: function ($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName",
                },
              },
              _vm._l(_vm.userNameList, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box grow half" },
          [
            _c(
              "el-select",
              {
                staticClass: "card-shadow--small",
                attrs: { size: "mini", clearable: "", placeholder: "Ruolo" },
                model: {
                  value: _vm.userRole,
                  callback: function ($$v) {
                    _vm.userRole = $$v
                  },
                  expression: "userRole",
                },
              },
              _vm._l(_vm.userRoleList, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "table-box card-base card-shadow--small box grow flex column",
          attrs: { id: "table-wrapper" },
        },
        [
          _vm.ready
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list, "max-height": _vm.height },
                  on: { "row-click": _vm.viewItem },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "Data/ora",
                      width: "180",
                      prop: "datetime",
                      fixed: !_vm.pagination.small,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dayjs")(
                                      scope.row.datetime,
                                      "format",
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      718557976
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Utente",
                      "min-width": "180",
                      prop: "user_name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "user-name",
                                  style: {
                                    color: _vm.stc(scope.row.user_name),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.user_name) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1033415231
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Ruolo",
                      "min-width": "150",
                      prop: "user_role",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "roles color",
                                  class: scope.row.user_role,
                                },
                                [_vm._v(_vm._s(scope.row.user_role))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3434427970
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Metodo", prop: "method" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "method",
                                  class: scope.row.method,
                                },
                                [_vm._v(_vm._s(scope.row.method))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      605114727
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Url", "min-width": "450", prop: "url" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", {
                                staticClass: "sel-string",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.parseUrl(
                                      scope.row.url,
                                      "/api/v1"
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4244755073
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ready
            ? _c("el-pagination", {
                staticClass: "box",
                attrs: {
                  small: _vm.pagination.small,
                  "current-page": _vm.pagination.page,
                  "page-sizes": _vm.pagination.sizes,
                  "page-size": _vm.pagination.size,
                  layout: _vm.pagination.layout,
                  total: _vm.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pagination, "page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pagination, "page", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pagination, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pagination, "size", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Dettaglio attività",
            visible: _vm.dialogLogVisible,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogVisible = $event
            },
          },
        },
        [
          _c("print-object", {
            attrs: {
              "printable-object": _vm.currentLog,
              "initial-collapsed": [
                "userData",
                "headers",
                "body",
                "params",
                "query",
                "device",
              ],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }