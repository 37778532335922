var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filters flex center" }, [
    _c("div", { staticClass: "box grow" }, [
      _c("div", { staticClass: "search-input flex" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filters.search,
              expression: "filters.search",
            },
          ],
          staticClass: "box grow",
          attrs: { type: "text", placeholder: "Cerca..." },
          domProps: { value: _vm.filters.search },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.filters, "search", $event.target.value)
            },
          },
        }),
        _vm.filters.search
          ? _c(
              "button",
              {
                staticClass: "box",
                on: {
                  click: function ($event) {
                    _vm.filters.search = ""
                  },
                },
              },
              [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              "open-delay": 500,
              content: "Mostra non letti",
              placement: "top-end",
              disabled: _vm.tooltipHidden,
            },
          },
          [
            _c(
              "button",
              {
                class: { active: _vm.filters.notSeen },
                on: {
                  click: function ($event) {
                    _vm.filters.notSeen = !_vm.filters.notSeen
                  },
                },
              },
              [_c("i", { staticClass: "mdi mdi-filter-variant" })]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "el-popover",
          {
            ref: "filterLabels",
            attrs: {
              placement: "bottom-end",
              width: "240",
              trigger: "click",
              "popper-class": "thread-labels-select",
            },
          },
          [
            _vm._l(_vm.threadLabels, function (label) {
              return _c(
                "button",
                {
                  key: label.name,
                  style: { backgroundColor: label.color },
                  on: {
                    click: function ($event) {
                      _vm.filters.label = label.name
                      _vm.$refs.filterLabels.showPopper = false
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(label.name))])]
              )
            }),
            _c(
              "div",
              { attrs: { slot: "reference" }, slot: "reference" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      "open-delay": 500,
                      content: "Filtra per etichette",
                      placement: "top-end",
                      disabled: _vm.tooltipHidden,
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.labelSelected,
                            expression: "!labelSelected",
                          },
                        ],
                      },
                      [_c("i", { staticClass: "mdi mdi-label-outline" })]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm.labelSelected
          ? _c(
              "button",
              {
                staticClass: "label-selected",
                style: { backgroundColor: _vm.labelSelected.color },
                on: {
                  click: function ($event) {
                    _vm.filters.label = ""
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-close" }),
                _c("span", [_vm._v(_vm._s(_vm.labelSelected.name))]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }