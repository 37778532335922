var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.filters, function (filter) {
        return _c("div", { key: filter.key, staticClass: "filter-box" }, [
          _c("div", { staticClass: "label" }, [_vm._v(_vm._s(filter.label))]),
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(filter.key, {
                tag: "component",
                staticClass: "component",
                attrs: { value: filter.value },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(filter, "value", $event)
                  },
                },
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.delFilter(filter.key)
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-delete-outline fs-20" })]
              ),
            ],
            1
          ),
        ])
      }),
      _vm.availableFilters.length
        ? _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                placeholder: "Aggiungi filtro",
                clearable: "",
                "no-data-text": "Nessun filtro disponibile",
              },
              on: {
                change: function ($event) {
                  return _vm.addFilter()
                },
              },
              model: {
                value: _vm.newFilter,
                callback: function ($$v) {
                  _vm.newFilter = $$v
                },
                expression: "newFilter",
              },
            },
            _vm._l(_vm.availableFilters, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }