<template>
    <div class="calendar" v-loading="loading">
        <div class="hint mb-10 text-right text-info fs-12">
            <i class="mdi mdi-information-outline"></i>
            <span class="ml-5">Dati salvati ogni ora<span v-if="!byRoom"> per ogni stanza</span></span>
        </div>
        <div class="header flex gaps" v-show="data && data.length">
            <div class="info box grow card-base card-shadow--small flex column" v-show="!loading">
                <div class="online flex column justify-space-between box grow">
                    <div class="title">Utenti online</div>
                    <div class="flex justify-space-around mh-10">
                        <div class="box col-4 flex column">
                            <span>max</span>
                            <strong>{{ $sugar.Number.format(online.max) }}</strong>
                            <small v-if="online.maxDate">
                                <span>{{ online.maxDate.split(" @ ")[0] }}</span>
                                <br />
                                <span>{{ online.maxDate.split(" @ ")[1] }}</span>
                                <br />
                                <span>{{ online.maxDate.split(" @ ")[2] }}</span>
                            </small>
                        </div>

                        <div class="box col-4 flex column">
                            <span>avg</span>
                            <strong>{{ $sugar.Number.format(online.avg) }}</strong>
                            <small class="only-cap">Media utenti online ogni ora</small>
                        </div>

                        <div class="box col-4 flex column">
                            <span>min</span>
                            <strong>{{ $sugar.Number.format(online.min) }}</strong>
                            <small v-if="online.minDate">
                                <span>{{ online.minDate.split(" @ ")[0] }}</span>
                                <br />
                                <span>{{ online.minDate.split(" @ ")[1] }}</span>
                                <br />
                                <span>{{ online.minDate.split(" @ ")[2] }}</span>
                            </small>
                        </div>
                    </div>
                    <div class="box grow"></div>
                    <div class="chart box">
                        <div class="chart-wrap">
                            <apexchart
                                type="area"
                                :options="chartOnline"
                                height="150"
                                :series="seriesOnline"
                            ></apexchart>
                        </div>
                        <!--<div
                            class="months-list flex justify-space-around"
                            :class="'months-length-' + $sugar.Object.size(months)"
                        >
                            <span v-for="m of months" :key="m.key">
                                {{ m.label }}
                            </span>
                        </div>-->
                    </div>
                </div>
            </div>
            <div class="info box grow card-base card-shadow--small flex column" v-show="!loading">
                <div class="messages flex column justify-space-between box grow">
                    <div class="title">Messaggi</div>
                    <div class="flex justify-space-around mh-10">
                        <div class="box col-4 flex column">
                            <span>Testo</span>
                            <strong>{{ $sugar.Number.format(messages.total) }}</strong>
                        </div>

                        <div class="box col-4 flex column">
                            <span>Immagini</span>
                            <strong>{{ $sugar.Number.format(messages.images) }}</strong>
                        </div>

                        <div class="box col-4 flex column">
                            <span>TXT / IMG</span>
                            <strong>{{ messages.imgPer }}%</strong>
                        </div>
                    </div>
                    <div class="box grow"></div>
                    <div class="chart box">
                        <div class="chart-wrap">
                            <apexchart
                                type="bar"
                                :options="chartMessages"
                                height="200"
                                ref="chartMessages"
                                :series="seriesMessages"
                            ></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info box grow card-base card-shadow--small flex column" v-show="!loading" v-if="!byRoom">
                <div class="ranking flex column justify-space-between box grow">
                    <div class="title">Classifica Stanze</div>
                    <div class="box grow flex column" v-if="!byRoom">
                        <Ranking class="box grow" :data="data"></Ranking>
                    </div>
                </div>
            </div>
        </div>

        <div class="months" v-if="months !== null" v-show="data && data.length">
            <div v-for="month of months" :key="month.id" class="month" :class="month.label">
                <div class="wrapper card-base card-shadow--small flex column" :class="{ 'no-room': byRoom }">
                    <div class="label">{{ month.label }}</div>

                    <div class="online flex column">
                        <div class="label">
                            <span>Online</span>
                        </div>
                        <div class="max">
                            <span>max</span>
                            <strong>
                                {{ month.online.max }}
                            </strong>
                        </div>
                        <div class="avg">
                            <span>avg</span>
                            <strong>
                                {{ month.online.avg }}
                            </strong>
                        </div>
                        <div class="min">
                            <span>min</span>
                            <strong>
                                {{ month.online.min }}
                            </strong>
                        </div>
                    </div>

                    <div class="messages flex column">
                        <div class="label">
                            <span>Messaggi</span>
                        </div>
                        <div class="msg">
                            <span>msg</span>
                            <strong>
                                {{ $sugar.Number.format(month.messages.total) }}
                            </strong>
                        </div>
                        <div class="img">
                            <span>img</span>
                            <strong>
                                {{ $sugar.Number.format(month.messages.images) }}
                            </strong>
                        </div>
                    </div>

                    <div class="rooms" v-if="!byRoom">
                        <el-popover placement="top" width="280" trigger="hover">
                            <h4 class="label text-capitalized mt-0 ml-8 mb-12 info-text">
                                {{ month.label }} {{ year }}
                            </h4>
                            <Ranking :data="month.data"></Ranking>
                            <div slot="reference">Stanze</div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import VueApexCharts from "vue-apexcharts"
import { ma, dma, ema, sma, wma } from "moving-averages"
import smoothish from "smoothish"
import Ranking from "@/components/Chat/Stats/Ranking"

export default {
    name: "Calendar",
    props: ["year", "data", "byRoom"],
    data() {
        return {
            online: {
                max: 0,
                min: 0,
                avg: 0,
                maxDate: "",
                minDate: ""
            },
            messages: {
                total: 0,
                images: 0,
                imgPer: 0
            },
            months: null,
            monthsList: [
                { label: "gen", key: 1 },
                { label: "feb", key: 2 },
                { label: "mar", key: 3 },
                { label: "apr", key: 4 },
                { label: "mag", key: 5 },
                { label: "giu", key: 6 },
                { label: "lug", key: 7 },
                { label: "ago", key: 8 },
                { label: "set", key: 9 },
                { label: "ott", key: 10 },
                { label: "nov", key: 11 },
                { label: "dic", key: 12 }
            ],

            seriesMessages: [],
            seriesOnline: [],

            chartMessages: {
                chart: {
                    id: "year_messages",
                    type: "bar",
                    offsetY: 20,
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false
                        }
                    },
                    selection: {
                        enabled: false
                    }
                },
                colors: ["#00E396"],
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: "top" // top, center, bottom
                        }
                    }
                },
                states: {
                    normal: {
                        filter: {
                            type: "none",
                            value: 0
                        }
                    },
                    hover: {
                        filter: {
                            type: "lighten",
                            value: 0.15
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: "none",
                            value: 0
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                    formatter: val => {
                        return this.$sugar.Number.format(val)
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"]
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -10
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                            return this.$sugar.Number.format(value)
                        },
                        title: {
                            formatter: function formatter(val) {
                                return ""
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                },
                xaxis: {
                    categories: [""],
                    position: "bottom",
                    labels: {
                        show: true,
                        style: {
                            cssClass: "apexcharts-xaxis-label"
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        show: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            },

            chartOnline: {
                chart: {
                    id: "year_online-avg",
                    type: "area",
                    sparkline: {
                        enabled: true
                    },
                    dropShadow: {
                        enabled: true,
                        top: 1,
                        left: 1,
                        blur: 2,
                        opacity: 0.2
                    }
                },
                stroke: {
                    curve: "smooth",
                    width: 3
                },
                markers: {
                    size: 0
                },
                colors: ["#34a8f9"],
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.0,
                        stops: [0, 100]
                    }
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                            const date = this.seriesOnline[0].xaxis[dataPointIndex].created_at
                            return this.$sugar.String.titleize(this.$dayjs(date).format("dddd DD MMMM"))
                        }
                    },
                    y: {
                        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                            return _.toSafeInteger(value)
                        },
                        title: {
                            formatter(value, { series, seriesIndex, dataPointIndex, w }) {
                                return "AVG: "
                            }
                        }
                    }
                }
            },

            loading: true
        }
    },
    watch: {
        year(val) {
            this.reset()
        },
        data(val) {
            this.reset()
            this.init()
        }
    },
    methods: {
        init() {
            this.loading = true

            setTimeout(() => {
                this.parseData()
            }, 100)
        },
        reset() {
            this.online = {
                max: 0,
                min: 0,
                avg: 0,
                maxDate: "",
                minDate: ""
            }
            this.messages = { total: 0, images: 0, imgPer: 0 }
            this.months = null
        },
        parseData() {
            const data = this.data

            this.online = this.setOnline(data)
            this.messages = this.setMessages(data)
            this.months = this.setMonths(data)

            this.seriesMessages = [this.messages.total - this.messages.images, this.messages.images]

            const total = data.map(i => i.data.total || 0)
            //const ma_data = ema(total, 48)
            const ma_data = smoothish(total, { radius: 24, algorithm: "movingAverage" /*falloff: "step"*/ })
            this.seriesOnline = [
                {
                    name: "Online",
                    type: "area",
                    data: ma_data.filter((value, index, Arr) => {
                        return index % 24 == 0
                    }),
                    xaxis: data.filter((value, index, Arr) => {
                        return index % 24 == 0
                    })
                }
            ]

            this.seriesMessages = [
                {
                    name: "Messaggi",
                    type: "bar",
                    data: _.map(this.months, i => i.messages.total)
                }
            ]
            this.$refs.chartMessages.updateOptions({
                xaxis: {
                    categories: _.map(this.months, i => i.label)
                }
            })

            this.$nextTick(() => {
                setTimeout(() => {
                    this.loading = false
                }, 30)
            })
        },
        setMessages(data) {
            const messages = _.map(data, i => {
                return i.data.messages || 0
            })
            const images = _.map(data, i => {
                return i.data.images || 0
            })
            const msgTotal = _.sum(messages)
            const imgTotal = _.sum(images)

            return {
                total: msgTotal,
                images: imgTotal,
                imgPer: imgTotal ? _.floor((100 / msgTotal) * imgTotal, 2) : 0
            }
        },
        setOnline(data) {
            const online = _.map(data, i => {
                return i.data.total
            })

            const max = _.toSafeInteger(_.max(online))
            const min = _.toSafeInteger(_.min(online))
            const avg = _.toSafeInteger(_.mean(online))
            const maxObj = _.find(data, i => {
                return i.data.total === max
            })
            const minObj = _.find(data, i => {
                return i.data.total === min
            })

            return {
                max,
                min,
                avg,
                maxDate: _.get(maxObj, "created_at")
                    ? this.$dayjs(maxObj.created_at).format("dddd @ DD MMMM @ HH:mm")
                    : "",
                minDate: _.get(minObj, "created_at")
                    ? this.$dayjs(minObj.created_at).format("dddd @ DD MMMM @ HH:mm")
                    : ""
            }
        },
        setMonths(data) {
            const months = this.monthsList

            const obj = {}

            for (const month of months) {
                const currentYear = _.toSafeInteger(this.$dayjs().format("YYYY"))
                const currentMonth = _.toSafeInteger(this.$dayjs().format("M"))

                if (currentYear === _.toSafeInteger(this.year) && currentMonth < month.key) {
                    break
                }

                const items = _.filter(data, i => this.$dayjs(i.created_at).format("M") == month.key)

                obj[month.label] = {
                    id: month.key,
                    label: month.label,
                    online: this.setOnline(items),
                    messages: this.setMessages(items),
                    data: _.cloneDeep(items)
                }
            }

            return obj
        }
    },
    mounted() {
        this.init()
    },
    components: {
        apexchart: VueApexCharts,
        Ranking
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.calendar {
    $online: $color-primary;
    $messages: #00e396;
    min-height: 200px;
    width: 100%;
    position: relative;

    & > .header {
        margin-bottom: 20px;
        min-height: 200px;
        --flex-gap: 20px;

        & > .info {
            & > .online,
            & > .messages {
                margin-bottom: 5px;

                & > .title {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 20px;
                    color: darken($online, 20%);
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    border-bottom: 2px solid $online;
                }

                & > .flex {
                    .box:not(.chart) {
                        text-align: center;
                        margin: 0 10px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 1px solid $online;

                        & > span {
                            background: transparentize($online, 0.85);
                            margin-bottom: 5px;
                            width: 100%;
                            font-weight: 900;
                            color: $online;
                            font-size: 14px;
                            padding: 6px 8px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-transform: uppercase;
                            border-bottom: 1px solid $online;
                        }
                        & > strong {
                            font-size: 22px;
                            padding: 4px 8px;
                            box-sizing: border-box;
                            margin-bottom: 2px;
                            color: $text-color;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-weight: 800;
                            white-space: nowrap;
                        }
                        & > small {
                            margin-bottom: 4px;
                            padding: 4px 8px;
                            box-sizing: border-box;
                            color: darken($online, 20%);

                            &:not(.only-cap) {
                                text-transform: capitalize;
                            }
                        }
                    }
                }

                .chart {
                    width: 100%;
                    padding: 0;
                    margin-top: 20px;

                    .chart-wrap {
                        width: 100%;

                        :deep {
                            .apexcharts-xaxis-label {
                                opacity: 0.6;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-family: inherit !important;
                            }
                        }
                    }
                    .months-list {
                        padding: 4px 0px;
                        box-sizing: border-box;
                        opacity: 0.6;
                        text-transform: uppercase;
                        font-size: 12px;
                        box-sizing: border-box;

                        &.months-length-10,
                        &.months-length-11,
                        &.months-length-12 {
                            @media (max-width: 1650px) and(min-width:1301px) {
                                span {
                                    &:nth-child(2n) {
                                        display: none;
                                    }
                                }
                            }
                            @media (max-width: 870px) and(min-width:769px) {
                                span {
                                    &:nth-child(2n) {
                                        display: none;
                                    }
                                }
                            }
                            @media (max-width: 768px) {
                                font-size: 10px;
                            }
                            @media (max-width: 360px) {
                                span {
                                    &:nth-child(2n) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            & > .messages {
                & > .title {
                    color: darken($messages, 20%);
                    border-color: $messages;
                }
                & > .flex {
                    .box:not(.chart) {
                        border-color: $messages;

                        & > span {
                            background: transparentize($messages, 0.85);
                            color: darken($messages, 10%);
                            border-color: $messages;
                        }
                        & > small {
                            color: darken($messages, 20%);
                        }
                    }
                }
            }

            & > .ranking {
                & > .title {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 20px;
                    color: $text-color;
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    border-bottom: 2px solid $text-color;
                }

                & > .box {
                    padding: 0 20px;
                }
            }
        }

        @media (max-width: 1700px) {
            & > .info {
                & > .online,
                & > .messages {
                    & > .flex {
                        .box:not(.chart) {
                            & > span {
                                font-size: 12px;
                            }
                            & > strong {
                                font-size: 18px;
                            }
                            & > small {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: 1301px) and (max-width: 1500px), (max-width: 500px) {
            & > .info {
                & > .online,
                & > .messages {
                    & > .flex {
                        flex-direction: column;

                        .box:not(.chart) {
                            border-radius: 4px;
                            flex-direction: row;
                            margin-bottom: 5px;

                            & > span {
                                font-size: 12px;
                                width: auto;
                                margin: 0;
                                line-height: 24px;
                                min-width: 75px;
                                text-align: left;
                                border-bottom: none;
                            }
                            & > strong {
                                font-size: 18px;
                                overflow: initial;
                                margin: 0;
                                line-height: 28px;
                            }
                            & > small {
                                font-size: 12px;
                                line-height: 28px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                margin: 0;

                                span {
                                    margin: 0 2px;
                                }

                                br {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 1300px) {
            flex-direction: column;

            & > .box {
                margin-right: 0 !important;

                & + .box {
                    margin-top: 20px;
                }
            }
        }
    }

    & > .months {
        overflow: hidden;
        margin-left: -10px;
        margin-right: -10px;

        .month {
            float: left;
            width: 16.666667%;
            padding: 10px;
            box-sizing: border-box;

            .wrapper {
                font-size: 13px;

                & > .label {
                    margin-right: auto;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    font-weight: bold;
                    background: transparentize($text-color, 0.95);
                    color: $text-color;
                    font-size: 16px;
                    padding: 4px 8px;
                    border-bottom-right-radius: 4px;
                }

                & > .messages,
                & > .online {
                    position: relative;
                    padding: 4px 10px;
                    margin: 10px 0px;
                    margin-left: 30px;
                    margin-right: 15px;

                    & > .label {
                        position: absolute;
                        display: block;
                        top: 0px;
                        bottom: 0px;
                        text-transform: uppercase;
                        color: $online;
                        margin: 0;
                        height: 100%;
                        width: 20px;
                        left: -20px;
                        text-align: center;

                        span {
                            transform: rotate(-180deg);
                            writing-mode: vertical-lr;
                            font-size: 8px;
                            line-height: 1;
                            min-width: initial;
                            height: 100%;
                        }
                    }

                    & > div:not(.label) {
                        margin-bottom: 4px;

                        span {
                            color: darken($online, 20%);
                            text-transform: uppercase;
                            font-size: 12px;
                            min-width: 30px;
                            display: inline-block;
                        }
                        strong {
                            font-size: 14px;
                            position: relative;
                            top: 1px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                & > .online {
                    background: transparentize($online, 0.95);
                    border-left: 1px solid $online;
                    margin-bottom: 5px;
                }
                & > .messages {
                    border-left: 1px solid $messages;
                    background: transparentize($messages, 0.95);

                    & > .label {
                        color: $messages;
                        border-color: $messages;
                    }
                    & > div:not(.label) {
                        span {
                            color: darken($messages, 20%);
                        }
                    }
                }

                & > .rooms {
                    text-align: center;
                    color: transparentize($text-color, 0.4);
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 12px;
                    background: $background-color;
                    padding: 5px;
                    margin-top: 5px;
                }

                &.no-room {
                    padding-bottom: 4px;
                }
            }

            @media (min-width: 1750px) {
                .wrapper {
                    .messages,
                    .online {
                        & > .label {
                            span {
                                font-size: 10px;
                            }
                        }
                        & > div:not(.label) {
                            span {
                                font-size: 14px;
                                min-width: 40px;
                            }
                            strong {
                                font-size: 16px;
                            }
                        }
                    }

                    .rooms {
                        font-size: 14px;
                    }
                }
            }
            @media (max-width: 1500px) {
                .wrapper {
                    .messages,
                    .online {
                        margin-left: 20px;
                    }
                }
            }
            @media (max-width: 1400px) {
                width: 25%;
            }
            @media (max-width: 1100px) {
                width: 33.333333%;
            }
            @media (max-width: 950px) {
                .wrapper {
                    .messages,
                    .online {
                        & > div:not(.label) {
                            span {
                                font-size: 10px;
                            }
                            strong {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            @media (min-width: 769px) and (max-width: 900px) {
                width: 50%;
            }
            @media (max-width: 530px) {
                width: 50%;
            }
            @media (max-width: 360px) {
                .wrapper {
                    .messages,
                    .online {
                        margin-left: 15px;

                        & > .label {
                            display: none;
                        }
                        & > div:not(.label) {
                            span {
                                min-width: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
