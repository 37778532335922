<template>
    <div class="qna-user flex align-center" :class="size" @click="open(user.id)">
        <div class="user-pic">
            <vue-load-image>
                <img slot="image" class="image" :src="profilePic" />
                <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                <img slot="error" class="error" :src="require('@/assets/images/person.png')" />
            </vue-load-image>
        </div>
        <div class="flex column justify-center pl-10 user-info">
            <div class="name">{{ user.name }} {{ user.surname }}</div>
            <div class="username">@{{ user.username }}</div>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import { openUserDialog } from "@/common"

const VueLoadImage = () => import("vue-load-image")

export default {
    name: "QnaUser",
    props: {
        user: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            default: "large"
        }
    },
    methods: {
        open(id) {
            openUserDialog({ id })
        }
    },
    computed: {
        profilePic() {
            return _.get(this.user, "picture.50") || require(`@/assets/images/person.png`)
        }
    },
    components: {
        "vue-load-image": VueLoadImage
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.qna-user {
    cursor: pointer;
    border-radius: 40px;
    max-width: 100%;
    overflow: hidden;
    padding-right: 20px;

    .user-pic {
        width: 40px;
        height: 40px;

        img {
            border-radius: 50%;
            width: 40px;
            display: block;
        }
    }

    .user-info {
        overflow: hidden;
    }

    .name {
        font-weight: 600;
        text-decoration: underline;
        text-decoration-color: $color-primary;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .username {
        opacity: 0.7;
    }

    &:hover {
        background-color: transparentize($color-primary, 0.9);
    }

    &.small {
        .username {
            display: none;
        }

        .user-pic {
            width: 25px;
            height: 25px;

            img {
                width: 25px;
                display: block;
                border-radius: 50%;
            }
        }
    }
}
</style>