<template>
    <div class="box-logo flex align-center" :class="{ 'nav-collapsed': collapseNav, [mode]: true }">
        <!--<div class="letter-logo">P</div>
		<div class="app-name" @click="goto('/')">iMamma<span> Panel</span></div>-->
        <img class="image-logo" src="@/assets/images/icon-mini.svg" alt="logo" />
        <img class="image-text-logo" src="@/assets/images/logo_h_mini-v2.svg" alt="logo" @click="goto('/dashboard')" />
        <!--
        <button class="collapse-nav" @click="collapseNavToggle">
            <i class="mdi mdi-menu"></i>
        </button>
		-->
    </div>
</template>

<script>
export default {
    name: "Logo",
    props: ["collapseNav", "mode"],
    data() {
        return {}
    },
    methods: {
        collapseNavToggle() {
            this.$emit("collapse-nav-toggle")
        },
        goto(index, indexPath) {
            this.$router.push(index).catch(err => {})
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.box-logo {
    user-select: none;
    height: 100px;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    //@include text-bordered-shadow();

    .letter-logo {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: $text-color;
        color: $color-primary;
        border-radius: 5px;
        margin-right: 10px;
        font-weight: bolder;
        font-size: 20px;
    }

    .image-logo {
        width: 34px;
        height: 34px;
        margin-right: 10px;
        //filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
        display: none;
    }

    .image-text-logo {
        width: 140px;
    }

    .app-name {
        cursor: pointer;
        line-height: 18px;
        font-size: 18px;

        & > span {
            color: $color-primary;
        }
    }

    .collapse-nav {
        position: absolute;
        top: 50%;
        left: 10px;
        height: 30px;
        width: 30px;
        margin-top: -15px;
        opacity: 0;
        box-shadow: 0px 0px 20px 10px $background-color;
        cursor: pointer;
        border: 1px solid $color-primary;
        border-radius: 50%;
        color: $color-primary;
        outline: none;
        background: $background-color;
        transition: all 0.5s;
    }

    &:hover {
        .collapse-nav {
            opacity: 1;
            left: 22px;
        }
    }

    &.nav-collapsed {
        .app-name {
            display: none;
        }
        .image-logo {
            display: block;
        }
        .image-text-logo {
            display: none;
        }
    }

    &.horizontal {
        height: 100%;

        .collapse-nav {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .box-logo {
        height: 80px;

        .collapse-nav {
            display: none;
        }

        &.horizontal {
            padding: 0 10px;

            .app-name {
                display: none;
            }
        }
    }
}
</style>
