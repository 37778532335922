<template>
	<div v-loading="loading">
		<div class="block flex column card-base card-shadow--small mb-30" v-for="bot of bots" :key="bot._id">
			<div class="flex">
				<div class="label box">
					Codici autorizzazione {{ bot.type }} <strong>{{ bot.name }}</strong>
				</div>
				<div class="value box grow tag-selector">
					<el-select
						v-model="bot.keys"
						multiple
						filterable
						allow-create
						default-first-option
						placeholder="Aggiungi token"
						popper-class="bot-tag-selector"
						@change="value => updateKeys(bot.name, value)"
					>
					</el-select>
				</div>
			</div>
			<div class="sub-block chat-list flex column mt-10" v-loading="bot.loading">
				<div class="label">Chat autenticate:</div>
				<div class="value scrollable only-x" v-if="bot.subscribers && bot.subscribers.length">
					<div class="box card-base card-shadow--small" v-for="sub in bot.subscribers" :key="sub.id">
						<div>
							<span>ID:</span>
							<strong>{{ sub.id }}</strong>
						</div>
						<div>
							<span>Data/ora:</span>
							<strong>{{ sub.date | dayjs("format", "DD/MM/YYYY HH:mm") }}</strong>
						</div>
						<div>
							<span>Nome:</span>
							<strong>{{ sub.chat.title }}</strong>
						</div>
						<div>
							<span>Tipo:</span>
							<strong>{{ sub.chat.type }}</strong>
						</div>
						<div>
							<span>Utente:</span>
							<strong>{{ sub.from.first_name }} (@{{ sub.from.username }})</strong>
						</div>
						<div class="del-btn">
							<button @click="delBotSubscriber(bot.name, sub.id)">
								<i class="mdi mdi-delete-outline"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="value" v-if="!bot.subscribers || !bot.subscribers.length">
					<div class="box">Nessuna chat si è ancora autenticata</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

export default {
	name: "BotToken",
	data() {
		return {
			loading: false,
			bots: []
		}
	},
	computed: {},
	methods: {
		getBotsList() {
			this.loading = true

			api.bot
				.getBotsList()
				.then(res => {
					if (res.status === 200 && res.body && res.body.length) {
						this.bots = _.map(res.body, b => {
							b.loading = false
							return b
						})
					}
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.loading = false
				})
		},
		updateKeys(botName, keys) {
			this.bots.find(b => b.name === botName).loading = true

			api.bot
				.updateBotToken(botName, keys)
				.then(res => {})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.bots.find(b => b.name === botName).loading = false
				})
		},
		getBotSubscribersList(botName) {
			this.bots.find(b => b.name === botName).loading = true

			api.bot
				.getBotSubscribersList(botName)
				.then(res => {
					if (res.status === 200 && res.body && res.body.length) {
						this.bot[botName].subscribers = res.body
					}
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.bots.find(b => b.name === botName).loading = false
				})
		},
		delBotSubscriber(botName, id) {
			this.$confirm("Sicura di voler disconnettere questa chat ?", "Attenzione", {
				confirmButtonText: "Si",
				cancelButtonText: "Annulla",
				type: "warning",
				confirmButtonClass: "el-button--danger"
			})
				.then(() => {
					this.bots.find(b => b.name === botName).loading = true

					api.bot
						.delBotSubscriber(id)
						.then(res => {
							if (res.status === 200 && res.body && res.body.length) {
								this.getBotSubscribersList(botName)

								this.$notify.success({
									title: "Fatto!",
									message: `Chat disconnessa`
								})
							} else {
								this.$notify.error({
									title: "Ops...",
									message: "Errore disconnessione chat"
								})
							}
						})
						.catch(err => {
							this.$notify.error({
								title: "Ops...",
								message: "Errore disconnessione chat"
							})
						})
				})
				.catch(() => {})
		}
	},
	created() {
		this.getBotsList()
	},
	mounted() {},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.block {
	padding: 20px;
	border-radius: 8px;

	.label {
		margin-right: 20px;
	}

	.tag-selector {
		.el-select {
			width: 100%;

			:deep {
				.el-select__tags {
					max-width: 100% !important;
				}
				.el-input__inner {
					padding: 0 15px;
				}
				.el-input__suffix {
					display: none;
				}
			}
		}
	}

	.sub-block {
		background: rgba($background-color, 0.5);
		padding: 20px;
		border-radius: 6px;

		.label {
			margin-bottom: 20px;
		}

		&.chat-list {
			.value {
				margin: 0 -8px;
				white-space: nowrap;

				.box {
					//background: rgba($color-primary, 0.1);
					margin: 8px;
					margin-right: 20px;
					padding: 10px;
					border-radius: 4px;
					position: relative;
					display: inline-block;
					white-space: nowrap;

					& > div {
						white-space: nowrap;

						span {
							margin-right: 10px;
						}

						&.del-btn {
							position: absolute;
							top: 5px;
							right: 5px;
							opacity: 0;
							transition: all 0.2s;

							button {
								cursor: pointer;
								background: rgba($color-danger, 0.1);
								color: $color-danger;
								font-size: 20px;
								border: none;
								outline: none;
								border-radius: 4px;
								padding: 3px 7px;
								transition: all 0.2s;

								&:hover {
									box-shadow: 0px 0px 0px 2px rgba($color-danger, 0.6);
								}
							}
						}
					}

					&:hover {
						.del-btn {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1000px) {
	.block {
		flex-direction: column;
		-webkit-box-orient: vertical;

		& > .flex:not(.sub-block) {
			flex-direction: column;
			-webkit-box-orient: vertical;
			margin-bottom: 20px;
		}

		.label {
			margin-bottom: 20px;
		}
	}
}
</style>
<style lang="scss">
.bot-tag-selector {
	display: none;
}
</style>
