var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "component",
      staticClass: "review-form",
      class: _vm.size,
    },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _vm.review
        ? _c("div", { ref: "wrapper", staticClass: "review-wrapper flex" }, [
            _c(
              "div",
              { staticClass: "content box grow scrollable only-y" },
              [
                _c("ReviewEditor", {
                  attrs: {
                    review: _vm.review,
                    size: _vm.size,
                    readonly: _vm.isReadonly,
                    textAreaResize: _vm.tAreaResize,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "sidebar box scrollable only-y",
                class: { collapsed: _vm.sidebarCollapsed },
              },
              [
                _c(
                  "div",
                  { staticClass: "wrapper card-base card-shadow--small" },
                  [
                    _c("div", { staticClass: "block review-status flex" }, [
                      _c("div", { staticClass: "box grow flex column" }, [
                        _c("span", { staticClass: "o-050" }, [_vm._v("stato")]),
                        _vm.isPublished
                          ? _c("strong", { staticClass: "success-text" }, [
                              _vm._v("Pubblicato"),
                            ])
                          : _vm._e(),
                        _vm.isDraft
                          ? _c("strong", { staticClass: "info-text" }, [
                              _vm._v("Bozza"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "box collapse-btn" }, [
                        _vm.sidebarCollapsed
                          ? _c("i", {
                              staticClass: "mdi mdi-chevron-right",
                              on: {
                                click: function ($event) {
                                  _vm.sidebarCollapsed = false
                                },
                              },
                            })
                          : _vm._e(),
                        !_vm.sidebarCollapsed
                          ? _c("i", {
                              staticClass: "mdi mdi-chevron-down",
                              on: {
                                click: function ($event) {
                                  _vm.sidebarCollapsed = true
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block review-category flex column" },
                      [
                        _c("span", [_vm._v("categoria")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "Donna, Bambino, Famiglia...",
                              disabled: _vm.isReadonly,
                            },
                            model: {
                              value: _vm.review.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.review, "category", $$v)
                              },
                              expression: "review.category",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "woman", label: "Donna" },
                            }),
                            _c("el-option", {
                              attrs: { value: "baby", label: "Bambino" },
                            }),
                            _c("el-option", {
                              attrs: { value: "family", label: "Famiglia" },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "earlychildhood",
                                label: "Prima infanzia",
                              },
                            }),
                            _c("el-option", {
                              attrs: { value: "newborn", label: "New born" },
                            }),
                          ],
                          1
                        ),
                        _vm.isWoman
                          ? _c(
                              "div",
                              { staticClass: "mt-10" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder:
                                        "Fertilità, Post Partum, Gravidanza...",
                                      disabled: _vm.isReadonly,
                                    },
                                    model: {
                                      value: _vm.womanCategory,
                                      callback: function ($$v) {
                                        _vm.womanCategory = $$v
                                      },
                                      expression: "womanCategory",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { value: 0, label: "Fertilità" },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        value: 99,
                                        label: "Post Partum",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: { value: 10, label: "Gravidanza" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isPregnancy
                          ? _c("div", { staticClass: "flex mt-10" }, [
                              _c(
                                "div",
                                { staticClass: "box grow mr-5" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "Mese Da",
                                        disabled: _vm.isReadonly,
                                      },
                                      model: {
                                        value: _vm.womanPregnancyFrom,
                                        callback: function ($$v) {
                                          _vm.womanPregnancyFrom = $$v
                                        },
                                        expression: "womanPregnancyFrom",
                                      },
                                    },
                                    _vm._l(9, function (i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: {
                                          value: i,
                                          label: i,
                                          disabled:
                                            _vm.womanPregnancyTo &&
                                            i > _vm.womanPregnancyTo,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "box grow ml-5" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "Mese A",
                                        disabled: _vm.isReadonly,
                                      },
                                      model: {
                                        value: _vm.womanPregnancyTo,
                                        callback: function ($$v) {
                                          _vm.womanPregnancyTo = $$v
                                        },
                                        expression: "womanPregnancyTo",
                                      },
                                    },
                                    _vm._l(9, function (i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: {
                                          value: i,
                                          label: i,
                                          disabled:
                                            _vm.womanPregnancyFrom &&
                                            i < _vm.womanPregnancyFrom,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.isBaby
                          ? _c("div", { staticClass: "flex mt-10" }, [
                              _c(
                                "div",
                                { staticClass: "box grow mr-5" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "Mese Da",
                                        disabled: _vm.isReadonly,
                                      },
                                      model: {
                                        value: _vm.babyMonthFrom,
                                        callback: function ($$v) {
                                          _vm.babyMonthFrom = $$v
                                        },
                                        expression: "babyMonthFrom",
                                      },
                                    },
                                    _vm._l(48, function (i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: {
                                          value: i,
                                          label: i,
                                          disabled:
                                            _vm.babyMonthTo &&
                                            i > _vm.babyMonthTo,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "box grow ml-5" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "Mese A",
                                        disabled: _vm.isReadonly,
                                      },
                                      model: {
                                        value: _vm.babyMonthTo,
                                        callback: function ($$v) {
                                          _vm.babyMonthTo = $$v
                                        },
                                        expression: "babyMonthTo",
                                      },
                                    },
                                    _vm._l(48, function (i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: {
                                          value: i,
                                          label: i,
                                          disabled:
                                            _vm.babyMonthFrom &&
                                            i < _vm.babyMonthFrom,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.isFamily ? _c("div") : _vm._e(),
                        _vm.isEarlychildhood ? _c("div") : _vm._e(),
                        _vm.isNewborn ? _c("div") : _vm._e(),
                      ],
                      1
                    ),
                    _vm.genreAvailable.length
                      ? _c(
                          "div",
                          { staticClass: "block review-genre" },
                          [
                            _c("span", [_vm._v("sesso")]),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Maschio, Femmina...",
                                  disabled: _vm.isReadonly,
                                },
                                model: {
                                  value: _vm.review.genre,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.review, "genre", $$v)
                                  },
                                  expression: "review.genre",
                                },
                              },
                              _vm._l(_vm.genreAvailable, function (g) {
                                return _c("el-option", {
                                  key: g.value,
                                  attrs: { value: g.value, label: g.label },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "block review-type" },
                      [
                        _c("span", [_vm._v("tipo")]),
                        _c("el-input", {
                          attrs: {
                            placeholder: "Tipo...",
                            disabled: _vm.isReadonly,
                          },
                          model: {
                            value: _vm.review.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "type", $$v)
                            },
                            expression: "review.type",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "block review-color" }, [
                      _c("span", [_vm._v("colore")]),
                      _c("div", { staticClass: "flex" }, [
                        _vm.isValidColor
                          ? _c("div", {
                              staticClass: "box color",
                              style: { background: _vm.color },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "box grow" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "Colore...",
                                disabled: _vm.isReadonly,
                              },
                              model: {
                                value: _vm.review.color,
                                callback: function ($$v) {
                                  _vm.$set(_vm.review, "color", $$v)
                                },
                                expression: "review.color",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block review-featured" },
                      [
                        _c("span", [_vm._v("in evidenza")]),
                        _c("el-switch", {
                          attrs: { disabled: _vm.isReadonly },
                          model: {
                            value: _vm.review.featured,
                            callback: function ($$v) {
                              _vm.$set(_vm.review, "featured", $$v)
                            },
                            expression: "review.featured",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingSave,
                            expression: "loadingSave",
                          },
                        ],
                        staticClass: "block review-actions flex column",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _vm.canSaveMeta && _vm.can_PublishReview
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "box grow",
                                    attrs: { plain: "", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateMeta()
                                      },
                                    },
                                  },
                                  [_vm._v(" Salva info ")]
                                )
                              : _vm._e(),
                            _vm.haveDraft
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "box grow",
                                    attrs: { plain: "", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToReview(_vm.haveDraft)
                                      },
                                    },
                                  },
                                  [_vm._v(" Vedi bozza ")]
                                )
                              : _vm._e(),
                            !_vm.haveDraft
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "box grow",
                                    attrs: {
                                      plain: "",
                                      type: "primary",
                                      disabled: !_vm.isDraftValid,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.update(false)
                                      },
                                    },
                                  },
                                  [_vm._v(" Salva bozza ")]
                                )
                              : _vm._e(),
                            _vm.can_PublishReview && _vm.isDraft
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "box grow",
                                    attrs: {
                                      plain: "",
                                      type: "success",
                                      disabled: !_vm.isValid,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.update(true)
                                      },
                                    },
                                  },
                                  [_vm._v(" Pubblica ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.isDisposable
                          ? _c(
                              "div",
                              { staticClass: "flex mt-10" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "box grow",
                                    attrs: { plain: "", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteDraft()
                                      },
                                    },
                                  },
                                  [_vm._v(" Elimina bozza ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canSaveMeta && _vm.can_PublishReview
                          ? _c("div", { staticClass: "mt-16 fs-14" }, [
                              _vm._v(" Cliccando su "),
                              _c("strong", [_vm._v('"Salva info"')]),
                              _vm._v(" verranno modificati soltanto: "),
                              _vm._m(0),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    !_vm.isProductsValid
                      ? _c("div", { staticClass: "fs-14" }, [
                          _vm._v(
                            " Per pubblicare una recensione è necessario creare prodotti validi "
                          ),
                        ])
                      : _vm._e(),
                    _vm.review.products.length > 15
                      ? _c("div", { staticClass: "fs-14 mt-10" }, [
                          _vm._v(
                            " È possibile caricare un massimo di 20 prodotti "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isNew
                      ? _c("div", { staticClass: "info mt-15" }, [
                          _vm.review.author_id
                            ? _c(
                                "div",
                                { staticClass: "block review-author" },
                                [
                                  _c("span", [_vm._v("autore")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openUser(
                                            _vm.review.author_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "@" +
                                            _vm._s(_vm.review.author_username)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.review.created_at
                            ? _c(
                                "div",
                                { staticClass: "block review-created_at" },
                                [
                                  _c("span", [_vm._v("creato il")]),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dayjs")(
                                          _vm.review.created_at,
                                          "format",
                                          "DD MMM YYYY HH:mm"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.review.updated_at
                            ? _c(
                                "div",
                                { staticClass: "block review-updated_at" },
                                [
                                  _c("span", [_vm._v("aggiornato il")]),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dayjs")(
                                          _vm.review.updated_at,
                                          "format",
                                          "DD MMM YYYY HH:mm"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.review.wp_post_url
                            ? _c(
                                "div",
                                { staticClass: "block review-wp_post_url" },
                                [
                                  _c("span", [_vm._v("blog url")]),
                                  _c("strong", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.review.wp_post_url,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("link")]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.review.id
                            ? _c(
                                "div",
                                { staticClass: "block review-mysqlid" },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c("span", [_vm._v("versione online")]),
                                    _vm._v(" "),
                                    _c("strong", { staticClass: "ml-10" }, [
                                      _vm._v("#" + _vm._s(_vm.review.id)),
                                    ]),
                                  ]),
                                  _vm.havePublished
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToReview(
                                                _vm.havePublished
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("vedi recensione"),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex mt-8" }, [
      _c("div", { staticClass: "box grow" }, [
        _c("strong", [_vm._v("- categoria")]),
        _c("br"),
        _c("strong", [_vm._v("- sesso")]),
        _c("br"),
      ]),
      _c("div", { staticClass: "box grow" }, [
        _c("strong", [_vm._v("- colore")]),
        _c("br"),
        _c("strong", [_vm._v("- in evidenza")]),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }