var render = function render(_c, _vm) {
  return _vm._m(0)
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c(
      "div",
      { staticClass: "not-found flex align-center scrollable" },
      [
        _c("div", { staticClass: "msg-box" }, [
          _c("h1", { staticClass: "animated fadeInDown" }, [_vm._v("404")]),
          _c("h2", { staticClass: "animated fadeInUp" }, [
            _vm._v("Oops!"),
            _c("br"),
            _vm._v("Pagina non trovata."),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }