var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-reviews-list flex column",
    },
    [
      _c(
        "div",
        { staticClass: "reviews-list scrollable only-y box grow" },
        [
          _vm._l(_vm.reviews, function (r) {
            return _c("ReviewCard", {
              key: r._id,
              attrs: { review: r, handle: false },
              on: {
                click: function ($event) {
                  return _vm.goTo(r._id)
                },
              },
            })
          }),
          !_vm.loading && !_vm.reviews.length
            ? _c(
                "div",
                {
                  staticClass: "review flex align-center",
                  on: {
                    click: function ($event) {
                      return _vm.goTo()
                    },
                  },
                },
                [_vm._m(0)]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "box grow info" }, [
      _c("div", { staticClass: "title mt-10" }, [
        _vm._v("Non sono presenti recensioni in bozza, creane una!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }