<template>
    <div v-loading="!loaded" ref="box">
        <resize-observer @notify="__resizeHandler" />
        <div class="main-list scrollable only-y" v-if="list && list.length" :class="[size]">
            <div class="item flex" v-for="item of list" :key="item.id">
                <div class="flex column propic">
                    <vue-load-image>
                        <img slot="image" class="image" :src="item.profilePic" />
                        <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                        <img slot="error" class="error" :src="require('@/assets/images/person.png')" />
                    </vue-load-image>
                </div>
                <div class="flex column box grow">
                    <div class="item-from box flex justify-space-between">
                        <div class="name">{{ item.user.name }} {{ item.user.surname }}</div>
                        <div class="item-date box">
                            {{ item.created | dayjs("format", "DD/MM/YYYY [alle] HH:mm") }}
                        </div>
                        <QnaActions :item="item" itemType="question" @restored="init" @deleted="init"></QnaActions>
                    </div>
                    <div class="item-date box">
                        {{ item.created | dayjs("format", "DD/MM/YYYY [alle] HH:mm") }}
                    </div>
                    <div class="item-content box flex" v-if="item.content">
                        {{ item.content }}
                    </div>
                    <div class="item-image box flex" v-if="item.image">
                        <img :src="item.image" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!list || !list.length">Non sono presenti domande sospese</div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import VueLoadImage from "vue-load-image"
import QnaActions from "@/components/Qna/QnaActions"

export default {
    name: "QuestionsReported",
    data() {
        return {
            loaded: false,
            list: [],
            size: "large"
        }
    },
    computed: {},
    methods: {
        init() {
            this.getItems()
        },
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.box) {
                const width = this.$refs.box.offsetWidth

                this.size = "large"
                if (width < 600) {
                    this.size = "small"
                } else if (width < 890) {
                    this.size = "medium"
                }
            }
        },
        getItems() {
            const params = {
                suspended: true,
                pagination: 10,
                page: 1
            }
            api.qna
                .getAll(params)
                .then(res => {
                    if (res.status === 200 && res.body && res.body.data) {
                        const list = _.get(res, "body.data").map(i => {
                            i.profilePic = _.get(i, "user.picture.50") || require(`@/assets/images/person.png`)

                            return i
                        })
                        this.list = list || []
                        this.$emit("update:count", _.get(res, "body.pagination.totalElements", 0) || 0)
                    } else {
                        this.list = []
                    }

                    this.loaded = true
                })
                .catch(err => {
                    console.error(err)

                    this.loaded = true
                })
        }
    },
    created() {},
    mounted() {
        this.init()
    },
    beforeDestroy() {},
    components: {
        "vue-load-image": VueLoadImage,
        QnaActions
    }
}
</script>



<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.main-list {
    max-height: 380px;

    .item {
        background: rgba($background-color, 0.5);
        border-radius: 8px;
        padding: 20px;
        margin: 10px 0;

        .propic {
            --size: 40px;
            margin-right: 0;
            width: var(--size);
            height: var(--size);

            .vue-load-image {
                width: var(--size);
                height: var(--size);

                img {
                    width: 100%;
                    border-radius: 50%;
                }
            }
        }

        .item-from {
            font-weight: bold;
            padding-left: 20px;
            padding-bottom: 4px;

            .item-date {
                display: none;
            }
        }
        .item-date {
            font-size: 12px;
            padding-left: 20px;
            opacity: 0.7;
            padding-bottom: 20px;
        }
        .item-content {
            padding: 0px 20px;
            font-size: 14px;
            padding-bottom: 20px;
        }
        .item-image {
            padding: 0px 20px;
            font-size: 14px;
            padding-bottom: 20px;

            img {
                width: 100%;
                max-width: 100px;
                border-radius: 4px;
            }
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.small {
        .item {
            .item-date {
                display: none;
            }
            .item-from {
                flex-direction: column;
                margin-bottom: 10px;

                .item-date {
                    display: inherit;
                    padding-left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>
