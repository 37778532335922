<template>
    <div class="template card-base card-shadow--small flex column">
        <div class="flex header justify-space-between">
            <div class="box grow tags" v-if="template && template.tags && template.tags.length">
                {{ template.tags[0] }}
            </div>
            <div class="box buttons">
                <button @click="copyTemplate(template)" class="primary">
                    <i class="mdi mdi-content-copy"></i>
                </button>
                <button @click="updateTemplate(template)" class="primary">
                    <i class="mdi mdi-pencil-outline"></i>
                </button>
                <button @click="deleteTemplate(template._id)" class="danger">
                    <i class="mdi mdi-delete-outline"></i>
                </button>
            </div>
        </div>

        <div class="body" v-html="template.body"></div>

        <div class="info flex justify-space-between">
            <div class="box">
                <span v-if="btnCount">
                    Contiene <strong>{{ btnCount }}</strong> {{ btnCount === 1 ? "pulsante" : "pulsanti" }}
                </span>
                <span v-else> <strong>NON</strong> contiene pulsanti </span>
            </div>
            <div class="date box">
                <span> ultima modifica: </span>
                <strong>
                    {{ template.updated_at | dayjs("format", "DD MMM YYYY, HH:mm") }}
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChannelMessageTemplate",
    props: ["template"],
    data() {
        return {}
    },
    computed: {
        btnCount() {
            let count = 0
            if (
                this.template &&
                this.template.meta &&
                this.template.meta.buttons &&
                this.template.meta.buttons.length
            ) {
                for (const r of this.template.meta.buttons) {
                    count += r.length
                }
            }
            return count
        }
    },
    methods: {
        copyTemplate(template) {
            this.$emit("copy", template)
        },
        updateTemplate(template) {
            this.$emit("edit", template)
        },
        deleteTemplate(id) {
            this.$emit("delete", id)
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: {}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.template {
    margin-bottom: 30px;
    transition: all ease 0.2s;
    position: relative;

    .header {
        padding: 20px;

        .tags {
            font-weight: bold;
            font-size: 20px;
        }

        .buttons {
            margin-right: -5px;
            background: #fff;
            box-shadow: -10px 0px 15px 5px #fff;

            button {
                font-size: 20px;
                margin-left: 14px;
                color: $color-primary;
                cursor: pointer;
                transition: all 0.2s;
                background: transparent;
                border: none;
                outline: none;

                &:hover {
                    text-shadow: 0px 2px 3px rgba($color-primary, 0.3);
                }

                &.danger {
                    color: $color-danger;

                    &:hover {
                        text-shadow: 0px 2px 3px rgba($color-danger, 0.3);
                    }
                }
            }
        }
    }

    .body {
        padding: 20px;

        :deep {
            img {
                max-width: 100%;
                border-radius: 6px;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .info {
        background: rgba($background-color, 0.9);
        padding: 10px 20px;
        font-size: 14px;
        color: rgba($text-color, 0.7);

        .date {
            text-align: right;
        }
    }

    &.closed {
        height: 0;
        margin: 0;
    }
}
</style>
