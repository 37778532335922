<template>
    <div class="page-reply-templates scrollable only-y" ref="page">
        <div class="form-box flex column card-base card-shadow--small" v-loading="loadingSend">
            <div class="header" v-if="!currentTemplateId">Nuova risposta</div>
            <div class="header" v-if="currentTemplateId">Modifica risposta</div>

            <div class="tag-selector">
                <el-select
                    v-model="tags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="Aggiungi etichetta"
                    popper-class="template-tag-selector"
                >
                </el-select>
            </div>

            <Editor
                :html.sync="html"
                :text.sync="text"
                :list="true"
                :table="false"
                :hiding="false"
                :placeholder="'Scrivi risposta...'"
                ref="editor"
            ></Editor>

            <div class="toolbar-box flex justify-space-between">
                <div class="box grow mr-20">
                    usa <strong v-html="'{{name}}'" @click="appendTag('name')" class="clickable"></strong> per
                    sostituirlo col nome del mittente
                </div>
                <div class="box text-right">
                    <el-button size="medium" plain @click="reset">Reset</el-button>
                    <el-button type="primary" plain size="medium" :disabled="!text" @click="send">Salva</el-button>
                </div>
            </div>
        </div>

        <div class="list" v-loading="loadingTemplates">
            <div class="header fs-20 mt-50">Lista risposte</div>

            <div v-if="!templates.length && !loadingTemplates">Non hai ancora una risposta</div>

            <ReplyTemplate
                v-for="t in templates"
                :key="t._id"
                :class="{ closed: t._id === currentTemplateId }"
                :template="t"
                @edit="updateTemplate(t)"
                @delete="deleteTemplate(t._id)"
            >
            </ReplyTemplate>
        </div>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
import Editor from "@/components/common/Editor"
import ReplyTemplate from "@/components/Support/ReplyTemplate"

export default {
    name: "ReplyTemplates",
    data() {
        return {
            templates: [],
            loadingSend: false,
            loadingTemplates: false,
            currentTemplateId: null,
            tags: [],
            html: "",
            text: ""
        }
    },
    computed: {},
    methods: {
        getTemplates() {
            this.loadingTemplates = true
            api.emails
                .getTemplates()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        const templates = res.body
                        this.templates = _.map(templates, t => {
                            t.tagsString = (t.tags || []).join(", ")
                            return t
                        }).reverse()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.loadingTemplates = false
                })
        },
        reset() {
            this.$refs.editor.reset()
            this.tags = []
            this.currentTemplateId = null
        },
        updateTemplate(template) {
            this.currentTemplateId = template._id
            this.$refs.editor.setContent(template.body)
            this.tags = template.tags

            this.$refs.page.scrollTo({ top: 0, behavior: "smooth" })
        },
        deleteTemplate(id) {
            this.$confirm("Sicura di voler eliminare questa risposta ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    this.$message("Sto eliminando la risposta...")

                    return api.emails
                        .deleteTemplate(id)
                        .then(response => {
                            this.getTemplates()

                            this.$notify.success({
                                title: "Fatto!",
                                message: `Risposta eliminata correttamente`
                            })
                        })
                        .catch(err => {
                            this.$notify.error({
                                title: "Ops...",
                                message: "Errore eliminazione risposta"
                            })
                        })
                })
                .catch(() => {})
        },
        send() {
            this.loadingSend = true
            this.$message("Sto salvando la risposta...")

            const method = this.currentTemplateId ? "updateTemplate" : "addTemplate"

            const template = {
                body: this.html,
                tags: this.tags
            }

            return api.emails[method](template, this.currentTemplateId)
                .then(response => {
                    this.getTemplates()
                    this.reset()

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Risposta salvata correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Errore salvataggio risposta"
                    })
                })
                .finally(() => {
                    this.loadingSend = false
                })
        },
        appendTag(tag) {
            const transaction = this.$refs.editor.getCtx().state.tr.insertText(` {{${tag}}}`)
            this.$refs.editor.getCtx().view.dispatch(transaction)
        }
    },
    created() {},
    mounted() {
        this.getTemplates()
    },
    beforeDestroy() {},
    components: {
        Editor,
        ReplyTemplate
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.page-reply-templates {
    .form-box {
        padding: 20px;

        & > .header {
            font-size: 20px;
            margin-bottom: 20px;
            user-select: none;
        }

        .tag-selector {
            .el-select {
                width: 100%;

                :deep {
                    .el-select__tags {
                        max-width: 100% !important;
                    }
                    .el-input__inner {
                        padding: 0 15px;
                    }
                    .el-input__suffix {
                        display: none;
                    }
                }
            }
        }

        .editor {
            border: solid 1px #e4e7ed;
            border-radius: 4px;
            padding: 15px;
            box-sizing: border-box;
            margin-top: 20px;
        }

        .toolbar-box {
            user-select: none;
            margin-top: 20px;

            .clickable {
                cursor: pointer;
                text-decoration: underline;
                text-decoration-color: $color-primary;
            }
        }
    }

    .list {
        & > .header {
            margin-bottom: 20px;
            user-select: none;
        }

        :deep {
            .template {
                &.closed {
                    height: 0;
                    margin: 0;
                }
            }
        }
    }
}
@media (max-width: 900px) {
    .page-reply-templates {
        .form-box {
            .toolbar-box {
                -webkit-box-orient: vertical;
                -webkit-box-direction: reverse;
                -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;

                .box {
                    margin-bottom: 20px;

                    &:first-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.template-tag-selector {
    display: none;
}
</style>
