var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "answer card-base card-shadow--small",
      class: [{ nested: _vm.nested, highlight: _vm.highlight }, _vm.size],
      attrs: { "data-answer-id": _vm.answer.id },
    },
    [
      _c(
        "div",
        { staticClass: "header flex" },
        [
          _c("QnaUser", {
            staticClass: "mr-10",
            attrs: {
              user: _vm.answer.user,
              "show-user": _vm.showUser,
              size: _vm.size,
            },
          }),
          _c("QnaDatetime", {
            staticClass: "box grow",
            attrs: { infoAbout: _vm.answer, size: _vm.size },
          }),
          _c("QnaActions", {
            attrs: { item: _vm.answer, nested: _vm.nested, itemType: "answer" },
            on: {
              deleted: function ($event) {
                return _vm.$emit("deleted")
              },
              restored: function ($event) {
                return _vm.$emit("restored")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content flex" },
        [
          _vm.answer.content
            ? _c("div", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.answer.content) + " "),
              ])
            : _vm._e(),
          _vm.answer.image
            ? _c("div", { staticClass: "body-image" }, [
                _c("img", { attrs: { src: _vm.answer.image, alt: "" } }),
              ])
            : _vm._e(),
          _c("QnaInfo", { attrs: { infoAbout: _vm.answer, size: _vm.size } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }