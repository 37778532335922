<template>
	<div class="bloodpressure card-base card-shadow--small">
		<div class="bb pv-10 ph-20 text-capitalized">
			<strong>{{ data.date | dayjs("format", "DD MMMM") }}</strong>
			<span class="ml-5">{{ data.date | dayjs("format", "YYYY") }}</span>
		</div>
		<div class="flex pv-10 ph-20">
			<div class="box grow text-center">
				<div class="fs-14 mb-5">MIN</div>
				<div>
					<strong>{{ data.min }}</strong>
				</div>
			</div>
			<div class="box grow text-center">
				<div class="fs-14 mb-5">MAX</div>
				<div>
					<strong>{{ data.max }}</strong>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const BloodPressure = function (id, user_id, min, max, date, created_at, updated_at) {
	this.id = id
	this.user_id = user_id
	this.min = min
	this.max = max
	this.date = date
	this.created_at = created_at
	this.updated_at = updated_at
}

export default {
	name: "BloodPressure",
	props: {
		data: [BloodPressure, Object]
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	methods: {},
	mounted() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.bloodpressure {
	display: inline-block;
}
</style>
