<template>
	<div class="hydration card-base card-shadow--small">
		<span class="date">
			<strong>{{ data.day | dayjs("format", "DD MMMM") }}</strong>
			<small class="ml-5">{{ data.day | dayjs("format", "YYYY") }}</small>
		</span>
		<span class="icon">
			<strong>{{ data.quantity }}</strong>
			<i class="mdi mdi-cup-water"></i>
		</span>
	</div>
</template>

<script>
const Hydration = function (id, user_id, day, quantity, created_at, updated_at) {
	this.id = id
	this.user_id = user_id
	this.day = day
	this.quantity = quantity
	this.created_at = created_at
	this.updated_at = updated_at
}

export default {
	name: "Hydration",
	props: {
		data: [Hydration, Object]
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	methods: {},
	mounted() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.hydration {
	display: inline-block;
	text-transform: capitalize;
	padding: 10px 20px;

	.date {
		margin-right: 20px;
	}
	.icon {
		font-size: 16px;

		strong {
			margin-right: 8px;
			font-size: 18px;
		}

		i {
			color: #6ed7e8;
		}
	}
}
</style>
