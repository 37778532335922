var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bloodpressure card-base card-shadow--small" },
    [
      _c("div", { staticClass: "bb pv-10 ph-20 text-capitalized" }, [
        _c("strong", [
          _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "DD MMMM"))),
        ]),
        _c("span", { staticClass: "ml-5" }, [
          _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "YYYY"))),
        ]),
      ]),
      _c("div", { staticClass: "flex pv-10 ph-20" }, [
        _c("div", { staticClass: "box grow text-center" }, [
          _c("div", { staticClass: "fs-14 mb-5" }, [_vm._v("MIN")]),
          _c("div", [_c("strong", [_vm._v(_vm._s(_vm.data.min))])]),
        ]),
        _c("div", { staticClass: "box grow text-center" }, [
          _c("div", { staticClass: "fs-14 mb-5" }, [_vm._v("MAX")]),
          _c("div", [_c("strong", [_vm._v(_vm._s(_vm.data.max))])]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }