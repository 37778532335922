<template>
	<div class="block flex card-base card-shadow--small" v-loading="loading">
		<div class="label box">Stop words</div>
		<div class="value box grow">
			<el-tag
				:key="tag"
				v-for="tag in stopWords"
				closable
				effect="plain"
				:disable-transitions="false"
				@close="removeStopWord(tag)"
			>
				{{ tag }}
			</el-tag>
			<transition name="slide-fade" mode="out-in">
				<el-input
					class="input-new-tag"
					v-if="stopWordInputVisible"
					v-model="stopWordInputValue"
					ref="stopWordInput"
					size="small"
					placeholder="Nuova parola"
					@keyup.enter.native="addStopWord"
					@keyup.esc.native="toggleStopWordInput(false)"
					@blur="toggleStopWordInput(false)"
				>
				</el-input>
				<el-button v-else size="small" @click="toggleStopWordInput(true)"> + Aggiungi parola </el-button>
			</transition>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

export default {
	name: "StopWords",
	data() {
		return {
			loading: false,
			stopWords: [],
			stopWordInputVisible: false,
			stopWordInputValue: ""
		}
	},
	computed: {},
	methods: {
		getStopWords() {
			this.loading = true

			api.chat
				.getStopWords()
				.then(res => {
					if (res.status === 200 && res.body) {
						this.stopWords = res.body
					}
				})
				.catch(err => {
					console.error(err)
				})
				.finally(() => {
					this.loading = false
				})
		},
		removeStopWord(tag) {
			api.chat
				.removeStopWord(tag)
				.then(res => {
					if (res.status === 200 && res.body) {
						this.stopWords.splice(this.stopWords.indexOf(tag), 1)
					}
				})
				.catch(err => {
					console.error(err)
				})
		},
		addStopWord() {
			const value = _.trim(this.stopWordInputValue)
			if (value && !_.includes(this.stopWords, value)) {
				api.chat
					.addStopWord(value)
					.then(res => {
						if (res.status === 200 && res.body) {
							this.stopWords.push(value)
							this.stopWordInputVisible = false
							this.stopWordInputValue = ""
						}
					})
					.catch(err => {
						console.error(err)
					})
			} else {
				this.toggleStopWordInput(false)
			}
		},
		/**
		 * @param {boolean} show
		 */
		toggleStopWordInput(show) {
			this.stopWordInputValue = ""
			this.stopWordInputVisible = show

			if (show) {
				setTimeout(() => {
					this.$refs.stopWordInput.$refs.input.focus()
				}, 400)
			}
		}
	},
	created() {},
	mounted() {
		this.getStopWords()
	},
	beforeDestroy() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.block {
	padding: 20px;
	border-radius: 8px;

	.label {
		margin-right: 20px;
	}

	.el-tag {
		margin-bottom: 10px;
		margin-right: 10px;
	}
	.input-new-tag {
		width: 200px;
	}
	.slide-fade-enter-active {
		transition: all 0.2s ease;
	}
	.slide-fade-leave-active {
		transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
	}
	/* .slide-fade-leave-active below version 2.1.8 */
	.slide-fade-enter,
	.slide-fade-leave-to {
		transform: translateX(16px);
		opacity: 0;
	}
}

@media (max-width: 1000px) {
	.block {
		flex-direction: column;
		-webkit-box-orient: vertical;

		.label {
			margin-bottom: 20px;
		}
	}
}
</style>
