<template>
    <div class="info box grow" :class="size" v-if="infoAbout">
        <span v-if="isNumber(infoAbout.score)">
            <span class="label">Punteggio: </span>
            <span :class="['icon', size]"><i class="mdi mdi-trophy-outline"></i></span>
            <strong>{{ infoAbout.score }}</strong>
        </span>
        <span v-if="isNumber(infoAbout.comments_count)">
            <span class="mh-10">~</span>
            <span class="label">Numero risposte: </span>
            <span :class="['icon', size]"><i class="mdi mdi-forum-outline"></i></span>
            <strong>{{ infoAbout.comments_count }}</strong>
        </span>

        <span v-if="isNumber(infoAbout.likes)">
            <span class="mh-10">~</span>
            <span class="label">Mi piace: </span>
            <span :class="['icon', size]"><i class="mdi mdi-thumb-up-outline"></i></span>
            <strong>{{ infoAbout.likes }}</strong>
        </span>

        <span v-if="isNumber(infoAbout.dislikes)">
            <span class="mh-10">~</span>
            <span class="label">Non mi piace: </span>
            <span class="icon"><i class="mdi mdi-thumb-down-outline"></i></span>
            <strong>{{ infoAbout.dislikes }}</strong>
        </span>
    </div>
</template>

<script>
export default {
    name: "QnaInfo",
    props: {
        infoAbout: {
            type: Object,
            required: false
        },
        size: {
            type: String,
            default: "large"
        }
    },
    methods: {
        isNumber(number) {
            return typeof number === "number"
        }
    }
}
</script>

<style lang="scss" scoped>
.info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 5px;

    .label {
        display: unset;
    }

    .icon {
        display: none;
    }
}

.info.small {
    .icon {
        display: inline-block;
        margin-right: 3px;
        margin-left: 20px;
    }
    .label {
        display: none;
    }
    .mh-10 {
        display: none;
    }
}
</style>