var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "child",
      class: { [_vm.child.gender]: true, active: _vm.active },
    },
    [
      _vm.data
        ? _c(
            "div",
            {
              staticClass: "content",
              on: {
                click: function ($event) {
                  return _vm.$emit("click", $event)
                },
              },
            },
            [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("div", { staticClass: "avatar" }, [
                  _c("img", { attrs: { src: _vm.child.picture } }),
                ]),
                _c("div", { staticClass: "side" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(" " + _vm._s(_vm.child.name) + " "),
                  ]),
                  _c("div", { staticClass: "birth-date" }, [
                    _vm._v(" " + _vm._s(_vm.birth_date) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "info flex align-center mt-20" }, [
                _c("div", { staticClass: "badge-box flex" }, [
                  _c("div", { staticClass: "badge" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(_vm.birth_weight)),
                    ]),
                  ]),
                  _c("div", { staticClass: "badge" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(_vm.birth_height)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "buttons-box flex align-center" }, [
                  _vm.can_ViewChildDetails
                    ? _c(
                        "div",
                        {
                          staticClass: "btn clickable ml-6",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.dialogItemVisible = true
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "mdi mdi-information-outline",
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogItemVisible,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogItemVisible = $event
            },
          },
        },
        [
          _c("print-object", {
            attrs: {
              "printable-object": _vm.child,
              "initial-collapsed": [
                "kicks",
                "contractions",
                "exams",
                "weights",
              ],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("i", { staticClass: "mdi mdi-weight" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("i", { staticClass: "mdi mdi-ruler" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }