<template>
    <div class="login-page flex">
        <div class="scrollable align-vertical side-box box-left">
            <div class="align-vertical-middle wrapper text-center">
                <!--<img class="image-logo" src="@/assets/images/logo_v.webp" alt="logo-app" />-->
            </div>
        </div>
        <div class="scrollable align-vertical side-box box-right">
            <div class="align-vertical-middle wrapper">
                <form class="form-box card-base card-shadow--extraLarge" @submit.prevent="login()" v-loading="loading">
                    <img class="image-logo" src="@/assets/images/logo_h_mini.png" alt="logo-app" />

                    <float-label class="styled">
                        <input type="email" name="email" placeholder="Email" v-model="email" autocomplete="email" />
                    </float-label>
                    <float-label class="styled">
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            v-model="password"
                            autocomplete="password"
                        />
                    </float-label>
                    <float-label class="styled">
                        <input type="text" name="otp" placeholder="OTP Code" v-model="otp" />
                    </float-label>

                    <div class="hint text-right">
                        <a href="https://obpanel.imamma.net" rel="noopener noreferrer nofollow" target="_blank">
                            come ottenere il codice OTP
                        </a>
                    </div>

                    <div class="flex text-center center pt-30 pb-10">
                        <el-button
                            plain
                            size="small"
                            type="primary"
                            native-type="submit"
                            :disabled="!isValid"
                            class="login-btn tada animated"
                        >
                            ENTRA
                        </el-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import _ from "lodash"

export default {
    name: "Login",
    data() {
        return {
            email: "",
            password: "",
            otp: "",
            loading: false
        }
    },
    computed: {
        isValid() {
            return _.trim(this.email) && _.trim(this.password) && _.trim(this.otp)
        }
    },
    methods: {
        login(token = "") {
            this.loading = true

            api.auth
                .login({ email: this.email, password: this.password, otp: this.otp, token })
                .then(res => {
                    if (!_.isEmpty(res.body)) {
                        this.$store.commit("setLogin", _.get(res, "body"))
                        const redirect_url = _.get(this.$route, "query.redirect_url") || "/"
                        this.$router
                            .replace({ path: redirect_url })
                            .then()
                            .catch(err => {})
                    } else {
                        console.error(res)
                        this.$alert("Credenziali errate", "Errore Login", {
                            confirmButtonText: "OK",
                            type: "error"
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                    if (err?.body?.error_code == 5) {
                        this.$alert("OTP non configurato", "Errore Login", {
                            confirmButtonText: "Configura OTP",
                            type: "warning"
                        })
                            .then(res => {
                                if (res === "confirm") {
                                    window.open("https://obpanel.imamma.net")
                                }
                            })
                            .catch(err => {})
                    } else if (err?.body?.error_code == 6) {
                        this.$alert("OTP mancante o errato", "Errore Login", {
                            confirmButtonText: "OK",
                            type: "error"
                        })
                    } else {
                        this.$alert("Credenziali errate", "Errore Login", {
                            confirmButtonText: "OK",
                            type: "error"
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        const token = _.get(this.$route, "query.token")
        if (token) {
            this.login(token)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.login-page {
    width: 100%;
    height: 100%;
    background: #fdf4f7;

    .side-box {
        .wrapper {
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
        }
    }

    .box-left {
        width: 50%;
        background-color: #fff;
        background-image: url("../../assets/images/login-cover.jpg");
        background-size: cover;
        background-position: 50% 50%;

        .wrapper {
            color: #416b8a;
            font-size: 30px;
            text-shadow: 0px 0px 20px #fff, 0px 0px 20px #fff, 0px 0px 20px #fff;
            font-family: "Times New Roman", Times, serif;

            .image-logo {
                width: 90%;
                max-width: 150px;
            }
        }
    }
    .box-right {
        width: 50%;
        padding: 20px;
        box-sizing: border-box;
        /*background-image: url("../../assets/images/login-box-1.jpg");
		background-size: cover;
		background-position: 50% 50%;*/
    }

    .form-box {
        width: 100%;
        max-width: 340px;
        padding: 50px;
        box-sizing: border-box;
        margin: 20px auto;
        //opacity: 0.85;

        a {
            font-size: 14px;
            color: $color-primary;
            text-decoration: none;
            font-weight: 500;
        }

        .image-logo {
            width: 100%;
            max-width: 200px;
            //width: 80px;
            margin: 0px auto;
            margin-bottom: 50px;
            display: block;
        }

        .login-btn,
        .social-btn {
            width: 160px;

            &.google {
                margin-bottom: 10px;
                background-color: #d73d32;
                color: white;

                &:hover {
                    border-color: #d73d32;
                }
            }
            &.facebook {
                background-color: #3f5c9a;
                color: white;

                &:hover {
                    border-color: #3f5c9a;
                }
            }
        }

        .signin-box {
            font-size: 14px;
        }
    }
}

@media (max-width: 1200px) {
    .login-page {
        .box-left {
            .wrapper {
                .h-big {
                    font-size: 50px;
                }
            }
        }
    }
}
@media (max-width: 900px) {
    .login-page {
        .box-left {
            .wrapper {
                .h-big {
                    font-size: 30px;
                }
            }
        }
    }
}
@media (max-width: 1050px) {
    .login-page {
        display: block;
        overflow: auto;

        .side-box {
            display: block;
        }

        .box-left {
            display: none;
        }
        .box-right {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
