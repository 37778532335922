var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.loaded,
          expression: "!loaded",
        },
      ],
    },
    [
      _vm.emails && _vm.emails.length
        ? _c(
            "div",
            { staticClass: "main-list scrollable only-y" },
            _vm._l(_vm.emails, function (email) {
              return _c(
                "div",
                {
                  key: email._id,
                  staticClass: "email flex",
                  attrs: { "data-card": "email" },
                  on: {
                    click: function ($event) {
                      return _vm.goToEmail(email._id)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "initials",
                        style: { backgroundColor: email.color },
                      },
                      [_vm._v(_vm._s(email.initials))]
                    ),
                    email.hasAttachments
                      ? _c("div", { staticClass: "attachments" }, [
                          _c("i", { staticClass: "mdi mdi-attachment" }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "flex column box grow" }, [
                    _c("div", { staticClass: "email-date box" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dayjs")(
                              email.date,
                              "format",
                              "DD MMM YYYY HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "email-from box" }, [
                      _vm._v(
                        " " +
                          _vm._s(email.from.name || email.from.address) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "email-subject box flex" }, [
                      _vm._v(" " + _vm._s(email.subject) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "email-labels" },
                      _vm._l(email.labels, function (label) {
                        return _c(
                          "span",
                          {
                            key: label.name,
                            style: { backgroundColor: label.color },
                          },
                          [_vm._v(" " + _vm._s(label.name) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.emails || !_vm.emails.length
        ? _c("div", [_vm._v("Non sono presenti richieste non lette")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }