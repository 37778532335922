var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.loaded,
          expression: "!loaded",
        },
      ],
    },
    [
      _vm.reports && _vm.reports.length
        ? _c(
            "div",
            { staticClass: "reports-list scrollable only-y" },
            _vm._l(_vm.reports, function (report) {
              return _c(
                "div",
                {
                  key: report._id,
                  staticClass: "report",
                  on: {
                    click: function ($event) {
                      return _vm.goToReport(report._id)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "header flex mb-5" }, [
                    _c(
                      "div",
                      {
                        staticClass: "msg-author",
                        style: { color: report.message.author.color },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openUser(report.message.author)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " @" + _vm._s(report.message.author.username) + " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "msg-time hidden-xs-only" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dayjs")(
                              report.message.date_sent,
                              "format",
                              "DD MMMM YYYY [alle] HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    report.message.body
                      ? _c("div", {
                          staticClass: "msg-body",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.selected(
                                report.message.body,
                                report.stop_words
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                    report.message.type === "img" ||
                    report.message.type === "sticker"
                      ? _c("div", { staticClass: "msg-body-img" }, [
                          _c("img", {
                            attrs: { src: report.message.imageThumb },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.reports || !_vm.reports.length
        ? _c("div", [_vm._v("Non sono presenti segnalazioni in evidenza")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }