<template>
    <el-menu
        :default-active.sync="activeLink"
        :mode="mode"
        @select="goto"
        :collapse="isCollapse"
        :unique-opened="true"
        background-color="transparent"
        class="main-navigation-menu"
        :class="{ 'nav-collapsed': isCollapse }"
    >
        <el-menu-item index="/dashboard">
            <i class="mdi mdi-gauge"></i>
            <span slot="title">Dashboard</span>
        </el-menu-item>

        <div class="el-menu-item-group__title">
            <span>Utenti</span>
        </div>
        <el-menu-item index="/users">
            <i class="mdi mdi-account-multiple-outline"></i>
            <span slot="title">Lista</span>
        </el-menu-item>
        <el-menu-item index="/users-export">
            <i class="mdi mdi-download-box-outline"></i>
            <span slot="title">Export</span>
        </el-menu-item>

        <div v-if="can_ViewReviewsNav">
            <div class="el-menu-item-group__title">
                <span>Recensioni</span>
            </div>
            <el-menu-item index="/reviews/published">
                <i class="mdi mdi-file-check-outline"></i>
                <span slot="title">Pubblicate</span>
            </el-menu-item>
            <el-menu-item index="/reviews/draft">
                <i class="mdi mdi-file-edit-outline"></i>
                <span slot="title">Bozze</span>
            </el-menu-item>
            <el-menu-item index="/reviews">
                <i class="mdi mdi-file-plus-outline"></i>
                <span slot="title">Crea nuova</span>
            </el-menu-item>
        </div>

        <div v-if="can_ViewBotNav">
            <div class="el-menu-item-group__title">
                <span>Canali</span>
            </div>
            <el-menu-item index="/channels/telegram">
                <i class="mdi mdi-send-circle-outline"></i>
                <span slot="title">Telegram</span>
            </el-menu-item>
        </div>

        <div class="el-menu-item-group__title">
            <span>Domande & Risposte</span>
        </div>
        <el-menu-item index="/qna/questions">
            <i class="mdi mdi-account-question-outline"></i>
            <span slot="title">Domande</span>
        </el-menu-item>
        <el-menu-item index="/qna/answers">
            <i class="mdi mdi-reply-all-outline"></i>
            <span slot="title">Risposte</span>
        </el-menu-item>
        <el-menu-item index="/qna/question-attachments">
            <i class="mdi mdi-paperclip"></i>
            <span slot="title">Allegati domande</span>
        </el-menu-item>
        <el-menu-item index="/qna/answer-attachments">
            <i class="mdi mdi-paperclip"></i>
            <span slot="title">Allegati risposte</span>
        </el-menu-item>

        <div class="el-menu-item-group__title">
            <span>Chat</span>
        </div>
        <el-menu-item index="/chat/rooms">
            <i class="mdi mdi-forum-outline"></i>
            <span slot="title">Stanze</span>
        </el-menu-item>
        <el-menu-item index="/chat/attachments">
            <i class="mdi mdi-paperclip"></i>
            <span slot="title">Allegati</span>
        </el-menu-item>
        <el-menu-item index="/chat/reports">
            <i class="mdi mdi-message-alert-outline"></i>
            <span slot="title">Segnalazioni</span>
        </el-menu-item>
        <el-menu-item index="/chat/institutional" v-if="can_ViewChatInstitutionalNav">
            <i class="mdi mdi-bullhorn-outline"></i>
            <span slot="title">Messaggi Istituzionali</span>
        </el-menu-item>
        <!--
        <el-menu-item index="/chat/stats">
            <i class="mdi mdi-chart-bar"></i>
            <span slot="title">Statistiche</span>
        </el-menu-item>
		-->

        <div class="el-menu-item-group__title">
            <span>Supporto</span>
        </div>
        <el-menu-item index="/support/requests" v-if="!hideOnTest">
            <i class="mdi mdi-email-outline"></i>
            <span slot="title">Richieste</span>
        </el-menu-item>
        <el-menu-item index="/support/templates" v-if="can_ViewSupportTemplatesNav">
            <i class="mdi mdi-reply-outline"></i>
            <span slot="title">Risposte preimpostate</span>
        </el-menu-item>

        <div v-if="can_ViewLogsNav">
            <div class="el-menu-item-group__title">
                <span>Logs</span>
            </div>
            <el-menu-item index="/activity-logs">
                <i class="mdi mdi-timeline-text-outline"></i>
                <span slot="title">Activity</span>
            </el-menu-item>
            <el-menu-item index="/error-logs">
                <i class="mdi mdi-timeline-alert-outline"></i>
                <span slot="title">Errors</span>
            </el-menu-item>
        </div>

        <div v-if="can_ViewSettingsNav" class="mt-30">
            <el-menu-item index="/settings">
                <i class="mdi mdi-cog-outline"></i>
                <span slot="title">Impostazioni</span>
            </el-menu-item>
            <el-menu-item index="https://obpanel.imamma.net/">
                <i class="mdi mdi-open-in-new"></i>
                <span slot="title">Vai su OB Panel</span>
            </el-menu-item>
        </div>

        <div class="collapse-menu-btn" :class="{ collapsed: isCollapse }">
            <button @click="$emit('collapse-nav-toggle')" class="flex align-center">
                <span v-if="!isCollapse"> <i class="mdi mdi-unfold-less-vertical"></i> Comprimi </span>
                <span v-if="isCollapse"> <i class="mdi mdi-unfold-more-vertical"></i></span>
            </button>
        </div>
    </el-menu>
</template>

<script>
import utils from "@/utils"
import { detect } from "detect-browser"

const browser = detect()

export default {
    name: "Nav",
    props: ["mode", "isCollapse"],
    data() {
        return {
            isIe: true,
            isEdge: true,
            activeLink: null,
            hideOnTest: utils.isEnvTest()
        }
    },
    computed: {
        can_ViewReviewsNav() {
            return this.$store.getters.can_ViewReviewsNav
        },
        can_ViewBotNav() {
            return this.$store.getters.can_ViewBotNav
        },
        can_ViewLogsNav() {
            return this.$store.getters.can_ViewLogsNav
        },
        can_ViewSettingsNav() {
            return this.$store.getters.can_ViewSettingsNav
        },
        can_ViewSupportTemplatesNav() {
            return this.$store.getters.can_ViewSupportTemplatesNav
        },
        can_ViewChatInstitutionalNav() {
            return this.$store.getters.can_ViewChatInstitutionalNav
        }
    },
    methods: {
        goto(index, indexPath) {
            if (index.charAt(0) === "/") {
                this.$router
                    .push(index)
                    .then()
                    .catch(err => {})
                this.$emit("push-page", { page: index })
            } else if (index.indexOf("http://") === 0 || index.indexOf("https://") === 0) {
                window.open(index)
            }
        },
        setLink(path) {
            this.activeLink = path
        }
    },
    created() {
        if (browser.name !== "ie") this.isIe = false
        if (browser.name !== "edge") this.isEdge = false

        this.setLink(this.$router.currentRoute.path)
        this.$router.afterEach((to, from) => {
            this.setLink(this.$router.currentRoute.path)
        })
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.el-menu {
    border: none;
}

.el-menu::before,
.el-menu::after {
    display: none;
}

.el-submenu,
.el-menu-item {
    font-size: 16px;

    .mdi {
        vertical-align: middle;
        margin-right: 5px;
        display: inline-block;
        width: 24px;
        text-align: center;
        font-size: 18px;
    }
}

.collapse-menu-btn {
    button {
        border-radius: 4px;
        border: none;
        color: $color-primary;
        background: lighten($color-primary, 34);
        font-weight: 600;
        padding: 4px 8px;
        margin-top: 20px;
        font-size: 12px;
        margin-left: 20px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;

        i {
            font-size: 14px;
            margin-right: 5px;
        }
    }

    &.collapsed {
        button {
            margin-left: 17px;

            i {
                margin-right: -2px;
            }
        }
    }
}
</style>

<style lang="scss">
@import "../../assets/scss/_variables";

.main-navigation-menu {
    user-select: none;
    transition: width 0.5s;

    &:not(.el-menu--collapse) {
        .el-submenu__title,
        .el-menu-item {
            height: 40px;
            line-height: 40px;
            background-color: transparent !important;
        }

        &:not(.el-menu--horizontal) {
            margin-bottom: 20px;

            .el-menu-item,
            .el-submenu {
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    width: 0px;
                    height: 1px;
                    position: absolute;
                    bottom: 10px;
                    left: 30px;
                    background: $text-color;
                    z-index: 1;
                    opacity: 0;
                    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
                }

                &:hover {
                    &::before {
                        width: 100px;
                        opacity: 1;
                        //left: 50px;
                        transform: translate(20px, 0);
                    }
                }

                &.is-active {
                    &::before {
                        background: $color-primary;
                    }
                }
            }
        }

        .el-submenu.is-opened {
            //background: #edf1f6 !important;
            //background: rgba(223, 228, 234, 0.38) !important;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 2px;
                position: absolute;
                top: 40px;
                bottom: 10px;
                left: 31px;
                background: $text-color;
                z-index: 1;
            }

            &::before {
                display: none;
            }

            .el-menu-item,
            .el-submenu {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .el-menu-item-group__title {
            padding: 15px 0 0px 20px;
            font-size: 14px;
            color: transparentize($text-color, 0.65);
        }
    }

    .el-submenu__title,
    .el-menu-item:not(.is-active) {
        color: $text-color;

        i {
            color: $text-color;
        }
    }

    &.el-menu--collapse {
        .el-menu-item-group__title {
            padding: 15px 0 0px 0px;
            width: 100%;
            text-align: center;
        }

        .el-submenu__title:hover,
        .el-menu-item:hover {
            background-color: rgba(0, 0, 0, 0.05) !important;
        }
    }

    &.el-menu--horizontal {
        white-space: nowrap;
        /*width: fit-content;
    width: max-content;*/
        overflow: hidden;
        display: table;

        & > * {
            float: inherit !important;
            display: inline-block;
        }
    }

    &.nav-collapsed {
        .el-menu-item,
        .el-submenu__title {
            & > span {
                display: none;
            }
        }
    }
}

.main-navigation-submenu {
    .el-menu {
        background: #fff !important;

        .el-menu-item:not(.is-active) {
            color: $text-color;
        }

        .el-menu-item:hover {
            background-color: transparentize($background-color, 0.3) !important;
        }
    }
}
</style>
