<template>
    <div class="page-user scrollable only-y">
        <div class="buttons-box">
            <div @click="$router.go(-1)" class="go-back-btn mr-30" v-if="showGoToBack">
                <i class="mdi mdi-arrow-left"></i> indietro
            </div>
        </div>

        <UserInfo
            :id="$route.params.id"
            v-if="$route.params.id"
            @loaded="setUser"
            @user-deleted="hardGoToList"
            class="mb-60"
        ></UserInfo>

        <el-tabs v-model="activeTab" v-if="user.username">
            <el-tab-pane label="Cloud" name="cloud">
                <UserCloud
                    :id="$route.params.id"
                    v-if="$route.params.id && user.username"
                    :flow-duration="user.flow_duration"
                ></UserCloud>
            </el-tab-pane>
            <el-tab-pane label="Bambini" name="children">
                <UserChildren :id="$route.params.id" v-if="$route.params.id && user.username"></UserChildren>
            </el-tab-pane>
            <el-tab-pane label="Domande" name="questions">
                <QuestionList :user="userId"></QuestionList>
            </el-tab-pane>
            <el-tab-pane label="Risposte" name="answers">
                <AnswerList :user="userId"></AnswerList>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
const UserInfo = () => import("@/components/User/UserInfo")
const UserCloud = () => import("@/components/User/UserCloud")
const UserChildren = () => import("@/components/User/UserChildren")
const QuestionList = () => import("@/components/Qna/QuestionList")
const AnswerList = () => import("@/components/Qna/AnswerList")

export default {
    name: "UserInfoPage",
    data() {
        return {
            user: {},
            activeTab: "cloud"
        }
    },
    computed: {
        showGoToBack() {
            return window.history.state !== null
        },
        userId() {
            return this.$route.params.id ? parseInt(this.$route.params.id) : 0
        }
    },
    methods: {
        setUser(user) {
            this.user = user
        },
        hardGoToList() {
            this.$router.replace({ path: "/users" }).catch(err => {})
        }
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    components: { UserInfo, UserCloud, UserChildren, QuestionList, AnswerList }
}
</script>

<style lang="scss" scoped>
.page-user {
    .go-back-btn {
        cursor: pointer;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }

    .buttons-box {
        margin-bottom: 20px;
    }

    .el-tabs {
        :deep {
            .el-tabs__content {
                padding: 0 5px;
                margin: 0 -5px;
            }
        }
    }

    .question-list,
    .answer-list {
        padding: 0 5px;
        margin: 0 -5px;
        padding-bottom: 10px;

        :deep {
            .list-wrap {
                margin-right: -6px;
            }
        }
    }
}
</style>
