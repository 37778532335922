var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "user-children",
    },
    [
      _vm.children && _vm.children.length
        ? _c(
            "div",
            { staticClass: "children-list" },
            _vm._l(_vm.children, function (child) {
              return _c("Child", {
                key: child.id,
                staticClass: "mr-20 mb-10",
                attrs: { data: child, active: _vm.active === child.id },
                on: {
                  click: function ($event) {
                    _vm.active = child.id
                  },
                },
              })
            }),
            1
          )
        : _c("div", [_vm._v("Nessun bambino registrato")]),
      !_vm.active && _vm.children && _vm.children.length
        ? _c("div", [_vm._v("Seleziona un bimbo per visualizzare i dettagli")])
        : _vm._e(),
      _vm.cloud
        ? _c("div", [
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/bagnetto.svg"),
                  },
                }),
                _vm._v(" Bagnetto "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.baths.length) + ")"),
                ]),
              ]),
              _vm.cloud.baths.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.baths, function (bath) {
                          return _c("CloudItem", {
                            key: bath.id,
                            staticClass: "mr-20",
                            attrs: { data: bath, type: "bath" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun bagnetto registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/seno.svg"),
                  },
                }),
                _vm._v(" Seno "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.breastfeeding.length) + ")"),
                ]),
              ]),
              _vm.cloud.breastfeeding.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(
                          _vm.cloud.breastfeeding,
                          function (breastfeeding) {
                            return _c("CloudItem", {
                              key: breastfeeding.id,
                              staticClass: "mr-20",
                              attrs: {
                                data: breastfeeding,
                                type: "breastfeeding",
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun allattamento registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/biberon.svg"),
                  },
                }),
                _vm._v(" Biberon "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.biberon.length) + ")"),
                ]),
              ]),
              _vm.cloud.biberon.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.biberon, function (biberon) {
                          return _c("CloudItem", {
                            key: biberon.id,
                            staticClass: "mr-20",
                            attrs: { data: biberon, type: "biberon" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun biberon registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/pannolino.svg"),
                  },
                }),
                _vm._v(" Pannolini "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.diapers.length) + ")"),
                ]),
              ]),
              _vm.cloud.diapers.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.diapers, function (diaper) {
                          return _c("CloudItem", {
                            key: diaper.id,
                            staticClass: "mr-20",
                            attrs: { data: diaper, type: "diaper" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun pannolino registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/sonno.svg"),
                  },
                }),
                _vm._v(" Sonno "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.sleeps.length) + ")"),
                ]),
              ]),
              _vm.cloud.sleeps.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.sleeps, function (sleep) {
                          return _c("CloudItem", {
                            key: sleep.id,
                            staticClass: "mr-20",
                            attrs: { data: sleep, type: "sleep" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun sonno registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/pappa.svg"),
                  },
                }),
                _vm._v(" Pappa "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.meals.length) + ")"),
                ]),
              ]),
              _vm.cloud.meals.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.meals, function (meal) {
                          return _c("CloudItem", {
                            key: meal.id,
                            staticClass: "mr-20",
                            attrs: { data: meal, type: "meal" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun pasto registrato")]),
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "header flex align-center" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/tools-baby-icons/dentini.svg"),
                  },
                }),
                _vm._v(" Dentini "),
                _c("small", { staticClass: "o-070" }, [
                  _vm._v("(" + _vm._s(_vm.cloud.teeth.length) + ")"),
                ]),
              ]),
              _vm.cloud.teeth.length
                ? _c(
                    "div",
                    { staticClass: "list scrollable only-x" },
                    [
                      _c(
                        "XyzTransitionGroup",
                        {
                          attrs: {
                            appear: "",
                            xyz: "fade small appear-stagger",
                          },
                        },
                        _vm._l(_vm.cloud.teeth, function (teeth) {
                          return _c("CloudItem", {
                            key: teeth.id,
                            staticClass: "mr-20",
                            attrs: { data: teeth, type: "teeth" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Nessun dentino registrato")]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }