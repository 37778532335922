var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rooms-select card-base card-shadow--small" },
    [
      _c(
        "el-select",
        {
          attrs: {
            clearable: !!_vm.clearable,
            placeholder: "Scegli una stanza",
            filterable: "",
          },
          on: { change: _vm.change },
          model: {
            value: _vm.propModel,
            callback: function ($$v) {
              _vm.propModel = $$v
            },
            expression: "propModel",
          },
        },
        _vm._l(_vm.roomsGroups, function (group) {
          return _c(
            "el-option-group",
            {
              key: group.name,
              attrs: { label: _vm.$sugar.String.titleize(group.name) },
            },
            _vm._l(group.items, function (room) {
              return _c(
                "el-option",
                {
                  key: room._id,
                  attrs: {
                    label:
                      _vm.$sugar.String.titleize(group.name) +
                      " / " +
                      room.name,
                    value: room._id,
                  },
                },
                [_vm._v(" " + _vm._s(room.name) + " ")]
              )
            }),
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }