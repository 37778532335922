var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-review flex column",
    },
    [
      _c("div", { staticClass: "buttons-box box" }, [
        _vm.showGoToBack
          ? _c(
              "div",
              { staticClass: "go-back-btn mr-30", on: { click: _vm.goToBack } },
              [
                _c("i", { staticClass: "mdi mdi-arrow-left" }),
                _vm._v(" indietro "),
              ]
            )
          : _vm._e(),
      ]),
      _c("ReviewForm", {
        staticClass: "box grow",
        attrs: { id: _vm.$route.params.id, dirty: _vm.dirty },
        on: {
          "update:dirty": function ($event) {
            _vm.dirty = $event
          },
          error: _vm.goToPublished,
          deleted: _vm.goToDraft,
          goToReview: _vm.goToReview,
          loaded: function ($event) {
            _vm.loading = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }