var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "years-list scrollable only-x" },
    _vm._l(_vm.years, function (year) {
      return _c(
        "div",
        {
          key: year,
          staticClass: "item card-base card-shadow--small",
          on: {
            click: function ($event) {
              return _vm.$emit("select", year)
            },
          },
        },
        [_vm._v(" " + _vm._s(year) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }