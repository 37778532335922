var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "page", staticClass: "page-reply-templates scrollable only-y" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingSend,
              expression: "loadingSend",
            },
          ],
          staticClass: "form-box flex column card-base card-shadow--small",
        },
        [
          !_vm.currentTemplateId
            ? _c("div", { staticClass: "header" }, [_vm._v("Nuova risposta")])
            : _vm._e(),
          _vm.currentTemplateId
            ? _c("div", { staticClass: "header" }, [
                _vm._v("Modifica risposta"),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "tag-selector" },
            [
              _c("el-select", {
                attrs: {
                  multiple: "",
                  filterable: "",
                  "allow-create": "",
                  "default-first-option": "",
                  placeholder: "Aggiungi etichetta",
                  "popper-class": "template-tag-selector",
                },
                model: {
                  value: _vm.tags,
                  callback: function ($$v) {
                    _vm.tags = $$v
                  },
                  expression: "tags",
                },
              }),
            ],
            1
          ),
          _c("Editor", {
            ref: "editor",
            attrs: {
              html: _vm.html,
              text: _vm.text,
              list: true,
              table: false,
              hiding: false,
              placeholder: "Scrivi risposta...",
            },
            on: {
              "update:html": function ($event) {
                _vm.html = $event
              },
              "update:text": function ($event) {
                _vm.text = $event
              },
            },
          }),
          _c("div", { staticClass: "toolbar-box flex justify-space-between" }, [
            _c("div", { staticClass: "box grow mr-20" }, [
              _vm._v(" usa "),
              _c("strong", {
                staticClass: "clickable",
                domProps: { innerHTML: _vm._s("{{name}}") },
                on: {
                  click: function ($event) {
                    return _vm.appendTag("name")
                  },
                },
              }),
              _vm._v(" per sostituirlo col nome del mittente "),
            ]),
            _c(
              "div",
              { staticClass: "box text-right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium", plain: "" },
                    on: { click: _vm.reset },
                  },
                  [_vm._v("Reset")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      plain: "",
                      size: "medium",
                      disabled: !_vm.text,
                    },
                    on: { click: _vm.send },
                  },
                  [_vm._v("Salva")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingTemplates,
              expression: "loadingTemplates",
            },
          ],
          staticClass: "list",
        },
        [
          _c("div", { staticClass: "header fs-20 mt-50" }, [
            _vm._v("Lista risposte"),
          ]),
          !_vm.templates.length && !_vm.loadingTemplates
            ? _c("div", [_vm._v("Non hai ancora una risposta")])
            : _vm._e(),
          _vm._l(_vm.templates, function (t) {
            return _c("ReplyTemplate", {
              key: t._id,
              class: { closed: t._id === _vm.currentTemplateId },
              attrs: { template: t },
              on: {
                edit: function ($event) {
                  return _vm.updateTemplate(t)
                },
                delete: function ($event) {
                  return _vm.deleteTemplate(t._id)
                },
              },
            })
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }