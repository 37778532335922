var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qna-attachment card-base card-shadow--small" },
    [
      _c(
        "div",
        { staticClass: "wrapper flex column" },
        [
          _c("QnaUser", {
            staticClass: "mb-15",
            attrs: { user: _vm.item.user, size: "small" },
          }),
          _c(
            "div",
            { staticClass: "qna-body-img box grow" },
            [
              _c("vue-load-image", [
                _c("img", {
                  directives: [
                    {
                      name: "img",
                      rawName: "v-img",
                      value: { src: _vm.item.image },
                      expression: "{ src: item.image }",
                    },
                  ],
                  staticClass: "image",
                  attrs: { slot: "image", src: _vm.item.image },
                  slot: "image",
                }),
                _c(
                  "div",
                  {
                    staticClass: "pt-50",
                    attrs: { slot: "preloader" },
                    slot: "preloader",
                  },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: true,
                          expression: "true",
                        },
                      ],
                    }),
                  ]
                ),
                _c("img", {
                  staticClass: "error",
                  attrs: {
                    slot: "error",
                    src: require("@/assets/images/img-not-found.svg"),
                  },
                  slot: "error",
                }),
              ]),
            ],
            1
          ),
          _c("QnaActions", {
            staticClass: "mt-5",
            attrs: { item: _vm.item, itemType: _vm.item.itemType },
            on: {
              deleted: function ($event) {
                return _vm.$emit("deleted")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }