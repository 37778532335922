<template>
    <div class="child" :class="{ [child.gender]: true, active }">
        <div v-if="data" class="content" @click="$emit('click', $event)">
            <div class="header flex align-center">
                <div class="avatar">
                    <img :src="child.picture" />
                </div>
                <div class="side">
                    <div class="name">
                        {{ child.name }}
                    </div>
                    <div class="birth-date">
                        {{ birth_date }}
                    </div>
                </div>
            </div>

            <div class="info flex align-center mt-20">
                <div class="badge-box flex">
                    <div class="badge">
                        <div class="left"><i class="mdi mdi-weight"></i></div>
                        <div class="right">{{ birth_weight }}</div>
                    </div>
                    <div class="badge">
                        <div class="left">
                            <i class="mdi mdi-ruler"></i>
                        </div>
                        <div class="right">{{ birth_height }}</div>
                    </div>
                </div>
                <div class="buttons-box flex align-center">
                    <div
                        class="btn clickable ml-6"
                        v-if="can_ViewChildDetails"
                        @click.stop.prevent="dialogItemVisible = true"
                    >
                        <i class="mdi mdi-information-outline"></i>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogItemVisible"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            width="600px"
        >
            <print-object
                :printable-object="child"
                :initial-collapsed="['kicks', 'contractions', 'exams', 'weights']"
            ></print-object>
        </el-dialog>
    </div>
</template>

<script>
import PrintObject from "vue-print-object"

export default {
    name: "Child",
    props: {
        data: Object,
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            child: {
                id: this.data?.id,
                pregnancy_id: this.data?.pregnancy_id,
                name: this.data?.name,
                gender: this.data?.gender,
                picture: this.data?.picture || require("@/assets/images/img-not-found.svg"),
                birth_date: this.data?.birth_date,
                birth_weight: this.data?.birth_weight,
                birth_height: this.data?.birth_height,
                created: this.data?.created
            },
            dialogItemVisible: false
        }
    },
    computed: {
        can_ViewChildDetails() {
            return this.$store.getters.can_ViewChildDetails
        },
        dialogTitle() {
            return `Dettaglio ${this.child?.gender === "male" ? "bambino" : "bambina"}`
        },
        birth_date() {
            if (this.child?.birth_date) {
                return this.$sugar.String.titleize(this.$dayjs(this.child.birth_date).format("DD MMM YYYY"))
            }
            return ""
        },
        birth_weight() {
            return this.child.birth_weight ? this.child.birth_weight + " kg" : "?"
        },
        birth_height() {
            return this.child.birth_height ? this.child.birth_height + " cm" : "?"
        }
    },
    watch: {},
    methods: {},
    mounted() {},
    components: { PrintObject }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.child {
    --card-color: #eeeeee;
    --card-color-rgb: 238, 238, 238;

    &.male {
        --card-color: #63bcfd;
        --card-color-rgb: 99, 188, 253;
    }
    &.female {
        --card-color: #f991ff;
        --card-color-rgb: 249, 145, 255;
    }

    overflow: visible !important;
    border: 4px solid transparent;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    transition: all 0.25s;

    &.active {
        border-color: var(--card-color);
        box-shadow: 0px 7px 6px -6px var(--card-color) !important;
    }

    .content {
        padding: 20px;
        overflow: visible;
        position: relative;

        .header {
            .avatar {
                margin-right: 20px;

                img {
                    display: block;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }

            .side {
                .name {
                    font-size: 20px;
                    font-weight: 700;
                    color: rgba(var(--card-color-rgb), 0.8);
                    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
                }
            }
        }

        .badge-box {
            .badge {
                display: inline-block;
                border-radius: 8px;
                overflow: hidden;
                border: 2px solid rgba(var(--card-color-rgb), 0.2);
                margin-right: 8px;

                .left {
                    background: rgba(var(--card-color-rgb), 0.2);
                    display: inline-block;
                    padding: 1px 5px;
                }
                .right {
                    padding: 1px 8px;
                    display: inline-block;
                }

                &.clickable {
                    cursor: pointer;
                    outline: none;
                }
            }
        }

        .buttons-box {
            height: 30px;

            .btn {
                display: inline-block;
                font-size: 18px;

                &.clickable {
                    cursor: pointer;
                    outline: none;
                    position: relative;
                }
            }
        }
    }
}
</style>
