<template>
    <div class="toolbar" :class="[size]" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <el-tooltip
            class="item"
            effect="dark"
            :open-delay="500"
            content="Segna come non letto"
            placement="top-start"
            :disabled="tooltipHidden"
        >
            <button v-show="thread.isSeen" @click="toggleSeen" :disabled="seenLoading">
                <i class="mdi mdi-email-outline" v-if="!seenLoading"></i>
                <i class="mdi mdi-loading" v-if="seenLoading"></i>
            </button>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="dark"
            :open-delay="500"
            content="Segna come letto"
            placement="top-start"
            :disabled="tooltipHidden"
        >
            <button v-show="!thread.isSeen" @click="toggleSeen" :disabled="seenLoading">
                <i class="mdi mdi-email-open-outline" v-if="!seenLoading"></i>
                <i class="mdi mdi-loading" v-if="seenLoading"></i>
            </button>
        </el-tooltip>

        <el-popover placement="bottom-start" width="280" trigger="click" popper-class="thread-labels-select">
            <button
                v-for="label in filteredLabels"
                :key="label.name"
                :style="{ backgroundColor: label.color }"
                @click="addLabel(label)"
            >
                <span>{{ label.name }}</span>
            </button>
            <div v-if="!filteredLabels.length">Non sono presenti etichette assegnabili</div>

            <div slot="reference">
                <el-tooltip
                    class="item"
                    effect="dark"
                    :open-delay="500"
                    content="Assegna etichetta"
                    placement="top-start"
                    :disabled="tooltipHidden"
                >
                    <button :disabled="labelsLoading">
                        <i class="mdi mdi-label-outline" v-if="!labelsLoading"></i>
                        <i class="mdi mdi-loading" v-if="labelsLoading"></i>
                    </button>
                </el-tooltip>
            </div>
        </el-popover>

        <button
            v-for="label in thread.labels"
            :key="label.name"
            :style="{ backgroundColor: label.color }"
            @click="delLabel(label.name)"
            class="label"
            :disabled="labelsLoading"
        >
            <i class="mdi mdi-close" v-if="!labelsLoading"></i>
            <i class="mdi mdi-loading" v-if="labelsLoading"></i>
            <span>{{ label.name }}</span>
        </button>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"

export default {
    name: "ThreadToolbar",
    props: ["size", "tooltipHidden", "thread"],
    data() {
        return {
            threadLabels: [],
            seenLoading: false,
            labelsLoading: false
        }
    },
    computed: {
        filteredLabels() {
            return this.thread
                ? _.filter(this.threadLabels, l => {
                      return !_.includes(
                          _.map(this.thread.labels || [], label => label.name),
                          l.name
                      )
                  })
                : []
        }
    },
    methods: {
        swipeLeft() {
            this.$emit("swipeLeft")
        },
        swipeRight() {
            this.$emit("swipeRight")
        },
        addLabel(label) {
            this.labelsLoading = true

            const id = this.thread._id
            api.emails
                .addThreadLabel(id, label)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.thread.labels.push(label)
                        this.$emit("updateThread")
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.labelsLoading = false
                })
        },
        delLabel(name) {
            this.labelsLoading = true

            const id = this.thread._id
            api.emails
                .delThreadLabel(id, name)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.thread.labels = _.filter(this.thread.labels, l => l.name !== name)
                        this.$emit("updateThread")
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.labelsLoading = false
                })
        },
        toggleSeen() {
            this.setSeenLoading(true)

            const id = this.thread._id
            const method = this.thread.isSeen ? "setNotSeen" : "setSeen"
            api.emails[method](id)
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.$emit("updateThread")
                        this.thread.isSeen = !this.thread.isSeen
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.setSeenLoading(false)
                })
        },
        getThreadLabels() {
            api.common
                .getThreadLabels()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.threadLabels = res.body
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        setSeenLoading(val) {
            this.seenLoading = val
        }
    },
    mounted() {
        this.getThreadLabels()
    },
    created() {},
    beforeDestroy() {},
    components: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.toolbar {
    padding: 20px;
    padding-bottom: 0px;
    min-height: 45px;

    button {
        background: $background-color;
        border: none;
        padding: 4px 10px;
        font-size: 18px;
        outline: none;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 6px;
        font-family: inherit;
        cursor: pointer;
        display: inline-block;
        height: 32px;
        transition: all ease 0.2s;

        &.label {
            font-weight: bold;
            font-size: 16px;
            position: relative;
            top: -1px;
            color: rgba(0, 0, 0, 0.5);
            overflow: hidden;

            i {
                padding-right: 12px;
                position: relative;
                top: 1px;
                margin-left: -30px;
                box-sizing: border-box;
                transition: all ease 0.2s;
            }

            &:hover {
                i {
                    margin-left: 0px;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;
        }

        i.mdi-loading {
            color: $color-primary;

            &::before {
                animation: spin 2s linear infinite;
            }
        }

        &:hover:not(.label) {
            color: $color-primary;
        }
    }
    span {
        display: inline-block;
    }

    &.medium,
    &.small {
        padding-top: 0;

        button {
            &.label {
                i {
                    margin-left: 0px;
                }
            }
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
</style>
<style lang="scss">
.thread-labels-select {
    button {
        font-size: 14px;
        display: inline-block;
        padding: 4px 8px;
        border-radius: 6px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
        cursor: pointer;
        border: none;
        outline: none;
    }
}
</style>
