var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "box", staticClass: "question-single-page flex column" },
    [
      _c("resize-observer", { on: { notify: _vm.__resizeHandler } }),
      _c("div", { staticClass: "buttons-box" }, [
        _c(
          "div",
          { staticClass: "go-back-btn mr-30", on: { click: _vm.goBack } },
          [_c("i", { staticClass: "mdi mdi-arrow-left" }), _vm._v(" indietro")]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "list-wrap box grow flex column",
        },
        [
          _c(
            "div",
            { ref: "list", staticClass: "list-box box grow scrollable only-y" },
            [
              !_vm.question && !_vm.loading
                ? _c("span", [_vm._v("Domanda non trovata")])
                : _vm._e(),
              _vm.question
                ? _c(
                    "Question",
                    {
                      attrs: {
                        question: _vm.question,
                        size: _vm.size,
                        "show-goto": false,
                      },
                      on: { deleted: _vm.deleted },
                    },
                    [
                      _vm.question.comments_count
                        ? _c("AnswerList", {
                            staticClass: "mt-20",
                            attrs: {
                              nested: true,
                              "full-pagination": true,
                              qnaId: _vm.question.id,
                              targetId: _vm.targetId,
                            },
                            on: { found: _vm.scrollToAnswer },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }