<template>
    <div class="answer card-base card-shadow--small" :class="[{ nested, highlight }, size]" :data-answer-id="answer.id">
        <div class="header flex">
            <QnaUser :user="answer.user" :show-user="showUser" :size="size" class="mr-10"></QnaUser>
            <QnaDatetime :infoAbout="answer" :size="size" class="box grow"></QnaDatetime>
            <QnaActions
                :item="answer"
                :nested="nested"
                itemType="answer"
                @deleted="$emit('deleted')"
                @restored="$emit('restored')"
            ></QnaActions>
        </div>
        <div class="content flex">
            <div class="text" v-if="answer.content">
                {{ answer.content }}
            </div>
            <div class="body-image" v-if="answer.image">
                <img :src="answer.image" alt="" />
            </div>
            <QnaInfo :infoAbout="answer" :size="size"></QnaInfo>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import _ from "lodash"

const QnaUser = () => import("./QnaUser")
const QnaInfo = () => import("./QnaInfo")
const QnaActions = () => import("./QnaActions")
const QnaDatetime = () => import("./QnaDatetime")

export default {
    props: {
        answer: {
            type: Object,
            required: true
        },
        showUser: {
            type: Boolean,
            default: true
        },
        nested: {
            type: Boolean,
            default: false
        },
        highlight: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            required: true,
            validator: val => ["large", "medium", "small"].indexOf(val) !== -1
        }
    },
    methods: {},
    components: {
        QnaUser,
        QnaInfo,
        QnaActions,
        QnaDatetime
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.answer {
    margin-bottom: 30px;
    padding: 16px;

    .content {
        flex-direction: column;

        .text {
            margin-top: 20px;
            background-color: $background-color;
            color: $color-black;
            padding: 15px 20px;
            border-radius: 8px;
            margin-bottom: 5px;
        }

        .body-image {
            margin-top: 10px;
            padding: 15px 15px;
            background-color: $background-color;
            margin-bottom: 5px;
            border-radius: 8px;
            display: inline-block;
            box-sizing: border-box;

            img {
                border-radius: 6px;
                max-height: 300px;
                max-width: 300px;
                display: block;
            }

            @media (max-width: 450px) {
                max-width: 100%;
                width: 100%;

                img {
                    max-height: inherit;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
    &.nested {
        box-shadow: none;
        background: transparent;

        .content {
            .text {
                background-color: #fff;
            }

            .body-image {
                background-color: #fff;
            }
        }
    }

    &.highlight {
        .content {
            .text {
                background-color: rgba($color-warning, 0.2);
            }

            .body-image {
                background-color: rgba($color-warning, 0.2);
            }
        }
    }

    &.small {
        .header {
            flex-direction: column;

            .info {
                min-width: unset;
                margin-bottom: 15px;
                margin-left: 30px;
            }
        }
    }
}
</style>
