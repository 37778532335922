var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "w-full",
      attrs: { clearable: true, placeholder: _vm.placeholder || "Seleziona" },
      model: {
        value: _vm.model,
        callback: function ($$v) {
          _vm.model = $$v
        },
        expression: "model",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }