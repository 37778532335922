var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template card-base card-shadow--small flex column" },
    [
      _c("div", { staticClass: "flex header justify-space-between" }, [
        _vm.message && _vm.message.datetime
          ? _c("div", { staticClass: "box grow tags" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dayjs")(
                      _vm.message.datetime,
                      "format",
                      "DD MMM YYYY, HH:mm"
                    )
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "box buttons" }, [
          _c(
            "button",
            {
              staticClass: "danger",
              on: {
                click: function ($event) {
                  return _vm.deleteTemplate(_vm.message._id)
                },
              },
            },
            [_c("i", { staticClass: "mdi mdi-delete-outline" })]
          ),
        ]),
      ]),
      _c("div", { staticClass: "flex body-box" }, [
        _vm.message.image
          ? _c("div", { staticClass: "image" }, [
              _c("img", { attrs: { src: _vm.message.image } }),
            ])
          : _vm._e(),
        _c("div", {
          staticClass: "body",
          domProps: { innerHTML: _vm._s(_vm.message.text) },
        }),
      ]),
      _c("div", { staticClass: "info flex justify-space-between" }, [
        _c("div", { staticClass: "box" }, [
          _vm.btnCount
            ? _c("span", [
                _vm._v(" Contiene "),
                _c("strong", [_vm._v(_vm._s(_vm.btnCount))]),
                _vm._v(
                  " " +
                    _vm._s(_vm.btnCount === 1 ? "pulsante" : "pulsanti") +
                    " "
                ),
              ])
            : _c("span", [
                _c("strong", [_vm._v("NON")]),
                _vm._v(" contiene pulsanti "),
              ]),
        ]),
        _c("div", { staticClass: "date box" }, [
          _c("span", [_vm._v(" creato il: ")]),
          _c("strong", [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("dayjs")(
                    _vm.message.created_at,
                    "format",
                    "DD MMM YYYY, HH:mm"
                  )
                ) +
                " "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }