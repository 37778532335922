<template>
    <div ref="box" class="qna-attachment-list flex column">
        <!-- @scroll="listScrollHandler" -->
        <resize-observer @notify="__resizeHandler" />
        <div class="list-wrap box grow flex column" v-loading="loading">
            <div class="list-box box grow scrollable only-y" ref="list">
                <div v-if="!items.length && !loading">Non sono presenti allegati</div>
                <QnaAttachment
                    v-for="item of items"
                    :key="item.uid"
                    :item="item"
                    @deleted="deleteItem(item.uid)"
                ></QnaAttachment>
            </div>
        </div>

        <!--        <div class="qna-box" v-if="loading">-->
        <!--            <div class="qna-content p-20 flex column" v-loading="true"></div>-->
        <!--        </div>-->
        <el-pagination
            class="card-base box card-shadow--small"
            ref="pagination"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="layout"
            :total="pagination.total"
            @size-change="loadAttachments"
            @current-change="loadAttachments"
            @prev-click="loadAttachments"
            @next-click="loadAttachments"
        >
            <ul class="el-pager">
                <li class="number active">{{ pagination.page }}</li>
            </ul>
        </el-pagination>
    </div>
</template>

<script>
import QnaAttachment from "@/components/Qna/QnaAttachment"
import api from "@/api"
import _ from "lodash"

export default {
    name: "AttachmentList",
    data() {
        return {
            items: [],
            loading: false,
            pagination: {
                page: 1,
                size: 50,
                sizes: [30, 50, 100],
                layout: this.layout,
                small: false
            },
            size: "large",
            fullPagination: true
            // stopGet: false
            // page: 0,
        }
    },
    props: {
        itemType: {
            type: String,
            required: true,
            default: "mixed",
            validator: val => ["question", "answer", "mixed"].indexOf(val) !== -1
        }
    },
    methods: {
        deleteItem(itemUid) {
            this.items = this.items.filter(i => i.uid !== itemUid)
            setTimeout(() => {
                this.$nextTick(() => {
                    const list = this.$refs.list
                    if (list && (list?.scrollHeight || 0) <= (list.offsetHeight || 0)) {
                        this.getNewPage()
                    }
                })
            }, 1000)
        },
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.box) {
                const width = this.$refs.box.offsetWidth
                this.fullPagination = true
                this.size = "large"
                if (width < 700) {
                    this.size = "small"
                    this.fullPagination = false
                } else if (width < 890) {
                    this.size = "medium"
                }
            }
        },
        loadAttachments() {
            const params = {
                pagination: this.pagination.size,
                page: this.pagination.page,
                attachments: true
            }

            switch (this.itemType) {
                case "question":
                    this.getAttachments(api.qna.getAll(params))
                    break
                case "answer":
                    this.getAttachments(api.qna.getComments(params))
                    break
                default:
                    this.getMixedAttachments(params)
            }
        },
        getAttachments(promise) {
            this.loading = true
            promise
                .then(result => {
                    this.items = _.get(result, "body.data", []).map(i => {
                        i.itemType = this.itemType
                        i.uid = this.itemType.charAt(0) + "-" + i.id
                        return i
                    })
                    this.pagination.total = result.body.pagination.totalElements
                    if (this.$refs.list) {
                        this.$refs.list.scrollTo({ top: 0 })
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getMixedAttachments() {
            this.loading = true
            Promise.all([api.qna.getAll(params), api.qna.getComments(params)])
                .then(result => {
                    const Q = _.get(result[0], "body.data", []).map(i => {
                        i.itemType = "question"
                        i.uid = "q-" + i.id
                        return i
                    })
                    const A = _.get(result[1], "body.data", []).map(i => {
                        i.itemType = "answer"
                        i.uid = "a-" + i.id
                        return i
                    })

                    this.pagination.total = Math.max(
                        result[0].body.pagination.totalElements,
                        result[1].body.pagination.totalElements
                    )

                    this.items = [...Q, ...A]

                    if (this.$refs.list) {
                        this.$refs.list.scrollTo({ top: 0 })
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        }
        /*
        listScrollHandler(ev) {
            const canGet = ev.target.scrollHeight - ev.target.offsetHeight === ev.target.scrollTop

            if (canGet && !this.loading) {
                this.getNewPage()
            }
        },
        getQnaAttachmentsOnScroll() {
            this.loading = true

            Promise.all([
                api.qna.getAll({ page: this.page, attachments: true }),
                api.qna.getComments({ page: this.page, attachments: true })
            ])
                .then(result => {
                    const Q = _.get(result[0], "body.data", []).map(i => {
                        i.itemType = "question"
                        i.uid = "q-" + i.id
                        return i
                    })
                    const A = _.get(result[1], "body.data", []).map(i => {
                        i.itemType = "answer"
                        i.uid = "a-" + i.id
                        return i
                    })

                    const list = [...Q, ...A]

                    if (list.length) {
                        this.items = [...this.items, ...list]
                        setTimeout(() => {
                            this.$nextTick(() => {
                                if (this.$refs.list.scrollHeight <= this.$refs.list.offsetHeight) {
                                    this.getNewPage()
                                }
                            })
                        }, 1000)
                    } else {
                        this.stopGet = true
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getNewPage() {
            if (!this.stopGet) {
                this.page++
                this.getQnaAttachmentsOnScroll()
            }
        }, */
    },
    computed: {
        layout() {
            if (this.fullPagination && this.size === "large") {
                return this.itemType === "mixed"
                    ? "->, prev, pager, next"
                    : "total, ->, prev, pager, next, jumper, sizes"
            } else {
                if (this.size === "small") {
                    return "->, prev, slot, next"
                } else {
                    return "->, prev, pager, next"
                }
            }
        }
    },
    mounted() {
        this.loadAttachments()
    },
    components: {
        QnaAttachment
    }
}
</script>

<style lang="scss" scoped>
.qna-attachment-list {
    height: 100%;

    .list-wrap {
        overflow: hidden;
        padding: 0 10px;
        margin: 0 -20px;

        .list-box {
            padding: 0 10px;
            margin: 0 -10px;
        }
    }

    .qna-box {
        width: 100%;
        clear: both;
        display: block;
        padding: 20px;
        box-sizing: border-box;
        background: #fff;
    }

    .el-pagination {
        margin-top: 10px;
        padding: 10px 20px;
        padding-right: 10px;
        margin-right: 5px;

        :deep {
            .el-pagination__sizes {
                margin-right: 0;

                .el-input {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
