<template>
	<div class="period card-base card-shadow--small">
		<strong>{{ startDate | dayjs("format", "DD MMMM") }}</strong>
		<small class="ml-5" v-if="!sameYear">{{ startDate | dayjs("format", "YYYY") }}</small>
		<span v-if="endDate">
			<span class="mh-5">-</span>
			<strong>{{ endDate | dayjs("format", "DD MMMM") }}</strong>
			<small class="ml-5">{{ endDate | dayjs("format", "YYYY") }}</small>
		</span>
	</div>
</template>

<script>
const Period = function (id, user_id, date, created_at, updated_at, fake) {
	this.id = id
	this.user_id = user_id
	this.date = date
	this.created_at = created_at
	this.updated_at = updated_at
	this.fake = fake
}

export default {
	name: "Period",
	props: {
		data: [Period, Object],
		flowDuration: Number
	},
	data() {
		return {}
	},
	computed: {
		startDate() {
			return this.data.date
		},
		endDate() {
			return this.flowDuration ? this.$dayjs(this.data.date).add(this.flowDuration, "d") : null
		},
		sameYear() {
			return this.endDate
				? this.$dayjs(this.startDate).format("YYYY") === this.$dayjs(this.endDate).format("YYYY")
				: false
		}
	},
	watch: {},
	methods: {},
	mounted() {},
	components: {}
}
</script>

<style lang="scss" scoped>
.period {
	display: inline-block;
	text-transform: capitalize;
	padding: 10px 20px;
}
</style>
