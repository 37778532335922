var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      staticClass: "main-navigation-menu",
      class: { "nav-collapsed": _vm.isCollapse },
      attrs: {
        "default-active": _vm.activeLink,
        mode: _vm.mode,
        collapse: _vm.isCollapse,
        "unique-opened": true,
        "background-color": "transparent",
      },
      on: {
        "update:defaultActive": function ($event) {
          _vm.activeLink = $event
        },
        "update:default-active": function ($event) {
          _vm.activeLink = $event
        },
        select: _vm.goto,
      },
    },
    [
      _c("el-menu-item", { attrs: { index: "/dashboard" } }, [
        _c("i", { staticClass: "mdi mdi-gauge" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Dashboard"),
        ]),
      ]),
      _c("div", { staticClass: "el-menu-item-group__title" }, [
        _c("span", [_vm._v("Utenti")]),
      ]),
      _c("el-menu-item", { attrs: { index: "/users" } }, [
        _c("i", { staticClass: "mdi mdi-account-multiple-outline" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Lista"),
        ]),
      ]),
      _c("el-menu-item", { attrs: { index: "/users-export" } }, [
        _c("i", { staticClass: "mdi mdi-download-box-outline" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Export"),
        ]),
      ]),
      _vm.can_ViewReviewsNav
        ? _c(
            "div",
            [
              _c("div", { staticClass: "el-menu-item-group__title" }, [
                _c("span", [_vm._v("Recensioni")]),
              ]),
              _c("el-menu-item", { attrs: { index: "/reviews/published" } }, [
                _c("i", { staticClass: "mdi mdi-file-check-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Pubblicate"),
                ]),
              ]),
              _c("el-menu-item", { attrs: { index: "/reviews/draft" } }, [
                _c("i", { staticClass: "mdi mdi-file-edit-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Bozze"),
                ]),
              ]),
              _c("el-menu-item", { attrs: { index: "/reviews" } }, [
                _c("i", { staticClass: "mdi mdi-file-plus-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Crea nuova"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.can_ViewBotNav
        ? _c(
            "div",
            [
              _c("div", { staticClass: "el-menu-item-group__title" }, [
                _c("span", [_vm._v("Canali")]),
              ]),
              _c("el-menu-item", { attrs: { index: "/channels/telegram" } }, [
                _c("i", { staticClass: "mdi mdi-send-circle-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Telegram"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "el-menu-item-group__title" }, [
        _c("span", [_vm._v("Domande & Risposte")]),
      ]),
      _c("el-menu-item", { attrs: { index: "/qna/questions" } }, [
        _c("i", { staticClass: "mdi mdi-account-question-outline" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Domande"),
        ]),
      ]),
      _c("el-menu-item", { attrs: { index: "/qna/answers" } }, [
        _c("i", { staticClass: "mdi mdi-reply-all-outline" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Risposte"),
        ]),
      ]),
      _c("el-menu-item", { attrs: { index: "/qna/question-attachments" } }, [
        _c("i", { staticClass: "mdi mdi-paperclip" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Allegati domande"),
        ]),
      ]),
      _c("el-menu-item", { attrs: { index: "/qna/answer-attachments" } }, [
        _c("i", { staticClass: "mdi mdi-paperclip" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Allegati risposte"),
        ]),
      ]),
      _c("div", { staticClass: "el-menu-item-group__title" }, [
        _c("span", [_vm._v("Chat")]),
      ]),
      _c("el-menu-item", { attrs: { index: "/chat/rooms" } }, [
        _c("i", { staticClass: "mdi mdi-forum-outline" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Stanze"),
        ]),
      ]),
      _c("el-menu-item", { attrs: { index: "/chat/attachments" } }, [
        _c("i", { staticClass: "mdi mdi-paperclip" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Allegati"),
        ]),
      ]),
      _c("el-menu-item", { attrs: { index: "/chat/reports" } }, [
        _c("i", { staticClass: "mdi mdi-message-alert-outline" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Segnalazioni"),
        ]),
      ]),
      _vm.can_ViewChatInstitutionalNav
        ? _c("el-menu-item", { attrs: { index: "/chat/institutional" } }, [
            _c("i", { staticClass: "mdi mdi-bullhorn-outline" }),
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Messaggi Istituzionali"),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "el-menu-item-group__title" }, [
        _c("span", [_vm._v("Supporto")]),
      ]),
      !_vm.hideOnTest
        ? _c("el-menu-item", { attrs: { index: "/support/requests" } }, [
            _c("i", { staticClass: "mdi mdi-email-outline" }),
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Richieste"),
            ]),
          ])
        : _vm._e(),
      _vm.can_ViewSupportTemplatesNav
        ? _c("el-menu-item", { attrs: { index: "/support/templates" } }, [
            _c("i", { staticClass: "mdi mdi-reply-outline" }),
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("Risposte preimpostate"),
            ]),
          ])
        : _vm._e(),
      _vm.can_ViewLogsNav
        ? _c(
            "div",
            [
              _c("div", { staticClass: "el-menu-item-group__title" }, [
                _c("span", [_vm._v("Logs")]),
              ]),
              _c("el-menu-item", { attrs: { index: "/activity-logs" } }, [
                _c("i", { staticClass: "mdi mdi-timeline-text-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Activity"),
                ]),
              ]),
              _c("el-menu-item", { attrs: { index: "/error-logs" } }, [
                _c("i", { staticClass: "mdi mdi-timeline-alert-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Errors"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.can_ViewSettingsNav
        ? _c(
            "div",
            { staticClass: "mt-30" },
            [
              _c("el-menu-item", { attrs: { index: "/settings" } }, [
                _c("i", { staticClass: "mdi mdi-cog-outline" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("Impostazioni"),
                ]),
              ]),
              _c(
                "el-menu-item",
                { attrs: { index: "https://obpanel.imamma.net/" } },
                [
                  _c("i", { staticClass: "mdi mdi-open-in-new" }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Vai su OB Panel"),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "collapse-menu-btn",
          class: { collapsed: _vm.isCollapse },
        },
        [
          _c(
            "button",
            {
              staticClass: "flex align-center",
              on: {
                click: function ($event) {
                  return _vm.$emit("collapse-nav-toggle")
                },
              },
            },
            [
              !_vm.isCollapse
                ? _c("span", [
                    _c("i", { staticClass: "mdi mdi-unfold-less-vertical" }),
                    _vm._v(" Comprimi "),
                  ])
                : _vm._e(),
              _vm.isCollapse
                ? _c("span", [
                    _c("i", { staticClass: "mdi mdi-unfold-more-vertical" }),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }