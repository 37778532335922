var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "weight card-base card-shadow--small" }, [
    _c("span", { staticClass: "date" }, [
      _c("strong", [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "DD MMMM"))),
      ]),
      _c("small", { staticClass: "ml-5" }, [
        _vm._v(_vm._s(_vm._f("dayjs")(_vm.data.date, "format", "YYYY"))),
      ]),
    ]),
    _c("span", { staticClass: "icon" }, [
      _c("strong", [_vm._v(_vm._s(_vm.data.weight))]),
      _vm._v(" g "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }