var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "page",
      staticClass: "page-users-list column scrollable only-y",
      class: { flex: !_vm.isMobile, overflow: _vm.isMobile, [_vm.size]: true },
    },
    [
      _c("resize-observer", { on: { notify: _vm.handleResize } }),
      _c(
        "div",
        {
          staticClass:
            "table-box card-base card-shadow--small box grow flex column",
          attrs: { id: "table-wrapper" },
        },
        [
          _vm.ready
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list, "max-height": _vm.height },
                  on: { "row-click": _vm.viewItem },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "Id",
                      prop: "id",
                      fixed: !_vm.pagination.small,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Username",
                      "min-width": "160",
                      prop: "username",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Email",
                      "min-width": "300",
                      prop: "email",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Nome", "min-width": "120", prop: "name" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Cognome",
                      "min-width": "120",
                      prop: "surname",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Telefono",
                      "min-width": "180",
                      prop: "phone",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Ruolo", "min-width": "80", prop: "role" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "roles color",
                                  class: scope.row.role,
                                },
                                [_vm._v(_vm._s(scope.row.role))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3278185442
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ready
            ? _c("el-pagination", {
                staticClass: "box",
                attrs: {
                  small: _vm.pagination.small,
                  "current-page": _vm.pagination.page,
                  "page-sizes": _vm.pagination.sizes,
                  "page-size": _vm.pagination.size,
                  layout: _vm.pagination.layout,
                  total: _vm.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pagination, "page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pagination, "page", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pagination, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pagination, "size", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "users-list__filters-drawer",
            title: "Filtri",
            visible: _vm.fitersDrawerOpened,
            modal: true,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.fitersDrawerOpened = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filters-box" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "..." },
                  model: {
                    value: _vm.id,
                    callback: function ($$v) {
                      _vm.id = $$v
                    },
                    expression: "id",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _c("div", { staticClass: "prepend-wrapper" }, [
                      _vm._v("Id"),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "..." },
                  model: {
                    value: _vm.username,
                    callback: function ($$v) {
                      _vm.username = $$v
                    },
                    expression: "username",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _c("div", { staticClass: "prepend-wrapper" }, [
                      _vm._v("Username"),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "..." },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _c("div", { staticClass: "prepend-wrapper" }, [
                      _vm._v("Email"),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "..." },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _c("div", { staticClass: "prepend-wrapper" }, [
                      _vm._v("Nome"),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "..." },
                  model: {
                    value: _vm.surname,
                    callback: function ($$v) {
                      _vm.surname = $$v
                    },
                    expression: "surname",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _c("div", { staticClass: "prepend-wrapper" }, [
                      _vm._v("Cognome"),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-input",
                {
                  attrs: { placeholder: "..." },
                  model: {
                    value: _vm.phone,
                    callback: function ($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _c("div", { staticClass: "prepend-wrapper" }, [
                      _vm._v("Telefono"),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.resetFilters } },
                [_vm._v("Cancella")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showDrawerPicker
        ? _c(
            "div",
            {
              staticClass: "drawer-picker",
              on: {
                click: function ($event) {
                  _vm.fitersDrawerOpened = true
                },
              },
            },
            [
              _vm.isFiltered
                ? _c("div", { staticClass: "notify-badge" })
                : _vm._e(),
              _vm._m(0),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-box" }, [
      _c("i", { staticClass: "mdi mdi-cog" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }