<template>
    <div class="msg-box">
        <div class="msg-content flex column">
            <div class="msg-info box">
                <div class="msg-author" @click="openUser(msg.author)" :style="{ color: msg.author.color }">
                    @{{ msg.author.username }}
                </div>
            </div>
            <div class="msg-body-img box grow">
                <vue-load-image>
                    <img slot="image" class="image" :src="msg.imageThumb" v-img="{ src: msg.image, title: msg.body }" />
                    <img slot="preloader" class="preloader" :src="require('@/assets/images/loading.png')" />
                    <img slot="error" class="error" :src="require('@/assets/images/img-not-found.svg')" />
                </vue-load-image>
            </div>
            <div class="msg-icons box">
                <el-tooltip :content="$dayjs(msg.date_sent).format('DD MMMM YYYY [alle] HH:mm')">
                    <div class="msg-time info-text"><i class="mdi mdi-history"></i></div>
                </el-tooltip>

                <el-tooltip :content="'Testo: ' + msg.body" placement="bottom-end">
                    <div class="has-body info-text" v-show="msg.body">
                        <i class="mdi mdi-message-text-outline"></i>
                    </div>
                </el-tooltip>

                <el-tooltip content="Vedi il messaggio in chat" placement="bottom-end">
                    <div class="btn-actions info-text" @click="openChat">
                        <i class="mdi mdi-comment-eye-outline"></i>
                    </div>
                </el-tooltip>

                <el-tooltip content="Segnala" placement="bottom-end">
                    <div class="btn-actions info-text" @click="report" v-show="!msg.reported">
                        <i class="mdi mdi-alert-outline"></i>
                    </div>
                </el-tooltip>

                <el-tooltip content="Già segnalato" placement="bottom-end">
                    <div class="is-reported warning-text" v-show="msg.reported">
                        <i class="mdi mdi-alert-outline"></i>
                    </div>
                </el-tooltip>

                <el-tooltip content="Elimina" placement="bottom-end" v-if="can_DeleteChatMessage">
                    <div class="btn-actions danger-text" @click="deleteMsg">
                        <i class="mdi mdi-delete-outline"></i>
                    </div>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
const VueLoadImage = () => import("vue-load-image")
import { openUserDialog } from "@/common"

export default {
    name: "MsgAttachment",
    props: ["msg"],
    data() {
        return {}
    },
    computed: {
        can_DeleteChatMessage() {
            return this.$store.getters.can_DeleteChatMessage
        }
    },
    methods: {
        openUser(author) {
            if (author.id) {
                openUserDialog({ id: author.id, user: author })
            }
        },
        openChat() {
            this.$emit("openChat")
        },
        report() {
            this.$emit("report")
        },
        deleteMsg() {
            this.$emit("delete")
        }
    },
    components: {
        "vue-load-image": VueLoadImage
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";

.msg-box {
    margin-bottom: 20px;
    margin-right: 20px;
    display: inline-block;

    .msg-content {
        height: 100%;
        background: $background-color;
        border-radius: 12px;
        transition: all 0.35s;
        padding: 10px;
        box-sizing: border-box;

        .msg-info {
            font-size: 12px;
            margin-bottom: 10px;

            .msg-author {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
                margin-right: 4px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .msg-body-img {
            overflow: hidden;

            .vue-load-image {
                overflow: hidden;
                height: 300px;

                img {
                    border-radius: 6px;
                    display: block;
                    //height: 100%;
                    max-height: 100%;
                    //min-height: 100%;
                    max-width: 100%;
                }
            }
        }

        .msg-icons {
            margin-top: 10px;

            .is-reported,
            .msg-time,
            .has-body,
            .btn-actions {
                display: inline-block;
                margin-right: 15px;
                font-size: 20px;
            }

            .btn-actions {
                cursor: pointer;
            }
        }

        &:hover {
            box-shadow: 0px 0px 0px 2px rgba($color-primary, 0.5), 0px 4px 20px -10px rgba(0, 0, 0, 0.5);
        }
    }
}

@media (max-width: 1000px) {
    .msg-box {
        width: 100%;
        display: block;

        .msg-content {
            .msg-body-img {
                .vue-load-image {
                    width: 100%;
                    height: auto;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
