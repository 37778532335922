import { render, staticRenderFns } from "./Configuration.vue?vue&type=template&id=f6095ba8&scoped=true&"
import script from "./Configuration.vue?vue&type=script&lang=js&"
export * from "./Configuration.vue?vue&type=script&lang=js&"
import style0 from "./Configuration.vue?vue&type=style&index=0&id=f6095ba8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6095ba8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/myfamilyplace/web/imamma-community-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f6095ba8')) {
      api.createRecord('f6095ba8', component.options)
    } else {
      api.reload('f6095ba8', component.options)
    }
    module.hot.accept("./Configuration.vue?vue&type=template&id=f6095ba8&scoped=true&", function () {
      api.rerender('f6095ba8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Configuration.vue"
export default component.exports