<template>
    <div class="page-chat-reports flex column" v-loading="loading" :class="size" ref="page">
        <resize-observer @notify="__resizeHandler" />

        <div class="flex box report-toolbar">
            <div class="card-base card-shadow--small box grow flex align-center card-stats">
                <div class="count box">{{ count }}</div>
                <div class="content box grow">
                    <div class="header">
                        <span>MESSAGGI DA CONTROLLARE</span>
                        <el-popover width="270" trigger="click" ref="clearDialog" v-if="can_PerformReportsActions">
                            <div>
                                <div class="break-word">
                                    Stai per segnare come non sospetti tutti i messaggi di tutte le stanze
                                </div>

                                <div class="mv-10">
                                    <el-button
                                        type="warning"
                                        size="mini"
                                        @click="
                                            $refs.clearDialog.showPopper = false
                                            removeBulk(reports, false)
                                        "
                                    >
                                        Elimina tutti <strong>TRANNE</strong> quelli in <strong>evidenza</strong>
                                    </el-button>
                                </div>
                                <div class="mv-10">
                                    <el-button
                                        type="warning"
                                        size="mini"
                                        @click="
                                            $refs.clearDialog.showPopper = false
                                            removeBulk(reports, true)
                                        "
                                    >
                                        Elimina tutti, <strong>ANCHE</strong> quelli in <strong>evidenza</strong>
                                    </el-button>
                                </div>
                                <div>
                                    <el-button size="mini" @click="$refs.clearDialog.showPopper = false">
                                        Annulla
                                    </el-button>
                                </div>
                            </div>
                            <span
                                class="ml-10 clickable"
                                slot="reference"
                                v-pure-tooltip.down-right="'Elimina tutti i report'"
                            >
                                <i class="mdi mdi-broom"></i>
                            </span>
                        </el-popover>
                    </div>
                    <div class="info flex">
                        <div class="box grow flex">
                            <div class="box mr-10">
                                <i class="mdi mdi-alert-outline fs-22"></i>
                            </div>
                            <div class="box grow flex column">
                                <span>Segnalati</span>
                                <strong>{{ reportedCount }}</strong>
                            </div>
                        </div>
                        <div class="box grow flex">
                            <div class="box mr-10">
                                <i class="mdi mdi-comment-search-outline fs-22"></i>
                            </div>
                            <div class="box grow flex column">
                                <span>Sospetti</span>
                                <strong> {{ suspiciousCount }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-base card-shadow--small box grow flex align-center">
                <el-select
                    v-model="userSelected"
                    filterable
                    placeholder="Seleziona utente"
                    clearable
                    popper-class="chat-reports-select"
                >
                    <el-option v-for="user in users" :key="user.username" :value="user.username" class="users-option">
                        <strong>({{ user.count }})</strong><span>@{{ user.username }}</span>
                    </el-option>
                </el-select>
            </div>

            <div class="card-base card-shadow--small box grow flex align-center">
                <el-select v-model="typeSelected" placeholder="Scegli tipologia" clearable>
                    <el-option :label="'Segnalazioni'" :value="'reported'"></el-option>
                    <el-option :label="'Sospetti'" :value="'suspicious'"></el-option>
                </el-select>
            </div>
            <div class="card-base card-shadow--small box grow flex align-center">
                <el-switch v-model="onlyPinned" active-text="Solo in evidenza"> </el-switch>
            </div>
        </div>

        <div class="rooms-list scrollable only-y box grow" ref="roomsList">
            <div v-for="room in rooms" :key="room.id" class="room-box" :class="{ collapsed: isRoomCollapsed(room.id) }">
                <div class="room-header flex">
                    <div class="room-report-count">{{ room.reports.length }}</div>
                    <div class="room-name" @click="toggleCollapseRoom(room.id)">
                        <span>{{ room.type }}</span>
                        <span>/</span>
                        <span>{{ room.name }}</span>
                    </div>
                    <div class="room-info" @click.stop="openRoomInfo(room.id)">
                        <i class="mdi mdi-information-outline"></i>
                    </div>

                    <el-popover width="270" trigger="click" :ref="`room-${room.id}`">
                        <div>
                            <div class="break-word">
                                Stai per segnare come non sospetti tutti i messaggi di questa stanza
                            </div>

                            <div class="mv-10">
                                <el-button
                                    type="warning"
                                    size="mini"
                                    @click="
                                        $refs[`room-${room.id}`][0].showPopper = false
                                        removeBulk(room.reports, false)
                                    "
                                >
                                    Elimina tutti <strong>TRANNE</strong> quelli in <strong>evidenza</strong>
                                </el-button>
                            </div>
                            <div class="mv-10">
                                <el-button
                                    type="warning"
                                    size="mini"
                                    @click="
                                        $refs[`room-${room.id}`][0].showPopper = false
                                        removeBulk(room.reports, true)
                                    "
                                >
                                    Elimina tutti, <strong>ANCHE</strong> quelli in <strong>evidenza</strong>
                                </el-button>
                            </div>
                            <div>
                                <el-button size="mini" @click="$refs[`room-${room.id}`][0].showPopper = false">
                                    Annulla
                                </el-button>
                            </div>
                        </div>
                    </el-popover>
                    <div
                        class="clickable"
                        v-popover="`room-${room.id}`"
                        v-pure-tooltip.down="'Elimina report stanza'"
                        v-if="can_PerformReportsActions"
                    >
                        <i class="mdi mdi-broom"></i>
                    </div>
                </div>
                <div class="reports-list" v-show="!isRoomCollapsed(room.id)">
                    <div
                        class="report-box"
                        v-for="report in room.reports"
                        :key="report._id"
                        :id="'r-' + report._id"
                        :class="{ 'show-context': report.showBeforeAfter, found: '#' + report._id === $route.hash }"
                    >
                        <div class="before-box" v-if="report.before.length && report.showBeforeAfter">
                            <div class="msg-box" v-for="before in report.before" :key="before._id">
                                <div class="msg-content">
                                    <div class="msg-info flex justify-space-between">
                                        <div class="flex box" v-if="before.author">
                                            <div class="msg-author" @click="openUser(before.author)">
                                                @{{ before.author.username }}
                                            </div>
                                            <div class="msg-time">
                                                {{ before.date_sent | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}
                                            </div>
                                        </div>
                                        <div class="msg-actions box flex">
                                            <div
                                                class="action"
                                                @click="toggleContext(report._id)"
                                                v-show="report.showBeforeAfter"
                                            >
                                                <el-tooltip
                                                    content="Nascondi il contesto del messaggio"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-eye-off-outline"></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="msg-body" v-if="before.body" v-html="before.body"></div>
                                    <div class="msg-body-img" v-if="before.type === 'img' || before.type === 'sticker'">
                                        <img :src="before.imageThumb" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="msg-box" :id="'m-' + report.message_id">
                            <div class="msg-content">
                                <div class="msg-info flex justify-space-between">
                                    <div class="flex box">
                                        <el-popover placement="top" width="270" trigger="click">
                                            <div
                                                v-for="(item, index) in report.reporters"
                                                :key="item._id"
                                                class="m-6 mb-10"
                                            >
                                                <div>@{{ item.username }}</div>
                                                <div class="o-050">
                                                    il
                                                    {{
                                                        report.dates_report[index]
                                                            | dayjs("format", "DD MMM YYYY [alle] HH:mm")
                                                    }}
                                                </div>
                                            </div>
                                            <div v-if="!report.reporters.length" class="m-6">
                                                messaggio segnalato automaticamente
                                            </div>

                                            <div class="msg-report-count" slot="reference">
                                                {{ report.reports_count }}
                                            </div>
                                        </el-popover>

                                        <div
                                            class="msg-author"
                                            @click="openUser(report.message.author)"
                                            :style="{ color: report.message.author.color }"
                                        >
                                            @{{ report.message.author.username }}
                                        </div>
                                        <div class="msg-time">
                                            {{
                                                report.message.date_sent | dayjs("format", "DD MMMM YYYY [alle] HH:mm")
                                            }}
                                        </div>
                                    </div>
                                    <div class="box flex">
                                        <div class="msg-actions flex">
                                            <div class="action" @click="openDetailDialog(report)">
                                                <el-tooltip content="Vedi dettaglio segnalazione" placement="top-end">
                                                    <i class="mdi mdi-information-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action"
                                                @click="toggleContext(report._id)"
                                                v-show="report.showBeforeAfter"
                                            >
                                                <el-tooltip
                                                    content="Nascondi il contesto del messaggio"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-eye-off-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action hide-mobile"
                                                @click="toggleContext(report._id)"
                                                v-show="!report.showBeforeAfter"
                                            >
                                                <el-tooltip
                                                    content="Vedi il contesto del messaggio"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-eye-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="action" @click="openChat(room.id, report.message._id)">
                                                <el-tooltip content="Vedi il messaggio in chat" placement="top-end">
                                                    <i class="mdi mdi-comment-eye-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="action" @click="copyReportUrl(report._id)">
                                                <el-tooltip content="Copia link report" placement="top-end">
                                                    <i class="mdi mdi-link-variant"></i>
                                                </el-tooltip>
                                            </div>

                                            <div
                                                class="action"
                                                @click="removeReport(report)"
                                                v-if="can_PerformReportsActions"
                                            >
                                                <el-tooltip content="Messaggio non sospetto" placement="top-end">
                                                    <i class="mdi mdi-comment-check-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action"
                                                @click="removeMessage(report)"
                                                v-if="can_PerformReportsActions"
                                            >
                                                <el-tooltip content="Elimina il messaggio" placement="top-end">
                                                    <i class="mdi mdi-delete-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action"
                                                @click="delMuteReport(report)"
                                                v-if="!report.isMuted && can_PerformReportsActions"
                                            >
                                                <el-tooltip
                                                    content="Elimina il messaggio e silenzia l'utente"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-volume-off"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action disabled"
                                                v-if="report.isMuted && can_PerformReportsActions"
                                            >
                                                <el-tooltip content="Utente già silenziato" placement="top-end">
                                                    <i class="mdi mdi-volume-off"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action"
                                                @click="delBanReport(report)"
                                                v-if="!report.isBanned && can_PerformReportsActions"
                                            >
                                                <el-tooltip
                                                    content="Elimina il messaggio e blocca l'utente"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-cancel"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action disabled"
                                                v-if="report.isBanned && can_PerformReportsActions"
                                            >
                                                <el-tooltip content="Utente già bloccato" placement="top-end">
                                                    <i class="mdi mdi-cancel"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action"
                                                v-if="!report.pinned && can_PerformReportsActions"
                                                @click="togglePin(report._id)"
                                            >
                                                <el-tooltip
                                                    content="Metti la segnalazione in evidenza"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-pin-outline"></i>
                                                </el-tooltip>
                                            </div>
                                            <div
                                                class="action"
                                                v-if="report.pinned && can_PerformReportsActions"
                                                @click="togglePin(report._id)"
                                            >
                                                <el-tooltip
                                                    content="Togli segnalazione in evidenza"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-pin-off-outline"></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                        <div class="msg-flags flex">
                                            <div class="action hide-on-hover" v-if="report.pinned">
                                                <el-tooltip content="Segnalazione in evidenza" placement="top-end">
                                                    <i class="mdi mdi-pin-outline warning-text"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="action" v-if="report.isReported">
                                                <el-tooltip content="Messaggio segnalato" placement="top-end">
                                                    <i class="mdi mdi-alert-outline danger-text"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="action" v-if="report.isSuspicious">
                                                <el-tooltip content="Messaggio sospetto" placement="top-end">
                                                    <i class="mdi mdi-comment-search-outline warning-text"></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="msg-body"
                                    v-if="report.message.body"
                                    v-html="$options.filters.selected(report.message.body, report.stop_words)"
                                ></div>
                                <div
                                    class="msg-body-img"
                                    v-if="report.message.type === 'img' || report.message.type === 'sticker'"
                                >
                                    <vue-load-image>
                                        <img
                                            slot="image"
                                            class="image"
                                            :src="report.message.imageThumb"
                                            v-img="{ src: report.message.image, title: report.message.body }"
                                        />
                                        <img
                                            slot="preloader"
                                            class="preloader"
                                            :src="require('@/assets/images/loading.png')"
                                        />
                                        <img
                                            slot="error"
                                            class="error"
                                            :src="require('@/assets/images/img-not-found.svg')"
                                        />
                                    </vue-load-image>
                                </div>
                            </div>
                        </div>
                        <div class="after-box" v-if="report.after.length && report.showBeforeAfter">
                            <div class="msg-box" v-for="after in report.after" :key="after._id">
                                <div class="msg-content">
                                    <div class="msg-info flex justify-space-between">
                                        <div class="flex box" v-if="after.author">
                                            <div class="msg-author" @click="openUser(after.author)">
                                                @{{ after.author.username }}
                                            </div>
                                            <div class="msg-time">
                                                {{ after.date_sent | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}
                                            </div>
                                        </div>
                                        <div class="msg-actions box flex">
                                            <div
                                                class="action"
                                                @click="toggleContext(report._id)"
                                                v-show="report.showBeforeAfter"
                                            >
                                                <el-tooltip
                                                    content="Nascondi il contesto del messaggio"
                                                    placement="top-end"
                                                >
                                                    <i class="mdi mdi-eye-off-outline"></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="msg-body" v-if="after.body" v-html="after.body"></div>
                                    <div class="msg-body-img" v-if="after.type === 'img' || after.type === 'sticker'">
                                        <img :src="after.imageThumb" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Dettaglio segnalazione" :visible.sync="dialogReportVisible" width="300">
            <div v-if="currentReport" class="report-dialog">
                <div class="data-info">
                    <span class="label">Stanza:</span>
                    <strong class="value room">
                        <span>{{ currentReport.room_type }}</span>
                        <span>/</span>
                        <span>{{ currentReport.room_name }}</span>
                    </strong>
                </div>
                <div class="data-info">
                    <span class="label">Autore:</span>
                    <strong
                        class="value clickable"
                        @click="openUser(currentReport.message.author)"
                        :style="{ color: currentReport.message.author.color }"
                        >@{{ currentReport.message.author.username }}</strong
                    >
                </div>
                <div class="data-info">
                    <span class="label">Data/ora messaggio:</span>
                    <strong class="value">
                        {{ currentReport.message.date_sent | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}
                    </strong>
                </div>
                <div class="data-info">
                    <span class="label">Data/ora prima segnalazione:</span>
                    <strong class="value">
                        {{ currentReport.date_insert | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}
                    </strong>
                </div>
                <div class="data-info">
                    <span class="label">Data/ora ultima segnalazione:</span>
                    <strong class="value">
                        {{ currentReport.date_update | dayjs("format", "DD MMMM YYYY [alle] HH:mm") }}
                    </strong>
                </div>
                <div class="data-info" v-if="!currentReport.reporters || !currentReport.reporters.length">
                    <span class="label">Messaggio segnalato automaticamente</span>
                </div>
                <div class="data-info" v-if="currentReport.reporters && currentReport.reporters.length">
                    <span class="label">Lista segnalazioni:</span>
                    <ul class="value">
                        <li v-for="(item, index) in currentReport.reporters" :key="item._id" class="m-6">
                            <strong class="value clickable" @click="openUser(item)" :style="{ color: item.color }">
                                @{{ item.username }}
                            </strong>
                            <span class="o-050 ml-10">
                                il
                                {{ currentReport.dates_report[index] | dayjs("format", "DD MMM YYYY [alle] HH:mm") }}
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="data-info" v-if="!currentReport.stop_words || !currentReport.stop_words.length">
                    <span class="label">Il messaggio <strong>NON</strong> contiene stop words</span>
                </div>
                <div class="data-info" v-if="currentReport.stop_words && currentReport.stop_words.length">
                    <span class="label">Contiene le seguenti stop words:</span>
                    <ul class="value">
                        <li v-for="word in currentReport.stop_words" :key="word">
                            <strong>{{ word }}</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div slot="footer">
                <el-button size="small" plain @click="dialogReportVisible = false">Chiudi</el-button>
            </div>
        </el-dialog>

        <el-dialog title="Elimina il messaggio e silenzia l'utente" :visible.sync="dialogDelMuteVisible" width="300">
            <div v-if="currentReport" class="report-dialog">
                <div class="mb-20">
                    @{{ currentReport.message.author.username }} (#{{ currentReport.message.author.id }})
                </div>
                <div class="text-left mb-10">
                    <div class="o-080">Durata in ore</div>
                    <el-input-number v-model="muteDuration" :min="1"></el-input-number>
                </div>
                <div>
                    <el-select v-model="muteReason" placeholder="Motivazione" clearable>
                        <el-option
                            v-for="item of penaltyReasons"
                            :key="item.penalty"
                            :label="item.penalty"
                            :value="item.penalty"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer">
                <el-button size="small" plain @click="dialogDelMuteVisible = false">Chiudi</el-button>
                <el-button @click="mute(currentReport)" type="warning" size="small" :disabled="muteDuration <= 0"
                    >Silenzia</el-button
                >
            </div>
        </el-dialog>

        <el-dialog title="Elimina il messaggio e blocca l'utente" :visible.sync="dialogDelBanVisible" width="300">
            <div v-if="currentReport" class="report-dialog">
                <div class="mb-20">
                    @{{ currentReport.message.author.username }} (#{{ currentReport.message.author.id }})
                </div>
                <div>
                    <el-select v-model="banReason" placeholder="Motivazione" clearable>
                        <el-option
                            v-for="item of penaltyReasons"
                            :key="item.penalty"
                            :label="item.penalty"
                            :value="item.penalty"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="footer">
                <el-button size="small" plain @click="dialogDelBanVisible = false">Chiudi</el-button>
                <el-button @click="ban(currentReport)" type="danger" size="small">Blocca</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash"
import api from "@/api"
const VueLoadImage = () => import("vue-load-image")
import { EventBus } from "@/event-bus"
import { openUserDialog, openRoomDialog } from "@/common"

export default {
    name: "ChatReports",
    data() {
        return {
            reports: [],
            loading: true,
            stickersList: [],
            collapsed: [],
            currentReport: null,

            dialogReportVisible: false,
            dialogDelMuteVisible: false,
            dialogDelBanVisible: false,

            clearRoomPopoverVisible: false,
            clearAllPopoverVisible: false,

            banReason: "",
            muteReason: "",
            muteDuration: 0,

            userSelected: "",
            typeSelected: "",
            onlyPinned: false,

            size: "large"
        }
    },
    computed: {
        rooms() {
            return _.chain(this.reports)
                .filter(r => {
                    let flag = true

                    if (this.onlyPinned) {
                        if (!r.pinned) {
                            flag = false
                        }
                    }

                    if (this.userSelected) {
                        if (r.username !== this.userSelected) {
                            flag = false
                        }
                    }

                    if (this.typeSelected) {
                        if (this.typeSelected === "reported" && !r.isReported) {
                            flag = false
                        }
                        if (this.typeSelected === "suspicious" && !r.isSuspicious) {
                            flag = false
                        }
                    }

                    return flag
                })
                .groupBy(r => r.room_id)
                .map(r => {
                    return {
                        id: r[0].room_id,
                        name: r[0].room_name,
                        type: r[0].room_type,
                        collapseReports: false,
                        reports: r
                    }
                })
                .value()
        },
        count() {
            return this.reports.length
        },
        reportedCount() {
            return _.filter(this.reports, r => r.isReported).length
        },
        suspiciousCount() {
            return _.filter(this.reports, r => r.isSuspicious).length
        },
        users() {
            return _.chain(this.reports)
                .groupBy(r => r.username)
                .map(r => {
                    return {
                        count: r.length,
                        username: r[0].username
                    }
                })
                .orderBy(r => r.count, "desc")
                .value()
        },
        penaltyReasons() {
            return this.$store.getters.penaltyReasons
        },
        can_PerformReportsActions() {
            return this.$store.getters.can_PerformReportsActions
        }
    },
    filters: {
        /**
         * @param {string} value
         * @param {string[]} sel
         *
         * @returns {string}
         */
        selected: function (value, words) {
            if (!value) return ""
            value = value.toString()

            if (!words || !words.length) return value

            for (const word of words) {
                const startIndex = value.toLowerCase().indexOf(word.toLowerCase())
                if (startIndex !== -1) {
                    const endLength = word.length
                    const matchingString = value.substr(startIndex, endLength)
                    value = value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
                }
            }
            //return value.toString().replace(new RegExp(sel,"gim"), `<span class="sel">${sel}</span>`)
            return value
        }
    },
    methods: {
        __resizeHandler: _.throttle(function (e) {
            this.calcDim()
        }, 700),
        calcDim() {
            if (this.$refs.page) {
                const width = this.$refs.page.offsetWidth

                this.size = "large"
                if (width < 370) {
                    this.size = "small"
                } else if (width < 960) {
                    this.size = "medium"
                }
            }
        },
        msgSanitizer(msg) {
            if (msg.body) {
                msg.body = msg.body.replace(/(?:\r\n|\r|\n)/g, "<br>")
            }

            if (msg.type === "sticker") {
                if (_.some(this.stickersList, s => s.name === msg.body)) {
                    msg.imageThumb = _.find(this.stickersList, s => s.name === msg.body).image
                    msg.body = ""
                }
            }

            return msg
        },
        parseReports(data) {
            const parsed = _.map(data, r => {
                r.reports_count = r.reporters.length
                r.username = r.message.author.username
                r.room_name = r.room.name
                r.room_type = r.room.roomType
                r.isReported = r.type === "reported"
                r.isSuspicious = r.stop_words && r.stop_words.length !== 0
                r.showBeforeAfter = false
                r.message = this.msgSanitizer(r.message)
                r.before = _.map(r.before, m => {
                    return this.msgSanitizer(m)
                })
                r.after = _.map(r.after, m => {
                    return this.msgSanitizer(m)
                })
                r.isMuted = r.author?.muted ? this.$dayjs(r.author.muted, "YYYY-MM-DD HH:mm").isSameOrAfter() : false
                r.isBanned = !!r.author?.banned
                r.userId = r.author?.id
                return r
            })
            const ordered = _.orderBy(parsed, r => r.reports_count, "desc")

            return ordered
        },
        getReports() {
            this.loading = true

            api.chat
                .getReports()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        this.reports = this.parseReports(res.body)
                        this.loading = false

                        if (this.$route.hash) {
                            setTimeout(() => {
                                this.scrollToReport("r-" + this.$route.hash.substring(1))
                            }, 500)
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        scrollToReport(id) {
            const el_box = this.$refs.roomsList
            const el_report = document.getElementById(id)
            const report_top = el_report?.offsetTop || 0

            if (el_box && el_box.offsetHeight && el_report && el_report.offsetHeight) {
                const scrollTo = report_top - (el_box.offsetHeight - el_report.offsetHeight) / 2

                if (scrollTo) {
                    setTimeout(() => {
                        this.$refs.roomsList.scrollTo({ top: scrollTo, behavior: "smooth" })
                    }, 0)
                }
            }
        },
        getStickers(cb) {
            api.chat
                .getStickers()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        let list = []

                        for (const pack of res.body) {
                            list = [...pack.stickers, ...list]
                        }

                        this.stickersList = list
                    }
                    if (cb && typeof cb === "function") {
                        cb()
                    }
                })
                .catch(err => {
                    console.error(err)
                    if (cb && typeof cb === "function") {
                        cb()
                    }
                })
        },
        openUser(author) {
            if (author.id) {
                openUserDialog({ id: author.id, user: author })
            }
        },
        openRoomInfo(id) {
            if (id) {
                openRoomDialog({ id })
            }
        },
        toggleCollapseRoom(id) {
            if (_.some(this.collapsed, r => r === id)) {
                this.collapsed = _.filter(this.collapsed, r => r !== id)
            } else {
                this.collapsed.push(id)
            }
        },
        isRoomCollapsed(id) {
            return _.some(this.collapsed, r => r === id)
        },
        openChat(roomId, messageId) {
            this.$router.push(`/chat/rooms/${roomId}/${messageId}`).catch(err => {})
        },
        copyReportUrl(id) {
            this.$clipboard(`${location.origin}/chat/reports#${id}`)
            this.$message.success("Link copiato")
        },
        openDetailDialog(report) {
            this.currentReport = report
            this.dialogReportVisible = true
        },
        delMuteReport(report) {
            this.currentReport = report
            this.dialogDelMuteVisible = true
        },
        delBanReport(report) {
            this.currentReport = report
            this.dialogDelBanVisible = true
        },
        togglePin(id) {
            const report = _.find(this.reports, r => r._id === id)
            const method = report.pinned ? "reportUnpin" : "reportPin"
            api.chat[method](report._id)
                .then(response => {
                    report.pinned = !report.pinned

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Adesso la segnalazione è in evidenza`
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.$notify.error({
                        title: "Ops...",
                        message: "La segnalazione non è stata messa in evidenza"
                    })
                })
        },
        toggleContext(id) {
            const report = _.find(this.reports, r => r._id === id)

            if (report.showBeforeAfter) {
                report.showBeforeAfter = false
            } else {
                if (report.before.length || report.after.length) {
                    report.showBeforeAfter = true
                } else {
                    api.chat
                        .getReportsById(id)
                        .then(res => {
                            if (res.status === 200 && res.body) {
                                const reports = this.parseReports(res.body)

                                report.before = reports[0].before
                                report.after = reports[0].after

                                report.showBeforeAfter = !report.showBeforeAfter

                                if (report.showBeforeAfter) {
                                    setTimeout(() => {
                                        const el_report = window.document.getElementById(`r-${id}`)
                                        const el_msg = window.document.querySelector(`#r-${id} #m-${report.message_id}`)
                                        const top_msg = el_msg?.offsetTop || 0

                                        if (el_report && el_report.offsetHeight && el_msg && el_msg.offsetHeight) {
                                            const scrollTo =
                                                top_msg - (el_report.offsetHeight - el_msg.offsetHeight) / 2

                                            if (scrollTo) {
                                                el_report?.scrollTo({
                                                    top: scrollTo,
                                                    behavior: "smooth"
                                                })
                                                this.scrollToReport(`r-${id}`)
                                            }
                                        }
                                    }, 300)
                                }
                            }
                        })
                        .catch(err => {
                            console.error(err)
                        })
                }
            }
        },

        action_deleteReport(report) {
            this.$message("Sto eliminando la segnalazione...")

            return api.chat
                .removeReport(report._id)
                .then(response => {
                    this.reports = _.filter(this.reports, r => r._id !== report._id)

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Segnalazione eliminata`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "La segnalazione non è stata eliminata"
                    })
                })
        },

        action_deleteMessage(report) {
            this.$message("Sto eliminando il messaggio...")

            return api.chat
                .deleteMessage(report.message_id, report.room_id)
                .then(response => {
                    this.$notify.success({
                        title: "Fatto!",
                        message: `Messaggio eliminato`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Il messaggio non è stato eliminato"
                    })
                })
        },

        action_muteUser(report, muteReason, muteDuration) {
            this.$message("Sto silenziando l'utente...")

            return api.users
                .mute(report.message.author.id, muteReason, muteDuration)
                .then(response => {
                    this.reports = _.map(this.reports, r => {
                        if (r.message.author.id === report.message.author.id) {
                            r.isMuted = true
                        }
                        return r
                    })

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Utente @${report.message.author.username} (#${report.message.author.id}) silenziato correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore silenziamento utente @${report.message.author.username} (#${report.message.author.id})`
                    })
                })
        },

        action_banUser(report, banReason) {
            this.$message("Sto bloccando l'utente...")

            return api.users
                .ban(report.message.author.id, banReason)
                .then(response => {
                    this.reports = _.map(this.reports, r => {
                        if (r.message.author.id === report.message.author.id) {
                            r.isBanned = true
                        }
                        return r
                    })

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Utente @${report.message.author.username} (#${report.message.author.id}) bloccato correttamente`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: `Errore blocco utente @${report.message.author.username} (#${report.message.author.id})`
                    })
                })
        },

        mute(report) {
            if (this.muteDuration) {
                const muteReason = this.muteReason
                const muteDuration = this.muteDuration
                this.closeMuteBanDialog()

                this.action_muteUser(report, muteReason, muteDuration)
                    .then(() => {
                        return this.action_deleteMessage(report)
                    })
                    .then(() => {
                        return this.action_deleteReport(report)
                    })
            }
        },
        ban(report) {
            const banReason = this.banReason
            this.closeMuteBanDialog()

            this.action_banUser(report, banReason)
                .then(() => {
                    return this.action_deleteMessage(report)
                })
                .then(() => {
                    return this.action_deleteReport(report)
                })
        },
        removeReport(report) {
            this.$confirm("Sicura di voler segnalare il messaggio come NON sospetto ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning"
            })
                .then(() => {
                    this.action_deleteReport(report)
                })
                .catch(() => {})
        },
        removeMessage(report) {
            this.$confirm("Sicura di voler eliminare il messaggio ?", "Attenzione", {
                confirmButtonText: "Si",
                cancelButtonText: "Annulla",
                type: "warning",
                confirmButtonClass: "el-button--danger"
            })
                .then(() => {
                    this.action_deleteMessage(report).then(() => {
                        return this.action_deleteReport(report)
                    })
                })
                .catch(() => {})
        },

        closeMuteBanDialog() {
            this.dialogDelMuteVisible = false
            this.dialogDelBanVisible = false
            this.banReason = ""
            this.muteReason = ""
            this.muteDuration = 1
        },

        /**
         * @param {report[]} list reports list
         * @param {boolean} pinned remove also pinned
         *
         * @returns {void}
         */
        removeBulk(list, pinned = false) {
            this.$message("Sto eliminando le segnalazioni...")

            const ids = _.chain(list)
                .filter(r => {
                    if (!pinned && r.pinned === true) {
                        return false
                    }
                    return true
                })
                .map(r => r._id)
                //.join(",")
                .value()

            return api.chat
                .removeReport(ids)
                .then(response => {
                    this.getReports()

                    this.$notify.success({
                        title: "Fatto!",
                        message: `Segnalazioni eliminate`
                    })
                })
                .catch(err => {
                    this.$notify.error({
                        title: "Ops...",
                        message: "Le segnalazioni non sono state eliminate"
                    })
                })
        }
    },
    created() {},
    mounted() {
        this.calcDim()

        this.getStickers(() => {
            this.getReports()
        })

        const query_type = _.get(this.$route, "query.type")
        if (query_type === "reported" || query_type === "suspicious") {
            this.typeSelected = query_type
        }
        if (query_type === "pinned") {
            this.onlyPinned = true
        }

        EventBus.$on("update:user", data => {
            if (data.id) {
                if (data.muted !== undefined) {
                    this.reports = _.map(this.reports, r => {
                        if (r.message.author.id === data.id) {
                            r.isMuted = data.muted
                        }
                        return r
                    })
                }
                if (data.banned !== undefined) {
                    this.reports = _.map(this.reports, r => {
                        if (r.message.author.id === data.id) {
                            r.isBanned = data.banned
                        }
                        return r
                    })
                }
            }
        })
    },
    beforeDestroy() {},
    components: {
        "vue-load-image": VueLoadImage
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
@import "@/assets/scss/card-shadow";

.page-chat-reports {
    [aria-label][data-balloon-length="small"]:after {
        white-space: nowrap;
        width: inherit;
        font-family: inherit !important;
        font-size: 14px !important;
    }

    .report-toolbar {
        margin: 0 -10px;
        margin-bottom: 20px;

        .card-base {
            margin: 0 10px;
            padding: 20px;

            .el-select {
                max-width: 100%;
                width: 100%;
            }
            .el-switch {
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                padding: 9px 15px;
                max-width: 100%;
                width: 100%;
            }
        }
        .card-stats {
            padding: 6px 0;
            min-width: 350px;

            .count {
                color: $color-primary;
                font-weight: bold;
                font-size: 30px;
                padding: 20px;
            }
            .header {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 6px;

                .clickable {
                    opacity: 0.6;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                        opacity: 1;
                    }
                }
            }
            .info {
                font-size: 14px;
                opacity: 0.7;

                & > .box {
                    background: rgba($color-warning, 0.2);
                    margin-right: 10px;
                    border-radius: 4px;
                    padding: 4px 10px;
                }
            }
        }
    }

    .report-count {
        background: $color-warning;
        color: #fff;
        font-size: 12px;
        font-family: monospace;
        font-weight: bold;
        width: 28px;
        min-width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 50%;
        text-align: center;
        box-shadow: 0 3px 6px 0 rgba($color-warning, 0.35), 0 1px 1px 0 rgba(255, 255, 255, 0.3);
    }

    .rooms-list {
        margin: 0 -15px;
        padding: 0px 10px;
        padding-left: 15px;
        position: relative;

        .room-box {
            margin: 50px 0;
            background: #fff;
            border-radius: 5px;
            @extend .card-shadow--small;
            padding: 6px;
            transition: all 0.35s;

            .room-header {
                .room-report-count {
                    @extend .report-count;
                    margin-top: 4px;
                    margin-left: -20px;
                    margin-right: -6px;
                    font-size: 14px;
                    width: 34px;
                    min-width: 34px;
                    height: 34px;
                    line-height: 34px;
                    z-index: 1;
                }
                .room-name {
                    padding: 10px;
                    cursor: pointer;

                    span {
                        margin: 0 4px;

                        &:first-child {
                            text-transform: capitalize;
                        }
                        &:last-child {
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
                .room-info {
                    padding: 10px;
                    cursor: pointer;
                    padding-left: 0;
                }
                .clickable {
                    opacity: 0.6;
                    cursor: pointer;
                    padding: 10px;

                    &:hover {
                        text-decoration: underline;
                        opacity: 1;
                    }
                }
            }

            .reports-list {
                padding: 10px;

                .report-box {
                    box-sizing: border-box;
                    transition: all 0.35s;
                    border-radius: 12px;
                    position: relative;

                    .msg-box {
                        margin-bottom: 20px;

                        .msg-content {
                            text-align: left;
                            background: $background-color;
                            border-radius: 12px;
                            transition: all 0.35s;

                            .msg-info {
                                padding: 10px;
                                padding-bottom: 0;
                                font-size: 14px;
                                width: 100%;
                                box-sizing: border-box;

                                .msg-report-count {
                                    @extend .report-count;
                                    margin-top: -5px;
                                    margin-left: -22px;
                                    margin-right: 5px;
                                    cursor: pointer;
                                }

                                .msg-author {
                                    font-weight: bold;
                                    margin-right: 10px;
                                    cursor: pointer;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                                .msg-time {
                                    opacity: 0.5;
                                    text-transform: capitalize;
                                }
                                .msg-actions,
                                .msg-flags {
                                    font-size: 20px;
                                    margin-top: -5px;
                                    opacity: 0;
                                    transition: all 0.2s;

                                    .action {
                                        margin-left: 20px;
                                        cursor: pointer;
                                        opacity: 0.7;
                                        outline: none;

                                        &.disabled {
                                            opacity: 0.3;
                                            cursor: no-drop;
                                        }
                                    }
                                }
                                .msg-flags {
                                    opacity: 1;
                                }
                            }

                            .msg-body {
                                padding: 10px;
                            }
                            .msg-body-img {
                                padding: 10px;

                                img {
                                    border-radius: 6px;
                                    display: block;
                                    max-width: 300px;
                                    width: 100%;
                                }
                            }

                            &:hover {
                                .msg-actions {
                                    opacity: 1;
                                }
                                .msg-flags {
                                    .hide-on-hover {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &.found {
                        .msg-box {
                            .msg-content {
                                box-shadow: 0px 0px 0px 2px rgba($color-warning, 0.5),
                                    0px 4px 20px -10px rgba(0, 0, 0, 0.5);
                            }
                        }
                    }

                    & > .msg-box {
                        .msg-content {
                            .msg-body {
                                padding: 20px;
                                padding-top: 10px;
                            }
                            .msg-body-img {
                                padding: 20px;
                                padding-top: 10px;
                            }
                            &:hover {
                                box-shadow: 0px 0px 0px 2px rgba($color-primary, 0.5),
                                    0px 4px 20px -10px rgba(0, 0, 0, 0.5);
                            }
                        }
                    }

                    .before-,
                    .after- {
                        &box {
                            .msg-box {
                                .msg-content {
                                    .msg-info {
                                        font-size: 12px;
                                    }
                                    .msg-body {
                                        padding-top: 0;
                                        font-size: 14px;
                                    }
                                    .msg-body-img {
                                        padding-top: 0px;

                                        img {
                                            max-width: 250px;
                                            max-height: 250px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.show-context {
                        padding: 20px 15px;
                        background: white;
                        margin: 20px 0;
                        border: 2px solid rgba($color-primary, 0.5);
                        @extend .card-shadow--medium;
                        max-height: 700px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch !important;

                        & > .msg-box {
                            .msg-content {
                                background: $color-primary;
                                color: white;

                                .msg-info {
                                    .msg-author {
                                        color: white !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.collapsed {
                margin: 14px 0;
            }

            &:first-child {
                margin-top: 0px;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    .report-dialog {
        font-size: 16px;

        .data-info {
            margin-bottom: 10px;
            background: rgba($color-primary, 0.05);
            padding: 10px 20px;
            border-radius: 6px;

            .label {
                margin-right: 10px;
            }
            .value {
                &.room {
                    span {
                        margin: 0 4px;

                        &:first-child {
                            text-transform: capitalize;
                        }
                        &:last-child {
                            font-weight: bold;
                        }
                    }
                }
                &.clickable {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .el-select {
            width: 100%;
        }
    }

    &.medium,
    &.small {
        overflow-x: hidden !important;
        overflow-y: auto !important;
        flex: inherit;
        display: block;

        [aria-label][data-balloon-pos="down"]:before,
        [aria-label][data-balloon-pos="down-right"]:before,
        [aria-label][data-balloon-length="small"]:after {
            display: none;
        }

        .report-toolbar {
            flex-direction: column;
            -webkit-box-orient: vertical;

            .card-base {
                margin-bottom: 10px;
                padding: 0;

                &.card-stats {
                    padding: 10px 0;
                }
            }
        }

        .rooms-list {
            overflow: hidden;

            .room-box {
                .reports-list {
                    .report-box {
                        .msg-box {
                            .msg-content {
                                .msg-info {
                                    flex-direction: column;
                                    -webkit-box-orient: vertical;

                                    .msg-actions {
                                        margin-top: 10px;
                                        margin-left: -10px;
                                        opacity: 1;
                                        display: inline-block;

                                        .action {
                                            display: inline-block;
                                            margin-top: 5px;

                                            /* &.hide-mobile {
                                            	 display: none;
                                            } */
                                        }
                                    }
                                    .msg-flags {
                                        margin-top: 10px;

                                        .action {
                                            margin-top: 5px;

                                            &.hide-on-hover {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.small {
        .report-toolbar {
            .card-base {
                &.card-stats {
                    min-width: inherit;

                    .count {
                        font-size: 22px;
                        padding: 10px;
                    }
                    .header {
                        font-size: 12px;

                        .clickable {
                            margin-left: 20px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables";

.chat-reports-select {
    .users-option {
        strong {
            width: 30px;
            display: inline-block;
            text-align: right;
            margin-right: 10px;
        }
    }
}

.page-chat-reports {
    .rooms-list {
        .room-box {
            .reports-list {
                .report-box {
                    .msg-box {
                        .msg-content {
                            .msg-body {
                                .sel {
                                    background: transparentize($color-warning, 0.8);
                                    border-radius: 5px;
                                    //text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.medium,
    &.small {
        .report-toolbar {
            .el-select {
                .el-input__inner {
                    border: none;
                }
            }
            .el-switch {
                border: none !important;
            }
        }
    }
}
</style>
